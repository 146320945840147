import React from "react";

import amplitude from "amplitude-js";
import {connect} from "react-redux";


class FakeAmplitude {
  logEvent = (eventType, params) => {
    console.log(`[amp] [${this._userId}] ${eventType}`, params)
  };
  
  setUserId = (userId) => {
    this._userId = userId
  }
}

const AmplitudeContext = React.createContext();

export const AmplitudeProvider = connect(
  state => ({
    anonId: (state.user.currentUser||{}).anonId,
  })
)(function AmplitudeProvider({anonId, children}) {

  const [instance, setInstance] = React.useState(null);

  React.useEffect(() => {
    if (window.__env__.backend === 'prod') {
      const project = amplitude.getInstance();
      const API_KEY = "b61e36320549ee172a0b0ec5c4619ab5";
      project.init(API_KEY);
      setInstance(project)
    } else if (window.__env__.backend === 'candidate') {
      const project = amplitude.getInstance();
      const API_KEY = "b61e36320549ee172a0b0ec5c4619ab5";
      project.init(API_KEY);
      setInstance(project)
    } else {
      setInstance(new FakeAmplitude())
      // var project = amplitude.getInstance();
      // const API_KEY = "b61e36320549ee172a0b0ec5c4619ab5";
      // project.init(API_KEY);
      // setInstance(project)
    }
  }, []);

  React.useEffect(() => {
    if (instance && anonId) {
      instance.setUserId(anonId);
    }
  }, [instance, anonId]);

  return <AmplitudeContext.Provider value={instance}>{children}</AmplitudeContext.Provider>
});

export function useAmplitude() {
  return React.useContext(AmplitudeContext);
}
