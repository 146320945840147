import React from "react";
import {connect} from "react-redux";

const UserContext = React.createContext();

export function useUser() {
  return React.useContext(UserContext);
}

export const UserProvider = connect(
  state => ({ user: state.user.currentUser })
)(
  function UserProvider ({user, children}) {
    return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
  }
);
