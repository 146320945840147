import React from "react";

import ReactModal from "react-modal";
import styled from "styled-components";
import {Link} from "react-router-dom";

import {Spinner} from "./primitives/things";
import {linkblue} from "../styles/styleConsts";
import ErrorBoundary from "./error_boundary";
import logoImage from "../images/dna-white.png"

export function Modal({ overlayStyles = {}, contentStyles = {}, children }) {
  return (
    <ReactModal
      isOpen={true}
      style={{
        overlay: {
          zIndex: 90000,
          backgroundColor: "rgba(0,0,0,0.4)",
          ...overlayStyles
        },
        content: {
          padding: 0,
          ...contentStyles
        }
      }}
      onAfterOpen={() => {}}
      ariaHideApp={ false }
    >
      {children}
    </ReactModal>
  );
}

const StyledOverlay = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 15px 15px 15px 15px;

  .closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 12px;
    font-weight: bold;
    padding: 4px 16px;
    text-decoration: none;
    color: red;
  }

  .row {
    display: flex;
    flex-direction: row;
    padding: 5px;
  }
  .title {
    flex-grow: 1;
    padding: 0 10px;
  }
  
  .popupNavLink {
    display: inline-block;
    color: ${linkblue};
    padding: 0 3px;
    text-decoration: none;
  }
  .popupNavLink:nth-of-type(2) {
    :before {
      content: " | ";
    }
  }
`;


export function RoutedModal({
  isOpen,
  isLoadingContent,
  closeLinkTo,
  overlayStyles = {},
  contentStyles = {},
  onAfterOpen,
  children
}) {
  return (
    <ReactModal
      isOpen={isOpen}
      style={{
        overlay: {
          zIndex: 90000,
          backgroundColor: "rgba(0,0,0,0.4)",
          ...overlayStyles
        },
        content: {
          padding: 0,
          ...contentStyles
        }
      }}
      onAfterOpen={onAfterOpen}
      ariaHideApp={ false }
    >
      <ErrorBoundary>
      <StyledOverlay>
        <Link className="closeButton" to={closeLinkTo}>
          Close
        </Link>
        {isLoadingContent ? (
          <Spinner />
        ) : (
          <div className="overlayContents">{children}</div>
        )}
      </StyledOverlay>
      </ErrorBoundary>
      <CornerLogo/>
    </ReactModal>
  );
}


const StyledCornerLogo = styled.div`
    position: fixed;
    width: 50px;
    height: 50px;
    bottom: 80px;
    right:80px;
    background-color: ${'#666'};
  
  img {
    width: 100%;
    height: 100%;
    border: 1px solid ${'#666'};
  }
`;

export function CornerLogo() {
  return <StyledCornerLogo><img alt="" src={logoImage} /></StyledCornerLogo>
}
