import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import { createSelector } from "reselect";

import { dangerAction } from "../styles/styleConsts";

import { lighten } from "polished";

import _ from "lodash";

import posed, { PoseGroup } from "react-pose";

const Styled = styled.div`
  position: absolute;
  top: 0;
  //left: calc(50% - 150px + 41px);
  left: 0;
  z-index: 1000;
  width: 200px;
  
  li {
    
   background-color: ${lighten(0.1, dangerAction)};
  color: white;
  font-weight: bold;
  padding: 5px 10px;
  margin-bottom: 5px; 
  
  &:hover {
    opacity: 0.4;
  }

  ::before {
    content: "⚠️";
    display: inline-block;
    margin-right: 5px;
    opacity: 0.8;
    zoom: 1.7;
    position: relative;
    top: 3px;
    margin-top: -8px;
  }
  
  ::after {
    content: "x";
    float: right;
    font-weight: normal;
  } 
`;

const Anim = posed.li({
  enter: {
    translateX: 0,
    translateY: 0,
    scale: 1,
    opacity: 1,
    transition: { type: "spring", stiffness: 50 }
  },
  preEnter: { translateX: 0, translateY: -40, scale: 0.6, opacity: 0.4 }
});

class _ErrorMessages extends React.Component {
  constructor(props) {
    super(props);
    this.timeouts = {};
    this.manageTimeouts = this.manageTimeouts.bind(this);
  }

  manageTimeouts(messages) {
    const msgIds = {};
    messages.forEach(msg => {
      if (!this.timeouts[msg.uid]) {
        this.timeouts[msg.uid] = setTimeout(
          () => this.props.handleClear(msg.uid),
          6000
        );
      }
      msgIds[msg.uid] = true;
    });
    Object.keys(this.timeouts).forEach(k => {
      if (!msgIds[k]) {
        try {
          clearTimeout(this.timeouts[k]);
        } catch (e) {
          console.warn("Failed to clear error mesage timeout ", k, e);
        }
        delete this.timeouts[k];
      }
    });
  }

  componentDidMount() {
    this.manageTimeouts(this.props.messages);
  }

  componentDidUpdate() {
    this.manageTimeouts(this.props.messages);
  }

  componentWillUnmount() {
    this.manageTimeouts([]);
  }

  render() {
    const { messages, handleClear } = this.props;
    return (
      <Styled>
        <ul>
          <PoseGroup preEnterPose="preEnter">
            {messages.map(message => (
              <Anim key={message.uid} onClick={() => handleClear(message.uid)}>
                {message.message}
              </Anim>
            ))}
          </PoseGroup>
        </ul>
      </Styled>
    );
  }
}


const THIS_IS_DISABLED = []

const selectErrors = createSelector(
  state => THIS_IS_DISABLED, //state.ui.errorMessages,
  errorMessages => errorMessages.slice(0).reverse()
);

export const ErrorMessages = connect(
  state => {
    return {
      messages: selectErrors(state)
    };
  },
  dispatch => {
    return {
      handleClear: messageId =>
        dispatch({ type: "errorMessages/clear", messageId })
    };
  }
)(_ErrorMessages);

const errorMessageMapping = {};

export function reducer(state = [], action) {
  switch (action.type) {
    case "errorMessages/clear":
      return state.filter(msg => msg.uid !== action.messageId);

    case "errorMessages/clearAll":
    case "loginStarted":
      return [];

    case "fetchUserFailed":
      return state;
    default:
      break;
  }
  if (action.originalActionType && action.type.endsWith("Failed")) {
    const message =
      errorMessageMapping[action.originalActionType] || action.error;
    return state.concat([{ message, uid: _.uniqueId() }]);
  }
  return state;
}

export default ErrorMessages;
