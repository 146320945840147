import { useRef, useState, useEffect, useDebugValue, useCallback } from "react";
import hash from 'object-hash';
import {useReduxDispatch} from "../reducers/ReduxHooks";

/**
 * This hook provides a function, that looks like
 * http fetch, but uses the redux api middleware, so permissioning
 * and logout / user credentials are handled.
 *
 * The method returns a promise, that will resolve with the json or
 * text body if a 2xx or reject with the error body.
 *
 * xxx: This hook is a workaround for (1) our api middleware not returning a promise
 * like how redux-thunk does, and (2) wanting to put every api request through
 * the store so that we can show the login page / clear expired
 * credentials as needed.
 */
export const useFetchPromise = (actionName) => {
  const reduxDispatch = useReduxDispatch();
  const fetcher = useCallback((url, options) => {
    return new Promise((resolve, reject) => {
      reduxDispatch({
          type: actionName || 'useFetchPromise',
          api: {
            resource: url,
            options,
            thenFunc: resolve,
            onError: reject,
          }
        });
    });
    // eslint-disable-next-line
  }, [reduxDispatch]);
  return fetcher;
};

/**
 * This hook provides a fetching lifecycle in a similar style to the upcoming React.Suspense
 * feature.  A component can use
 * @param url
 * @param options
 * @returns {
 *   isLoading: boolean,
 *   data: json object returned in body of request,
 *   refresh: function that can re-run the fetch. useful to refresh results
 *   error: any
 * }
 */
export const useJsonFetch = (url, options) => {
  options = options || {};
  const isMounted = useRef(true);
  const [isLoading, setIsLoading] = useState(false);
  const [jsonData, setJsonData] = useState((options).stub || null);
  const [error, setError] = useState(null);
  const [refreshCount, setRefreshCount] = useState(0);
  const reduxDispatch = useReduxDispatch();

  useDebugValue(
    `url: ${url} ${isLoading ? "Loading..." : error ? error : jsonData}`
  );

  const optionsHash = hash(options);


  useEffect(() => {

    if (!url || options.stub) {
      return;
    }

    setIsLoading(true);

    if (options.body && typeof options.body === "object") {
      options.body = JSON.stringify(options.body);
    } else if (options.json) {
      options.body = JSON.stringify(options.json);
    }

    // xxx: refactor to use useFetchPromise
    reduxDispatch({
      type: 'useFetch',
      api: {
        resource: url,
        options,
        thenFunc: jsonData => {
          if (!isMounted.current) {
            return;
          }

          setJsonData(jsonData);
          setError(null);
          setIsLoading(false);
        },
        onError: e => {
          if (!isMounted.current) {
            return;
          }

          setError(String(e));
          setJsonData(null);
          setIsLoading(false);
        }
      }
    });
    // 'optionsHash' covers the use of 'options' object in dependencies
    // eslint-disable-next-line
  }, [url, optionsHash, refreshCount]);

  useEffect(() => () => (isMounted.current = false), []);

  const refresh = () => setRefreshCount(refreshCount + 1);

  return {
    isLoading,
    data: jsonData,
    error,
    refresh,
  };
};
