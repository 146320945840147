export function dfdt(dataMatrix, applyVelocity, useDaysSinceThisDate) {
  if (!dataMatrix) {
    return dataMatrix;
  }

  if (!applyVelocity && !useDaysSinceThisDate) {
    return dataMatrix;
  }

  const startDaySinceEpoch = useDaysSinceThisDate
    ? Math.floor(useDaysSinceThisDate.getTime() / (24 * 3600 * 1000))
    : 0;


  if (applyVelocity) {
    return dataMatrix.slice(1).reduce(
      (acc, row) => {
        let dt = row[0] - acc.prev[0];

        acc.series.push(
          row.map((val, i) => {
            if (i === 0) {
              return val - startDaySinceEpoch; // the date
            }
            return (val - acc.prev[i]) / dt;
          })
        );

        acc.prev = row;
        return acc;
      },
      {
        series: [],
        prev: dataMatrix[0]
      }
    ).series;
  }

  return dataMatrix.map(row => {
    return row.map((val, i) => {
      if (i === 0) {
        return val - startDaySinceEpoch; // the date
      }
      return val;
    });
  });
}
