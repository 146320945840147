import { createSelector } from "reselect";
import { getArtist } from "../reducers/data_access";

export const selectSource = state => state.ui.location.matchParams.source;

export const selectSourceId = state => state.ui.location.matchParams.id;

export const selectArtist = createSelector(
  selectSource,
  selectSourceId,
  state => state.domain.artistDb,
  (source, id, artistDb) => {
    return getArtist(artistDb, source, id);
  }
);
