import React from "react";
import moment from "moment";
import _ from "lodash";

import Autosuggest from "react-autosuggest";

import styled from "styled-components";
import {Spinner} from "../../components/primitives/things";
import {createSelector} from "reselect";

import {Link} from "react-router-dom";

import ReactModal from "react-modal";

import {MetricsGraph} from "../../components/metrics/modal";
import {connect} from "react-redux";
import {splitTrackTitle} from "../../components/track_link";
import {BigTitle, MediumTitle, SmallLabel} from "../../components/metrics/styles";
import {CornerLogo} from "../../components/modal";

ReactModal.setAppElement("#root");

const StyledOverlay = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 15px 15px 15px 15px;

  .closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 12px;
    font-weight: bold;
    padding: 4px 16px;
    text-decoration: none;
    color: red;
  }

  .row {
    display: flex;
    flex-direction: row;
    padding: 5px;
  }
  .title {
    flex-grow: 1;
    padding: 0 10px;
  }
`;

const METRICS = [
  { index: 1, label: "Views" },
  { index: 2, label: "Likes" },
  { index: 3, label: "Dislikes" },
  { index: 4, label: "Favoritings" },
  { index: 5, label: "Comments" }
];

class _VideoDetailOverlay extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      comparisons: []
    };

    this.handleRemoveComparison = this.handleRemoveComparison.bind(this);
    this.handleAddComparison = this.handleAddComparison.bind(this);
  }

  handleAddComparison(ytid) {
    this.setState({
      comparisons: _.uniq(this.state.comparisons.concat(ytid))
    });
  }

  handleRemoveComparison(ytid) {
    this.setState({
      comparisons: _.without(this.state.comparisons, ytid)
    });
  }

  render() {
    const {
      selectedVideoId,
      selectedVideoData,
      videosIndex,
      videos,
      fetchVideoDetail,
      windowDotLocation
    } = this.props;

    if (!selectedVideoId) {
      return <span />;
    }

    return (
      <ReactModal
        isOpen={!!selectedVideoId}
        style={{
          overlay: {
            zIndex: 90000,
            backgroundColor: "rgba(0,0,0,0.4)"
          },
          content: {
            padding: 0
          }
        }}
        onAfterOpen={() => {
          fetchVideoDetail(selectedVideoId);
        }}
      >
        <StyledOverlay>
          <Link
            className="closeButton"
            to={windowDotLocation.hash.split("/.m/")[0].substring(1)}
          >
            Close
          </Link>
          <div className="overlayContents">
            {!selectedVideoData || !selectedVideoData.ytid ? (
              <Spinner />
            ) : (
              <div>
                <div className="row">
                  <SmallLabel>YouTube Track:</SmallLabel>
                </div>
                <div
                  className="row"
                  style={{ backgroundColor: "#f4f4f4", color: "black" }}
                >
                  <div className="title">
                    <BigTitle>
                      {splitTrackTitle(selectedVideoData.title)}
                    </BigTitle>
                    <MediumTitle>{selectedVideoData.channel_name}</MediumTitle>
                    <span>
                      Published{" "}
                      {moment(selectedVideoData.published.val || selectedVideoData.published).format(
                        "D MMM, YYYY"
                      )}
                      <span>
                        {" "}
                        ({moment(selectedVideoData.published.val || selectedVideoData.published).fromNow()})
                      </span>
                    </span>
                  </div>
                  <iframe
                      title="Video Player"
                    id="ytplayer"
                    type="text/html"
                    width="355"
                    height="200"
                    src={`https://www.youtube.com/embed/${
                      selectedVideoData.ytid
                    }?theme=light&autoplay=0&controls=1&showinfo=0&modestbranding=1&rel=0&origin=whtlst.in`}
                    frameBorder="0"
                    allowFullScreen="allowFullScreen"
                  />
                </div>

                <MetricsGraph
                  {...{
                    selectedTrackData: selectedVideoData,
                    metrics: METRICS,

                    comparisons: this.state.comparisons.map(
                      ytid => videosIndex[ytid]
                    ),
                    handleRemoveComparison: this.handleRemoveComparison,
                    ComparisonFinderComponent: () => {
                      return !videos || !videos.length ? null : (
                        <SelectVideo
                          videos={videos}
                          handleChoice={video => {
                            fetchVideoDetail(video.ytid);
                            this.handleAddComparison(video.ytid);
                          }}
                        />
                      );
                    }
                  }}
                />
              </div>
            )}
          </div>
        </StyledOverlay>
        <CornerLogo/>
      </ReactModal>
    );
  }
}



function selectSelectedVideoId(state) {
  return ((state.ui.location || {}).matchParams || {}).selectedVideoId || null;
}

const selectVideoData = createSelector(
  state => state.domain.ytData.videosIndex,
  selectSelectedVideoId,
  (videosIndex, selectedVideoId) => {
    return videosIndex && selectedVideoId ? videosIndex[selectedVideoId] : null;
  }
);

const selectRelatedVideos = createSelector(
  state => state.domain.ytData.videosIndex,
  selectSelectedVideoId,
  state => (selectVideoData(state) || {}).channel_ytid,
  (videosIndex, selectedVideoId, selectedChannelId) => {
    return Object.values(videosIndex).filter(
      v => v.channel_ytid === selectedChannelId && v.ytid !== selectedVideoId
    );
  }
);

export const VideoDetailOverlay = connect(
  state => {
    const videosIndex = state.domain.ytData.videosIndex;
    const selectedVideoId = selectSelectedVideoId(state);
    const selectedVideoData = selectVideoData(state);
    return {
      videos: selectRelatedVideos(state),
      videosIndex,
      selectedVideoId,
      selectedVideoData,
      windowDotLocation: window.location
    };
  },
  dispatch => ({
    fetchVideoDetail: videoId =>
      dispatch({
        type: "fetchVideoDetail",
        videoId: videoId,
        api: {
          resource: "/api/ytvideos/" + videoId
        }
      }),
    changePrimaryMetric: metricValue =>
      dispatch({
        type: "youTubeChartChangeMetric",
        metricValue
      })
  })
)(_VideoDetailOverlay);

function escapeRegexCharacters(str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

function getSuggestions(videos, value) {
  const escapedValue = escapeRegexCharacters(value.trim());

  if (escapedValue === "") {
    return [];
  }

  const regex = new RegExp("\\b" + escapedValue, "i");

  return videos.filter(vid => regex.test(getSuggestionValue(vid)));
}

function getSuggestionValue(video) {
  return `${video.title}`;
}

function renderSuggestion(handleChoice, video) {
  return <span onClick={() => handleChoice(video)}>{video.title}</span>;
}

const StyledAutosuggest = styled.span`
  .react-autosuggest__container {
    position: relative;
  }

  .react-autosuggest__input {
    width: 190px;
    height: 30px;
    padding: 5px 10px;
    border: 1px solid #aaa;
    border-radius: 4px;
  }

  .react-autosuggest__input:focus {
    outline: none;
  }

  .react-autosuggest__container--open .react-autosuggest__input {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .react-autosuggest__suggestions-container {
    display: none;
    max-height: 300px;
    overflow-y: auto;
  }

  .react-autosuggest__container--open
    .react-autosuggest__suggestions-container {
    display: block;
    position: absolute;
    top: 31px;
    width: 190px;
    border: 1px solid #aaa;
    background-color: #fff;
    font-weight: 300;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 5px 10px;
  }

  .react-autosuggest__suggestion:not(:first-child) {
    border-top: 1px solid #ddd;
  }

  .react-autosuggest__suggestion--focused {
    background-color: #0c7eaf;
    color: #fff;
  }

  .suggestion-content {
    display: flex;
    align-items: center;
    background-repeat: no-repeat;
  }

  .highlight {
    color: #ee0000;
    font-weight: bold;
  }

  .react-autosuggest__suggestion--focused .highlight {
    color: #120000;
  }
`;

class SelectVideo extends React.Component {
  constructor() {
    super();

    this.state = {
      suggestions: [],
      term: ""
    };

    this.onChange = this.onChange.bind(this);
    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(
      this
    );
    this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(
      this
    );
  }

  onChange(event, { newValue, method }) {
    this.setState({ term: newValue });
  }

  onSuggestionsFetchRequested({ value }) {
    this.setState({
      suggestions: getSuggestions(this.props.videos, value)
    });
  }

  onSuggestionsClearRequested() {
    this.setState({
      suggestions: [],
      term: ""
    });
  }

  render() {
    const { suggestions, term } = this.state;
    const inputProps = {
      placeholder: "Find video...",
      onChange: this.onChange,
      value: term
    };

    return (
      <StyledAutosuggest>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion.bind(
            this,
            this.props.handleChoice
          )}
          inputProps={inputProps}
        />
      </StyledAutosuggest>
    );
  }
}
