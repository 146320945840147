import React from "react";
import moment from "moment";
import {compose} from "redux";
import {connect} from "react-redux";
import styled from "styled-components";
import {LittleSourceIcon, Loading,} from "../components/primitives/things";

import {
  createInstagramProfileLookup,
  createSoundCloudProfileLookup,
  createSpotifyProfileLookup,
  createTwitterProfileLookup,
  createYouTubeProfileLookup
} from "./profilelookup";

import {Button} from "../components/primitives/inputs";

import {actionCreators as artistActionCreators} from "../reducers/artist";

import {selectArtist, selectSource, selectSourceId} from "./artist_selectors";

import {shortenUrlForDisplay} from "../utils";
import {Heading} from "../components/primitives/grid";

const Styled = styled.div`
  li {
    margin-bottom: 5px;
    &.isDisplaying {
      white-space: nowrap;
      overflow: hidden;
    }
  }
  
  .hardSourceLink::after {
    content: "✓";
    font-size: 0.8em;
    opacity: 0.4;
  }
  .softSourceLink::after {
    content: ""
  }
  img,
  a {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
  }

  a {
    color: #1da1f2;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
      color: #08c7ff;
    }
    &:visited {
      color: #d92d75;
    }
  }
  .artistSource {
    display: inline-block;
    position: relative;
  }
  .resultBox {
    position: absolute;
    z-index: 3;
    top: 22px;
    border: 1px solid #bbb;
    border-radius: 3px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
    max-width: 300px;
    min-height: 0;
    &.empty {
      display: none;
    }
  }

  button {
    margin-right: 5px;
    margin-bottom: 5x;
  }
`;

const mapping = {
  sc: {
    creator: createSoundCloudProfileLookup,
    namespace: "nsArtistSoundCloud"
  },
  tw: { creator: createTwitterProfileLookup, namespace: "nsArtistTwitter" },
  in: { creator: createInstagramProfileLookup, namespace: "nsArtistInstagram" },
  spy: { creator: createSpotifyProfileLookup, namespace: "nsArtistSpotify" },
  yt: { creator: createYouTubeProfileLookup, namespace: "nsArtistYouTube"}
};

function EditableSource({ sourceObj, handleUpsert, ...ownProps }) {
  const { creator, namespace } = mapping[sourceObj.source];
  const ProfileLookup = creator(namespace, profile => {
    handleUpsert(
      sourceObj.arid,
      sourceObj.source,
      profile.id,
      profile.source_url
    );
  });
  return <ProfileLookup {...ownProps} initialSourceObject={sourceObj} className="artistSource" />;
}

export function appendSourceUrlToArtistLinks(artistLinks) {
  if (!artistLinks) return [];

  return artistLinks.map(s => {
    if (s.source_url) {
      return s;
    }

    let source_url;
    if (s.source === "spy")
      source_url = "https://open.spotify.com/artists/" + s.id;
    else if (s.source === "in")
      source_url = "https://www.instagram.com/" + s.id + "/";
    else if (s.source === "tw") source_url = s.id;
    // todo, fix this!
    else if (s.source === "sc") source_url = s.id; // todo, fix this!

    return { ...s, source_url };
  });
}

const hoc = compose(
  connect(
    state => {
      const artist = selectArtist(state);
      const source = selectSource(state);
      const sourceId = selectSourceId(state);

      return {
        arid: artist.arid,
        source,
        sourceId,
        lastSpidered: artist.lastSpidered ? artist.lastSpidered.val : null,
        spiderable:
          !artist.lastSpidered ||
          moment(artist.lastSpidered.val).isBefore(
            moment().subtract(3, "hours")
          ),
        editable: state.ui.artist.isEditingSources,
        creatingArtistRecord: state.ui.artist.isEditingSources && !artist.arid,
        spideringInProgress: state.ui.artist.spideringInProgress,
        sources: artist.links,
        updateInProgress: state.ui.artist.sourceUpdateInProgress
      };
    },
    dispatch => {
      return {
        handleStartSpider: (source, sourceId) => {
          dispatch(artistActionCreators.fetchUpdatedArtist(source, sourceId));
        },
        handleBeginEdit: () => {
          dispatch({ type: "manageArtistSources/startEdit" });
        },
        handleEndEdit: () =>
          dispatch({ type: "manageArtistSources/finishEdit" }),
        handleUpsertArtistSource: (arid, source, source_id, source_url) => {
          dispatch({
            type: "manageArtistSources/upsert",
            ...{ arid, source, source_id, source_url },
            api: {
              resource: "/api/artist_sources/" + arid,
              options: {
                method: "PUT",
                body: JSON.stringify({
                  arid,
                  source,
                  source_id,
                  source_url
                })
              },
              then: () => {
                dispatch(artistActionCreators.fetchArtist("a", arid));
                dispatch(artistActionCreators.artistBrains("a", arid));
              }
            }
          });
        }
      };
    }
  )
);

const smallerButtonStyle = {
  padding: '3px 6px',
  marginBottom: '2px',
}

class _ManageArtistSources extends React.Component {
  render() {
    const {
      arid,
      sources,
      source,
      sourceId,
      editable,
      spiderable,
      lastSpidered,
      updateInProgress,
      spideringInProgress
    } = this.props;
    const {
      handleUpsertArtistSource,
      handleStartSpider,
      handleBeginEdit,
      handleEndEdit
    } = this.props;

    if (spideringInProgress) {
      return (
        <Loading>
          Spidering for other sources, this may take a bit of time....
        </Loading>
      );
    }

    const allSources = Object.keys(mapping).sort(); // don't actually care about order, just want it stable.

    const SpiderButton = spiderable ? (
      <Button onClick={() => handleStartSpider(source, sourceId)} style={smallerButtonStyle}>
        Launch Spider
      </Button>
    ) : (
      <Button
        className="tooltip"
        style={smallerButtonStyle}
        data-tooltip={
          "This artists was spidered " +
          moment(lastSpidered).fromNow() +
          ". We can't spider too often, and require 3 hours before spidering again."
        }
        disabled
      >
        Launch Spider
      </Button>
    );

    const ManualEditButton = arid ? (
      <Button style={smallerButtonStyle} onClick={() => handleBeginEdit()}>Edit Manually</Button>
    ) : (
      <Button
        className="tooltip"
        style={smallerButtonStyle}
        data-tooltip={"Try spidering this artist before manual editing."}
        disabled
      >
        Edit Manually
      </Button>
    );

    return (
      <Styled>
        <Heading>Whitelist Sources</Heading>
        <ul>
          {allSources.map(source => {
            let existingSourceRecord = sources.find(
              src => src.source === source
            );

            if (!existingSourceRecord && !editable) {
              return null;
            }

            if (!existingSourceRecord) {
              // make an empty record:
              existingSourceRecord = {
                source: source,
                arid: arid,
                source_url: ""
              };
            }
            const rowData = updateInProgress[existingSourceRecord.source] ? (
              <Loading style={{ display: "inline-block" }}>Saving...</Loading>
            ) : editable ? (
              <EditableSource
                sourceObj={existingSourceRecord}
                className={"artistSource"}
                handleUpsert={handleUpsertArtistSource}
                initialValue={existingSourceRecord.source_url}
              />
            ) : String(existingSourceRecord.source_url || "").indexOf(
              "http"
            ) !== 0 ? (
              <span style={{ color: "grey" }}>
                {existingSourceRecord.source_url}
              </span>
            ) : (
              <a href={existingSourceRecord.source_url} target="_blank" rel="noopener noreferrer">
                {shortenUrlForDisplay(existingSourceRecord.source_url)}
              </a>
            );

            return (
              <li key={existingSourceRecord.source} className={
                (editable ? "isEditing" : "isDisplaying")

                // This === is to support API rollout, old version doesn't specify
                + (existingSourceRecord.is_hard_link === true
                  ? ' hardSourceLink'
                  : existingSourceRecord.is_hard_link === false
                  ? ' softSourceLink'
                  : '')
              }>
                <LittleSourceIcon source={existingSourceRecord.source} />
                {rowData}
              </li>
            );
          })}
        </ul>
        {editable ? (
          <Button onClick={handleEndEdit}>Done</Button>
        ) : (
          <table><tbody><tr>
            <td>{SpiderButton}</td>
            <td>{ManualEditButton}</td>
          </tr></tbody></table>
        )}
      </Styled>
    );
  }
}

export const ManageArtistSources = hoc(_ManageArtistSources);

// Exported for storybook:
export const ManageArtistSourceComponent = _ManageArtistSources;
