import { connect } from "react-redux";

import { actionCreators } from "../reducers/datasources";
import { fetchOnMount } from "../utils/hocs";
import { actions as scoutOverlayActions } from "./modals/ScoutInfoOverlay";

import Results, { makeGroupActivitySelect } from "./category_grouping_table";

export const SoundCloud = connect(
  makeGroupActivitySelect(
    "soundcloud_by_day",
    "soundcloud",
    "SoundCloud",
    true
  ),
  dispatch => ({
    fetchData: () =>
      dispatch(actionCreators.fetchDatasource("soundcloud_by_day")),
    filter: (field, value) =>
      dispatch(
        actionCreators.filterDatasource("soundcloud_by_day", field, value)
      ),
    selectInfluencer: (source, id) => {
      dispatch(scoutOverlayActions.selectInfluencer(source, id));
    }
  })
)(fetchOnMount(Results));

export const Twitter = connect(
  makeGroupActivitySelect("twitter_by_day", "twitter", "Twitter", false),
  dispatch => ({
    fetchData: () => {
      dispatch(actionCreators.fetchDatasourceExp("twitter_by_day"));
    },
    fetchMoreArtists: nextUrl => {
      dispatch(actionCreators.fetchMoreArtists("twitter_by_day", nextUrl));
    },
    filter: (field, value) =>
      dispatch(actionCreators.filterDatasource("twitter_by_day", field, value)),
    selectInfluencer: (source, id) => {
      dispatch(scoutOverlayActions.selectInfluencer(source, id));
    }
  })
)(fetchOnMount(Results));

export const Instagram = connect(
  makeGroupActivitySelect("instagram_by_day", "instagram", "Instagram", false),
  dispatch => ({
    fetchData: () =>
      dispatch(actionCreators.fetchDatasource("instagram_by_day")),
    filter: (field, value) =>
      dispatch(
        actionCreators.filterDatasource("instagram_by_day", field, value)
      ),
    selectInfluencer: (source, id) => {
      dispatch(scoutOverlayActions.selectInfluencer(source, id));
    }
  })
)(fetchOnMount(Results));
