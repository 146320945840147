import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Loading, XBox } from "../components/primitives/things";
import { createSoundCloudProfileLookup } from "./profilelookup";
import { actionCreators as currentScoutsActionCreators } from "../reducers/admin";
import { andList, withEllipse } from "../utils/index";
import numeral from "numeral";
import { Button } from "../components/primitives/inputs";

const tooltipText =
  "❓ This artist is being processed for recommendations. New recommended scouts will appear below as this progresses.  🕒 Note this could take over an hour for some artists so you may want come back later to see the full list";

const Styled = styled.div`
  width: 500px;
  padding: 20px;
  height: 100%;
  overflow-y: auto;
  border-left: 1px solid #ccc;
 
  li {
    list-style: none;
    margin: 2px 0;
    padding: 5px;
    
    img {
      width: 25px;
      height: 25px;
      border-radius: 100%;
    }
    a, img, b, span {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
    }

  }
  .removeHandle {
    float: right;
  }
  .isProcessing {
    position:relative;
    animation: throb 1s infinite alternate;
    @keyframes throb {
      from { background-color: rgb(255,252,207); }
      to { background-color: rgb(255,254,240); }
    }

  }
  
  a {
    text-decoration: none;
    font-weight: bold;
    &:hover {
      text-decoration: underline;
    }
  }
  
  .loading {
    color: #ff7a40;
    float: right;
    margin-top: 3px;
    font-size: 0.8em;
  }
  
  .loading::before {
    display: none;
    opacity: 0;
        transition: opacity 300ms ease-in;
  }
  .loading:hover::before {
    display: block;
    opacity: 1;
    position: absolute;
    width: 200px;
    left: 100px;
    top: 24px;
    z-index: 5;
    background-color: white;
    box-shadow: 1px 1px 5px rgba(0,0,0,0.3);
    border: 1px solid #bbbbbb;
    border-radius: 5px;
    padding: 5px 10px;
    color: #666;
    content: '${tooltipText}';
  }
  
  .loading {
    display: inline-block;
  }
  
  .scouts {
    a {
      font-size: 18px;
    }
    small {
      font-size: 12px;
    }
    span, img {
      display: inline-block;
    }
    img {
      width: 50px;
      height: 50px;
    }
    li {
      margin: 10px 0;
      padding: 10px 0;
      border-top: 1px solid #bbbbbb;
      
    }
    button {
     margin: 5px 0;
    }
  }
`;

class _ExistingRegressionArtists extends React.Component {
  render() {
    const { artists, handleRemove } = this.props;
    return (
      <ul>
        {artists.map(a => {
          const isProcessing = a.isProcessing;
          return (
            <li className={isProcessing ? "isProcessing" : ""} key={a.id}>
              <img src={a.avatar} alt="" />
              <a href={a.source_url} target="_blank" rel="noopener noreferrer">
                {a.name}
                <br />
                <small style={{ textDecoration: "none", color: "black" }}>
                  {numeral(a.followers_count).format("0,0")} followers
                </small>
              </a>
              <XBox className="removeHandle" onClick={() => handleRemove(a)}>
                ✕
              </XBox>
              {isProcessing ? (
                <Loading className="loading">Processing...</Loading>
              ) : null}
            </li>
          );
        })}
      </ul>
    );
  }
}

const ExistingRegressionArtists = connect(
  state => {
    return {
      artists: state.user.scouts.sc.regressionArtists
    };
  },
  dispatch => {
    return {
      handleRemove: artist => {
        dispatch({
          type: "regressionArtist/remove",
          api: {
            resource: "/api/recommended_influencers/sc",
            options: {
              method: "DELETE",
              body: JSON.stringify(artist)
            }
          },
          artist
        });
      }
    };
  }
)(_ExistingRegressionArtists);

class _RecommendedScoutsList extends React.Component {
  render() {
    const { scouts, handleAdd } = this.props;
    return (
      <ul className="scouts">
        {scouts.map(s => {
          const followedList = andList(s.followed.map(f => f.full_name));
          return (
            <li key={s.profile.id}>
              <img src={s.profile.avatar} alt="" />
              <span>
                <a
                  href={s.profile.source_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {s.profile.name}
                  <br />
                  <small style={{ fontWeight: "normal", color: "black" }}>
                    {s.profile.source_url}
                  </small>
                </a>
              </span>
              <div>
                <small>
                  Has followed {followedList} and{" "}
                  {numeral(s.profile.followings_count).format("0,0")} artists in
                  total.
                </small>
                <br />
                {s.profile.description ? (
                  <small>"{withEllipse(s.profile.description, 100)}"</small>
                ) : null}
              </div>
              <div>
                <Button onClick={() => handleAdd(s)}>Add to my scouts</Button>
              </div>
            </li>
          );
        })}
      </ul>
    );
  }
}

const RecommendedScoutsList = connect(
  state => ({
    scouts: state.user.scouts.sc.recommendedScouts
  }),
  dispatch => {
    return {
      handleAdd: scout =>
        dispatch({
          type: "recommendedScouts/add",
          scout: scout,
          api: {
            resource: "/api/admin/soundcloud_influencer/add",
            options: {
              method: "POST",
              body: JSON.stringify({ url: scout.profile.permalink_url })
            },
            then: dispatch => {
              dispatch(
                currentScoutsActionCreators.adminInfluencersGetList(
                  "soundcloud"
                )
              );
            }
          }
        })
    };
  }
)(_RecommendedScoutsList);

class _RecommendScouts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: this.props.initialValue || ""
    };

    this.ProfileLookup = createSoundCloudProfileLookup(
      "nsRecommendSoundCloud",
      this.props.handleAddArtist
    );

    this.fetcher = null;
  }

  componentDidMount() {
    // Poll for recommended artists.  We exponentially back off the polling to save dynamo resources.
    const ref = this;

    function fetchAndSetTimeout(iterationNumber) {
      const initialTimeoutMillis = 8000;
      const nextTime = initialTimeoutMillis*Math.pow(1.2, iterationNumber);
      ref.props.refreshRegressionArtists();
      console.log(iterationNumber, nextTime);
      ref.fetcher = window.setTimeout(() => fetchAndSetTimeout(iterationNumber+1), nextTime);
    }

    fetchAndSetTimeout(0);
  }

  componentWillUnmount() {
    if (this.fetcher) window.clearTimeout(this.fetcher);
  }

  render() {
    if (!this.props.isAllowed) {
      return null;
    }
    const ProfileLookup = this.ProfileLookup;

    return (
      <Styled>
        <h2>Find more SoundCloud scouts, based on new artists you like</h2>
        <p>
          Add some SoundCloud artists below and I'll recommend new scouts for
          you based on those artists.
        </p>
        <p>
          The best artists to add are ones that are fairly recent and have less
          than 5,000 followers.
        </p>
        <p>
          The form below will take a url from the artists's soundcloud page eg.
          https://soundcloud.com/kevin
        </p>
        <ProfileLookup />
        <h2>Your List of Liked Artists:</h2>
        <ExistingRegressionArtists />
        <h2>Recommended Scouts:</h2>
        <p>Click to add them to your scout list.</p>
        <RecommendedScoutsList />
      </Styled>
    );
  }
}

export const RecommendScouts = connect(
  state => ({
    isAllowed: true
  }),
  dispatch => {
    return {
      handleAddArtist: artistObj => {
        dispatch({
          type: "addSoundCloudRegressionArtist",
          api: {
            resource: "/api/recommended_influencers/sc",
            options: {
              method: "POST",
              body: JSON.stringify(artistObj)
            }
          }
        });
      },
      refreshRegressionArtists: () => {
        dispatch({
          type: "refreshSoundCloudRegressionArtist",
          api: {
            resource: "/api/recommended_influencers/sc",
            options: {
              method: "GET"
            }
          }
        });
      }
    };
  }
)(_RecommendScouts);
