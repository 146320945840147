import React from "react";
import PropTypes from "prop-types";
import moment from "moment/moment";
import numeral from "numeral";

import CountUp from "react-countup";

import RadioGroup from "../../components/radio";

import { ErrorBoundary } from "../../components/error_boundary";

import { Spinner } from "../primitives/things";

import {
  VictoryAxis,
  VictoryChart,
  VictoryLine,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from "victory";

import { compareColors } from "../../styles/styleConsts";
import { MediumTitle, SmallLabel, withSelectedBoxStyle } from "./styles";

import { dfdt } from "./dfdt";

import styled from "styled-components";

import _ from "lodash";

const VideoChart = ({
  timeseries,
  primaryMetricIndex = 1,
  comparisonDataMatrixes,
}) => {
  console.log("videochart", comparisonDataMatrixes);
  const isRelativeTime = timeseries[0][0] < 100; // we are (likely) graphing 'days since published'
  const timezoneOffsetMillis = new Date().getTimezoneOffset() * 60000;
  return (
    <VictoryChart
      padding={{
        top: 50,
        bottom: 50,
        left: 70,
        right: 50,
      }}
      domainPadding={{ x: 10, y: 50 }}
      width={600}
      height={300}
      containerComponent={
        <VictoryVoronoiContainer
          labels={(a, b, c) => {
            // TODO: fix tooltip
            return [
              isRelativeTime
                ? "After " + a[0] + " days"
                : moment(
                    new Date(a[0] * (24 * 3600 * 1000) + timezoneOffsetMillis)
                  ).format("Do MMM"),
              "\nViews: " + numeral(a[1]).format("0,0"),
              "\nLikes: " + numeral(a[2]).format("0,0"),
              "\nDislikes: " + numeral(a[3]).format("0,0"),
              "\nFavoritings: " + numeral(a[4]).format("0,0"),
              "\nComments: " + numeral(a[5]).format("0,0"),
            ];
          }}
          labelComponent={
            <VictoryTooltip
              renderInPortal={true}
              flyoutStyle={{
                fill: "#ffffff",
                stroke: "#bbbbbb",
                strokeWidth: 1,
              }}
              style={{
                fontSize: "10px",
                textAnchor: "start",
                fill: "black",
              }}
            />
          }
        />
      }
      scale={{ x: isRelativeTime ? "linear" : "time", y: "linear" }}
    >
      <VictoryAxis
        tickFormat={d => {
          return isRelativeTime ? d + " days" : moment(d).format("D MMM");
        }}
        // domain={domainX}
      />
      <VictoryAxis
        dependentAxis={true}
        tickFormat={d => {
          return numeral(d).format("0.0 a");
        }}
        // domain={domainYPrimary}
        orientation="left"
        standalone={false}
      />
      <VictoryLine
        x={row =>
          isRelativeTime
            ? row[0]
            : row[0] * 24 * 3600 * 1000 + timezoneOffsetMillis
        }
        y={primaryMetricIndex}
        data={timeseries}
        style={{
          data: { stroke: "#f36435" },
        }}
        standalone={false}
        animate={true}
      />
      {comparisonDataMatrixes.map((d, i) => {
        console.log("Comparisons", d);
        return !d || d.length === 0 ? null : (
          <VictoryLine
            key={i}
            x={row => (isRelativeTime ? row[0] : row[0] * 24 * 3600 * 1000)}
            y={primaryMetricIndex}
            data={d}
            style={{
              data: { stroke: compareColors[i] },
            }}
            standalone={false}
            animate={true}
          />
        );
      })}
    </VictoryChart>
  );
};

function ListMetrics({
  currentValues,
  metrics,
  primaryMetricIndex,
  handlePrimaryMetricIndex,
}) {
  if (!currentValues) {
    return null;
  }
  return (
    <React.Fragment>
      <SmallLabel className="currentMetricsTitle">
        Current Metrics <br />
        <i style={{ opacity: 0.7, fontSize: "0.8em" }}>
          (click to change graphed metric)
        </i>
      </SmallLabel>
      {metrics.map((metric, i) => {
        const sty = withSelectedBoxStyle(
          {},
          primaryMetricIndex === metric.index
        );
        return (
          <div
            key={metric.index}
            style={sty}
            className="metricValue"
            onClick={() => handlePrimaryMetricIndex(metric.index)}
          >
            <SmallLabel>{metric.label}:</SmallLabel>
            <MediumTitle>
              <CountUp
                start={0}
                duration={2}
                useEasing={true}
                separator={","}
                end={currentValues[i]}
              />
            </MediumTitle>
          </div>
        );
      })}
    </React.Fragment>
  );
}

function getIdKey(obj) {
  return obj.uid || obj.arid || obj.scid || obj.ytid || obj.spyid || obj.inid;
}

function GraphOptions({
  seriesMode,
  handleSeriesMode,
  comparisonMode,
  handleComparisonMode,
  comparisons,
  handleRemoveComparison,
  ComparisonFinderComponent,
}) {
  return (
    <React.Fragment>
      <SmallLabel>Graph values:</SmallLabel>
      <RadioGroup
        defaultSelectedIndex={seriesMode === "velocity" ? 1 : 0}
        choices={[
          { label: "Count", value: "" },
          { label: "Daily Change", value: "velocity" },
        ]}
        labelStyle={withSelectedBoxStyle(
          { fontSize: "13px", display: "inline-block", padding: "5px" },
          false
        )}
        selectedLabelStyle={withSelectedBoxStyle({}, true)}
        handleChange={handleSeriesMode}
      />

      {ComparisonFinderComponent && (
        <ErrorBoundary>
          <SmallLabel style={{ marginTop: "10px" }}>
            Compare with track:
          </SmallLabel>
          <ComparisonFinderComponent />
        </ErrorBoundary>
      )}

      {comparisonMode !== "disabled" && (
        <React.Fragment>
          <SmallLabel style={{ marginTop: "10px" }}>
            Compare values on:
          </SmallLabel>
          <RadioGroup
            defaultSelectedIndex={comparisonMode === "publishedDaysAgo" ? 1 : 0}
            choices={[
              { label: "Date", value: "date" },
              { label: "Days since published", value: "publishedDaysAgo" },
            ]}
            labelStyle={withSelectedBoxStyle(
              { fontSize: "13px", display: "inline-block", padding: "5px" },
              false
            )}
            selectedLabelStyle={withSelectedBoxStyle({}, true)}
            handleChange={handleComparisonMode}
          />
        </React.Fragment>
      )}

      <ul>
        {comparisons.map((obj, i) =>
          !((obj.dataMatrix && obj.dataMatrix.length) || obj.dataMatrixV2) ? (
            <span key={getIdKey(obj)} style={{ transform: "scale(0.1)" }}>
              <Spinner />
            </span>
          ) : (
            <li
              key={getIdKey(obj)}
              onClick={() => handleRemoveComparison(getIdKey(obj), i)}
              style={{
                fontSize: "13px",
                maxWidth: "200px",
                marginBottom: "5px",
                color: compareColors[i],
              }}
            >
              {obj.title}
            </li>
          )
        )}
      </ul>
    </React.Fragment>
  );
}

const StyledInnerGraphControls = styled.section`
  display: grid;

  grid-template-columns: 150px auto 250px;

  padding-top: 10px;

  .metricValue {
    display: block;
    min-width: 100px;
    //display: inline-block;
    padding: 2px 4px 0 4px;
    margin: 0 2px;
  }

  @media (max-width: 900px) {
    grid-template-columns: auto;
    .listMetrics {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
    .currentMetricsTitle {
      width: 100%;
    }
    .metricValue {
      flex: 1;
      min-width: 100px;
      //display: inline-block;
      padding: 2px 4px 0 4px;
      margin: 0 2px;
    }
  }

  @media (max-width: 500px) {
    ${SmallLabel} {
      font-size: inherit;
    }
    ${MediumTitle} {
      font-size: 14px;
    }
  }
`;

function convertToMatrixData(
  selectedTrackData,
  primaryMetricIndex,
  metrics,
  seriesMode,
  comparisonMode
) {
  // Converts the values to the right matrix form, using either the  'dataMatrix' or 'dataMatrixV2'

  let videoMatrix = [],
    selectedMetricIndex,
    videoChartMetricIndex,
    currentMetricValues = [];

  if (selectedTrackData.dataMatrix && selectedTrackData.dataMatrix.length) {
    videoChartMetricIndex = _.isNumber(primaryMetricIndex)
      ? primaryMetricIndex
      : 1;
    selectedMetricIndex = videoChartMetricIndex;
    videoMatrix = selectedTrackData.dataMatrix;
    currentMetricValues = _.last(videoMatrix).slice(1); // item 0 is the date (stat date fmt), slice it off.
  } else if (selectedTrackData.dataMatrixV2) {
    videoChartMetricIndex = 1;
    selectedMetricIndex = _.isNumber(primaryMetricIndex)
      ? primaryMetricIndex
      : _.findIndex(selectedTrackData.dataMatrixV2);
    videoMatrix = selectedTrackData.dataMatrixV2[selectedMetricIndex];
    currentMetricValues = metrics.map(
      m =>
        (_.last(selectedTrackData.dataMatrixV2[m.index] || []) || [
          0,
          Number.NaN,
        ])[1]
    );
  }

  videoMatrix = dfdt(
    videoMatrix,
    seriesMode === "velocity",
    comparisonMode === "publishedDaysAgo"
      ? new Date(
          selectedTrackData.created_at ||
            (selectedTrackData.published || {}).val ||
            selectedTrackData.published
        )
      : null
  );
  return {
    videoMatrix,
    selectedMetricIndex,
    videoChartMetricIndex,
    currentMetricValues,
  };
}

export class MetricsGraph extends React.Component {
  // Draws the interactive graph for the modal.  dataMatrix is the style where
  // there's a datapoint for each metric on the day, this workd for a youtube video for example, where
  // eg [ [day1, views, comments], [day2, views, comments]...]
  // It doesn work for artists because we can have more history in one metric, eg we
  // may be tracking soundcloud followers for a year, and instagram followers for a week.
  //  dataMatrixV2 has a shape: [[day1, value2], [day2, value2]...]

  static propTypes = {
    initialComparisonMode: PropTypes.oneOf([
      "disabled",
      "publishedDaysAgo",
      "date",
    ]),
    selectedTrackData: PropTypes.shape({
      dataMatrix: PropTypes.array,
      dataMatrixV2: PropTypes.array,
      title: PropTypes.string,
      id: PropTypes.string,
      created_at: PropTypes.string, // iso string
    }),
    metrics: PropTypes.arrayOf(
      PropTypes.shape({
        index: PropTypes.number.isRequired,
        label: PropTypes.string.isRequired,
      })
    ),
    comparisons: PropTypes.arrayOf(
      PropTypes.shape({
        dataMatrix: PropTypes.array,
        dataMatrixV2: PropTypes.array,
        uid: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        created_at: PropTypes.string.isRequired, // iso string
      })
    ),
  };

  state = {
    primaryMetricIndex: null,
    seriesMode: "velocity",
    comparisonMode: this.props.initialComparisonMode || null,
  };

  render() {
    const {
      selectedTrackData,
      metrics,
      handleRemoveComparison,
      ComparisonFinderComponent,
    } = this.props;
    let comparisons = this.props.comparisons || [];
    const { primaryMetricIndex, seriesMode, comparisonMode } = this.state;

    if (!selectedTrackData.dataMatrix && !selectedTrackData.dataMatrixV2) {
      return <Spinner />;
    }

    // Can't compare dataMAtrixV2 and dataMatrix safely.
    comparisons = comparisons.filter(
      data =>
        (data.dataMatrix && selectedTrackData.dataMatrix) ||
        (data.dataMatrixV2 && selectedTrackData.dataMatrixV2)
    );

    const {
      videoMatrix,
      selectedMetricIndex,
      videoChartMetricIndex,
      currentMetricValues,
    } = convertToMatrixData(
      selectedTrackData,
      primaryMetricIndex,
      metrics,
      seriesMode,
      comparisonMode
    );

    const comparisonDataMatrixes = comparisons.map(comparisonData => {
      const { videoMatrix: compareMatrix } = convertToMatrixData(
        comparisonData,
        primaryMetricIndex,
        metrics,
        seriesMode,
        comparisonMode
      );
      return compareMatrix;
    });

    return (
      <StyledInnerGraphControls>
        <div className="listMetrics">
          <ErrorBoundary>
            <ListMetrics
              metrics={metrics}
              currentValues={currentMetricValues}
              primaryMetricIndex={selectedMetricIndex}
              handlePrimaryMetricIndex={index =>
                this.setState({ primaryMetricIndex: index })
              }
            />
          </ErrorBoundary>
        </div>

        <div className="videoChart">
          <ErrorBoundary>
            {videoMatrix.length && (
              <VideoChart
                timeseries={videoMatrix}
                primaryMetricIndex={videoChartMetricIndex}
                comparisonDataMatrixes={comparisonDataMatrixes}
              />
            )}
          </ErrorBoundary>
        </div>

        <div className="graphOptions">
          <ErrorBoundary>
            <GraphOptions
              {...{
                seriesMode,
                handleSeriesMode: choice =>
                  this.setState({ seriesMode: choice.value }),
                comparisonMode,
                handleComparisonMode: choice =>
                  this.setState({ comparisonMode: choice.value }),
                comparisons,
                handleRemoveComparison,
                ComparisonFinderComponent,
              }}
            />
          </ErrorBoundary>
        </div>
      </StyledInnerGraphControls>
    );
  }
}
