import { combineReducers } from "redux";
import { immutUpsert } from "../utils";
import { cognitoLogout } from "../middleware/auth";
import { _toKey } from "./data_access";
import { pk } from "./admin";
import { datasourceMapping } from "./domain";

const initialState = {
  errorMessage: null, // an error message to display (if there is one)
  currentUser: null, // current logged in user. null before checked, false if we know they are unauthenticated.
  userIsLoading: true, // at the very beginning, we need to login.
};

// Delete this when these have been added to the server side
const injectFakeCapabilities = user => {
  if (user.lastClearedAlerts === null) {
    return {
      ...user,
      sessionHasExpired: false,
      lastClearedAlerts: new Date(
        new Date().getTime() - 2 * 24 * 3600 * 1000
      ).toISOString(),
    };
  }
  return user;
};

function userIsLoading(state = initialState.userIsLoading, action) {
  switch (action.type) {
    case "fetchUserStarted":
    case "loginStarted":
      console.log("Login started");
      return true;

    case "fetchUserCompleted":
    case "fetchUserFailed":
    case "loginCompleted":
    case "loginFailed":
      console.log("Login completed", action.type);
      return false;
    default:
      return state;
  }
}

function currentUser(state = initialState.currentUser, action) {
  switch (action.type) {
    case "logout":
    case "clearUser":
      console.log(action.type + " recevied...");
      return null;

    case "fetchUserCompleted":
    case "loginCompleted":
      return injectFakeCapabilities(action.data);

    case "whitelist/clearNewAlertsCompleted":
      return {
        ...state,
        ...action.data,
      };

    case "charts/changeUsersCharts":
      return {
        ...state,
        charts: action.newChartIdsList,
      };

    case "artist/hideForMeCompleted":
    case "artist/unHideForMeCompleted":
      return {
        ...state,
        hiddenArtistKeys: action.data,
      };

    case "fetchMyWhitelistCompleted":
      const lastClearedAlertsIsoDate = state.lastClearedAlerts;
      return {
        ...state,
        numNewAlerts: action.data.artists.reduce((count, artist) => {
          return (
            count +
            (artist.artist.recentAlerts || []).filter(
              alert => alert.as_of > lastClearedAlertsIsoDate
            ).length
          );
        }, 0),
      };

    case "adminInfluencersGetListCompleted":
      let newScouts = {};
      if (action.datasource === "soundcloud") {
        newScouts.sc = action.data.map(({ scid, added, username }) => ({
          scid,
          added,
          username,
        }));
      } else if (action.datasource === "instagram") {
        newScouts["in"] = action.data.map(({ inid, added, username }) => ({
          inid,
          added,
          username,
        }));
      } else if (action.datasource === "twitter") {
        newScouts.tw = action.data.map(({ eid, added, username }) => ({
          eid,
          added,
          username,
        }));
      }
      if (Object.keys(newScouts).length === 0) {
        return state;
      }
      return {
        ...state,
        scouts: {
          ...state.scouts,
          ...newScouts,
        },
      };

    case "adminRemoveInfluencerCompleted":
      newScouts = {};
      if (action.datasource === "soundcloud") {
        newScouts.sc = state.scouts.sc.filter(x => x.scid !== action.data.scid);
      } else if (action.datasource === "instagram") {
        newScouts["in"] = state.scouts["in"].filter(
          x => x.inid !== action.data.inid
        );
      } else if (action.datasource === "twitter") {
        newScouts.tw = state.scouts.tw.filter(x => x.eid !== action.data.eid);
      }
      return {
        ...state,
        scouts: {
          ...state.scouts,
          ...newScouts,
        },
      };

    default:
      return state;
  }
}

function errorMessage(state = initialState.errorMessage, action) {
  if (action.type === "loginFailed") {
    return action.error || null;
  }
  return null;
}

const scoutsInitialState = {
  sc: {
    currentScouts: [],
    recommendedScouts: [],
    regressionArtists: [],
  },
  tw: {
    currentScouts: [],
    recommendedScouts: [],
    regressionArtists: [],
  },
  in: {
    currentScouts: [],
    recommendedScouts: [],
    regressionArtists: [],
  },
};

function reduceMyScouts(state = {}, action) {
  // a mapping of key to {category} as defined by the user.
  switch (action.type) {
    case "fetchUserCompleted":
    case "loginCompleted":
      // reset on a new login / refresh
      const data = {};
      action.data.scouts.in.forEach(scout => {
        const key = _toKey("in", scout.inid);
        data[key] = { key, category: scout.category };
      });
      action.data.scouts.tw.forEach(scout => {
        const key = _toKey("tw", scout.eid);
        data[key] = { key, category: scout.category };
      });
      action.data.scouts.sc.forEach(scout => {
        const key = _toKey("sc", scout.scid);
        data[key] = { key, category: scout.category };
      });
      return data;

    case "adminInfluencersGetListCompleted":
      const shortSource = datasourceMapping[action.datasource];
      if (shortSource && action.data.length > 0) {
        const byKey = { ...state };
        action.data.forEach(inf => {
          const id = inf[pk[shortSource]];
          const key = _toKey(shortSource, id);
          const scout = byKey[key] || { key };
          byKey[key] = {
            ...scout,
            category: inf.category,
          };
        });
        return byKey;
      }
    case "adminInfluencerEditFormSaveCompleted":
      if (action.key) {
        return {
          ...state,
          [action.key]: {
            ...state[action.key],
            category: action.influencerData.category,
          },
        };
      }
    // {
    //   datasource: 'in',
    //   influencerData: {
    //   inid: 'imiimmery',
    //     category: 'the thingfs'
    // },
    //   data: {
    //     inid: 'imiimmery',
    //       category: 'the thingfs'
    //   },
    //   originalActionType: 'adminInfluencerEditFormSave'
    // }
    default:
      return state;
  }
}

export function reduceUserScouts(state = scoutsInitialState, action) {
  function appendRegressionArtist(artistObj) {
    const regressionArtists = immutUpsert(
      state.sc.regressionArtists,
      "id",
      artistObj
    );

    return {
      ...state,
      sc: {
        ...state.sc,
        regressionArtists: regressionArtists,
      },
    };
  }

  switch (action.type) {
    case "fetchUserCompleted":
    case "loginCompleted":
      return {
        ...state,
        sc: {
          ...state.sc,
          currentScouts: action.data.scouts.sc,
          regressionArtists: action.data.regressionArtists.sc,
        },
        in: {
          ...state.in,
          currentScouts: action.data.scouts.in,
        },
        tw: {
          ...state.tw,
          currentScouts: action.data.scouts.tw,
        },
      };

    case "profileLookup/profileAccepted":
      if (action.namespace === "nsRecommendSoundCloud") {
        return appendRegressionArtist(action.profile);
      } else {
        return state;
      }

    case "addSoundCloudRegressionArtistCompleted":
      return appendRegressionArtist(action.data);

    case "addSoundCloudRegressionArtistFailed":
      // TODO: handle failures
      return state;

    case "regressionArtist/remove":
      return {
        ...state,
        sc: {
          ...state.sc,
          regressionArtists: state.sc.regressionArtists.filter(
            a => a.id !== action.artist.id
          ),
        },
      };

    case "refreshSoundCloudRegressionArtistCompleted":
      return {
        ...state,
        sc: {
          ...state.sc,
          recommendedScouts: action.data.recommendedScouts,
          regressionArtists: action.data.regressionArtists,
        },
      };
    default:
      return state;
  }
}

export default combineReducers({
  currentUser,
  scouts: reduceUserScouts,
  myScouts: reduceMyScouts,
  userIsLoading,
  errorMessage,
  whitelistedArtists: (state = [], action) => {
    if (action.type === "fetchMyWhitelistCompleted") {
      return action.data.artists.map(a => a.arid);
    }
    return state;
  },
  ytChannels: (state = [], action) => {
    switch (action.type) {
      case "fetchUserCompleted":
      case "loginCompleted":
        return action.data.ytChannels;

      default:
        return state;
    }
  },
});

export const actionCreators = {
  refreshUser: () => ({
    type: "fetchMyWhitelist",
    api: { resource: "/api/my_whitelist?use_cache=1" },
  }),
  fetchUser: () => ({
    type: "fetchUser",
    api: {
      resource: "/api/login",
      thenFunc: (data, action, store) => {
        store.dispatch({
          type: "fetchMyWhitelist",
          api: { resource: "/api/my_whitelist?use_cache=1" },
        });
      },
    },
  }),
  // old non-cognito endpoint. used in local devel:
  // hack here to suppport new login alongside old.
  login: (username, password) => ({
    type: "login",
    api: {
      resource: "/api/login",
      options: {
        method: "POST",
        body: JSON.stringify([username, password]),
      },
      thenFunc: (data, action, store) => {
        store.dispatch({
          type: "fetchMyWhitelist",
          api: { resource: "/api/my_whitelist?use_cache=1" },
        });
      },
    },
  }),
  loginToken: accessToken => ({
    type: "login",
    api: {
      resource: "/api/login",
      options: {
        method: "POST",
        body: JSON.stringify({ accessToken }),
      },
      thenFunc: (data, action, store) => {
        store.dispatch({
          type: "fetchMyWhitelist",
          api: { resource: "/api/my_whitelist?use_cache=1" },
        });
      },
    },
  }),
  loginAuth0Token: auth0Token => ({
    type: "login",
    api: {
      resource: "/api/login",
      options: {
        method: "POST",
        body: JSON.stringify({ auth0Token }),
      },
      thenFunc: (data, action, store) => {
        store.dispatch({
          type: "fetchMyWhitelist",
          api: { resource: "/api/my_whitelist?use_cache=1" },
        });
      },
    },
  }),
  logout: () => ({
    type: "logout",
    api: {
      resource: "/api/login",
      options: {
        method: "DELETE",
      },
    },
  }),
};
