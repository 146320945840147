export function applyFilters(dataRows, filters=[]) {


  if (filters.length === 0) {
    return dataRows;
  }

  const asFilterFunction = (row) => {
    for (let i=0; i<filters.length; i++) {
      const filter = filters[i];
      const keyName = filter.key;
      const filterFunc = filter.filter;
      const rowMatches = filterFunc(row[keyName]);
      if (!rowMatches) {
        return false;
      }
    }
    return true;
  };

  return dataRows.filter(asFilterFunction)

}
