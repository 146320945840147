// Reducer.  Installed at 'ui.autoSuggest'.
export const DEFAULT = {
  placeholder: "Find...",
  newResults: [],
  suggestionsLoading: false
};

function _assignWithDefault(state, namespace, obj) {
  const nameSpacedState = {
    ...DEFAULT,
    ...(state[namespace] || {}),
    ...obj
  };
  return {
    ...state,
    [namespace]: nameSpacedState
  };
}

export function reduceNamespacedSuggestions(state = {}, action) {
  if (!action.namespace) {
    return state;
  }

  switch (action.type) {
    case "lookupFetch":
    case "lookupFetchStarted":
      return _assignWithDefault(state, action.namespace, {
        moreLoading: true
      });

    case "lookupFetchCompleted":
      return _assignWithDefault(state, action.namespace, {
        moreLoading: false,
        newResults: action.data.results
      });

    case "lookupFetchFailed":
      return _assignWithDefault(state, action.namespace, {
        moreLoading: false
      });

    case "clearSuggestions":
      return _assignWithDefault(state, action.namespace, {
        suggestions: []
      });

    default:
      return state;
  }
}
