import {SortLink} from "../sort_header_cell";
import {Column} from "react-virtualized";
import numeral from "numeral";
import {ArtistNameCell, getArtworkStyle, getTitleCell, SHORT_ROW_HEIGHT, UniversalSparkline} from "./index";
import {EMPTY_OBJ} from "../../consts";
import moment from "moment";
import React from "react";
import {instagramPink} from "../../styles/styleConsts";
import {AvatarContainer} from "../avatar";
import {addLocationParts} from "../../routing/navigator";
import {Link} from "react-router-dom";

function makeColumns(columnDefinitions, currentSort, isSortedDesc, toggleSort) {
  return columnDefinitions.map(def => {
    const dataKey = def.dataKey || def.label;
    const args = {};
    let defaultCellRenderer;
    if (def.isNumeric) {
      args.style = { textAlign: "right" };
      args.headerStyle = { textAlign: "right" };
      args.headerRenderer = () => (
        <SortLink className={"column-sort " + dataKey} onClick={() => toggleSort(dataKey)}>
          {def.label} {currentSort === dataKey ? (isSortedDesc ? "↓" : "↑") : ""}
        </SortLink>
      )
      defaultCellRenderer = ({cellData: numVal}) => {
        try {
          return numVal === null || typeof numVal === 'undefined' ? '' : numeral(numVal).format(def.numberFormat || "0a");
        } catch (e) {
          console.error(e)
          // debugger;
        }
      }
    } else {
      defaultCellRenderer = ({cellData}) => String(cellData);
    }
    if (def.style) {
      args.style = {...args.style, ...def.style};
      args.headerStyle = {...args.style, ...def.style};
    }
    return (
      <Column
        key={dataKey}
        width={def.width || 80}
        dataKey={dataKey}
        label={def.label}
        flexGrow={def.flexGrow}
        cellRenderer={def.cellRenderer || defaultCellRenderer}
        {...args}
      />
    );
  })
}

const renderSparkline = ({rowData = {}}) => (
  rowData.track || rowData.video
    ? <UniversalSparkline track={rowData.track} video={rowData.video}  />
    : <span></span>
);

const COLUMNS = [
  {
    dataKey: "artwork",
    width: SHORT_ROW_HEIGHT,
    cellRenderer: ({rowData}) => <div
      style={getArtworkStyle(rowData || EMPTY_OBJ, {height: SHORT_ROW_HEIGHT + "px", width: "100%"})}></div>,
  },
  {
    label: "Track",
    dataKey: "trackTitle",
    width: 100,
    flexGrow: 1,
    cellRenderer: ({rowData = {}}) => getTitleCell(rowData, {shortRow: true}),
  },
  {
    label: "Artist",
    dataKey: "artistName",
    width: 100,
    flexGrow: 1,
    cellRenderer: ({rowData = {}}) => rowData.artist ?
      <ArtistNameCell displaySettings={{shortRow: true}} artist={rowData.artist} handleHide={rowData.handleHideArtist}/> : <span/>,
  },
  {
    label: "Daily Plays",
    dataKey: "dailyPlaysSparkline",
    width: 100,
    cellRenderer: renderSparkline
  },
  {
    label: "Track Popularity",
    dataKey: "popularitySparkline",
    width: 100,
    cellRenderer: renderSparkline
  },
  {
    label: "Views",
    dataKey: "ViewsSparkline",
    width: 100,
    cellRenderer: renderSparkline
  },
  {
    label: "Followers",
    dataKey: "inFollowersSparkline",
    width: 100,
    // XXX: in_stats.followers no longer exists.
    cellRenderer: ({rowData}) => (((rowData||{}).artist||{}).in_stats||{}).followers
      ? <UniversalSparkline timeseries={rowData.artist.in_stats.followers} color={instagramPink}/>
      : null
  },

  {
    label: "Released",
    dataKey: "release_days_ago",
    isNumeric: true,
    cellRenderer: ({cellData: daysAgo}) => (
      <span>
        {moment().subtract({days: daysAgo}).format("D MMM YYYY")}
        <br/>
        <small style={{fontSize: "9px", opacity: 0.8}}>
          {daysAgo ? daysAgo + " days ago" : "\u00a0"}
        </small>
      </span>
    )
  },
  {
    dataKey:"view_sc_track_details",
    label: "",
    width: 50,
    cellRenderer: ({rowData={}}) => (
      rowData.scid && <Link
        className="expandStats"
        to={addLocationParts("sct", rowData.scid)}
      >View</Link>
    )
  },
  {label: "Plays Yesterday", dataKey: "plays_yesterday", isNumeric: true},
  {label: "Plays", dataKey: "total_plays", isNumeric: true},
  {label: "Popularity", dataKey: "track_popularity", isNumeric: true, numberFormat: "0"},
  {label: "Followers", dataKey: "followers", isNumeric: true, numberFormat: "0,0"},
  {label: "Total Scouts", dataKey: "total_scouts", isNumeric: true, numberFormat: "0,0"},
  {label: "Recent Scouts", dataKey: "recent_scouts", isNumeric: true, numberFormat: "0,0"},

  {label: "Avg. Follower Growth", dataKey: "avg_change_followers", isNumeric: true, numberFormat: "0,0"},
  {label: "Last Scouted", dataKey: "last_scouted_at", isNumeric: true, cellRenderer: ({cellData: lastScouted}) => (
      <span>{moment(lastScouted).fromNow()}</span>
  )},
  {
    label: "Scouts",
    dataKey: "allScouts",
    isNumeric: false,
    width: 150,
    cellRenderer: ({rowData={}, allowedScoutsMap}) => {
      const artist = rowData.artist;
      if (!artist || !artist.influencers) {
        return null;
      }
      return <div style={{marginTop: "5px", width:"150px", minWidth:"150px", height: (SHORT_ROW_HEIGHT-6) + "px", overflowY: "auto"}}><AvatarContainer scoutList={artist.influencers} allowedScoutsMap={allowedScoutsMap}/></div>
    }
  },
  // tiktok:
  {
    width: 200, label: "Title", dataKey: "title", isNumeric: false,
    cellRenderer: ({rowData={}}) => (rowData.ttSound||{}).title},
  {
    width: 180, label: "Creator", dataKey: "author", isNumeric: false,
    cellRenderer: ({rowData={}}) => (rowData.ttSound||{}).author },
  {
    label: "Link", dataKey: "open_on_tiktok", isNumeric: false,
    cellRenderer: ({rowData={}}) => (rowData.ttSound||{}).web_url && <a href={rowData.ttSound.web_url} style={{whiteSpace:'normal'}} target="_blank" rel="noopener noreferrer">Open on TikTok</a> },
  {label: "# Total Videos", dataKey: "num_videos", isNumeric: true},
  {label: "Total Hearts", dataKey: "top_video_likes", isNumeric: true},
  {label: "Avg. Hearts", dataKey: "avg_video_likes", isNumeric: true},
  {width: 50, label: "# Scout Videos", dataKey: "num_scout_videos", isNumeric: true},
  {width: 50, label: "# Scouts", dataKey: "num_unique_scouts", isNumeric: true},
  {
    label: "Earliest Scout Video Created", dataKey: "earliest_scout_video_created", isNumeric: true,
    cellRenderer: ({cellData}) => cellData ? moment(new Date(cellData * 1000)).fromNow() : '',
  },
  {
    label: "Last Scout Video Created", dataKey: "last_scout_video_created", isNumeric: true,
    cellRenderer: ({cellData}) => cellData ? moment(new Date(cellData * 1000)).fromNow() : '',
  },
  {
    label: "Last Video By", dataKey: "last_scout", isNumeric: false,
    cellRenderer: ({rowData={}}) => ((rowData.ttSound||{}).last_video||{}).scout_name
  },
  {
    label: "First Video By", dataKey: "first_scout", isNumeric: false,
    cellRenderer: ({rowData={}}) => ((rowData.ttSound||{}).first_video||{}).scout_name
  },

  // youtube
  {
    dataKey:"viewYtVideoDetails",
    label: "",
    width: 50,
    cellRenderer: ({rowData={}}) => (
      rowData.videoYtid && <Link
        className="expandStats"
        to={addLocationParts("ytvid", rowData.videoYtid)}
      >View</Link>
    )
  },
  {
    dataKey:"channel_name",
    label: "Channel",
    width: 100,
    flexGrow: 1,
    cellRenderer: ({rowData = {}}) => rowData.video ? rowData.video.channel_name : '',

  },
];

const COLUMNS_MAPPING = COLUMNS.reduce((acc, def) => {
  acc[def.dataKey] = def;
  return acc;
}, {});


export function makeColumnComponents(columnList, currentSort, isSortedDesc, toggleSort) {
  const columnDefinitions = columnList.reduce((acc, c) => {
    const d = COLUMNS_MAPPING[c];
    if (d) {
      acc.push(d);
    } else {
      console.warn("No column definition for ", c)
    }
    return acc;
  }, []);
  return makeColumns(columnDefinitions, currentSort, isSortedDesc, toggleSort);
}
