import React from "react";
import {connect} from "react-redux";

import {withRouter} from "react-router-dom";
import ResultsPage from "../components/results_page";

import {createSelector} from "reselect";

import DragDrop from "../components/charts/dragdrop";
import styled from "styled-components";

import ErrorBoundary from "../components/error_boundary";

import {ChartSelector} from "../components/charts/chartselector";
import intercomIcon from "../images/intercom-bubble.png";

import {actionMarkSpyArtistAsSigned} from "../reducers/domain";
import * as PropTypes from "prop-types";
import {hideArtistForMe} from "../reducers/artist";
import {ConnectedScrollTable, useChartPaginatedFetch} from "../components/charts/ScrollFetchTable"
import {UniversalRowItem} from "../components/charts";

const StyleUserInfoMessage = styled.i`
  display: block;
  padding: 10px;
  text-align: center;
  font-style: italic;
`;

const Styled = styled.div`
  button {
    padding: 3px 9px;
    background-color: rgba(240, 240, 240, 0.82);
    border: none;
    border-radius: 3px;
    font-size: inherit;

    :hover {
      background-color: #1da1f2;
    }
  }

  .toggleChartsEdit {
    margin: 10px;
  }

  .noRows {
    margin: 10px;
    padding: 50px 100px;
    font-size: 13px;
    color: #888;
    background-color: #efefef;

    aside {
      margin-top: 10px;
      font-size: 11px;

      img {
        display: inline-block;
        width: 16px;
        height: 16px;
        position: relative;
        top: 3px;
      }
    }
  }
`;



function SelectedChartResults({ selectedChart, handleHideForMe }) {
  /** Creates a ScrollTable using the selectedChart. */
  const noRowsRenderer = () => (
    <h4 className="noRows">
      There are no results currently, check back later for new
      artists and tracks on this chart.
      <aside>
        If this seems like an error, please let us know using
        the chat{" "}
        <img
          alt=""
          style={{ height: "12px", width: "12px" }}
          src={intercomIcon}
        />{" "}
        below.
      </aside>
    </h4>
  );
  let initialUrl = selectedChart.nextApi;
  if (initialUrl.indexOf('?') === -1) {
    initialUrl += '?use_cache_only=1&refresh_cache=1'
  }
  const fetchSettings = selectedChart.apiSettings;

  const pageFetcher = useChartPaginatedFetch(
    initialUrl,
    fetchSettings,
  );

  return (
    <>
      <button className="refreshChart" onClick={pageFetcher.refresh}>
        Refresh this chart
      </button>
      {selectedChart.userMessage && (
        <StyleUserInfoMessage>{selectedChart.userMessage}</StyleUserInfoMessage>
      )}
      <ErrorBoundary>
        <ConnectedScrollTable
          pageFetcher={pageFetcher}
          handleHideForMe={handleHideForMe}
          scrollFetchProps={{noRowsRenderer}}
          RenderFunction={props => <UniversalRowItem displaySettings={{includeTrackStats: true, includeFollowerStats: true}} {...props }/>}
        />
      </ErrorBoundary>
    </>
  );
}

SelectedChartResults.propTypes = {
  selectedChart: PropTypes.any,
  handleHide: PropTypes.any,
  handleSnooze: PropTypes.any,
  handleMarkSigned: PropTypes.any,
  handleHideForMe: PropTypes.func
};

function ChartsPage (props) {

  const handleSelectChart = chart => {
    props.history.push("/charts/" + chart.id);
  };

  const {
    allAvailableCharts,
    chartsList,
    selectedChart,
    changeUsersCharts,
    canSnooze,
    canDeactivate,
    handleMarkSigned,
    handleHideForMe
  } = props;

  const handleHide = canDeactivate ? props.handleHide : null;
  const handleSnooze = canSnooze ? props.handleSnooze : null;

  const [isEditChartsMode, setIsEditChartsMode] = React.useState(false);

  return (
      <Styled>
        {isEditChartsMode ? (
          <DragDrop
            allItems={allAvailableCharts}
            selectedIds={chartsList.map(c => c.id)}
            onChangeSelected={newSelectedIds => {
              if (!newSelectedIds || newSelectedIds.length === 0) {
                console.warn("No new selected charts onChangeSelected");
              } else {
                changeUsersCharts(newSelectedIds);
              }
            }}
          />
        ) : (
          <ChartSelector
            selectedChartId={(selectedChart || {}).id}
            handleSelectChart={handleSelectChart}
            chartsList={chartsList}
          />
        )}

        <button
          className="toggleChartsEdit"
          onClick={() => setIsEditChartsMode(!isEditChartsMode)}
        >
          {isEditChartsMode ? "Finish Editing" : "Add and remove charts"}
        </button>

        {!isEditChartsMode && selectedChart && (
          <SelectedChartResults
            key={selectedChart.id}
            selectedChart={selectedChart}
            handleHide={handleHide}
            handleSnooze={handleSnooze}
            handleMarkSigned={handleMarkSigned}
            handleHideForMe={handleHideForMe}
          />
        )}
      </Styled>
  );
}

const selectAllAvailableCharts = createSelector(
  state => state.user.currentUser,
  currentUser => currentUser.allCharts
);

const selectCharts = createSelector(
  state => state.user.currentUser,
  selectAllAvailableCharts,
  (currentUser, allCharts) => {
    const allDict = allCharts.reduce((acc, chart) => {
      acc[chart.id] = chart;
      return acc;
    }, {});
    return currentUser.charts.map(chartId => allDict[chartId]).filter(x => x);
  }
);

const getChartId = state => (state.ui.location.matchParams || {}).chartId;

const selectSelectedChart = createSelector(
  selectCharts,
  getChartId,
  (charts, selectedChartId) => {
    return charts.find(c => c.id === selectedChartId) || charts[0];
  }
);

export const ConnectedCharts = withRouter(
  connect(
    state => ({
      allAvailableCharts: selectAllAvailableCharts(state),
      chartsList: selectCharts(state),
      selectedChart: selectSelectedChart(state),
      selectedChartId: getChartId(state),
      // canSnooze: state.user.currentUser.capabilities.admin,
      // canDeactivate: state.user.currentUser.capabilities.admin,
      // canMarkSigned: state.user.currentUser.capabilities.admin,
      domainArtistDb: state.domain.artistDb
    }),
    dispatch => ({
      handleHide: (chartId, key) => {
        // key of the source source/id
        return new Promise((resolve, reject) => {
          dispatch({
            type: "artist/deactivate",
            namespace: chartId,
            key,
            source: key.split("/")[0],
            id: key.split("/")[1],
            api: {
              resource: "/api/admin/" + key + "/deactivate",
              options: { method: "PUT" },
              then: resolve
            }
          });
        });
      },
      handleSnooze: (chartId, key) => {
        return new Promise((resolve, reject) => {
          dispatch({
            type: "artist/snooze",
            namespace: chartId,
            key,
            source: key.split("/")[0],
            id: key.split("/")[1],
            api: {
              resource: "/api/admin/" + key + "/snooze",
              options: { method: "PUT" },
              then: resolve
            }
          });
        });
      },
      handleMarkSigned: artistSpyid => {
        return new Promise((resolve, reject) => {
          dispatch(actionMarkSpyArtistAsSigned(artistSpyid, resolve));
        });
      },

      handleHideForMe: artist => {
        return new Promise((resolve, reject) => {
          dispatch(hideArtistForMe(artist));
        });
      },

      changeUsersCharts: newChartsList => {
        const chartIdsList = newChartsList.map(c => c.id);
        dispatch({
          type: "charts/changeUsersCharts",
          newChartIdsList: chartIdsList,
          api: {
            resource: "/api/charts/my_charts",
            options: {
              method: "PUT",
              body: JSON.stringify({ charts: chartIdsList })
            }
          }
        });
      }
    })
  )(ChartsPage)
);

export default () => (
  <ResultsPage
    pageId="whitelist"
    pageTitle="Charts"
    filters={null}
    loading={false}
    loaded={true}
  >
    <ConnectedCharts/>
  </ResultsPage>
)
