import {lighten} from "polished";
import styled from "styled-components";

const styleConsts = {
  space: "20px",
  green: "#20C9A3",
  red: "#DB2B3D",
  purplish: "#923660",
// twitter-blue: "#1DA1F2",
// instagram-pink: "#D92D75",
// spotify-green: "#2FD465",
// soundcloud-orange: "#FF5500",
// youtube-red: "#E62117",

  linkblue: "#50a3ff",
  visitedLink: "@linkblue",
  highlightBlue: lighten(0.25, "#50a3ff"),
  selectedOption: "#dcdcdc",
  blackish: "#282C2D",
  blackishFaded: "rgba(40, 44, 45, 0.1)",
  text: "#fff",
// subdued-text: "fade(@text, 40%)",
}

export const StyledSidebarFilters = styled.div`

.tags {
  // Tags in the table row.
  i {
    display: inline-block;
    font-size: 15px;
    text-align: center;
    padding: 4px 0;
    min-width: 21px;
    background-color: ${styleConsts.selectedOption};
    margin: 2px 2px 0 0;

    &.off {
      opacity: 0.2;
    }
    &:hover {
      opacity: 0.8;
      background-color: ${styleConsts.highlightBlue};
    }
  }
}

.searcher,
.toggler,
.tagger {

  text-align: left;
  margin-bottom: ${styleConsts.space};

  h3 {
    margin-bottom: 2px;
    margin-top: 0;
    font-size: 13px;
    padding-bottom: 0;
  }
  input {
    padding: 6px 10px 6px 25px;
    border-radius: 12px;
    background-color: ${styleConsts.blackishFaded};
    border: none;
  }

  .tagger__input-with-icon {
    position: relative;
  }
  .tagger__icon {
    position: absolute;
    left: 6px;
    top: 4px;
    width: 15px;
    height: 21px;
  }
}

.tagger {

  .tagger-input {
    position: relative;
    display: table-cell;
    width: 22em;
    input {
      display: block;
      width: auto;
    }
  }
  a {
    color: ${styleConsts.linkblue};
    cursor: pointer;
  }

  h3,
  .clear {
    display: inline-block;
  }
  .clear {
    margin-left: 10px;
  }

  .selected-tags {
    display: block;
    width: 100%;

    .choice {
      display: inline-block;
      padding: 2px 5px 2px 5px;
      background-color: #699db3;
      color: white;
      margin: 2px 2px 0 0;
      cursor: pointer;
      &:after {
        //content: '\\274C';
        content: '\\00D7';
        margin-left: 10px;
      }
      &:hover {
        opacity: 0.5;
      }
    }
  }

  li {
    text-align: left;
    cursor: pointer;
    display: block;
    width: auto;
    overflow: hidden;
    padding: 4px 8px;
    margin: 0;
    &:hover {
      background-color: ${styleConsts.highlightBlue};
    }
  }
  .options-box {
    position: absolute;
    left: -5px;
    top: 30px;
    width: 180px;
    display: block;
    padding: 0;
    margin: 0;

    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    background-color: #fff;
    z-index: 999;

    ul {

      display: block;
      padding: 0;
      margin: 0;
      max-height: 300px;
      overflow: auto;
    }

    .close-options {
      display: block;
      text-align: right;
      background-color: #f5f5f5;
      padding: 2px 5px;
    }
  }
}


.toggler {

  .choice {
    transition: 0.2s;
    cursor: pointer;
    padding: 5px 5px;
    display: block;
    font-weight: normal;
    font-size: 0.9em;
    margin-left: @space/2;

    &.off {
      opacity: 0.2;
      font-weight: normal;
      text-decoration: line-through;
    }
    &:hover {
      opacity: 0.2;
    }
  }
}

.filter-tags {
  .choice {
    .tagicon;
    &:before {
      padding-right: 4px;
    }
  }
}
`;
