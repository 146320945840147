import React from "react";
import {Refreshing} from "../components/loading";
import {Loading} from "../components/primitives/things";
import {
  blackish,
  HEADER_HEIGHT,
  instagramPink,
  navWidth,
  purplish,
  soundcloudOrange,
  space,
  spotifyGreen,
  tiktokBlack,
  tiktokCyan,
  tiktokPink,
  twitterBlue,
  youtubeRed
} from "../styles/styleConsts";
import {adjustHue, darken, lighten, transparentize} from "polished";
import styled, {css} from "styled-components";
import {TrackLink} from "./track_link";
import {SortLink} from "./sort_header_cell";
import {Link} from "react-router-dom";
import settingsIcon from "../images/channel-icon-admin-dark@2x.png";


class ResultsPage extends React.PureComponent {
  render() {
    const {
      pageId,
      narrow,
      pageTitle,
      settingsLink,
      filters,
      loaded,
      loading,
      children,
      headerStyle,
      ResultsContainerStyle,
      customSystemMessage,
    } = this.props;

    const Header = (
      <ResultsHeader pageId={pageId} narrow={narrow} style={headerStyle}>
        {!pageTitle ? null : <ResultsHeading>{pageTitle}</ResultsHeading>}
      </ResultsHeader>
    );

    const Settings = settingsLink ? <SettingsLink to={settingsLink}>Change My Scouts</SettingsLink> : null;

    const Filters = filters
        ? <FiltersContainer>{loaded ? filters : null}</FiltersContainer>
        : null;

    const innerData = loaded && children
        ? children
        : <div style={{textAlign: "center", padding:"50px"}}><Loading>Loading...</Loading></div>;
        
    return (
      <ResultsWrapper pageId={pageId}>
        {loaded && loading ? <Refreshing /> : null}
        {Header}
        {Settings}
        {customSystemMessage && <StyledSystemMessage>{customSystemMessage}</StyledSystemMessage>}
        <ResultsContainer narrow={narrow} style={ResultsContainerStyle} >
          {Filters}
          <ResultsContainerInner>
            {innerData}
          </ResultsContainerInner>
        </ResultsContainer>
      </ResultsWrapper>
    );
  }
}

const StyledSystemMessage = styled.div`
  padding: 10px 15px;
  background-color: #ffe1e1;
  border: 1px solid red;
  margin: 10px;
  border-radius: 2px;
`;

export default ResultsPage;

export const colourMap = {
  default: {primary: lighten(0.1, blackish), to: purplish},
  twitter: {primary: twitterBlue, to: lighten(0.1, adjustHue(360-20, twitterBlue))},
  instagram: {primary: instagramPink, to: lighten(0.1, adjustHue(360-20, instagramPink))},
  soundcloud: {primary: soundcloudOrange, to: lighten(0.1, adjustHue(10, soundcloudOrange))},
  soundcloud2: {primary: soundcloudOrange, to: lighten(0.1, adjustHue(10, soundcloudOrange))},
  youtube: {primary: youtubeRed, to: lighten(0.1, adjustHue(360-15, youtubeRed))},
  spotify: {primary: spotifyGreen, to: lighten(0.1, adjustHue(20, spotifyGreen))},
  tiktok: {primary: tiktokBlack, backgroundImage: `linear-gradient(40deg, ${darken(0.3, tiktokCyan)}, ${tiktokBlack}, ${lighten(0.15, tiktokPink)})`},
};

const bgGrad = props => {
  const c = colourMap[props.pageId] || colourMap.default;
  const backgroundImage = c.backgroundImage || `linear-gradient(30deg, ${(c.primary)}, ${(c.to)})`;
  return css`background-image: ${backgroundImage};`;
};

const primaryColour = props => {
  const c = colourMap[props.pageId] || colourMap.default;
  return c.primary;
};

const ResultsWrapper = styled.div`
  position: relative;
  height: 100%;
  
  ${TrackLink}:visited,
  ${SortLink} {
    color: ${primaryColour};
  }
  
  // TODO: Move to some styled table wrapper
  .fixedDataTableLayout_header {
    background-color: rgba(255,255,255,0.9);
    font-size: 10px;
  }

  .fixedDataTableCellGroupLayout_cellGroup,
  td, th {
    border: none;
    border-top: 1px solid ${transparentize(0.85, blackish)};
    vertical-align: top;
  }
  
  .public_fixedDataTableCell_wrap3, td, th {
    padding: 5px;
  }
  
  .public_fixedDataTableCell_main:first-child .public_fixedDataTableCell_wrap3,
  td:first-child,
  th:first-child {
    padding-left: ${space}px;
  }
  
  .public_fixedDataTableCell_main:last-child .public_fixedDataTableCell_wrap3,
  td:first-child,
  th:first-child {
    padding-right: ${space}px;
  }

  th,
  .public_fixedDataTable_header .public_fixedDataTableCell_wrap3 {
    padding-bottom: ${space}px;
    padding-top: ${space}px;
    color: ${transparentize(0.5, blackish)};
  }
`;

export const StorybookResultsWrapper = ResultsWrapper;

const contentWidthNarrow = 920;

const ResultsHeading = styled.h1`
  margin: 0;
  color: white;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  vertical-align: middle;
  img {
    width: 48px;
    height: 48px;
    vertical-align: -44%;
    margin-right: ${space/2}px;
  }
`;

const ResultsContainer = styled.div`
  margin: 0 auto;
  background-color: white;
  display: flex;
  text-align: left;
  width: ${props => props.narrow ? contentWidthNarrow+"px" : "100%"};
  height: calc(100% - ${HEADER_HEIGHT}px);
`;

const ResultsContainerInner = styled.div`
  width: 100%;
`;

const filtersWidth = 210;

const FiltersContainer = styled.div`
  min-width: ${filtersWidth}px;
  max-width: ${filtersWidth}px;
  background-color: ${transparentize(0.93, blackish)};
  border-right: 1px solid ${transparentize(0.93, blackish)};
  border-radius: 5px 0 0 5px;
  overflow: auto;
  padding: ${space}px;
  padding-bottom: ${space+50}px;  /* room for settings link */
`;

const ResultsHeader = styled.div`
  ${bgGrad}
  min-height: ${navWidth}px;
  padding: ${space}px;
  background-repeat: no-repeat;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SettingsLink = styled(Link)`
  position: absolute;
  z-index: 10;
  bottom: 0;
  left: 0;
  color: #999;
  text-decoration: none;
  :before {
    content: " ";
    background-image: url(${settingsIcon});
    background-repeat: no-repeat;
    background-size: cover;
    display: inline-block;
    background-position: center left;
    width: 25px;
    height: 25px;
    vertical-align: middle;
    margin-right: 3px;
  }
  display: block;
  width: ${filtersWidth}px;
  padding: 5px;
  background-color: #e6e6e6;
  min-height: 25px;
  line-height: 25px;
  opacity: 1;
  :hover {
    color: ${darken(0.2, "#777777")};
  }
`;
