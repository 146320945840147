import React from "react";

import styled from "styled-components";
import {blackish, dangerAction, navWidth, sourceIconMap, space} from "../../styles/styleConsts";
import logo from "../../images/dna-white.png";
import numeral from "numeral";

export const Spinner = styled.div`
  
  border: 6px solid rgba(139,139,139,0.51);
  border-top-color: rgba(77,183,252,0.74);
  border-radius: 100%;
  background: transparent;
  width: 30px;
  height: 30px;
  animation: spin 0.8s linear infinite;
  margin: 0 auto;
  

  @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
  }
`;

export const Refreshing = styled.div`
  position: absolute;
  padding: 2px 8px;
  display: block;
  top: 5px;
  left: 50%;
  background-color: rgba(255,255,255,0.1);
  color: rgba(255,255,255,0.7);
  animation: throb 1s infinite alternate;
  
  @keyframes throb {
    from { opacity: 1; }
    to { opacity: 0.5; }
  }
  
  ::before {
    content: "Refreshing...";
  }
`;

const StyledLoading = styled.div`
  padding: 2px 8px;
  background-color: white;
  color: black;
  animation: throb 1s infinite alternate;
  
  @keyframes throb {
    from { opacity: 1; }
    to { opacity: 0.4; }
  }
`;


export const Loading = (props) => <StyledLoading style={props.style} className={props.className}>{props.children}</StyledLoading>;
const boxDim = "21px";
const StyledClickBox = styled.span`
  display: inline-block;
  width: ${boxDim};
  height: ${boxDim};
  text-align: center;
  line-height: ${boxDim};
  font-size: 14px;
  border-radius: 2px;
  
  &:hover {
    background-color: ${dangerAction};
    color: white;
  }
`;

export const XBox = ({onClick, className='', ...rest}) => (
    <StyledClickBox
        className={"clickBox xBox " + className}
        onClick={onClick}
        title={"Remove"}
        {...rest}
    >✕</StyledClickBox>);

/* eslint jsx-a11y/accessible-emoji: "off" */
export const PlusBox = ({onClick, className='', style, ...rest}) => (
    <StyledClickBox
        className={"clickBox plusBox " + className}
        style={{backgroundColor: "#7ea9f9", ...(style||{})}}
        onClick={onClick} {...rest}
        title={"Add"}
    >➕</StyledClickBox>);


export const LittleSourceIcon = ({source, height, width, style={}}) => {
  const [imgSrc, iconBg] = (sourceIconMap[source]||[undefined, undefined]);
  return <img alt={source} src={imgSrc} style={{backgroundColor: iconBg, height:height||"15px", width:width||"15px", ...style}} className="LittleSourceIcon"/>;
};


export const Logo = styled.div.attrs(p => ({children: <img src={logo} alt="WHITELIST" />})) `
  padding: ${space}px;
  background-color: ${blackish};
  height: ${navWidth};
  width: ${navWidth};
  img {
    width: 42px;
    height: 42px;
    display: block;
  }
`;


export function withToolTip(text) {
  // See the global styles for the tooltip css.
  return (Component) => {
    return (props) => {
      const className = 'tooltip ' + (props.className||'')
      return <Component {...props} className={className} data-tooltip={text}/>;
    };
  };
}


export const UpDownTriangleNumber = ({value, style, fmt="0,0"})=> {
  return <span style={style} className={value > 0 ? "positiveChange" : value < 0 ? "negativeChange" : ""}>{numeral(Math.abs(value)).format(fmt)}</span>;
}
