import React from "react";
import moment from "moment";

import {
  VictoryArea,
  VictoryBrushContainer,
  VictoryChart,
  VictoryGroup,
  VictoryLine,
  VictoryTheme,
  VictoryTooltip
} from "victory";

const convertTimeSeriesToVictoryXY = (stats, applyDfDt, applyPctChange) => {
  if (!stats) {
    return [];
  }

  const timezoneOffsetMillis = new Date().getTimezoneOffset() * 60000;
  console.log("timesone offset", timezoneOffsetMillis)

  if (applyDfDt) {
    return stats.slice(1).reduce(
      (acc, point) => {
        acc.series.push({
          x: point[0] * 24 * 3600 * 1000 + timezoneOffsetMillis,
          y: (point[1] - acc.prev[1]) / (point[0] - acc.prev[0] + 1)
        });
        acc.prev = point;
        return acc;
      },
      { series: [], prev: stats[0] }
    ).series;
  }

  if (applyPctChange) {
    return stats.slice(1).reduce(
      (acc, point) => {
        acc.series.push({
          x: point[0] * 24 * 3600 * 1000 + timezoneOffsetMillis,
          y: (point[1] - acc.prev[1]) / (acc.prev[1] + 1)
        });
        acc.prev = point;
        return acc;
      },
      { series: [], prev: stats[0] }
    ).series;
  }

  return stats.map(point => ({
    x: point[0] * 24 * 3600 * 1000 + timezoneOffsetMillis,
    y: point[1]
  }));
};

export class Timeseries extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      zoomDomain: null,
      renderError: null
    };
    this.handleZoom = this.handleZoom.bind(this);
  }

  handleZoom(domain) {
    this.setState({ zoomDomain: domain });
  }

  render() {
    const {
      primarySeries,
      primaryColour,
      applyDfdt,
      applyPercentChange,
      lastNDays
    } = this.props;

    const series = primarySeries;
    if (!series || series.length === 0) {
      return null;
    }

    if (series.length === 1) {
      return (
        <div
          style={{
            textAlign: "center",
            height: "50%",
            width: "100%",
            display: "block",
            paddingTop: "20%",
            marginTop: "10px",
            color: "#bbb",
            backgroundColor: "#f9f9f9"
          }}
        >
          Not enough data for graph.
        </div>
      );
    }

    const victoryXY = convertTimeSeriesToVictoryXY(
      series,
      applyDfdt,
      applyPercentChange
    );

    let zoomDomain = this.state.zoomDomain || {
      x: [victoryXY[0].x, victoryXY[victoryXY.length - 1].x]
    };
    if (lastNDays) {
      zoomDomain.x[0] =
        victoryXY[victoryXY.length - 1].x - lastNDays * 24 * 3600 * 1000;
    }
    return (
      <div>
        <VictoryChart
          theme={VictoryTheme.material}
          width={600}
          height={400}
          scale={{ x: "time" }}
          domain={{ x: zoomDomain.x }}
          animate={false}
        >
          <VictoryGroup
            labels={d => `y: ${d.y}`}
            labelComponent={<VictoryTooltip />}
          >
            <VictoryArea
              interpolation="linear"
              style={{
                data: {
                  fill: primaryColour,
                  fillOpacity: 0.1,
                  stroke: primaryColour,
                  strokeWidth: 2
                }
              }}
              data={victoryXY}
            />
          </VictoryGroup>
        </VictoryChart>

        <VictoryGroup
          theme={VictoryTheme.material}
          padding={{ top: 0, left: 50, right: 50, bottom: 0 }}
          width={600}
          height={80}
          scale={{ x: "time" }}
          containerComponent={
            <VictoryBrushContainer
              brushDimension="x"
              brushDomain={zoomDomain}
              onBrushDomainChange={this.handleZoom}
              brushStyle={{
                stroke: "transparent",
                fill: "black",
                fillOpacity: 0.13
              }}
            />
          }
        >
          <VictoryLine
            style={{
              data: { stroke: primaryColour, strokeWidth: 1 }
            }}
            data={victoryXY}
          />
        </VictoryGroup>
        <div style={{ textAlign: "center" }}>
          {moment(zoomDomain.x[0]).format("DD MMM YYYY")} to{" "}
          {moment(zoomDomain.x[1]).format("DD MMM YYYY")}
        </div>
      </div>
    );
  }
}

export default Timeseries;
