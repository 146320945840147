import {lighten, transparentize} from "polished";
import youtubeIcon from "../images/channel-icon-large-youtube@2x.png";
import spotifyIcon from "../images/channel-icon-large-spotify@2x.png";
import soundcloudIcon from "../images/channel-icon-large-soundcloud@2x.png";
import twitterIcon from "../images/channel-icon-large-twitter@2x.png";
import instagramIcon from "../images/channel-icon-large-instagram@2x.png";
import manualSourceIcon from "../images/user-icon@2x.png";
import tiktokIcon from "../images/tiktok.png";
import myListIcon from "../images/mylist-icon.png"
import styled from "styled-components";

export const green = "#20C9A3";
export const red = "#DB255B";
export const purplish = "#923660";
export const twitterBlue = "#1DA1F2";
export const instagramPink = "#D92D75";
export const spotifyGreen = "#2FD465";
export const soundcloudOrange = "#FF5500";
export const youtubeRed = "#E62117";

export const tiktokBlack = '#000000';
export const tiktokPink = '#fe2c55';
export const tiktokCyan = '#23f4ee';


export const linkblue = "#50a3ff";
export const visitedLink = linkblue;
export const highlightBlue = lighten(0.25, linkblue);
export const selectedOption = "#dcdcdc";
export const blackish = "#282C2D";
export const primaryAction = "#58646e";
export const dangerAction = "#b4222c";
export const text = "#fff";
export const subduedText = transparentize(0.4, text);

export const headerColour = lighten(0.05, blackish);

export const space = 17;

export const navWidth = 82;

export const HEADER_HEIGHT = 82;

export const COMPONENT_ELEMENT_COLOR = "#59bad6";

export const sourceIconMap = {
  'sc': [soundcloudIcon, soundcloudOrange],
  'tw': [twitterIcon, twitterBlue],
  'in': [instagramIcon, instagramPink],
  'yt': [youtubeIcon, youtubeRed],
  'spy': [spotifyIcon, spotifyGreen],
  'man': [manualSourceIcon, purplish],
  'tt': [tiktokIcon, tiktokBlack],
  'wl': [myListIcon, blackish],
};

export const compareColors = [0, 0.2].reduce((acc, lightenAmount) => {
  const c = [
    '#7832ff',
    '#ff778d',
    '#839498',
    '#cccd4c',
  ];
  acc = acc.concat(c.map(x => lighten(lightenAmount, x)));
  return acc;
}, []);


export const StyledControls = styled.div`
  margin-left: 10px;
  h4 {
    display: inline-block;
    font-weight: bold;
    font-size: 0.9em;
    width: 7em;
    margin: 0;
  }
  label {
    display: inline-block;
    min-width: 5em;
    padding: 6px 8px;
    margin: 4px;
    border-radius: 3px;
    border: 1px solid transparent;
    &:hover {
      background-color: rgb(130, 190, 255, 0.3);
    }

    &.selected {
      border: 1px solid rgb(130, 190, 255);
      background-color: rgb(244, 244, 244);
    }
  }
`;
