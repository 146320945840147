import _ from "lodash";
import "isomorphic-fetch";
import { cognitoLogout } from "./auth";
import { AccountEntity } from "@azure/msal-common";

/** return Promise<{text?, json?, response}> */
async function getResponseJsonOrText(response) {
  // calling response.json() on an empty response causes an error. This is a workaround
  // to allow it since 204 Content Created can be empty, we return an empty object
  // if the header's content-type isn't json then the string body will be returned.

  // This methoed doesn't consider the status code, since json may be returned as part of
  // a 4xx or even 5xx error.  The response itself is returned so that the status code
  // is available there.
  const isJson =
    response.headers.get("content-type") &&
    response.headers.get("content-type").indexOf("json") !== -1;
  const text = await response.text();
  return Promise.resolve({
    json: isJson ? JSON.parse(text || "{}") : undefined,
    text: isJson ? undefined : text,
    response: response,
  });
}

/**
 * Handles a fetch to our API.
 * Adds authorization tokens, and will try to refresh the cognito token
 * and retry the request, if the first response is a 401 expired.
 * @returns {Promise<HTTPResponse>}
 */
const fetchit = (url, obj = {}) => {
  switch (window.__env__.backend) {
    case "local":
      url = "http://localhost:5959" + url;
      break;
    case "local-docker":
      url = "http://localhost:5959" + url;
      break;
    case "candidate":
    case "prod":
      // not cross origin
      break;
    default:
      throw new Error("No backend specified for fetch.");
  }

  if (!obj.headers) {
    obj.headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
  }

  if (!obj.credentials) {
    // always include the session cookie
    obj.credentials = "include";
  }
  return fetch(url, obj);
};

window._fetchit = fetchit;

function createLifecycleAction(originalAction, suffix, extraData) {
  return {
    ...originalAction,
    ...extraData,
    api: undefined,
    type: originalAction.type + suffix,
    originalActionType: originalAction.type,
  };
}


async function dispatchLogoutIf401or403(resWithJson, dispatch, msalInstance) {
  const { response } = resWithJson;
  if (response.status === 401 || response.status === 403) {
    console.log("Got 40x status code, logging in again", response.status);
    // dispatch logout....
    return new Promise(resolve => {
      cognitoLogout(); // wipe local storage.
      msalInstance.clearCache();
      
      dispatch({
        type: "clearUser",
        displayMessage:
          response.status === 401
            ? "Your session has expired, please login again to continue."
            : response.json && response.json.info
            ? response.json.info
            : null,
      }); // remove from store
      resolve(resWithJson);
    });
  } else {
    return Promise.resolve(resWithJson);
  }
}

async function handleApiRequest(action, store, msalInstance) {
  const { resource, options, then, thenFunc, onError } = action.api;

  store.dispatch(createLifecycleAction(action, "Started"));

  return fetchit(resource, options)
    .then(res => getResponseJsonOrText(res))
    .then(res => dispatchLogoutIf401or403(res, store.dispatch, msalInstance))
    .then(({ json, text, response }) => {
      const responseData = json || text;

      if (response.ok) {
        store.dispatch(
          createLifecycleAction(action, "Completed", { data: responseData })
        );
        // FIXXME two different then functions.  ideally the returned promise
        // could be used anyway, like how redux-thunk does it
        if (then) {
          then(store.dispatch, responseData, store.getState(), response);
        } else if (thenFunc) {
          thenFunc(responseData, action, store);
        }
      } else {
        store.dispatch(
          createLifecycleAction(action, "Failed", {
            errorObject: responseData,
            statusCode: response.statusCode,
            error:
              response.statusCode && response.statusCode >= 500
                ? "Sorry, an error occurred"
                : _.isString(responseData)
                ? responseData
                : responseData.info || responseData.message,
          })
        );
        if (onError) {
          onError(responseData, response, action, store);
        }
      }
      return Promise.resolve(responseData);
    })
    .catch(e => {
      if (e instanceof TypeError) {
        console.error("Error fetching from API", e);
        store.dispatch(
          createLifecycleAction(action, "Failed", { errorObject: e })
        );
      } else {
        // Throw any other errors otherwise we miss helpful react error information,
        // and instead react gets into an inconsistent state and throws more obscure errors.
        throw e;
      }
    });
}

/**
 * Redux middleware for making API calls.
 * Looks for actions with an 'api' object. eg.
 *   dispatch({type: 'MyAction', api: { resource: 'https://example.com/', options: {} }})
 *
 * Additional actions are dispatched, with a suffix on the type, and the 'api' removed.
 * The suffixes are 'Started', 'Completed', 'Failed'.
 *
 * 'MyActionCompleted' will include a 'data' key with the body of the request (parsed into
 * a js object if it was json, otherwise text.
 *
 * eg. { type:'MyActionCompleted', data: {..result of api call to example.com...} }
 *
 * 'MyActionFailed' will have an errorObject that is a caught error or some text.  It may
 * also include an 'error' string.
 * eg. { type: 'myActionFailed', error: 'Some failure from api' }
 */
export default function(msalInstance) {  

  return store => next => action => {
    next(action);

    if (action.api) {
      handleApiRequest(action, store, msalInstance);
    }
  };

}
export const testingHandleApiRequest = handleApiRequest;
