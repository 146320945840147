import styled from "styled-components";
import {Table} from "react-virtualized";
import {transparentize} from "polished";
import {blackish} from "../styles/styleConsts";

import "react-virtualized/styles.css";

const StyledReactVirtualizedTable =  styled(Table)`
  .ReactVirtualized__Table__headerRow {
    text-transform: none;
    font-weight: normal;
    color: ${transparentize(0.7, blackish)};
    border-bottom: 1px solid ${transparentize(0.85, blackish)};
    font-size: 10px;
  }
  .ReactVirtualized__Table__row {
    border-bottom: 1px solid ${transparentize(0.85, blackish)};
  }
  
  .trackTitle {
    b, small {
      display: block;
    }
  }
  .copyright {
     display: block;  
  }
  .ReactVirtualized__Grid__innerScrollContainer {
    /** add some extra scrolling on the bottom  to allow for hovering controls, miscalculated heights */
    margin-bottom: 60px;
  }
`;

export default StyledReactVirtualizedTable;
