import React from "react";

const SoundcloudEmbed = ({urlEncodedPlaylistUrl, options={}}) => {
  // urlEncodedPlaylistUrl is a URL cut out from the embed code for a playlist. The bit
  // between "url=" and "&amp".
  // It should look like: "https%3A//api.soundcloud.com/playlists/343079156%3Fsecret_token%3Ds-ZPHG5"
  const params = {
    url: urlEncodedPlaylistUrl,
    color:"923660", // no leading hash
    auto_play:false,
    buying:false,
    liking:false,
    following: false,
    download:false,
    sharing:false,
    show_artwork:true,
    show_comments:false,
    show_playcount:true,
    show_user:false,
    show_reposts:false,
    hide_related:true,
    visual:true,
    start_track:0,
    ...options,
  };

  const queryStr = Object.keys(params).map((k) => [k, params[k]].join("=")).join("&amp;");

  return <iframe
      title="soundcloud_player"
    style={{borderWidth: 0, display: "block"}}
    width="100%"
    height="100%"
    scrolling="no"
    src={"https://w.soundcloud.com/player/?" + queryStr}
  />
};

export default SoundcloudEmbed;
