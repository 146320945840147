import React from "react";
import styled from "styled-components";
import { blackish } from "../styles/styleConsts";
import { transparentize } from "polished";

export const SpotifyTrackLink = styled.a.attrs(p => ({ target: "_blank" }))`
  color: ${blackish};
  display: flex;
  text-decoration: none;
  :hover {
    opacity: 0.5;
  }
  :hover .track-words {
    opacity: 1;
  }
  img {
    border-radius: 100%;
    width: 40px;
    height: 40px;
  }
`;

export const TrackLink = styled.div`
  color: ${blackish};
  display: flex;
  text-decoration: none;

  img {
    border-radius: 100%;
    width: 40px;
    height: 40px;
  }

  a {
    text-decoration: none;
  }
`;

const externalLinkSize = 14;
export const ExternalLink = styled.a.attrs(p => ({ target: "_blank" }))`
  box-sizing: border-box;
  width: ${externalLinkSize}px;
  height: ${externalLinkSize}px;
  min-width: ${externalLinkSize}px;
  min-height: ${externalLinkSize}px;
  font-size: 8px;
  line-height: ${externalLinkSize}px;
  text-align: center;
  padding: 0;
  margin: 12px 14px 0px 6px;
  text-decoration: none;
  background-color: #fff;
  color: #bbb;
  border: solid #bbb;
  border-width: 3px 1px 1px 1px;
  box-shadow: 1px 1px 0 #ccc;
  text-transform: uppercase;
  b {
    font-size: 13px;
    position: relative;
    top: -3px;
  }
  :hover {
    opacity: 0.5;
  }
  :hover .track-words {
    opacity: 1;
  }
`;

export const TrackWords = styled.div`
  padding-left: 5px;
  padding-top: 2px;
  max-width: 405px;
  line-height: 1rem;
  text-decoration: none;
  color: #4482a2;
  :hover {
    text-decoration: underline;
    opacity: 0.7;
  }
`;

export const TrackName = styled.div`
  font-weight: 700;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-decoration: inherit;
  
  small {
    margin-top: 0.4em;
    display: block;
    font-weight: normal;
  }
`;

export const TrackArtist = styled.div`
  font-size: inherit;
  text-decoration: inherit;
`;

export const TrackCopyrights = styled.div`
  font-size: 70%;
  line-height: 8pt;
  color: ${transparentize(0.5, blackish)};
`;

export function splitTrackTitle(title, className="") {
  return title.indexOf("(") > 3 ? (
    <span className={className}>
      <b>{title.substring(0, title.indexOf("("))}</b>
      <small>
        {title.substring(title.indexOf("("))}
      </small>
    </span>
  ) : title.indexOf("[") > 3 ? (
    <span className={className}>
      <b>{title.substring(0, title.indexOf("["))}</b>
      <small>
        {title.substring(title.indexOf("["))}
      </small>
    </span>
  ) : title.indexOf("|") > 3 ? (
    <span className={className}>
      <b>{title.substring(0, title.indexOf("|"))}</b>
      <small>
        {title.substring(title.indexOf("|"))}
      </small>
    </span>
  ) : (
    <span className={className}>
      <b>{title}</b>
    </span>
  );
}
