import React from "react";

import { connect } from "react-redux";
import { createSelector } from "reselect";
import moment from "moment";
import styled from "styled-components";
import { StyledControls } from "../styles/styleConsts";
import ResultsPage from "../components/results_page";
import { getArtist } from "../reducers/data_access";
import ErrorBoundary from "../components/error_boundary";
import { UniversalRowItem } from "../components/charts";
import RadioGroup from "../components/radio";
import {sortBy} from "../utils";

const StyledWhitelist = styled.section`
  padding: 10px;
`;

function Whitelist({ artists, includeWhitelistedInfo }) {
  return (
    <StyledWhitelist>
      {artists.map(a => {
        return (
          <UniversalRowItem
            key={a.arid}
            artist={a}
            displaySettings={{ includeScouts: true, includeAlerts: true, includeWhitelistedInfo: includeWhitelistedInfo }}
          />
        );
      })}
    </StyledWhitelist>
  );
}

const WhitelistControls = ({
  numArtists,
  alertsFromDays,
  alertCounts = { 1: 2, 2: 4, 7: 12, 15: 70, 30: 72 },
  handleAlertsFromDays,
  allWhitelistIds,
  handleToggleWhitelist
}) => {
  const validValues = ["1", "2", "7", "15", "30"];

  const numberOfDisplayedAlerts = alertCounts[alertsFromDays];

  const entries = Object.entries(allWhitelistIds);
  entries.sort();

  return (
    <StyledControls>
      { entries.length > 1 && (
        <h1>
          <b>Showing Artists On Lists: </b>
          {entries.map(([whitelistId, info]) => (
            <label key={whitelistId} className={info.enabled ? "selected" : ""}>
              <input type="checkbox" checked={info.enabled} onClick={() => handleToggleWhitelist(whitelistId)} readOnly/>
              {info.displayName}{" ("}{info.count}{")"}
            </label>
          ))}
        </h1>
      )}
      <h1>
        Tracking <b className={"bigNumber"}>{numArtists}</b> total artists.
      </h1>
      <h1>
        Showing{" "}
        <b
          className={"bigNumber"}
          style={{
            minWidth: "30px",
            display: "inline-block",
            textAlign: "center"
          }}
        >
          {numberOfDisplayedAlerts}
        </b>{" "}
        alerts from the last
        {/*from*/}
        {/*<input*/}
        {/*type="range"*/}
        {/*list="whitelistControlAlertsFromDays"*/}
        {/*max="4"*/}
        {/*min="0"*/}
        {/*step="1"*/}
        {/*defaultValue={validValues[alertsFromDays]}*/}
        {/*onChange={ev => handleAlertsFromDays(validValues[+ev.target.value])}*/}
        {/*/>*/}
        {/*<b>{alertsFromDays}</b> days.*/}
        <RadioGroup
          choices={validValues.map(d => ({
            label: d === "1" ? "1 day" : d + " days",
            value: +d
          }))}
          defaultSelectedIndex={validValues.indexOf(String(alertsFromDays))}
          handleChange={({ value }) => handleAlertsFromDays(+value)}
        />
      </h1>
    </StyledControls>
  );
};

class _MyWhitelist extends React.PureComponent {
  constructor() {
    super();
    this.timeout = null;
  }

  componentDidMount() {
    console.log("Mounting MyWhitelist")
    const mountTime = new Date();
    this.props.fetchData();
    if (!this.timeout) {
      this.timeout = window.setTimeout(() => {
        this.props.markAlertsAsCleared(mountTime);
      }, 2000);
    }
  }

  componentWillUnmount() {
    if (this.timeout) {
      window.clearTimeout(this.timeout);
    }
  }

  render() {
    const {
      artists,
      alertsFromDays,
      numberOfDisplayedAlerts,
      handleAlertsFromDays,
      excludedWhitelistIds,
      handleToggleWhitelistId,
      includeWhitelistedInfoInDisplay,
    } = this.props;

    const filteredArtists = [],
      allWhitelistIds = {};

    const nameMap = {private: 'My List', team: 'My Team List'}

    artists.forEach(artist => {
      let added = false;
      Object.entries(artist.whitelistedInfo).forEach(([whitelistId, info]) => {
        const isIncludedWhitelist = excludedWhitelistIds.indexOf(whitelistId) === -1;
        if (isIncludedWhitelist && !added) {
          filteredArtists.push(artist);
          added = true;
        }
        if (!allWhitelistIds[whitelistId]) {
          allWhitelistIds[whitelistId] = {
            enabled: isIncludedWhitelist,
            displayName: nameMap[whitelistId],
            count: 1
          };
        } else {
          allWhitelistIds[whitelistId].count += 1;
        }

      });
    });

    return (
      <ResultsPage
        pageId="artist"
        pageTitle={"My Artists"}
        loading={!artists}
        loaded={artists}
        ResultsContainerStyle={{ overflowY: "auto" }}
      >
        <ErrorBoundary>
          <WhitelistControls
            numArtists={filteredArtists.length}
            alertsFromDays={alertsFromDays}
            numberOfDisplayedAlerts={numberOfDisplayedAlerts}
            handleAlertsFromDays={handleAlertsFromDays}
            allWhitelistIds={allWhitelistIds}
            handleToggleWhitelist={handleToggleWhitelistId}
          />
        </ErrorBoundary>
        <ErrorBoundary>
          <Whitelist artists={filteredArtists} includeWhitelistedInfo={includeWhitelistedInfoInDisplay} />
        </ErrorBoundary>
      </ResultsPage>
    );
  }
}

const selectMyWhitelist = createSelector(
  state => state.user.whitelistedArtists,
  state => state.domain.artistDb,
  state => (state.user.currentUser || {}).lastClearedAlerts,
  state => state.ui.whitelist.alertsFromDays,
  (artistIds, artistDb, lastClearedAlerts, alertsFromDays) => {
    const minDateForDisplayIso = moment()
      .subtract(alertsFromDays, "days")
      .toISOString();
    const artists = artistIds.map(arid => {
      let artist;
      if (arid === null) {
        // indicates an artist that is loading from cache.
        artist = {
          artist: {cache_status: 'LOADING'}
        }
      } else {
        artist = getArtist(artistDb, "a", arid);
      }
      const recentAlerts = (artist.recentAlerts || []).map(alert => {
        return {
          ...alert,
          isNew: alert.as_of > lastClearedAlerts,
          isDisplayed: alert.as_of >= minDateForDisplayIso
        };
      });
      recentAlerts.sort(
        (a, b) => (a.as_of > b.as_of ? -1 : a.as_of === b.as_of ? 0 : 1)
      );
      return {
        ...artist,
        allAlerts: recentAlerts,
        displayedAlerts: recentAlerts.filter(a => a.isDisplayed)
      };
    });

    artists.sort((a, b) => {
      const a_asOf = ((a.recentAlerts || [])[0] || "0").as_of;
      const b_asOf = ((b.recentAlerts || [])[0] || "0").as_of;
      return a_asOf > b_asOf ? -1 : a_asOf === b_asOf ? 0 : 1;
    });
    return artists;
  }
);

export const selectNumNewAlerts = createSelector(selectMyWhitelist, artists =>
  artists.reduce(
    (count, artist) =>
      count + (artist.allAlerts || []).filter(al => al.isNew).length,
    0
  )
);

const selectNumberOfDisplayedAlerts = createSelector(
  selectMyWhitelist,
  artists =>
    artists.reduce(
      (count, artist) =>
        count + (artist.allAlerts || []).filter(al => al.isDisplayed).length,
      0
    )
);

export default connect(
  state => ({
    artists: selectMyWhitelist(state),
    numberOfDisplayedAlerts: selectNumberOfDisplayedAlerts(state),
    alertsFromDays: state.ui.whitelist.alertsFromDays,
    excludedWhitelistIds: state.ui.whitelist.excludedWhitelistIds,
    includeWhitelistedInfoInDisplay: state.user.currentUser.capabilities.teamWhitelists,
  }),
  dispatch => ({
    markAlertsAsCleared: datetime =>
      dispatch({
        type: "whitelist/clearNewAlerts",
        api: {
          resource: "/api/userdata",
          options: {
            method: "PUT",
            body: JSON.stringify({ lastClearedAlerts: datetime.toISOString() })
          }
        }
      }),
    handleAlertsFromDays: value =>
      dispatch({ type: "whitelist/alertsFromDays", value }),
    handleToggleWhitelistId: whitelistId =>
      dispatch({ type: "whitelist/toggleWhitelistId", whitelistId }),
    fetchData: () =>
      dispatch({
        type: "fetchMyWhitelist",
        api: { resource: "/api/MyWhitelists" }
      })
  })
)(_MyWhitelist);
