import React from "react";
import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";

import { icons } from "../icons";
import {
  instagramPink,
  soundcloudOrange,
  space,
  spotifyGreen,
  tiktokCyan,
  tiktokPink,
  twitterBlue,
  youtubeRed,
} from "../styles/styleConsts";

const tabs = [
  { id: "my_whitelist", name: "Whitelist", indicateAlerts: true },
  { id: "charts", name: "Charts" },
  {
    id: "analyzer",
    name: "SoundCloud Analyzer",
    capability: "my_whitelist",
    colorKey: "/soundcloud",
  },
  {
    id: "yt_analyzer",
    name: "YouTube Analyzer",
    capability: "my_whitelist",
    colorKey: "/youtube",
  },
  { id: "spotify", name: "Spotify" },
  { id: "twitter_by_day", name: "Twitter", localPath: "twitter" },
  { id: "soundcloud_by_day", name: "SoundCloud", localPath: "soundcloud" },
  { id: "youtube", name: "YouTube" },
  { id: "instagram", name: "Instagram" },
  { id: "instagram_by_day", name: "Instagram", localPath: "instagram" },
  {
    id: "tiktok",
    name: "Tik Tok",
    localPath: "tiktoks",
    capability: "my_whitelist",
  },
];

export const hasMenu = capabilities => {
  const menuCount = tabs.reduce(
    (count, tab) => (capabilities[tab.id] ? count + 1 : count),
    0
  );
  return menuCount > 1;
};

const colours = {
  "/twitter": twitterBlue,
  // "/charts": tiktokCyan,
  "/instagram": instagramPink,
  "/soundcloud": soundcloudOrange,
  "/youtube": youtubeRed,
  "/spotify": spotifyGreen,
  "/tiktoks": tiktokCyan,
};

function AlertCountBubble({ count }) {
  if (!count || count < 0) {
    return null;
  }
  const style = {
    fontSize: (count < 10 ? "11" : count < 100 ? "9" : "7") + "px",
  };
  const countStr = count < 1000 ? String(count) : "1k+";
  return <span className="bubble" style={style} data-count={countStr} />;
}

const Styled = styled.div`
  .menuTab-no_new {
    position: relative;
    ::after {
      content: "NEW";
      font-size: 7px;
      font-weight: bold;
      position: absolute;
      left: 10px;
      top: 3px;
      width: 19px;
      height: 9px;
      line-height: 9px;
      text-align: center;
    }
  }
`;

export const Menu = React.memo(function Menu({ user, numNewAlerts, location }) {
  // xxx: in order to trigger the NavLink's functionality of highlighting the matched route, we
  // include the location as a prop to ensure this renders (PureComponent).
  const capabilities = user ? user.capabilities : {};
  return (
    <Styled>
      {tabs.map(tab => {
        return capabilities[tab.capability] || capabilities[tab.id] ? (
          <NavButton
            key={tab.id}
            to={`/${tab.localPath || tab.id}`}
            className={" menuTab-" + tab.id}
            $colorKey={tab.colorKey}
          >
            <img
              alt={tab.id}
              src={icons[tab.icon || tab.id]}
              className={"sel-" + tab.id}
            />
            {tab.indicateAlerts && numNewAlerts > 0 ? (
              <AlertCountBubble count={numNewAlerts} />
            ) : null}
          </NavButton>
        ) : null;
      })}
    </Styled>
  );
});

const NavButton = styled(NavLink).attrs(p => ({
  activeClassName: "nav__button--current",
}))`
  text-decoration: none;
  display: block;
  width: 100%;
  padding: ${space}px;
  color: white;
  vertical-align: middle;
  border-left: 7px solid transparent;
  position: relative;

  img {
    opacity: 0.5;
    vertical-align: middle;
    margin-left: 3px;
    width: 25px;
    height: 25px;
  }

  &:hover img,
  &:focus img,
  &.nav__button--current img {
    opacity: 1;
  }
  &.nav__button--current {
    border-left: 7px solid white;
    ${props =>
      css`
        border-left-color: ${colours[props.$colorKey || props.to]};
      `};
  }

  .bubble {
    display: inline-block;
    position: absolute;
    right: 15px;
    top: 10px;
    background-color: red;
    border-radius: 100%;
    width: 19px;
    height: 19px;
    line-height: 19px;
    color: white;
    font-weight: bold;
    text-align: center;
    overflow: hidden;
    box-shadow: 1px 1px 2px #000000b8;
    &:before {
      content: attr(data-count);
    }
  }
`;
