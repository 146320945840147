import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { blackish, sourceIconMap } from "../styles/styleConsts";
import { Spinner } from "../components/primitives/things";
import { throttle } from "../utils";
import { useJsonFetch } from "../fetching/UseFetch";
import { addLocationParts } from "../routing/navigator";

const OverlayPanel = styled.div`
  position: absolute;
  top: 82px;
  bottom: 0;
  right: 0;
  background-color: #f5f5f5;
  width: 350px;
  box-sizing: border-box;
  height: calc(100vh - 82px);
  color: #282c2d;
  z-index: 5;
  box-shadow: -4px 0 6px #00000011;
  animation: slideIn 260ms ease-in;

  overflow-x: hidden;
  overflow-y: auto;

  @keyframes slideIn {
    from {
      -webkit-transform: translate3d(120%, 0, 0);
      transform: translate3d(120%, 0, 0);
      visibility: visible;
      z-index: 2;
    }

    to {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      z-index: 2;
    }
  }
`;

const StyledSearchInputContainer = styled.div`
  float: right;
  padding: 20px;

  input {
    background-color: rgba(255, 255, 255, 0.2);
    border: none;
    color: white;
    display: block;
    width: 160px;
    padding: 8px 15px;
    border-radius: 16px;
  }

  input:focus {
    background-color: white;
    color: ${blackish};
  }

  input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: rgba(255, 255, 255, 0.6);
  }

  input:focus::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: rgba(0, 0, 0, 0.3);
  }
`;

const StyledSearchResult = styled.div`
  display: block;
  margin: 0 0 5px 0;
  padding: 0 0 5px 0;
  border-bottom: 1px solid rgba(50, 50, 50, 0.2);

  a {
    display: block;
    margin: 0;
    padding: 0;
    text-decoration: none;
    color: #1f8ceb;
  }
  b {
    display: block;
    font-size: 16px;
  }
  img {
    display: inline-block;
    width: 20px;
    height: 20px;
    vertical-align: middle;
    margin: 2px 10px 2px 0;
    padding: 2px;
  }
  small {
    display: inline-block;
    height: 25px;
    line-height: 25px;
    vertical-align: top;
    font-size: 12px;
  }
`;

const CloseButton = styled.button`
  padding: 0;
  margin: 0;
  margin-right: 10px;
  font-size: 21px;
  border: none;
  background-color: transparent;
  color: rgba(91, 91, 91, 0.5);
  :hover {
    color: rgba(91, 91, 91, 0.9);
  }
  ::before {
    content: "✕";
  }
`;

const ResultsContainer = styled.div`
  padding: 0 20px 20px 20px;
`;

const ResultsHeader = styled.div`
  display: flex;
  position: sticky;
  top: 0;
  background-color: #f5f5f5;
  padding: 20px;
  align-items: center;
`;

const ResultsHeading = styled.h3`
  margin: 0;
  padding: 0;
`;

function SearchResult({ result }) {
  const [source, id, name, username] = result;
  const [imgSrc, iconBg] = sourceIconMap[source];
  return (
    <StyledSearchResult>
      <Link key={id} to={["/artist", source, id].join("/")}>
        <b>{name}</b>
        <img alt="" src={imgSrc} style={{ backgroundColor: iconBg }} />
        <small>{username}</small>
      </Link>
    </StyledSearchResult>
  );
}

function UniversalSearchResults({
  isLoading,
  handleClose,
  artistResults = [],
  searchResults = []
}) {
  const searchTypeToUrlMap = {
    sc_track: "sct",
    spy_track: "spyt",
    yt_video: "ytvid"
  };
  const searchTypeToIconSource = {
    sc_track: "sc",
    spy_track: "spy",
    yt_video: "yt"
  };
  const searchTypeToLabel = {
    sc_track: "SoundCloud Track",
    spy_track: "Spotify Track",
    yt_video: "YouTube Video"
  };

  return (
    <OverlayPanel>
      <ResultsHeader>
        <CloseButton onClick={handleClose} />
        <ResultsHeading>Results</ResultsHeading>
      </ResultsHeader>
      <ResultsContainer>
        {isLoading ? <Spinner /> : null}
        {artistResults.length > 0 ? (
          <div>Artists ({artistResults.length}):</div>
        ) : null}
        {artistResults.map(item => (
          <SearchResult
            key={item[0] + item[1]}
            onClick={() => setTimeout(handleClose, 100)}
            result={item}
          />
        ))}
        {searchResults.length > 0 ? (
          <div>Tracks & Videos ({searchResults.length}):</div>
        ) : null}
        {searchResults.map(hit => {
          const [imgSrc, iconBg] = sourceIconMap[
            searchTypeToIconSource[hit.type]
          ];
          return (
            <StyledSearchResult key={hit.type + hit.id}>
              <Link to={addLocationParts(searchTypeToUrlMap[hit.type], hit.id)}>
                <b>{hit.title}</b>
                <img alt="" src={imgSrc} style={{ backgroundColor: iconBg }} />
                <small>{searchTypeToLabel[hit.type]}</small>
              </Link>
            </StyledSearchResult>
          );
        })}
      </ResultsContainer>
    </OverlayPanel>
  );
}

export const LookupArtistContainer = () => {
  const inputRef = useRef();

  const handleChange = useRef(
    throttle(function lookupArtist(qry) {
      setQuery(`/api/search?q=${encodeURIComponent(qry)}`);
    }, 500)
  ).current;

  const [query, setQuery] = useState(null);
  const [panelOpen, setPanelOpen] = useState(false);
  const fetch = useJsonFetch(query);

  function handleClose() {
    setPanelOpen(false);
    if (inputRef.current) {
      inputRef.current.blur();
    }
  }

  return (
    <>
      <StyledSearchInputContainer>
        <input
          ref={inputRef}
          className={panelOpen ? "entering" : ""}
          placeholder="Find artist"
          onChange={ev => handleChange(ev.target.value)}
          onFocus={() => setPanelOpen(true)}
        />
      </StyledSearchInputContainer>
      {panelOpen && (
        <UniversalSearchResults
          handleClose={handleClose}
          isLoading={fetch.isLoading}
          artistResults={(fetch.data || {}).artistResults}
          searchResults={(fetch.data || {}).searchResults}
        />
      )}
    </>
  );
};
