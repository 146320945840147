
export function artistCacheStatusMiddleware() {
  // Given a result that returned artists, that have a cache_status of 'LOADING' or 'REFRESHING'
  // we wait a bit and then trigger a request for those artists.

  return store => next => action => {
    if (action.originalActionType && action.data && action.type.endsWith('Completed')) {
      const artistsToUpdate = [];

      const fetchedArtistObjectsList = Array.isArray(action.data.artists)
        ? action.data.artists
        : Array.isArray(action.data.results) && (action.data.results[0]||{}).artist
        ?  action.data.results
        : [];

      fetchedArtistObjectsList.forEach(({artist}) => {
        if (artist) {
          if (artist.cache_status === 'LOADING' || artist.cache_status === 'REFRESHING') {
            artistsToUpdate.push(artist.query_key);
          }
        }
      });

      if (artistsToUpdate.length > 0) {
        const sourceToIds = artistsToUpdate.reduce((acc, key) => {
          const [source, id] = key.split('/');
          acc[source] = (acc[source]||[]).concat(id);
          return acc;
        }, {});
        console.log("There are cached artists: ", sourceToIds);

        const refreshAttempts = (action.refreshAttempts||0);
        if (refreshAttempts >= 3) {
          console.warn("To many attempts to refresh. Something has gone wrong.")
        } else {
          window.setTimeout(() => {
            Object.entries(sourceToIds).forEach(([source, ids]) => {
              store.dispatch({
                type: 'getCachingArtists',
                refreshAttempts: refreshAttempts + 1,
                api: {
                   resource: '/api/artists_by_id?include_alerts=1&use_cache_only=1',
                   options: {
                     body: JSON.stringify({source, ids: Array.from(new Set(ids))}),
                     method: "POST"
                   }
                }
              })
            })
          }, 2000);
        }
      }
    }
    return next(action);
  }
}
