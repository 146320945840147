import React, {useState} from "react";
import styled from "styled-components";
import ResultsPage from "../components/results_page";
import ErrorBoundary from "../components/error_boundary";
import {UniversalRowItem} from "../components/charts";
import {useJsonFetch} from "../fetching/UseFetch";
import {unhideArtistForMe} from "../reducers/artist";
import {useReduxDispatch} from "../reducers/ReduxHooks";

const Styled = styled.section`
  padding: 10px;

  h1 {
    font-weight: bold;
    font-size: 1.4em;
    margin: 5px 0;
  }
  .hiddenArtistsTable {
    max-width: 400px;
  }
`;



export const UserSettings = () => {
  const { isLoading, data, error, refresh } = useJsonFetch(
    "/api/users/my_hidden_artists"
  );

  const [unhidden, setUnhidden] = useState([]);
  const reduxDispatch = useReduxDispatch();

  function Table({ artists, hiddenArtists }) {
    return (
      <>
        <h1>Hidden Artists</h1>
        {artists.length === 0 ? (
          <p>
            You can hide artists from charts by clicking "Hide from my charts"
            button.
          </p>
        ) : (
          <>
            <p>These artists will not appear in any of your charts.</p>
            <button onClick={refresh}>Refresh</button>
            <div className={"hiddenArtistsTable"}>
              {artists.map(a => {
                return (
                  <UniversalRowItem
                    key={a.id}
                    artist={a.artist}
                    displaySettings={{
                      includeScouts: false,
                      includeAlerts: false,
                      includeFollowerStats: false,
                      shortRow: true
                    }}
                    showOverlay={hiddenArtists.indexOf(a.id) !== -1}
                    handleUnhideForMe={() => {
                      setUnhidden([...hiddenArtists, a.id]);
                      reduxDispatch(unhideArtistForMe(a.artist))
                    }}
                  />
                );
              })}
            </div>
          </>
        )}
      </>
    );
  }

  return (
    <ResultsPage
      pageId="my_hidden_artists"
      pageTitle={"My Settings"}
      loading={isLoading}
      loaded={!isLoading}
      ResultsContainerStyle={{ overflowY: "auto" }}
    >
      <Styled>
        <ErrorBoundary>
          {isLoading ? null : error || !data ? (
            <span>{error}</span>
          ) : (
            <Table artists={data.results} hiddenArtists={unhidden} />
          )}
        </ErrorBoundary>
      </Styled>
    </ResultsPage>
  );
};
