import { combineReducers } from "redux";

import { reduceNamespacedSuggestions } from "../../containers/autosuggest/reducer";
import { reduceNamespacedProfileLookup } from "../../containers/profilelookup/reducer";
import { reducer as reduceApiErrorMessages } from "../../containers/error_messages";
import { reducer as reduceLocation } from "../../routing/hashredux";

/* eslint default-case: "off" */

const _TEAM_FILTERS = {
  sonyaus: { country_code: ["AU", "NZ"] },
  germany: { country_code: ["DE", "AT"] },
  whitelist: { country_code: ["AU", "US", "CA", "GB", "JM"] },
};

function makeFiltersReducer(datasource, initial) {
  return function reduce(state = initial, action) {
    switch (action.type) {
      case "fetchUserCompleted":
      case "loginCompleted":
        const userObj = action.data || action.profile;
        return {
          ...initial,
          currentFilter: _TEAM_FILTERS[userObj.team] || initial.currentFilter,
        };
    }

    if (datasource === action.datasource) {
      switch (action.type) {
        case "filterDatasource":
          return {
            ...state,
            currentFilter: {
              ...(state.currentFilter || {}),
              [action.field]: action.value,
            },
          };
      }
    }
    return state;
  };
}

const initialState = {
  currentArtistIndex: null,
  fetchesInProgress: {},
  displayedErrors: [],
};

function reduceFetchesInProgress(
  state = initialState.fetchesInProgress,
  action
) {
  // Track any api calls in progress
  if (action.originalActionType && action.type.endsWith("Started")) {
    return {
      ...state,
      [action.originalActionType]: true,
    };
  }
  if (action.originalActionType && action.type.endsWith("Completed")) {
    return {
      ...state,
      [action.originalActionType]: false,
    };
  }
  if (action.originalActionType && action.type.endsWith("Failed")) {
    return {
      ...state,
      [action.originalActionType]: false,
    };
  }
  return state;
}

const reduceLoginInProgress = (state = true, action) => {
  // Represents whether we are logging in, or checking if the user is
  // already logged in.
  // FIXXME there's a race condition where is fetchUserFailed happens whilte login
  // this reports wrong.
  switch (action.type) {
    case "fetchUser":
    case "login":
    case "loginStarted":
      return true;

    case "fetchUserCompleted":
    case "loginCompleted":
    case "fetchUserFailed":
    case "loginFailed":
      // 'Completed' indicates a successful login or fetch of
      // existing user
      return false;
  }
  return state;
};

const whitelistInitial = {
  alertsFromDays: 7,
  excludedWhitelistIds: [],
};

const namedReducers = {
  whitelist: (state = whitelistInitial, action) => {
    switch (action.type) {
      case "whitelist/alertsFromDays":
        return { ...state, alertsFromDays: action.value };
      case "whitelist/toggleWhitelistId":
        return {
          ...state,
          excludedWhitelistIds:
            state.excludedWhitelistIds.indexOf(action.whitelistId) === -1
              ? state.excludedWhitelistIds.concat([action.whitelistId])
              : state.excludedWhitelistIds.filter(
                  (i) => i !== action.whitelistId
                ),
        };
    }
    return state;
  },
  location: reduceLocation,
  fetchesInProgress: reduceFetchesInProgress,
  loginInProgress: reduceLoginInProgress,
  loginWithTokenInProgress: (state = false, action) => {
    switch (action.type) {
      case "login":
        return true;
      case "loginCompleted":
      case "loginFailed":
        return false;
      default:
        return state;
    }
  },
  loginPageMessage: (state = "", action) => {
    switch (action.type) {
      case "loginFailed":
        if (typeof action.error === "string") {
          return action.error;
        }
        return state;
      case "loginFailedWithMessage":
        return action.message;
      case "clearUser":
        return action.displayMessage;
      case "fetchUser":
      case "login":
        return null;
      default:
        return state;
    }
  },
  autoSuggest: reduceNamespacedSuggestions,
  profileLookup: reduceNamespacedProfileLookup,
  errorMessages: reduceApiErrorMessages,
  scComparisonLoading: (state = false, action) => {
    switch (action.type) {
      case "fetchScTrackDetailsStarted":
        return true;
      case "fetchScTrackDetailsCompleted":
      case "fetchScTrackDetailsFailed":
        return false;
    }
    return state;
  },
  soundcloudComparisons: (state = [], action) => {
    switch (action.type) {
      case "soundcloudComparisons/add":
        return state.concat(action.scid);

      case "soundcloudComparisons/remove":
        return state.filter((s) => s !== action.scid);
    }
    return state;
  },
  artist: combineReducers({
    fetchFailureInfo: (state = null, action) => {
      switch (action.type) {
        case "fetchArtist":
        case "fetchArtistCompleted":
          return null;
        case "fetchArtistFailed":
          return {
            status: (action.errorObject || {}).status,
          };
        default:
          return state;
      }
    },
    spideringInProgress: (state = false, action) => {
      switch (action.type) {
        case "fetchUpdatedArtist":
          return true;
        case "fetchUpdatedArtistCompleted":
        case "fetchUpdatedArtistFailed":
          return false;
      }
      return state;
    },
    sourceUpdateInProgress: (
      state = { sc: false, in: false, tw: false, spy: false },
      action
    ) => {
      switch (action.type) {
        case "manageArtistSources/upsert":
          return {
            ...state,
            [action.source]: true,
          };

        case "manageArtistSources/upsertCompleted":
        case "manageArtistSources/upsertFailed":
          return {
            ...state,
            [action.source]: false,
          };
      }
      return state;
    },
    isEditingSources: (state = false, action) => {
      switch (action.type) {
        case "manageArtistSources/startEdit":
          return true;

        case "manageArtistSources/upsertCompleted":
        case "manageArtistSources/finishEdit":
          return false;
      }
      return state;
    },
  }),

  lastFetchedYtVideoIds: (state = [], action) => {
    switch (action.type) {
      case "youtube/fetchDataCompleted":
        return action.data.videos.map((v) => v.ytid);
    }
    return state;
  },
  youtubeFetchInProgress: (state = false, action) => {
    switch (action.type) {
      case "youtube/fetchData":
        return true;
      case "youtube/fetchDataCompleted":
        return false;
    }
    return state;
  },
};

["soundcloud_by_day", "twitter_by_day", "instagram_by_day"].forEach(
  (datasource) => {
    namedReducers[datasource] = makeFiltersReducer(datasource, {
      currentFilter: {},
      currentSort: {},
    });
  }
);

export const reduceUi = combineReducers(namedReducers);
