import React from "react";
import styled from "styled-components";

import {Link} from "react-router-dom";
import {datify, daysAgo, isEmptyObject, isNumber} from "../../utils";
import numeral from "numeral";
import moment from "moment";
import SparkLine from "react-canvas-spark-line";
import {
  blackish,
  linkblue,
  purplish,
  soundcloudOrange,
  sourceIconMap,
  spotifyGreen, tiktokBlack,
  youtubeRed
} from "../../styles/styleConsts";

import * as polished from "polished";
import {LittleSourceIcon} from "../primitives/things";
import {splitTrackTitle} from "../track_link";

import soundcloudFallback from "../../images/channel-icon-large-soundcloud@2x.png";
import spotifyFallback from "../../images/channel-icon-large-spotify@2x.png";
import tiktokFallback from "../../images/tiktok.png";
import artistFallback from "../../images/channel-icon-admin-dark@2x.png";
import customItemFallback from "../../images/channel-icon-large-demos@2x.png";
import {addLocationParts} from "../../routing/navigator";

import {statelessPure} from "../../utils/hocs";
import {Flag} from "../flag_icons";
import country_mapping from "../primitives/country_mapping";
import {AvatarContainer} from "../avatar";

function ValueWithDelta({ latest, d7, metric }) {
  const latestVal = (latest || {})[metric];
  if (typeof latestVal !== "number") {
    return null;
  }
  const prevVal = (d7 || {})[metric];
  const pctChange =
    typeof prevVal !== "number" ? null : (latestVal - prevVal) / prevVal;
  const upDown =
    pctChange === null
      ? null
      : pctChange * 100 > 1
      ? " ▲"
      : pctChange * 100 < -1
      ? " ▼"
      : null;
  return (
    <React.Fragment>
      <span className="value">{numeral(latestVal).format("0,0")} </span>
      <span className={upDown === " ▲" ? "up" : "down"}>
        {upDown && upDown + numeral(Math.abs(pctChange)).format("0.0%")}
      </span>
    </React.Fragment>
  );
}

function FollowerCount({ latest, d7, metric, label, source }) {
  const latestVal = (latest || {})[metric];
  if (typeof latestVal !== "number") {
    return null;
  }
  return (
    <div className="followerCount">
      <LittleSourceIcon source={source} />
      {/*<span>{label}: </span>*/}
      <ValueWithDelta {...{ latest, d7, metric }} />
    </div>
  );
}

const StyledRecentAlertsCell = styled.div`
  padding-left: 8px;

  .recentActivity {
    display: block;
    padding-left: 12px;
    padding-top: 0;

    border-left: 1px solid grey;

    white-space: normal;
    position: relative;
    overflow: visible;
    max-width: 400px;
    min-height: 50px;
    padding-bottom: 5px;
    margin-bottom: 0;
    margin-top: 0;

    img {
      display: inline-block;
      vertical-align: middle;
      width: 20px;
      height: 20px;
      margin-left: -20px;
      margin-right: 5px;
      outline: 2px solid white;
    }

    .recentActivityDate {
      display: inline-block;
      vertical-align: middle;
      font-weight: normal;
      color: #8f8f8f;
    }
    .recentActivityTitle {
      display: inline-block;
      vertical-align: middle;
      font-weight: normal;
      color: #8f8f8f;
      &:before {
        content: "\u2015";
        padding: 0 3px;
      }
    }
    .recentActivityWords {
      margin-top: 3px;
    }
  }
`;

function maybeYearDateFormat(asOf, useFromNow = true) {
  if (typeof asOf === "string") {
    asOf = new Date(asOf);
  }
  const formattedDate =
    daysAgo(asOf) < 4 && useFromNow
      ? moment(asOf).fromNow()
      : asOf.getFullYear() === new Date().getFullYear()
      ? moment(asOf).format("Do MMM")
      : moment(asOf).format("Do MMM YYYY");
  return formattedDate;
}

export function ArtistAlertsList({ recentAlerts, showAll, extraCss = "" }) {
  if (!recentAlerts) {
    return null;
  }
  let recentActivity = [...recentAlerts];

  recentActivity.sort((a, b) =>
    a.as_of > b.as_of ? -1 : a.as_of === b.as_of ? 0 : 1
  );

  let andMore = null;
  if (!showAll && recentActivity.length > 2) {
    andMore = (
      <span onMouseOver={() => console.log(arguments)}>
        and {recentActivity.length - 2} more...
      </span>
    );
    recentActivity = [recentActivity[0]];
  }

  const Wrapper = styled(StyledRecentAlertsCell)([extraCss]);
  return (
    <Wrapper>
      {recentActivity.map((act, i) => {
        const source = act.type || "man";

        const [, iconBg] = sourceIconMap[source] || [undefined, undefined];
        const asOf = datify(act.as_of);
        const newBoldness = {
          fontWeight: act.isNew ? "bold" : "inherit"
        };
        const formattedDate = maybeYearDateFormat(asOf);

        return (
          <div
            key={i}
            className={"recentActivity"}
            style={{ borderColor: iconBg }}
          >
            <LittleSourceIcon source={act.type || "man"} />
            <div className="recentActivityDate">{formattedDate}</div>
            <div className="recentActivityTitle">{act.title}</div>
            <div className="recentActivityWords" style={newBoldness}>
              {act.words}
            </div>
          </div>
        );
      })}
      {andMore}
    </Wrapper>
  );
}

function RecentAlertsCell({ artist, displaySettings }) {
  const recentAlerts = artist.displayedAlerts || artist.recentAlerts;
  const recentActivity = recentAlerts.filter(
    act => act.isNew || daysAgo(act.as_of) < 15
  );
  if (displaySettings.shortRow && recentActivity.length < 1) {
    return <small style={{color: "gray"}}>No recent alerts</small>
  }
  return (
    <React.Fragment>
      {!displaySettings.shortRow && <label>{`Recent Alerts (${recentActivity.length}):`}</label>}
      <ArtistAlertsList
        recentAlerts={recentActivity}
        showAll={!displaySettings.shortRow}
        extraCss={`
        max-height: ${displaySettings.shortRow ? '50px' : '100px'};
        margin-top: ${displaySettings.shortRow ? '0' : '3px'};
        overflow: ${displaySettings.shortRow ? 'hidden' : 'auto'};
        .recentActivity {
          font-size: 10px;
          border-left: none !important;
        }
      `}
      />
    </React.Fragment>
  );
}

export function ArtistNameCell({ artist, displaySettings, useSpinner, handleHide }) {
  return (useSpinner
      ? <em>Loading artist info...</em>
      : <React.Fragment>
        {!displaySettings.shortRow && (
          <label>
            {"Artist:"}
          </label>
        )}
        {
          displaySettings.shortRow
           ? <Link to={"/artist/" + artist.keys[0]}><div className="artistInfoName" style={{color: 'black' }} title={artist.name}>{artist.name}</div></Link>
           : <div className="artistInfoName" title={artist.name}>{artist.name}</div>
        }
        {artist.bio && (
          <div className="artistInfoBio">{artist.bio.substring(0, 128)}</div>
        )}
        {displaySettings.includeWhitelistedInfo && artist.whitelistedInfo && (
          <div className="whitelistedInfo">
            {artist.whitelistedInfo.private ? <b><LittleSourceIcon source={'wl'} style={{top: "3px"}}/> Mine</b> : null}
            {artist.whitelistedInfo.team ? <b><LittleSourceIcon source={'wl'} style={{top: "3px"}}/> Team</b> : null}
          </div>
        )}
        {!displaySettings.shortRow && artistLink(artist)}

        {handleHide && (artist.artistKey || artist.query_key)
          ? <StyledRowButton onClick={() => handleHide(artist.artistKey || artist.query_key)}>Hide Artist</StyledRowButton>
          : null
        }
        </React.Fragment>
  );
}

function CustomItemCell({ item }) {
  return (
    <React.Fragment>
      <h1>
        <label>Title:</label>
        {item.title}
      </h1>
      <span className="released">{item.headline}</span>
      {item.genre && <span className="genre">{item.genre}</span>}
      {item.url && (
        <a href={item.url} target="_blank" rel="noopener noreferrer">
          Listen Now!
        </a>
      )}
    </React.Fragment>
  );
}

export function renderCopyright(copyrightsArray) {
  // from the spotify api, form is an array of {text, type:C|P}. we prefer P
  const pline = copyrightsArray.find(obj => obj.type === "P");
  let text = "";
  if (pline && pline.text) {
    if (!pline.text.toLowerCase().startsWith("(p)")) {
      text = "Ⓟ " + pline.text;
    } else {
      text = pline.text;
    }
  } else {
    const anyLine = copyrightsArray.find(obj => obj.text);
    if (anyLine) {
      text = anyLine.text;
    }
  }
  return text;
}

function getReleaseDateFromNow(track) {
  const date = track.releaseDate || track.published || track.release_date;
  return date ? moment(date).fromNow() : null;
}

const TrackInfoCell = React.memo(function TrackInfoCell({ track, displaySettings }) {
  const copyText = renderCopyright(track.copyrights || []);
  const releaseFromNow = getReleaseDateFromNow(track);
  return (
    <React.Fragment>
      {!displaySettings.shortRow && <label>Track:</label>}
      {splitTrackTitle(track.title || "", "trackTitle")}
      {!displaySettings.shortRow && (
        <span className="released">
        {
          track.removedAt
          ? <i>Removed {maybeYearDateFormat(track.removedAt, false)}</i>
          : releaseFromNow
          ? ("Released " + releaseFromNow)
          : null
        }
      </span>
      )}
      {(copyText || track.countryCodeIsrc) && (
        <span className="copyright" title={copyText}>
          {track.countryCodeIsrc && track.countryCodeIsrc !== "00" && (
            <Flag
              code={track.countryCodeIsrc.toLowerCase()}
              style={{
                zoom: 0.75,
                marginRight: "3px",
                verticalAlign: "middle",
                position: "relative",
                top: "0px"
              }}
            />
          )}
          {copyText || "Label unknown"}
        </span>
      )}
      {track.ttid && track.author && (
        <div style={{margin:"4px 0"}}>
          {track.author}
        </div>
      )}
      {!displaySettings.shortRow && (
        <React.Fragment>
          <span className="genre">{track.genre}</span>
          {/*{track.popularity && (*/}
          {/*<h3>*/}
          {/*<ValueWithDelta latest={track} metric="popularity" /> popularity*/}
          {/*</h3>*/}
          {/*)}*/}
          {/*{track.total_plays && <h3><ValueWithDelta latest={track} metric='total_plays'/> plays</h3>}*/}
          {track.ttid && track.web_url && (
            <a
              href={track.web_url}
              target=""
              rel="noopener noreferrer"
            >
              View on TikTok
            </a>
          )}
          {track.spotify_uri && (
            <a className="listenSpotify" href={track.spotify_uri}>
              Listen on Spotify
            </a>
          )}
          {track.soundcloud_url && (
            <a
              href={track.soundcloud_url}
              target=""
              rel="noopener noreferrer"
            >
              Listen on SoundCloud
            </a>
          )}
          {/*<span className="likes">{numeral(+track.total_likes||0).format('0,0')} likes.</span>*/}
        </React.Fragment>
      )}
    </React.Fragment>
  );
});

function Top200Cell({ top200, displaySettings }) {
  if (!top200 || !top200.length) {
    return null;
  }
  function Row(vals) {
    const country = vals[0];
    const change = vals[4] || 0;
    const streams = vals[3];
    const upDown = change === 0 ? "flat" : change * 100 > 1 ? "up" : "down";
    return (
      <React.Fragment>
        <span className="region">
          {country.toUpperCase() === "GL"
            ? "Global"
            : country_mapping[country.toUpperCase()]}
        </span>
        <span className="value">{numeral(vals[1]).format("0,0")}</span>
        <span className={upDown}>{numeral(Math.abs(change)).format("0")}</span>
        <span className="streams">
          {numeral(streams).format("0.0 a")} streams
        </span>
      </React.Fragment>
    );
  }

  function ShortItem({vals}) {
    let country = vals[0].toUpperCase();
    if (country_mapping[country] === 'United States') {
      country = 'US'; // special case for 'QX' and 'QZ' isrc
    }
    return (
        <span>{
          (country.toUpperCase() === "GL" ? "Global" : country)
            + ":\u00A0"
            + numeral(vals[1]).format("0,0")
            + " "
        }</span>
    );
  }

  return displaySettings.shortRow
    ? (<div className="trackTop200 flexBox">{top200.map( v => <ShortItem key={v[0]} vals={v} />)}</div>)
    : (
    <div className="trackTop200 flexBox">
      <span className="title">Top 200</span>
      {top200.map(row => (
        <div className="row" key={row[0]}>
          {Row(row)}
        </div>
      ))}
    </div>
  );
}

function playlistStats(track, displaySettings = {}) {
  if (!track.playlistStats) {
    return null;
  }
  const {
    currentPlaylistCount,
    allPlaylistCount,
    currentPlaylistFollowers,
    allPlaylistFollowers
  } = track.playlistStats;

  if (displaySettings.shortRow) {
    return currentPlaylistCount < 1
      ? <small style={{color:"gray"}}>Not on any tracked playlists</small>
      : <div className="currentPlaylistCount">
          {"On\u00a0"}<b>{currentPlaylistCount}</b>{"\u00a0playlists, "}
          {numeral(currentPlaylistFollowers).format("0,0a")}{"\u00a0followers"}
        </div>
  }

  return (
    <React.Fragment>
      <small className="subTitle">Playlists:</small>
      {currentPlaylistCount < 1 ? null : (
        <div className="currentPlaylistCount">
          <b>{currentPlaylistCount}</b> currently{", "}
          {numeral(currentPlaylistFollowers).format("0,0a")} followers{" "}
        </div>
      )}
      <div className="allPlaylistCount">
        <b>{allPlaylistCount}</b> total{", "}
        {allPlaylistFollowers > 0
          ? numeral(allPlaylistFollowers).format("0,0a") + " followers"
          : ""}
      </div>
    </React.Fragment>
  );
}

export function UniversalSparkline({video, track, timeseries, color}) {
  const stats_field = timeseries || (track||{}).df_stats || (track||{}).stats || (video||{}).stats || [];
  const factor = 1; //Math.floor(stats_field.length / 15);
  const stats = stats_field.reduce((acc, r, i) => {
    if (i % factor === 0) {
      acc.push(r[1]);
    }
    return acc;
  }, []);
  return <>
    <div className="trackPopularitySparkline">
      <SparkLine
        animate={false}
        width={120}
        height={32}
        color={
          color ? color : video ? youtubeRed : track && isNumber(track.popularity) ? spotifyGreen : soundcloudOrange
        }
        data={stats}
        includeZero={true} // Default: true
      />
    </div>
  </>;
}

export const TrackStatsCell = (function TrackStatsCell({
  track,
  video,
  ttSound,
  displaySettings
}) {
  if (isEmptyObject(track) && isEmptyObject(video) && isEmptyObject(ttSound)) {
    console.log("empty", track, video)
    return <span></span>;
  }

  track = track || {};


  if (ttSound) {
    return <div>
      <h1>
        <label>Stats:</label>
      </h1>
      {ttSound.num_posts && (
        <div style={{margin:"4px 0", fontSize: '1.2em'}}>
          {numeral(ttSound.num_posts).format('0,0')} videos
        </div>
      )}
      {ttSound.first_seen && (
        <div style={{margin:"4px 0", fontSize: '1.2em'}}>
          First seen: {moment(ttSound.first_seen).fromNow()}
        </div>
      )}
    </div>
  }

  return (
    <React.Fragment>
      {!displaySettings.shortRow && (
        <h1>
          <label>Stats:</label>
        </h1>
      )}
      <small className="subTitle">
        {video ? "Views" : track && isNumber(track.popularity) ? "Popularity:" : "Daily Plays"}
      </small>
      <UniversalSparkline video={video} track={track}/>

      {track.popularity && (
        <div className="trackPopularity">
          <b>{track.popularity}</b>
          <i>track</i>
        </div>
      )}
      {track.streamsEstimate100 && displaySettings.shortRow && (
        <div>
          <b style={{fontWeight: "bold"}}>
            {numeral(track.streamsEstimate100).format("0.0 a")}
          </b>
          <i> est. streams</i>
        </div>
      )}
      {track.total_plays && (
        <div className="trackPopularity">
          <b>{numeral(track.total_plays).format("0.0 a")}</b>
          <i>total plays</i>
        </div>
      )}
      {video && (
        <div className="trackPopularity">
          <b>
            {numeral(video.stats[video.stats.length - 1][1]).format("0.0 a")}
          </b>
          <i>total views</i>
        </div>
      )}
      {!displaySettings.shortRow && track.playlistStats && (
        <React.Fragment>
          {track.artistStats && (
            <div className="artistPopularity">
              <b>{track.artistStats[track.artistStats.length - 1][2]}</b>
              <i>artist</i>
            </div>
          )}
          {playlistStats(track)}
          <Link
            className="spotifyTrackMoreDetails"
            to={"/spotify2/detail/" + track.spyid}
          >
            More details
          </Link>
        </React.Fragment>
      )}
    </React.Fragment>
  );
});

const TrackExpandStatsCell = statelessPure(function TrackExpandStatsCell({
  track,
  video
}) {
  track = track || {};
  return <>
    {track.scid && (
      <Link className="expandStats" to={addLocationParts("sct", track.scid)}><small>Stats</small>
      </Link>
    )}
    {track.spyid && (
      <Link
        className="expandStats"
        to={addLocationParts("spyt", track.spyid)}
      >
        <small>Stats</small>
      </Link>
    )}
    {video && video.ytid && (
      <Link
        className="expandStats"
        to={addLocationParts("ytvid", video.ytid)}
      >
        <small>Stats</small>
      </Link>
    )}
  </>
});

const TrackListenLinkCell = statelessPure(function TrackListenLinkCell({
  track,
  video
}) {
  track = track || {};
  return (
    <React.Fragment>
      {track.spotify_uri && (
        <a className="listenSpotify" href={track.spotify_uri}><small>Listen</small></a>
      )}
      {track.soundcloud_url && (
        <a
          className="listenSpotify"
          href={track.soundcloud_url}
          rel="noopener noreferrer"
          target=""
        >
          <small>Listen</small>
        </a>
      )}
      {video && video.ytid && (
          <a
            className="listenSpotify"
            href={"https://youtube.com/watch?v=" + video.ytid}
            rel="noopener noreferrer"
            target=""
          >
            <small>Watch</small>
          </a>
      )}
    </React.Fragment>
  );
});

const PlaylistStatsCell = statelessPure(function PlaylistStatsCell({ track, displaySettings }) {
  return <React.Fragment>{playlistStats(track, displaySettings)}</React.Fragment>;
});

const ScTrackStatsCell = statelessPure(function ScTrackStatsCell({ track }) {
  const totalComments = track.total_comments;
  const totalLikes = track.total_likes;
  return (
    <React.Fragment>
      <div className="scLikeOrComment">{(totalLikes < 1000 ? totalLikes : numeral(totalLikes).format("0.0 a"))+"\u00A0likes"}</div>
      <div className="scLikeOrComment">{(totalComments < 1000 ? totalComments : numeral(totalComments + 1000).format("0.0 a")) + "\u00A0cmmts."}</div>
    </React.Fragment>
  );
});

function VideoInfoCell({ video }) {
  return (
    <React.Fragment>
      <h1>
        <label>Video:</label>
        {splitTrackTitle(video.title)}
      </h1>
      <span className="released">
        Released {moment(video.published).fromNow()}
      </span>
      <span className="channel">{video.channel_name}</span>
      <h3>
        <span className="value">
          {numeral(video.view_count).format("0,0")}{" "}
        </span>{" "}
        views
      </h3>
      {video.removed_at ? (
        <i>Video removed {maybeYearDateFormat(video.removed_at, false)}</i>
      ) : (
        <a
          href={"https://www.youtube.com/watch?v=" + video.ytid}
          target=""
          rel="noopener noreferrer"
        >
          Watch on YouTube
        </a>
      )}
    </React.Fragment>
  );
}

export const TALL_ROW_HEIGHT = 150;
export const SHORT_ROW_HEIGHT = 68;

const StyledUniversalRowItem = styled.div.attrs(p => ({
  className: "chartRowContainer"
}))`
  width: 100%;
  height: ${props => (props.short ? SHORT_ROW_HEIGHT : TALL_ROW_HEIGHT)}px;
  margin-top: 5px;
  position: relative;
  overflow: hidden;

  a {
    text-decoration: none;
    color: ${linkblue};
    &:hover {
      text-decoration: underline;
      opacity: 0.8;
    }
  }
  section {
    display: flex;
    width: 100%;
    height: 100%;
    border-bottom: 1px solid #e5e5e5;
    color: ${blackish};
  }

  label {
    display: block;
    text-transform: uppercase;
    opacity: 0.7;
    font-weight: 400;
    font-size: 11px;
    white-space: nowrap;
    max-width: 100%;
    text-overflow: ellipsis;
  }

  h1 {
    display: block;
    font-size: 18px;
    font-weight: 700;

    b {
      display: inline-block;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  small {
    font-weight: 300;
    display: block;
    font-size: 11px;
    opacity: 0.8;
  }

  .genre {
    display: block;
  }
  .copyright {
    display: block;
    width: 100%;
    margin: 1px 0 0 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 9px;
    line-height: 9px;
    padding: 0 0 5px 0;
    cursor: help;
  }

  .flexBox {
    height: ${props =>
      (props.short ? SHORT_ROW_HEIGHT : TALL_ROW_HEIGHT) * 0.8666}px;
    margin-top: 10px;
    border-right: 1px solid #e5e5e5;
    padding: 0 15px;
    &:last-child {
      border-right: none;
    }
  }
  
  .artwork {
    width: ${props => (props.short ? SHORT_ROW_HEIGHT : TALL_ROW_HEIGHT)}px;
    margin: 0 !important;
    padding: 0 !important;
    height: 100%;
    border-right: none;
  }

  .trackInfo {
    flex: 5;
    max-width: 300px;
    min-width: 30px;
  }

  .trackStats {
    flex: 4;
    min-width: 120px;
    max-width: 210px;

    font-size: 10px;
  }
  .shortRow {
   .flexBox {
      border-right:none;
      padding: 0 8px;
      margin-top: 5px;
    }
    .trackStats {
      flex: 0;
      width: 150px;
      min-width: 150px;
      max-width: 150px;
    }
    .trackListenLink {
      flex: 0;
      min-width: 42px;
      max-width: 42px;
      text-align: center;
      padding: 2px 0;
      font-size: 18px;
    }
    .releasedDateCell {
      flex: 0;
      min-width: 80px;
      max-width: 80px;
    }
    .scLikesCommentsCell {
      flex: 0;
      min-width: 85px;
      max-width: 85px;
      .scLikeOrComment {
        white-space: nowrap;
        overflow: hidden;
        font-size: 11px;
      }
    }
    .scouts {
      min-width: 215px;
      flex: 0;
      overflow-y: auto;
    }
    .recentActivity {
      border: none;
    }
    .recentAlerts {
      flex: 0;
      min-width: 270px;
      width: 270px;
      max-width: 270px;
    }
    .recentActivityDate {
      width: 52px;
      white-space: nowrap;
      overflow: hidden;
    }
    .recentActivityTitle {
      width: ${140}px;
      overflow: hidden;
      white-space: nowrap;
    }
    .recentActivityWords {
      overflow: hidden;
    }
    .trackTitle b,
    .trackTitle small,
    .artistInfoName {
      font-size: 14px;
    } 
    .artistInfoBio {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

 

  .artistInfo {
    flex: 3;
    min-width: 40px; /* Ensures the ellipses work on the name */
    max-width: 400px;
  }

  .trackTitle b,
  .trackTitle small,
  .artistInfoName {
    display: block;
    font-size: 19px;
    font-size: 19px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .trackTitle small {
    font-size: 11px !important;
  }

  .artistInfoBio {
    max-height: 50px;
    font-size: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 3px;
  }

  .followers {
    width: 170px;
    min-width: 170px;
    flex: 0;
  }
  .scouts {
    width: 130px;
    min-width: 130px;
    flex: 1;
    overflow-y: auto;
  }

  @media (max-width: 900px) {
    .artwork {
      width: 50px;
    }
    .followers {
      display: none;
    }
  }

  .embed {
    width: 150px;
    height: 140px;
    right: 0;
    top: 0;
  }

  .followerCount {
    display: flex;
    align-items: center;

    vertical-align: middle;
    .LittleSourceIcon {
      display: inline;
      margin-right: 5px;
    }
  }

  .trackTop200 {
    flex: 0;
    .title {
      display: inline-block;
      background-color: ${spotifyGreen};
      font-size: 9px;
      margin: 1px auto 2px;
      padding: 1px 2px;
      text-transform: uppercase;
      color: white;
      font-weight: bold;
    }
    .row {
      display: table-row;
      > span {
        display: table-cell;
        text-align: left;
      }
    }
    .region {
      padding: 0 3px;
    }
    .value {
      text-align: right;
    }
    .flat {
      visibility: hidden;
      :after {
        content: "- ";
        visibility: visible;
      }
    }
    .up {
      :before {
        content: "▲";
      }
    }
    .down {
      :before {
        content: "▼";
      }
    }
  }

  .recentAlerts {
    flex: 3;
    padding-right: 0; /* space for scrollbar if needed */
  }

  .recentActivity {
    img {
      display: inline-block;
      margin-right: 5px;
    }
  }

  .up {
    color: green;
    padding-left: 5px;
  }

  .down {
    color: red;
    padding-left: 5px;
  }

  .snoozeArtist,
  .hideArtist {
    margin: 2px;
    padding: 3px 3px;
  }

  @keyframes pulse {
    0% {
      background-color: rgba(173, 195, 209, 0.6);
    }
    50% {
      background-color: rgba(99, 212, 214, 0.77);
    }
    100% {
      background-color: rgba(173, 195, 209, 0.34);
    }
  }

  .requestsInProgress {
    animation: pulse 1s infinite;
    color: transparent;
  }
  .released {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }

  .trackPopularitySparkline {
    overflow: hidden;
    max-width: 120px;
    margin-left: auto;
  }

  .artistPopularity,
  .trackPopularity {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    opacity: 0.7;

    b {
      font-weight: bold;
      display: inline;
      padding-right: 0.3em;
    }
    i {
      display: inline;
    }
  }
  .trackPopularity {
    margin-top: -32px;
  }

  .subTitle {
    margin-top: 3px;
    opacity: 0.7;
  }
  
  .whitelistedInfo b {
    display: inline-block;
    padding: 3px 12px 6px 0;
    font-size: 10px;
    text-transform: uppercase;
    img {
      position: relative;
      top: 3px;
      opacity: 0.7;
    }
  }

  .chartItemOverlay {
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8);
    border: 1px solid #e5e5e5;

    button,
    span {
      font-weight: bold;
      color: #444;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;

export function getArtworkStyle({video, track, artist, customItem, ttSound}, extraStyles={}) {
  const artworkStyle = {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    backgroundColor: "transparent",
    ...extraStyles,
  };
  if (video) {
    artworkStyle.backgroundImage = `url(https://img.youtube.com/vi/${
      video.ytid
    }/default.jpg)`;
  } else if (ttSound) {
    if (ttSound.cover_image_url) {
      artworkStyle.backgroundImage = `url(${ttSound.cover_image_url})`;
    } else {
      artworkStyle.backgroundImage =  artworkStyle.backgroundImage = `url(${tiktokFallback})`;
      artworkStyle.backgroundSize = "45%";
      artworkStyle.backgroundColor = tiktokBlack;
    }
  } else if (track && track.artwork_url) {
    artworkStyle.backgroundImage = `url(${track.artwork_url})`;
  } else if (artist && artist.avatar) {
    artworkStyle.backgroundImage = `url(${artist.avatar})`;
  } else if (artist && artist.avatar_urls && artist.avatar_urls.length > 0) {
    artworkStyle.backgroundImage = `url(${artist.avatar_urls[0]})`;
  } else if (track && track.source === "sc") {
    artworkStyle.backgroundImage = `url(${soundcloudFallback})`;
    artworkStyle.backgroundSize = "45%";
    artworkStyle.backgroundColor = soundcloudOrange;
  } else if (track && track.source === "spy") {
    artworkStyle.backgroundImage = `url(${spotifyFallback})`;
    artworkStyle.backgroundSize = "45%";
    artworkStyle.backgroundColor = spotifyGreen;
  } else if (customItem) {
    artworkStyle.backgroundImage = `url(${customItemFallback})`;
    artworkStyle.backgroundSize = "55%";
    artworkStyle.backgroundColor = polished.saturate(0.1, purplish);
  } else {
    artworkStyle.backgroundImage = `url(${artistFallback})`;
    artworkStyle.backgroundSize = "45%";
    artworkStyle.backgroundColor = blackish;
  }
  return artworkStyle;
}

export function getTitleCell({customItem, track, video, ttSound}, displaySettings) {
  return <>
    {customItem && (
      <div className="trackInfo flexBox">
        <CustomItemCell
          item={customItem}
          displaySettings={displaySettings}
        />
      </div>
    )}
    {track && (<>
      <div className="trackInfo flexBox">
        <TrackInfoCell track={track} displaySettings={displaySettings}/>
      </div>
      {/*{displaySettings.shortRow &&*/}
      {/*<div className="releasedDateCell flexBox">{getReleaseDateFromNow(track) || 'Unknown'}</div>}*/}
    </>)}
    {ttSound && (
      <div className="trackInfo flexBox">
        <TrackInfoCell track={ttSound} displaySettings={displaySettings}/>
      </div>
    )}
    {video &&
    (displaySettings.shortRow ? (
      <div className="trackInfo flexBox">
        <TrackInfoCell track={video} displaySettings={displaySettings}/>
      </div>
    ) : (
      <div className="videoInfo flexBox">
        <VideoInfoCell video={video} displaySettings={displaySettings}/>
      </div>
    ))}
  </>;
}

export function getTrackStatsCell({track, video, ttSound, displaySettings}) {
  return <>
    {(
      <div className="trackStats flexBox">
        <TrackStatsCell
          ttSound={ttSound}
          track={track}
          video={video}
          displaySettings={displaySettings}
        />
      </div>
    )}
    {track &&
    track.playlistStats &&
    displaySettings.shortRow && (
      <div className="trackStats flexBox">
        <PlaylistStatsCell
          track={track}
          displaySettings={displaySettings}
        />
      </div>
    )}
    {track &&
    typeof track.total_likes === "number" &&
    displaySettings.shortRow && (
      <div className="scLikesCommentsCell flexBox">
        <ScTrackStatsCell
          track={track}
          displaySettings={displaySettings}
        />
      </div>
    )}
    {track && track.top200 && (
      <Top200Cell top200={track.top200} displaySettings={displaySettings}/>
    )}
    {displaySettings.shortRow && (<>
      <div className="trackListenLink flexBox">
        <TrackListenLinkCell
          track={track}
          video={video}
        />
      </div>
      <div className="trackListenLink flexBox">
        <TrackExpandStatsCell
          track={track}
          video={video}
        />
      </div>
    </>)}
  </>;
}

const StyledRowButton = styled.button`
  font-size: 9px !important;
  padding: 2px 6px !important;
  background-color: rgba(245, 245, 245, 0.8) !important;
  border: 1px solid #ddd !important;
  &:hover {
    opacity: 0.5;
  }
`;


export function UniversalRowItem({
  rowIndex,
  track,
  artist,
  video,
  ttSound,
  customItem,
  showOverlay,
  dataIsLoading,
  handleHide,
  handleSnooze,
  handleMarkSigned,
  handleRefreshChart,
  handleHideForMe,
  handleUnhideForMe,
  displaySettings = {},
  rowStyle
}) {
  const includeFollowerStats = displaySettings.includeFollowerStats;
  const allowedScoutsMap = displaySettings.allowedScoutsMap;
  const artworkStyle = getArtworkStyle({video, track, artist, customItem, ttSound});

  const includeTrackStats =
    displaySettings["includeTrackStats"] &&
    ((track && track.stats) || (video && video.stats) || ttSound);

  const artistIsCacheMiss = artist && artist.cache_status === 'LOADING'
  if (artistIsCacheMiss) {
    artist = null;
  }

  if (artist && !artist.keys) {
    console.warn("Unexpected artist record returned")
    artist = null;
  }

  const includeScouts = !(displaySettings["includeScouts"] === false) && artist;
  const includeAlerts =
    displaySettings["includeAlerts"] && artist && artist.recentAlerts;

  const requestsInProgress = displaySettings.requestsInProgress || {};

  return (
      <StyledUniversalRowItem short={displaySettings.shortRow} style={rowStyle}>
        {dataIsLoading ? (
          <div className="chartItemOverlay">
            <span>Loading...</span>
          </div>
        ) : null}
        {(track && track.showOverlay) || showOverlay ? (
          <div className="chartItemOverlay">
            {handleRefreshChart ? (
              <button onClick={handleRefreshChart}>
                Refresh to hide this artist
              </button>
            ) : (
              <span>Refresh to update chart</span>
            )}
          </div>
        ) : null}
        <section className={displaySettings.shortRow ? "shortRow" : ""}>
          <span className="artwork flexBox" style={artworkStyle}>
          {""}
            {handleHideForMe && artist && artist.keys.length > 0 ? (
              <StyledRowButton
                className={"hideArtist"}
                onClick={() => handleHideForMe(artist)}
              >
                Hide from My Charts
              </StyledRowButton>
            ) : null}
            {handleUnhideForMe && artist && artist.keys.length > 0 ? (
              <StyledRowButton
                className={"hideArtist"}
                onClick={() => handleUnhideForMe(artist)}
              >
                Unhide this artist
              </StyledRowButton>
            ) : null}
            {handleMarkSigned && artist && artist.spyid ? (
              <StyledRowButton
                className={
                  "hideArtist" +
                  (requestsInProgress[artist.spyid] ? " requestsInProgress" : "")
                }
                onClick={
                  requestsInProgress[artist.spyid]
                    ? undefined
                    : () => handleMarkSigned(artist.spyid)
                }
              >
                Mark as Signed
              </StyledRowButton>
            ) : null}
            {handleHide && artist && artist.query_key ? (
              <StyledRowButton
                className="hideArtist"
                onClick={() => handleHide(artist.query_key)}
              >
                Non-Artist / Deactivate
              </StyledRowButton>
            ) : null}
            {handleSnooze &&
            artist &&
            artist.query_key &&
            artist.query_key.indexOf("spy/") === -1 ? (
              <StyledRowButton
                className="snoozeArtist"
                onClick={() => handleSnooze(artist.query_key)}
              >
                Snooze from Charts
              </StyledRowButton>
            ) : null}
        </span>
          {getTitleCell({customItem, track, video, ttSound}, displaySettings)}
          {includeTrackStats && getTrackStatsCell({track, video, ttSound, displaySettings})}
          {artist && (<>
            {displaySettings.shortRow && artist.keys && <div className="trackListenLink flexBox">
              <Link to={"/artist/" + artist.keys[0]}><small>Artist<br/>Page</small></Link>
            </div>}
            <div className="artistInfo flexBox">
              <ArtistNameCell artist={artist} displaySettings={displaySettings}/>
            </div>
          </>)}
          {!artist && artistIsCacheMiss && (
            <div className="artistInfo flexBox">
              <ArtistNameCell useSpinner={true}/>
            </div>
          )}
          {includeAlerts && (
            <div className="recentAlerts flexBox">
              <RecentAlertsCell
                artist={artist}
                displaySettings={displaySettings}
              />
            </div>
          )}
          {includeFollowerStats && artist && (
            <div className="followers flexBox">
              {!displaySettings.shortRow && <label>Followers:</label>}
              <FollowerCount
                {...artist.sc_stats}
                metric="followers_count"
                label="SoundCloud"
                source="sc"
              />
              <FollowerCount
                {...artist.spy_stats}
                metric="followers"
                label="Spotify"
                source="spy"
              />
              <FollowerCount
                {...artist.in_stats}
                metric="followed_by_count"
                label="Instagram"
                source="in"
              />
              <FollowerCount
                {...artist.tw_stats}
                metric="followers_count"
                label="Twitter"
                source="tw"
              />
              <FollowerCount
                {...artist.yt_stats}
                metric="subscriber_count"
                label="YouTube"
                source="yt"
              />
            </div>
          )}

          {includeScouts && (
            <div className="scouts flexBox">
              {!displaySettings.shortRow && <label>Scouts:</label>}
              <AvatarContainer
                scoutList={(artist.influencers || [])}
                allowedScoutsMap={allowedScoutsMap}
              />
            </div>
          )}
        </section>
      </StyledUniversalRowItem>
  );
}

export function artistLink(artist) {
  return (
    artist.keys && (
      <li className="artistPageLink">
        <Link to={"/artist/" + artist.keys[0]}>View artist's page</Link>
      </li>
    )
  );
}

export function UniversalRowHeader({
                                     width,

                                     track,
                                     artist,
                                     video,
                                     customItem,
                                     displaySettings = {},
                                   }) {
  const includeFollowerStats = displaySettings.includeFollowerStats;
  const includeTrackStats =
    displaySettings["includeTrackStats"] &&
    ((track && track.stats) || (video && video.stats));

  const includeScouts = !(displaySettings["includeScouts"] === false) && artist;
  const includeAlerts =
    displaySettings["includeAlerts"] && artist && artist.recentAlerts;

  return (
    <StyledUniversalRowItem style={{backgroundColor: "red", height: "50px", width: width  + "px"}} short={displaySettings.shortRow}>
      <section>
        <span className="artwork flexBox">TRACK</span>
        {customItem && (
          <div className="trackInfo flexBox"> </div>
        )}
        {track && (
          <div className="trackInfo flexBox"> </div>
        )}
        {video &&
        (displaySettings.shortRow ? (
          <div className="trackInfo flexBox"> </div>
        ) : (
          <div className="videoInfo flexBox"> </div>
        ))}
        {includeTrackStats && (
          <div className="trackStats flexBox">Stats</div>
        )}
        {includeTrackStats &&
        track &&
        track.playlistStats &&
        displaySettings.shortRow && (
          <div className="trackStats flexBox">Playlists</div>
        )}
        {includeTrackStats &&
        track &&
        typeof track.total_likes === "number" &&
        displaySettings.shortRow && (
          <div className="trackStats flexBox">Stats</div>
        )}
        {includeTrackStats && track && track.top200 && (
          <div className="trackTop200 flexBox">Top 200</div>
        )}
        {includeTrackStats && displaySettings.shortRow && (
          <div className="trackListenLink flexBox">
            Listen
          </div>
        )}

        {artist && (
          <div className="artistInfo flexBox">
            Artist
          </div>
        )}
        {includeAlerts && (
          <div className="recentAlerts flexBox">
            Recent Alerts
          </div>
        )}
        {includeFollowerStats && artist && (
          <div className="followers flexBox"><label>Followers</label></div>
        )}

        {includeScouts && (
          <div className="scouts flexBox">Scouts</div>
        )}
      </section>
    </StyledUniversalRowItem>
  );

}
