import React from "react";
import {connect} from "react-redux";
import {UniversalRowItem} from "./index";
import {getArtistByKey} from "../../reducers/data_access";
import ErrorBoundary from "../error_boundary";
import {AutoSizer, InfiniteLoader, List} from "react-virtualized";
import styled from "styled-components";
import {usePaginatedFetch} from "../../fetching/UsePaginatedFetch";
import {EMPTY_OBJ} from "../../consts";


const StyledChart = styled.div`
  margin: 10px;
  height: calc(100vh - 160px - 82px - 70px);
`;

function ScrollFetchTable({
  hasNextPage,
  isNextPageLoading,
  dataList,
  loadNextPage,
  RenderFunction,
  containerStyle,
  rowHeight=160,
  noRowsRenderer=null,
}) {
  // If there are more items to be loaded then add an extra row to hold a loading indicator.
  const rowCount = hasNextPage ? dataList.length + 1 : dataList.length;

  // Only load 1 page of items at a time.
  // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
  const loadMoreRows = isNextPageLoading ? () => {} : loadNextPage;

  // Every row is loaded except for our loading indicator row.
  const isRowLoaded = ({ index }) => !hasNextPage || index < dataList.length;

  // Render a dataList item or a loading indicator.
  const rowRenderer = ({ index, key, style }) => {
    let content;

    if (!isRowLoaded({ index })) {
      content = "Loading...";
    } else {
      content = (
        <RenderFunction rowIndex={index} {...dataList[index]} />
      );
    }

    return (
      <div key={key} style={style}>
        {content}
      </div>
    );
  };

  noRowsRenderer = noRowsRenderer || (() => <h4>No results found.</h4>);

  return (
    <ErrorBoundary>
      <StyledChart style={containerStyle}>
        <section style={{ height: "100%" }}>
          <InfiniteLoader
            isRowLoaded={isRowLoaded}
            loadMoreRows={loadMoreRows}
            rowCount={rowCount}
            threshold={8}
          >
            {({ onRowsRendered, registerChild }) => (
              <AutoSizer>
                {({ height, width }) => (
                  <List
                    ref={registerChild}
                    onRowsRendered={onRowsRendered}
                    noRowsRenderer={noRowsRenderer}
                    rowRenderer={rowRenderer}
                    rowCount={rowCount}
                    height={height}
                    rowHeight={rowHeight}
                    width={width}
                  />
                )}
              </AutoSizer>
            )}
          </InfiniteLoader>
        </section>
      </StyledChart>
    </ErrorBoundary>
  );
}



export function getUniversalRowItemUsingDomain(rowProps, domainArtistDb=EMPTY_OBJ, domainScTrackData=EMPTY_OBJ, domainSpyTrackData=EMPTY_OBJ, domainYtVideoData=EMPTY_OBJ) {
  const renderProps = {...rowProps};
  if (rowProps.artist) {
    const reduxArtist = rowProps.artist.query_key ? getArtistByKey(domainArtistDb, rowProps.artist.query_key) : rowProps.artist.scid ? getArtistByKey(domainArtistDb, 'sc/' + rowProps.artist.scid) : null;
    if (reduxArtist) {
      renderProps.artist = reduxArtist;
    }
  }
  if (rowProps.track) {
    const reduxTrack = rowProps.track.scid ?  domainScTrackData[rowProps.track.scid] : rowProps.track.spyid ? domainSpyTrackData[rowProps.track.spyid] : null;
    if (reduxTrack) {
      renderProps.track = reduxTrack;
    }
  }
  if (rowProps.video) {
    const reduxVideo = rowProps.video.ytid ?  domainYtVideoData[rowProps.video.ytid] : null;
    if (reduxVideo) {
      renderProps.track = reduxVideo;
    }
  }
  return renderProps;
}

/**
 * Wires up a pageFetcher and redux domain store to a ScrollFetchTable.
 * Rows are rendered with the artist from the redix store, if there's one there.
 * This allows artists to be refreshed in the background. (see the artists
 * caching middleware).
 *
 * pageFetcher - a usePaginatedFetch hook, mose likely the
 *       useChartPaginatedFetch hook for consistency
 * handleHideForMe - optional callback to allow for rows to be hidden and
 *       note that the RenderFunction will need to support it, so if you
 *       aren't using UniversalRowItem you'll need to do your own heavy lifting
 * RenderFunction - optional method for rendering the row, will receive the row item
 *       as props, which should come from the redux store. default is UniversalRowItem
 * scrollFetchProps - optional object of additional props passed into ScrollFetchTable
 *      eg. { containerStyle: { padding: "10px" }, noRowsRenderer: () => <h1>Nothing here!</h1> }
 *
 */
export const ConnectedScrollTable = connect(
  function mapState(state) { return {domainArtistDb: state.domain.artistDb} }
)(function ConnectedScrollTableInner({pageFetcher, domainArtistDb, handleHideForMe=null, RenderFunction=null, scrollFetchProps}) {

  RenderFunction = RenderFunction || UniversalRowItem;
  const _handleHideForMe = !handleHideForMe ? null : artist =>
    handleHideForMe(artist)
    .then(
      pageFetcher.updateManyItems(
        {showOverlay: true},
        x => x.artist === artist
      )
    );

  function RenderConnectedRow(rowProps) {
    const renderProps = getUniversalRowItemUsingDomain(rowProps, domainArtistDb);
    return <RenderFunction
      handleRefreshChart={pageFetcher.refresh}
      handleHideForMe={_handleHideForMe}
      {...renderProps}
    />;
  }

  return <ScrollFetchTable
    hasNextPage={pageFetcher.hasMore}
    isNextPageLoading={pageFetcher.isLoading}
    loadNextPage={() => pageFetcher.fetchMore()}
    dataList={pageFetcher.allResults}
    RenderFunction={RenderConnectedRow}
    {...scrollFetchProps}
  />;
});


export function useChartPaginatedFetch(initialUrl, settings, onNewResults) {
  /** Page fetcher for 'charts' results which is designed to work with the redux store. */
  return usePaginatedFetch(initialUrl, settings, onNewResults, "charts/newChartResults");
}
