import { createGlobalStyle } from "styled-components";
import { blackish, linkblue, purplish } from "./styleConsts";

import flagsPng from "../images/flags.png";

export const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  
  div, span, b, i, em, small, label, h1, h2, h3, h4, h5, h6, a, td, th {
    font-style: inherit;
    font-weight: inherit;
    color: inherit;
    margin: 0;
  }
  
  h1, h2, h3, h4, h5, h6 {
    font-size: inherit;
  }
  
  table {
    border-spacing: 0;
  }
  
  a {
    color: ${purplish};
  }
  
  html, body {
    background-color: ${blackish};
    font-family: -apple-system, BlinkMacSystemFont, "Avenir Next", Rubik, sans-serif;
    font-weight: normal;
    font-size: 12px;
    line-height: 1.5;
    color: ${blackish};
    height: 100%;
    margin: 0 auto;
    padding: 0;
    width: 100%;
    height: 100%;
  }
  
  html body :focus {
    outline: none; // react virtualized puts a blue outline on a lot of things.
  }
  
  #root {
    background-color: #f9f9f9;
    height: 100%;
    overflow: hidden;
  }
  
  input, textarea, select, button {
    font-size: inherit;
    font-family: inherit;
  }
  
    
  ul, li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  /**
   * Tooltip used for the 'withTooltip hoc 
   */
   .tooltip {
     position: relative;
     cursor: help;
   }
  .tooltip::before {
    display: block;
    opacity: 1;
    transition: opacity 300ms ease-in;
  }
  .tooltip:hover::before {
    text-align: left;
    display: block;
    opacity: 1;
    position: absolute;
    width: 160px;
    left: 0px;
    top: 24px;
    z-index: 5;
    background-color: white;
    box-shadow: 1px 1px 5px rgba(0,0,0,0.3);
    border: 1px solid #bbbbbb;
    border-radius: 5px;
    padding: 5px 10px;
    color: #666;
    content: "❓" attr(data-tooltip);
  }
  
  .bigNumber {
    font-size: 19px;
    font-weight: 600;
  }
  
  /* links with icons */
  .artistPageLink a,
  .listenSpotify,
  .expandStats {
    line-height: 19px;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    color: ${linkblue};
    text-decoration: none;

    :before {
      content: "▶";
      display: inline-block;
      vertical-align: middle;
      width: 19px;
      height: 19px;
      text-align: center;
      line-height: 19px;
      color: white;
      background-color: ${linkblue};
      border-radius: 100%;
      margin-right: 3px;
    }
  }
  
  .artistPageLink a {
    :before {
      content: "♚";
    }
  }
  
  .expandStats {
    :before {
      content: "﹪"
    }
  }
  
  .positiveChange {
    color: green;
    &::before {
      zoom: 0.9;
      content: "▲";
    }
  }
  .negativeChange {
    color: red;
    &::before {
      zoom: 0.9;
      content: "▼";
    }
  }
/*!
 * Generated with CSS Flag Sprite generator (https://www.flag-sprites.com/)
 */

.flag {
    display: inline-block;
    vertical-align: middle;
    width: 24px;
    height: 24px;
    background: url(${flagsPng}) no-repeat;
}

.flag.flag-global, .flag.flag-GLOBAL {
  background: none;
  &:before {
    content: "🌐";
    font-size: 18px;
  }
}

.flag.flag-ae, .flag.flag-AE {
    background-position: -24px 0;
}

.flag.flag-ru, .flag.flag-RU {
    background-position: -144px -264px;
}

.flag.flag-cz, .flag.flag-CZ {
    background-position: -72px -72px;
}

.flag.flag-ma, .flag.flag-MA {
    background-position: -48px -192px;
}

.flag.flag-vg, .flag.flag-VG {
    background-position: -72px -336px;
}

.flag.flag-fo, .flag.flag-FO {
    background-position: -144px -96px;
}

.flag.flag-cl, .flag.flag-CL {
    background-position: -240px -48px;
}

.flag.flag-gg, .flag.flag-GG {
    background-position: -288px -96px;
}

.flag.flag-gl, .flag.flag-GL {
    background-position: -360px -96px;
}

.flag.flag-sc, .flag.flag-SC {
    background-position: -240px -264px;
}

.flag.flag-gt, .flag.flag-GT {
    background-position: -120px -120px;
}

.flag.flag-mf, .flag.flag-MF {
    background-position: -144px -192px;
}

.flag.flag-yt, .flag.flag-YT {
    background-position: -240px -336px;
}

.flag.flag-bs, .flag.flag-BS {
    background-position: -336px -24px;
}

.flag.flag-om, .flag.flag-OM {
    background-position: -120px -240px;
}

.flag.flag-an, .flag.flag-AN {
    background-position: -168px 0;
}

.flag.flag-rw, .flag.flag-RW {
    background-position: -168px -264px;
}

.flag.flag-sz, .flag.flag-SZ {
    background-position: -240px -288px;
}

.flag.flag-az, .flag.flag-AZ {
    background-position: -360px 0;
}

.flag.flag-tz, .flag.flag-TZ {
    background-position: -240px -312px;
}

.flag.flag-lu, .flag.flag-LU {
    background-position: -360px -168px;
}

.flag.flag-mu, .flag.flag-MU {
    background-position: -72px -216px;
}

.flag.flag-ax, .flag.flag-AX {
    background-position: -336px 0;
}

.flag.flag-gd, .flag.flag-GD {
    background-position: -240px -96px;
}

.flag.flag-tg, .flag.flag-TG {
    background-position: -336px -288px;
}

.flag.flag-hk, .flag.flag-HK {
    background-position: -216px -120px;
}

.flag.flag-vn, .flag.flag-VN {
    background-position: -120px -336px;
}

.flag.flag-by, .flag.flag-BY {
    background-position: -24px -48px;
}

.flag.flag-vi, .flag.flag-VI {
    background-position: -96px -336px;
}

.flag.flag-sa, .flag.flag-SA {
    background-position: -192px -264px;
}

.flag.flag-ao, .flag.flag-AO {
    background-position: -192px 0;
}

.flag.flag-be, .flag.flag-BE {
    background-position: -72px -24px;
}

.flag.flag-cu, .flag.flag-CU {
    background-position: -360px -48px;
}

.flag.flag-iq, .flag.flag-IQ {
    background-position: -96px -144px;
}

.flag.flag-pt, .flag.flag-PT {
    background-position: 0 -264px;
}

.flag.flag-sb, .flag.flag-SB {
    background-position: -216px -264px;
}

.flag.flag-tm, .flag.flag-TM {
    background-position: -72px -312px;
}

.flag.flag-mg, .flag.flag-MG {
    background-position: -168px -192px;
}

.flag.flag-mc, .flag.flag-MC {
    background-position: -72px -192px;
}

.flag.flag-gw, .flag.flag-GW {
    background-position: -168px -120px;
}

.flag.flag-bm, .flag.flag-BM {
    background-position: -240px -24px;
}

.flag.flag-fi, .flag.flag-FI {
    background-position: -48px -96px;
}

.flag.flag-kw, .flag.flag-KW {
    background-position: -96px -168px;
}

.flag.flag-kh, .flag.flag-KH {
    background-position: -336px -144px;
}

.flag.flag-tk, .flag.flag-TK {
    background-position: -24px -312px;
}

.flag.flag-gb, .flag.flag-GB {
    background-position: -216px -96px;
}

.flag.flag-th, .flag.flag-TH {
    background-position: -360px -288px;
}

.flag.flag-cg, .flag.flag-CG {
    background-position: -144px -48px;
}

.flag.flag-my, .flag.flag-MY {
    background-position: -168px -216px;
}

.flag.flag-sn, .flag.flag-SN {
    background-position: -72px -288px;
}

.flag.flag-uy, .flag.flag-UY {
    background-position: -336px -312px;
}

.flag.flag-aw, .flag.flag-AW {
    background-position: -312px 0;
}

.flag.flag-nl, .flag.flag-NL {
    background-position: -360px -216px;
}

.flag.flag-hn, .flag.flag-HN {
    background-position: -240px -120px;
}

.flag.flag-mp, .flag.flag-MP {
    background-position: -336px -192px;
}

.flag.flag-cm, .flag.flag-CM {
    background-position: -264px -48px;
}

.flag.flag-na, .flag.flag-NA {
    background-position: -216px -216px;
}

.flag.flag-fr, .flag.flag-FR {
    background-position: -168px -96px;
}

.flag.flag-mr, .flag.flag-MR {
    background-position: 0 -216px;
}

.flag.flag-al, .flag.flag-AL {
    background-position: -120px 0;
}

.flag.flag-fk, .flag.flag-FK {
    background-position: -96px -96px;
}

.flag.flag-je, .flag.flag-JE {
    background-position: -192px -144px;
}

.flag.flag-ly, .flag.flag-LY {
    background-position: -24px -192px;
}

.flag.flag-tv, .flag.flag-TV {
    background-position: -192px -312px;
}

.flag.flag-ne, .flag.flag-NE {
    background-position: -264px -216px;
}

.flag.flag-np, .flag.flag-NP {
    background-position: -24px -240px;
}

.flag.flag-mo, .flag.flag-MO {
    background-position: -312px -192px;
}

.flag.flag-sm, .flag.flag-SM {
    background-position: -48px -288px;
}

.flag.flag-ca, .flag.flag-CA {
    background-position: -72px -48px;
}

.flag.flag-kr, .flag.flag-KR {
    background-position: -72px -168px;
}

.flag.flag-td, .flag.flag-TD {
    background-position: -288px -288px;
}

.flag.flag-nr, .flag.flag-NR {
    background-position: -48px -240px;
}

.flag.flag-ch, .flag.flag-CH {
    background-position: -168px -48px;
}

.flag.flag-bf, .flag.flag-BF {
    background-position: -96px -24px;
}

.flag.flag-il, .flag.flag-IL {
    background-position: -24px -144px;
}

.flag.flag-sk, .flag.flag-SK {
    background-position: 0 -288px;
}

.flag.flag-pg, .flag.flag-PG {
    background-position: -216px -240px;
}

.flag.flag-ga, .flag.flag-GA {
    background-position: -192px -96px;
}

.flag.flag-dm, .flag.flag-DM {
    background-position: -168px -72px;
}

.flag.flag-kz, .flag.flag-KZ {
    background-position: -144px -168px;
}

.flag.flag-gy, .flag.flag-GY {
    background-position: -192px -120px;
}

.flag.flag-tn, .flag.flag-TN {
    background-position: -96px -312px;
}

.flag.flag-do, .flag.flag-DO {
    background-position: -192px -72px;
}

.flag.flag-in, .flag.flag-IN {
    background-position: -72px -144px;
}

.flag.flag-ph, .flag.flag-PH {
    background-position: -240px -240px;
}

.flag.flag-ua, .flag.flag-UA {
    background-position: -264px -312px;
}

.flag.flag-sl, .flag.flag-SL {
    background-position: -24px -288px;
}

.flag.flag-it, .flag.flag-IT {
    background-position: -168px -144px;
}

.flag.flag-uz, .flag.flag-UZ {
    background-position: -360px -312px;
}

.flag.flag-at, .flag.flag-AT {
    background-position: -264px 0;
}

.flag.flag-as, .flag.flag-AS {
    background-position: -240px 0;
}

.flag.flag-nf, .flag.flag-NF {
    background-position: -288px -216px;
}

.flag.flag-ss, .flag.flag-SS {
    background-position: -144px -288px;
}

.flag.flag-hr, .flag.flag-HR {
    background-position: -264px -120px;
}

.flag.flag-va, .flag.flag-VA {
    background-position: 0 -336px;
}

.flag.flag-us, .flag.flag-US {
    background-position: -312px -312px;
}

.flag.flag-bz, .flag.flag-BZ {
    background-position: -48px -48px;
}

.flag.flag-eg, .flag.flag-EG {
    background-position: -288px -72px;
}

.flag.flag-dk, .flag.flag-DK {
    background-position: -144px -72px;
}

.flag.flag-ye, .flag.flag-YE {
    background-position: -216px -336px;
}

.flag.flag-ky, .flag.flag-KY {
    background-position: -120px -168px;
}

.flag.flag-kn, .flag.flag-KN {
    background-position: -24px -168px;
}

.flag.flag-bo, .flag.flag-BO {
    background-position: -288px -24px;
}

.flag.flag-vu, .flag.flag-VU {
    background-position: -144px -336px;
}

.flag.flag-cw, .flag.flag-CW {
    background-position: -24px -72px;
}

.flag.flag-nz, .flag.flag-NZ {
    background-position: -96px -240px;
}

.flag.flag-ci, .flag.flag-CI {
    background-position: -192px -48px;
}

.flag.flag-gn, .flag.flag-GN {
    background-position: -24px -120px;
}

.flag.flag-mw, .flag.flag-MW {
    background-position: -120px -216px;
}

.flag.flag-bi, .flag.flag-BI {
    background-position: -168px -24px;
}

.flag.flag-ps, .flag.flag-PS {
    background-position: -360px -240px;
}

.flag.flag-lc, .flag.flag-LC {
    background-position: -216px -168px;
}

.flag.flag-ir, .flag.flag-IR {
    background-position: -120px -144px;
}

.flag.flag-la, .flag.flag-LA {
    background-position: -168px -168px;
}

.flag.flag-ck, .flag.flag-CK {
    background-position: -216px -48px;
}

.flag.flag-bh, .flag.flag-BH {
    background-position: -144px -24px;
}

.flag.flag-to, .flag.flag-TO {
    background-position: -120px -312px;
}

.flag.flag-pn, .flag.flag-PN {
    background-position: -312px -240px;
}

.flag.flag-nu, .flag.flag-NU {
    background-position: -72px -240px;
}

.flag.flag-mt, .flag.flag-MT {
    background-position: -48px -216px;
}

.flag.flag-pa, .flag.flag-PA {
    background-position: -144px -240px;
}

.flag.flag-km, .flag.flag-KM {
    background-position: 0 -168px;
}

.flag.flag-fm, .flag.flag-FM {
    background-position: -120px -96px;
}

.flag.flag-kg, .flag.flag-KG {
    background-position: -312px -144px;
}

.flag.flag-gs, .flag.flag-GS {
    background-position: -96px -120px;
}

.flag.flag-ag, .flag.flag-AG {
    background-position: -72px 0;
}

.flag.flag-so, .flag.flag-SO {
    background-position: -96px -288px;
}

.flag.flag-gr, .flag.flag-GR {
    background-position: -72px -120px;
}

.flag.flag-nc, .flag.flag-NC {
    background-position: -240px -216px;
}

.flag.flag-bt, .flag.flag-BT {
    background-position: -360px -24px;
}

.flag.flag-ht, .flag.flag-HT {
    background-position: -288px -120px;
}

.flag.flag-ml, .flag.flag-ML {
    background-position: -240px -192px;
}

.flag.flag-cf, .flag.flag-CF {
    background-position: -120px -48px;
}

.flag.flag-bl, .flag.flag-BL {
    background-position: -216px -24px;
}

.flag.flag-ar, .flag.flag-AR {
    background-position: -216px 0;
}

.flag.flag-ad, .flag.flag-AD {
    background-position: 0 0;
}

.flag.flag-au, .flag.flag-AU {
    background-position: -288px 0;
}

.flag.flag-st, .flag.flag-ST {
    background-position: -168px -288px;
}

.flag.flag-af, .flag.flag-AF {
    background-position: -48px 0;
}

.flag.flag-sv, .flag.flag-SV {
    background-position: -192px -288px;
}

.flag.flag-jo, .flag.flag-JO {
    background-position: -240px -144px;
}

.flag.flag-bw, .flag.flag-BW {
    background-position: 0 -48px;
}

.flag.flag-rs, .flag.flag-RS {
    background-position: -120px -264px;
}

.flag.flag-et, .flag.flag-ET {
    background-position: 0 -96px;
}

.flag.flag-dz, .flag.flag-DZ {
    background-position: -216px -72px;
}

.flag.flag-id, .flag.flag-ID {
    background-position: -360px -120px;
}

.flag.flag-bn, .flag.flag-BN {
    background-position: -264px -24px;
}

.flag.flag-py, .flag.flag-PY {
    background-position: -48px -264px;
}

.flag.flag-lr, .flag.flag-LR {
    background-position: -288px -168px;
}

.flag.flag-ic, .flag.flag-IC {
    background-position: -336px -120px;
}

.flag.flag-is, .flag.flag-IS {
    background-position: -144px -144px;
}

.flag.flag-er, .flag.flag-ER {
    background-position: -336px -72px;
}

.flag.flag-tj, .flag.flag-TJ {
    background-position: 0 -312px;
}

.flag.flag-am, .flag.flag-AM {
    background-position: -144px 0;
}

.flag.flag-gi, .flag.flag-GI {
    background-position: -336px -96px;
}

.flag.flag-si, .flag.flag-SI {
    background-position: -360px -264px;
}

.flag.flag-ug, .flag.flag-UG {
    background-position: -288px -312px;
}

.flag.flag-cy, .flag.flag-CY {
    background-position: -48px -72px;
}

.flag.flag-pr, .flag.flag-PR {
    background-position: -336px -240px;
}

.flag.flag-lt, .flag.flag-LT {
    background-position: -336px -168px;
}

.flag.flag-tw, .flag.flag-TW {
    background-position: -216px -312px;
}

.flag.flag-tl, .flag.flag-TL {
    background-position: -48px -312px;
}

.flag.flag-tc, .flag.flag-TC {
    background-position: -264px -288px;
}

.flag.flag-li, .flag.flag-LI {
    background-position: -240px -168px;
}

.flag.flag-ki, .flag.flag-KI {
    background-position: -360px -144px;
}

.flag.flag-cn, .flag.flag-CN {
    background-position: -288px -48px;
}

.flag.flag-mm, .flag.flag-MM {
    background-position: -264px -192px;
}

.flag.flag-ng, .flag.flag-NG {
    background-position: -312px -216px;
}

.flag.flag-bj, .flag.flag-BJ {
    background-position: -192px -24px;
}

.flag.flag-eu, .flag.flag-EU {
    background-position: -24px -96px;
}

.flag.flag-ni, .flag.flag-NI {
    background-position: -336px -216px;
}

.flag.flag-lb, .flag.flag-LB {
    background-position: -192px -168px;
}

.flag.flag-md, .flag.flag-MD {
    background-position: -96px -192px;
}

.flag.flag-bd, .flag.flag-BD {
    background-position: -48px -24px;
}

.flag.flag-pk, .flag.flag-PK {
    background-position: -264px -240px;
}

.flag.flag-pf, .flag.flag-PF {
    background-position: -192px -240px;
}

.flag.flag-ba, .flag.flag-BA {
    background-position: 0 -24px;
}

.flag.flag-mx, .flag.flag-MX {
    background-position: -144px -216px;
}

.flag.flag-sg, .flag.flag-SG {
    background-position: -312px -264px;
}

.flag.flag-mv, .flag.flag-MV {
    background-position: -96px -216px;
}

.flag.flag-qa, .flag.flag-QA {
    background-position: -72px -264px;
}

.flag.flag-pw, .flag.flag-PW {
    background-position: -24px -264px;
}

.flag.flag-ms, .flag.flag-MS {
    background-position: -24px -216px;
}

.flag.flag-sr, .flag.flag-SR {
    background-position: -120px -288px;
}

.flag.flag-me, .flag.flag-ME {
    background-position: -120px -192px;
}

.flag.flag-pl, .flag.flag-PL {
    background-position: -288px -240px;
}

.flag.flag-hu, .flag.flag-HU {
    background-position: -312px -120px;
}

.flag.flag-mz, .flag.flag-MZ {
    background-position: -192px -216px;
}

.flag.flag-ie, .flag.flag-IE {
    background-position: 0 -144px;
}

.flag.flag-tr, .flag.flag-TR {
    background-position: -144px -312px;
}

.flag.flag-lk, .flag.flag-LK {
    background-position: -264px -168px;
}

.flag.flag-eh, .flag.flag-EH {
    background-position: -312px -72px;
}

.flag.flag-cr, .flag.flag-CR {
    background-position: -336px -48px;
}

.flag.flag-zm, .flag.flag-ZM {
    background-position: -288px -336px;
}

.flag.flag-se, .flag.flag-SE {
    background-position: -288px -264px;
}

.flag.flag-sd, .flag.flag-SD {
    background-position: -264px -264px;
}

.flag.flag-mk, .flag.flag-MK {
    background-position: -216px -192px;
}

.flag.flag-bg, .flag.flag-BG {
    background-position: -120px -24px;
}

.flag.flag-im, .flag.flag-IM {
    background-position: -48px -144px;
}

.flag.flag-es, .flag.flag-ES {
    background-position: -360px -72px;
}

.flag.flag-ge, .flag.flag-GE {
    background-position: -264px -96px;
}

.flag.flag-br, .flag.flag-BR {
    background-position: -312px -24px;
}

.flag.flag-tf, .flag.flag-TF {
    background-position: -312px -288px;
}

.flag.flag-za, .flag.flag-ZA {
    background-position: -264px -336px;
}

.flag.flag-cd, .flag.flag-CD {
    background-position: -96px -48px;
}

.flag.flag-zw, .flag.flag-ZW {
    background-position: -312px -336px;
}

.flag.flag-jp, .flag.flag-JP {
    background-position: -264px -144px;
}

.flag.flag-gh, .flag.flag-GH {
    background-position: -312px -96px;
}

.flag.flag-ec, .flag.flag-EC {
    background-position: -240px -72px;
}

.flag.flag-dj, .flag.flag-DJ {
    background-position: -120px -72px;
}

.flag.flag-ro, .flag.flag-RO {
    background-position: -96px -264px;
}

.flag.flag-wf, .flag.flag-WF {
    background-position: -168px -336px;
}

.flag.flag-ai, .flag.flag-AI {
    background-position: -96px 0;
}

.flag.flag-tt, .flag.flag-TT {
    background-position: -168px -312px;
}

.flag.flag-ws, .flag.flag-WS {
    background-position: -192px -336px;
}

.flag.flag-sh, .flag.flag-SH {
    background-position: -336px -264px;
}

.flag.flag-mq, .flag.flag-MQ {
    background-position: -360px -192px;
}

.flag.flag-no, .flag.flag-NO {
    background-position: 0 -240px;
}

.flag.flag-co, .flag.flag-CO {
    background-position: -312px -48px;
}

.flag.flag-kp, .flag.flag-KP {
    background-position: -48px -168px;
}

.flag.flag-gu, .flag.flag-GU {
    background-position: -144px -120px;
}

.flag.flag-fj, .flag.flag-FJ {
    background-position: -72px -96px;
}

.flag.flag-gm, .flag.flag-GM {
    background-position: 0 -120px;
}

.flag.flag-mh, .flag.flag-MH {
    background-position: -192px -192px;
}

.flag.flag-mn, .flag.flag-MN {
    background-position: -288px -192px;
}

.flag.flag-de, .flag.flag-DE {
    background-position: -96px -72px;
}

.flag.flag-ls, .flag.flag-LS {
    background-position: -312px -168px;
}

.flag.flag-vc, .flag.flag-VC {
    background-position: -24px -336px;
}

.flag.flag-gq, .flag.flag-GQ {
    background-position: -48px -120px;
}

.flag.flag-ee, .flag.flag-EE {
    background-position: -264px -72px;
}

.flag.flag-ke, .flag.flag-KE {
    background-position: -288px -144px;
}

.flag.flag-lv, .flag.flag-LV {
    background-position: 0 -192px;
}

.flag.flag-sy, .flag.flag-SY {
    background-position: -216px -288px;
}

.flag.flag-pe, .flag.flag-PE {
    background-position: -168px -240px;
}

.flag.flag-cv, .flag.flag-CV {
    background-position: 0 -72px;
}

.flag.flag-jm, .flag.flag-JM {
    background-position: -216px -144px;
}

.flag.flag-bb, .flag.flag-BB {
    background-position: -24px -24px;
}

.flag.flag-ve, .flag.flag-VE {
    background-position: -48px -336px;
}

`;
