// src/react-auth0-spa.js
import React, {useContext, useEffect, useState} from "react";
import createAuth0Client from "@auth0/auth0-spa-js";

import { actionCreators as apiLoginActions } from "../reducers/user";
import { useReduxDispatch } from "../reducers/ReduxHooks";

export const Auth0Context = React.createContext();
export const useAuth0 = () => useContext(Auth0Context);

export const Auth0Provider = ({children}) => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [user, setUser] = useState();
  const [auth0Client, setAuth0] = useState();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);

  const reduxDispatch = useReduxDispatch();


  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client({
        "domain": "sme-dna.auth0.com",
        "client_id": "jyjK6FuMHtDMRd0eHrXVo3ILAWO8NXMn",
      });
      setAuth0(auth0FromHook);

      window._auth0Client = auth0FromHook;

      if (window.location.search.includes("code=")) {
        console.log("We appear to be loading from a auth0 redirect")
        await auth0FromHook.handleRedirectCallback();
        const idToken = Object.values(auth0FromHook.cache.cache)[0].id_token;
        console.log("Got Id token:", idToken)
        reduxDispatch(apiLoginActions.loginAuth0Token(idToken))
        window.history.replaceState({}, document.title, window.location.pathname)
      }
      //
      // const isAuthenticated = await auth0FromHook.isAuthenticated();
      //
      // setIsAuthenticated(isAuthenticated);
      //
      // if (isAuthenticated) {
      //   const user = await auth0FromHook.getUser();
      //   setUser(user);
      // }

      setLoading(false);
    };
    initAuth0();
    // eslint-disable-next-line
  }, []);

  const loginWithPopup = async () => {
    setPopupOpen(true);
    try {
      await auth0Client.loginWithPopup();
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }
    const user = await auth0Client.getUser();
    setUser(user);
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    const user = await auth0Client.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(user);
  };

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: () => {

          return auth0Client.loginWithRedirect({redirect_uri: window.location.origin, response_type: "id_token", audience: "https://wlapi.whtlst.in"});
        },
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout: (...p) => auth0Client.logout(...p),
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
