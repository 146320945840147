import styled from "styled-components";

export const Refreshing = styled.div`
  position: absolute;
  z-index: 50;
  padding: 2px 8px;
  display: block;
  top: 5px;
  left: 50%;
  background-color: rgba(255,255,255,0.1);
  color: rgba(255,255,255,0.7);
  animation: throb 1s infinite alternate;
  
  @keyframes throb {
    from { opacity: 1; }
    to { opacity: 0.5; }
  }
  
  ::before {
    content: "Refreshing...";
  }
`;

