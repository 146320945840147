import React from "react";
import styled from "styled-components";

import Lightbox from "react-images";


const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1px;
  grid-auto-rows: minmax(200px, auto);
  width: 100%;
`;
// This is a repeating pattern of photos that looks neat.
const rowSpans = [
  2, 1, 1, 2, 2, 1, 2, 1, 1, 1, 1, 1
];
const colSpans = [
  2, 1, 1, 1, 2, 2, 1, 1, 1, 1, 1, 1
];
const Brick = styled.div`
  grid-row-start: span ${props => rowSpans[props.index % rowSpans.length] || 2};
  grid-column-start: span ${props => colSpans[props.index % colSpans.length] || 2};
  transition: transform 0.4s, box-shadow 0.3s, opacity 0.3s;
  :hover {
    transform: scale(1.01) translate(-1px, -1px);
    box-shadow: 1px 1px 6px rgba(0,0,0,0.5);
    opacity: 0.7;
  }
`;
const GridImage = ({onClick, src, index}) => {
  const containerStyles = {
    overflow: "hidden",
    background: `url(${src}) no-repeat 50% 20% / cover`,
  };
  return (
      <Brick onClick={onClick} index={index} style={containerStyles}></Brick>
  );
};


class PhotoGrid extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      currentImage: 0,
      images: []
    };

    this.closeLightbox = this.closeLightbox.bind(this);
    this.openLightbox = this.openLightbox.bind(this);
    this.gotoNext = this.gotoNext.bind(this);
    this.gotoPrevious = this.gotoPrevious.bind(this);
  }

  openLightbox(event, indx) {
    this.setState({
      currentImage: indx,
      lightboxIsOpen: true,
    });
  }

  closeLightbox() {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    });
  }

  gotoPrevious() {
    this.setState({
      currentImage: this.state.currentImage - 1,
    });
  }

  gotoNext() {
    this.setState({
      currentImage: this.state.currentImage + 1,
    });
  }

  componentDidMount() {
    // Render img tags, and only use images that load successfully.
    let el = document.getElementById("__IMGS");
    if (!el) {
      el = document.createElement("div");
      el.id = "__IMGS";
      el.style.display = "none";
      document.body.appendChild(el);
    }
    while (el.firstChild) {
      el.removeChild(el.firstChild);
    }
    this.setState({images: new Array(this.props.images.length).fill(false)});
    this.props.images.forEach((imgObj, i) => {
      const url = imgObj.url;
      const img = document.createElement("img");
      img.onerror = (e) => null;
      img.onload = (e) => {
        const validimages = [...this.state.images];
        validimages[i] = imgObj;
        this.setState({"images": validimages});
      };
      img.src = url;
      el.appendChild(img);
    });
  }

  componentWillUnmount() {
    // When unmounting we want to remove any onload handlers that if the img hasn't loaded yet
    // would cause a 'setState' on an unmounted component.  Overwring the onload seems to do it.
    const el = document.getElementById("__IMGS");
    if (el) {
      while (el.firstChild) {
        el.firstChild.onload = function(){};
        el.removeChild(el.firstChild);
      }
    }
  }

  render() {
    const typeLinkMapping = {
      "spy": "Open on Spotify",
      "in": "Visit on Instagram",
      "tw": "Visit on Twitter",
      "sc": "Open on SoundCloud",
    };
    const images = this.state.images.filter(x => x); // remove failed.
    return <div>
        <Grid>{images.map((imgObj, i) => <GridImage key={i} onClick={e => this.openLightbox(e, i)} index={i} src={imgObj.url}/>)}</Grid>
        <Lightbox
            images={images.map((imgObj, i) => {
              return {
                src: imgObj.url,
                caption: (
                    <div style={{marginTop: "-120px", fontSize: "15px", padding: "20px 30px 60px 30px", width: "100%",
                      background:"linear-gradient(to right, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.1) 76%, rgba(0,0,0,0.1) 100%"}}>
                      <div>{imgObj.caption}</div>
                      <div><a target="_blank" rel="noopener noreferrer" href={imgObj.source_url}>{typeLinkMapping[imgObj.type] || "Visit"}</a></div>
                    </div>)
              };
            })}
            onClose={this.closeLightbox}
            onClickPrev={this.gotoPrevious}
            onClickNext={this.gotoNext}
            currentImage={this.state.currentImage}
            isOpen={this.state.lightboxIsOpen}
        />
    </div>;
  }
}

export default PhotoGrid;
