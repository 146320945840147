import React from "react";
import _ from "lodash";


const Radio = (props) => {
  const {
    groupName,
    choice,
    selected,
    inputStyle,
    labelStyle,
    selectedLabelStyle,
    labelBefore,
    handleChange,
  } = props;

  const style = {
    ...labelStyle,
    ...(selected ? selectedLabelStyle : {}),
  };

  return (
    <label style={style} className={selected ? 'selected' : ''}>
      {labelBefore ? choice.label || choice.value : null}
      <input
          type="radio"
          name={groupName}
          onChange={handleChange}
          style={inputStyle}

          value={choice.value||''}
          checked={selected}
      />
      {!labelBefore ? choice.label || choice.value : null}
    </label>
  );
};



class RadioGroup extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      groupName: props.groupName || _.uniqueId('radiogroup-'),
      selectedIndex: null,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(choice, index) {
    this.setState({
      selectedIndex: index,
    });
  }

  render() {
    const {
      choices,
      containerComponent,
      defaultSelectedIndex,
      handleChange,
    } = this.props;

    const {
      inputStyle,
      labelStyle,
      selectedLabelStyle,
      labelBefore
    } = this.props;

    const Container = containerComponent || React.Fragment;
    return (
      <Container>
        {choices.map((choice, i) => (
          <Radio
            key={this.state.groupName + i}
            {...{inputStyle, labelStyle, selectedLabelStyle, labelBefore}}
            groupName={this.state.groupName}
            choice={choice}
            index={i}
            selected={
              this.state.selectedIndex === i ||
              (this.state.selectedIndex === null && defaultSelectedIndex === i)
            }
            handleChange={(e) => {
              this.handleChange(choice, i);
              handleChange(choice, i);
            }}
          />))}
      </Container>);
  }
}

export default RadioGroup;