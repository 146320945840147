export default function sort(data, config) {
  if (!config.key || !config.dir) {
    return data.slice(0);
  }
  return data.slice(0).sort((a,b) => {
    const inverter = config.dir==="desc" ? -1 : 1;
    const aCompare = get(a, config.key);
    const bCompare = get(b, config.key);
    if (typeof aCompare === "string") {
      return aCompare.localeCompare(bCompare) * inverter;
    }
    return (aCompare - bCompare) * inverter;
  });
}

export function get(obj, key) {
  const dotPos = key.indexOf(".");
  if(dotPos === -1) {
    return obj[key];
  }
  const firstKey = key.substring(0,dotPos);
  const restOfKey = key.substring(dotPos+1);
  return get(obj[firstKey], restOfKey);
}
