import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import styled from "styled-components";

import { Spinner } from "../../components/primitives/things";
import { Button, TextInput } from "../../components/primitives/inputs";

const Styled = styled.div`
  padding: 0;

  .resultBox {
    padding: 10px;
    margin-top: 10px;
    min-height: 40px;
    background: #ffffff;
  }
  .resultBox.results {
    background: #fbfbfb;
  }
  .resultBox.error {
    background: #fff1f1;
    color: red;
  }
`;

const StyledProfile = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 40px);
  grid-gap: 5px;

  h3 {
    grid-column: span 6;

    margin: 5px 0 3px 0;
    padding: 0;
    text-align: left;
    font-size: 10px;
    font-weight: 500;
    color: darkgrey;
  }
  .fullText {
    grid-column: span 6;
  }
  img {
    grid-row: span 2;
    grid-column: 1;

    width: 40px;
    height: 40px;
    border-radius: 100%;
  }
  b {
    grid-row: 2;
    grid-column: 2 / span 5;

    font-size: 20px;
    line-height: 20px;
  }
  a {
    grid-row: 3;
    grid-column: 2 / span 5;

    color: inherit;
    &:hover {
      color: #1da1f2;
    }
  }
  button {
    grid-column: span 3;
    padding: 10px 20px;
  }
`;

export function renderProfile(profile, { onAcceptProfile, onCancel }) {
  return (
    <StyledProfile>
      {profile.validate_url
        ? <>
          <h3 style={{color: 'red'}}>{"⚠️"}Heads Up!</h3>
          <p className="fullText">We weren't able to validate that profile. Please visit{" "}
          <a href={profile.validate_url} rel="noopener,nofollow" target={"_blank"}>{profile.validate_url}</a>
            {" "} in your browser and confirm it's the account you mean.</p>
        </>
        : profile.preview_text
        ? <>
            <h3>Found profile:</h3>
            <b>{profile.preview_text}</b>
            <img alt="" src={profile.thumbnail} />
        </>
        : <>
        <h3>Found profile:</h3>
        <img alt="" src={profile.avatar} />
        <b>{profile.name}</b>
        <a target="_blank" rel="noopener noreferrer" href={profile.source_url}>
          {profile.username}
        </a>
      </>
      }
      {onAcceptProfile && <>
      <h3>Is this the right profile?</h3>
      <Button className="primary" onClick={() => onAcceptProfile(profile)}>
        Yes
      </Button>
      <Button className="secondary" onClick={onCancel}>
        No
      </Button>
      </>}
    </StyledProfile>
  );
}

export class ProfileLookup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: this.props.initialValue || "",
    };
  }

  render() {
    const {
      onInputChange,
      onSubmit,
      onAcceptProfile,
      onCancel,
      placeholder = "",
      exampleUrl = "",
      sourceName = "",
      profileToConfirm,
      lookupInProgress,
      profileShouldDisplay,
      errorMessage = "",
      className,
    } = this.props;

    const hasChangedAndSubmittable =
      this.state.inputValue &&
      this.state.inputValue !== this.props.initialValue;

    const isSoftLinkConfirmable =
      this.state.inputValue &&
      this.state.inputValue === this.props.initialValue &&
      this.props.initialSourceObject.id &&  // this is a 'link' from artist.links array
      this.props.isSoftLink;

    let boxContents;
    let resultBoxClassName;
    if (lookupInProgress) {
      resultBoxClassName = "loading";
      boxContents = <Spinner />;
    } else if (errorMessage) {
      resultBoxClassName = "error";
      boxContents = (
        <span>
          Sorry, something went wrong. You can try again. <br />({errorMessage})
          <br />
          <Button className="secondary" onClick={onCancel}>
            Cancel
          </Button>
        </span>
      );
    } else if (profileShouldDisplay) {
      resultBoxClassName = "results";
      if (!profileToConfirm) {
        boxContents = (
          <span>
            No profile found for{" "}
            <b style={{ fontFamily: "monospace" }}>{this.state.inputValue}</b>
            <br />
            Try copy and pasting the url from the user's {sourceName} page.
            <br />
            eg: <i style={{ fontFamily: "monospace" }}>{exampleUrl}</i>
            <br />
            <Button className="secondary" onClick={onCancel}>
              Cancel
            </Button>
          </span>
        );
      } else {
        boxContents = renderProfile(profileToConfirm, {
          onAcceptProfile: profile => {
            this.setState({ inputValue: "" });
            onAcceptProfile(profile);
          },
          onCancel: () => {
            this.setState({ inputValue: "" });
            onCancel();
          }
        });
      }
    } else if (hasChangedAndSubmittable) {
      resultBoxClassName = "changed";
      boxContents = (
        <span>
          Click submit to search for user{" "}
          <b style={{ fontFamily: "monospace" }}>{this.state.inputValue}</b> on
          {sourceName}.
        </span>
      );
    } else {
      resultBoxClassName = "empty";
      boxContents = <span />;
    }

    const ConfirmSoftLinkButton = () => {
      return <Button type={"button"} onClick={() => onAcceptProfile({
        id: this.props.initialSourceObject.id,
        source_url: this.props.initialSourceObject.source_url,
        source: this.props.initialSourceObject.source,
      })}>Confirm</Button>
    }
    return (
      <Styled className={className}>
        <form
          onSubmit={e => {
            e.preventDefault();
            onSubmit(this.state.inputValue);
          }}
        >
          <TextInput
            placeholder={placeholder || "profile link"}
            value={this.state.inputValue}
            onChange={e => {
              this.setState({ inputValue: e.target.value });
              if (onInputChange) onInputChange(e.target.value);
            }}
          />
          {isSoftLinkConfirmable ? <ConfirmSoftLinkButton /> : <Button
            type="submit"
            className={
              lookupInProgress
                ? "lookupInProgress"
                : !this.state.inputValue
                ? "emptyInput"
                : !hasChangedAndSubmittable
                ? "disabled"
                : ""
            }
            disabled={lookupInProgress || !hasChangedAndSubmittable}
          >
            {lookupInProgress ? "Searching" : "Submit"}
          </Button>}
        </form>
        <div className={"resultBox " + resultBoxClassName}>{boxContents}</div>
      </Styled>
    );
  }
}

function connectedProfileLookup(
  namespace,
  apiPath,
  { placeholder = null, exampleUrl, sourceName, onAcceptProfile }
) {
  return connect(
    (state, ownProps) => {
      return {
        ...(state.ui.profileLookup[namespace] || {}),
        placeholder: placeholder || "eg. " + exampleUrl,
        exampleUrl,
        sourceName: sourceName || namespace,
        initialSourceObject: ownProps.initialSourceObject,
        initialValue: ownProps.initialValue,
        isSoftLink: (ownProps.initialSourceObject||{}).is_hard_link === false,
      };
    },

    dispatch =>
      bindActionCreators(
        {
          onSubmit: value => ({
            type: "profileLookup/lookupProfile",
            namespace: namespace,
            query: value,
            api: { resource: apiPath + "?q=" + encodeURIComponent(value) }
          }),
          onInputChange: value => {
            return {
              type: "profileLookup/inputValueChanges",
              namespace: namespace,
              value: value
            };
          },
          onItemClicked: choice => {
            return {
              type: "profileLookup/lookupResultChosen",
              namespace: namespace,
              choice: choice
            };
          },
          onAcceptProfile: profile => {
            if (onAcceptProfile) onAcceptProfile(profile);
            return {
              type: "profileLookup/profileAccepted",
              namespace: namespace,
              profile: profile
            };
          },
          onCancel: () => {
            return {
              type: "profileLookup/reset",
              namespace: namespace
            };
          }
        },
        dispatch
      )
  )(ProfileLookup);
}

export const createSoundCloudProfileLookup = (namespace, handleAcceptProfile) =>
  connectedProfileLookup(namespace, "/api/profiles/sc", {
    exampleUrl: "https://soundcloud.com/artist",
    sourceName: "SoundCloud",
    onAcceptProfile: handleAcceptProfile
  });

export const createInstagramProfileLookup = (namespace, handleAcceptProfile) =>
  connectedProfileLookup(namespace, "/api/profiles/in", {
    exampleUrl: "https://www.instagram.com/artist/",
    sourceName: "Instagram",
    onAcceptProfile: handleAcceptProfile
  });

export const createTwitterProfileLookup = (namespace, handleAcceptProfile) =>
  connectedProfileLookup(namespace, "/api/profiles/tw", {
    exampleUrl: "https://twitter.com/artist",
    sourceName: "Twitter",
    onAcceptProfile: handleAcceptProfile
  });

export const createSpotifyProfileLookup = (namespace, handleAcceptProfile) =>
  connectedProfileLookup(namespace, "/api/profiles/spy", {
    exampleUrl: "spotify:artist:12345",
    sourceName: "Spotify",
    onAcceptProfile: handleAcceptProfile
  });

export const createYouTubeProfileLookup = (namespace, handleAcceptProfile) =>
  connectedProfileLookup(namespace, "/api/profiles/yt", {
    exampleUrl: "https://www.youtube.com/channel/abc12",
    sourceName: "YouTube",
    onAcceptProfile: handleAcceptProfile
  });
