import React from "react";
import sort from "../../utils/sort";
import {pk} from "../../reducers/admin";
import _ from "lodash";
import styled from "styled-components";

import {XBox} from "../primitives/things";
import {EditableCategory} from "../scouts/scout_components";
import {_toKey} from "../../reducers/data_access";
import {datasourceMapping} from "../../reducers/domain";

export default class List extends React.Component {
  componentDidMount() {
    this.props.getList(this.props.datasource);
  }

  render() {
    let influencers = this.props.influencers;
    if (influencers.length) {
      if (influencers[0].added) {
        influencers = sort(influencers, {key: "added.val", dir: "asc"});
      } else if (influencers[0].first_seen) {
        influencers = sort(influencers, {key: "first_seen.val", dir: "asc"});
      }

    }

    const allCategories = Array.from(influencers.reduce((uniqSet, scout) => {
      if (scout.category) {
        uniqSet.add(scout.category);
      }
      return uniqSet;
    }, new Set([])));

    return <section style={{height: "calc(100vh - 290px)", overflowY:"auto"}}>
      <table style={{width: "100%"}}>
        <thead>
          <tr>
            <td width="50%">Name</td>
            <td width="50%">Category</td>
            <td width="50px"></td>
          </tr>
        </thead>
        <tbody>
          {influencers.map(i => {
            const key_name = pk[this.props.datasource];
            const key = i[key_name];
            console.log("key", key_name, key)
            return (
              <ListItem
                key={key}
                datasource={this.props.datasource}
                influencer={i}
                allCategories={allCategories}
                editFormChange={this.props.editFormChange}
                editFormSave={this.props.editFormSave}
                removeInfluencer={this.props.removeInfluencer}
              />
            );
          })}
        </tbody>
      </table>
    </section>;
  }
}

class ListItem extends React.Component {
  constructor(props) {
    super();
    this.primaryKey = props.influencer[pk[props.datasource]];
    this.handleRemoveRow = this.handleRemoveRow.bind(this);
  }

  saveCategory = async (newCategory) => {
    return newCategory === this.props.category
      ? Promise.resolve()
      : new Promise(resolve => {
        this.props.editFormSave(
          this.props.datasource,
          {
            [pk[this.props.datasource]]: this.primaryKey,
            category: newCategory
          },
          _toKey(datasourceMapping[this.props.datasource], this.primaryKey),
          resolve
        );
      });
  }

  handleRemoveRow(event) {
    this.props.removeInfluencer(this.props.datasource, this.primaryKey);
  }

  render() {
    const {active, category} = this.props.influencer;
    const allCategories = this.props.allCategories || [];
    // Use scout_display_name from latest api
    const displayName = this.props.influencer.scout_display_name || this.props.influencer.name || this.props.influencer[pk[this.props.datasource]]; // for instagram that doesn't have names yet.;
    return (
      <tr className="t-influencer">
        <td className="t-name" style={{verticalAlign: "middle"}}>{displayName}<br/><small style={{color: active ? "green" : "red"}}>{active ? "active" : "disabled"}</small></td>
        <td style={{whiteSpace: "nowrap", verticalAlign: "middle"}}>
          <EditableCategory savedCategory={category} allCategories={allCategories} onSave={this.saveCategory} />
        </td>
        <td style={{verticalAlign: "middle"}}>
          <XBox onClick={this.handleRemoveRow} />
        </td>
      </tr>
    );
  }

}

export const ColorPreview = styled.div`
  display: inline-block;
  vertical-align: middle;
  width: 24px;
  height: 24px;
  border-radius: 3px;
  background-color: ${props => props.color};
`;
