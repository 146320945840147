import React, {useState} from "react";
import styled from "styled-components";
import posed from 'react-pose';

const collapsedWidth = 20;

const AnimPanel = posed.div({
  open: {
    width: ({width}) => width || 'auto',
    flip: true,
    transition: {ease: 'linear', duration: 110}
  },
  closed:  {
    width: collapsedWidth,
    flip: true,
    transition: {ease: 'linear', duration: 110}
  }
});

const colors = {
  bgColor: '#f9f9f9',
  controlHeader: '#b8b8b8',
};

const StyledLayout = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  
  .CollapsiblePanel {
    display: flex;
    flex-direction: column;
    background-color: ${colors.bgColor};
    margin-right: 2px;
    padding: 0;
    
    &.closed {
      background-color: ${colors.controlHeader};
      user-select: none;
      cursor: pointer;
    }
    &.closed:hover {
      opacity: 0.5;
    }
    
    &.noOverflow {
      overflow-y: unset;
    }
    
    overflow-y: auto;
  }
  .Results {
    flex: 1
  }
  .closePanelControl {
    display: block;
    flex: 0;
    background-color: ${colors.controlHeader};
    padding: 3px;
    position: sticky;
    top:0;
    user-select: none;
    cursor: pointer;
    i {
      float: right;
      font-size: 16px;
      position: relative;
      top: -4px;
    }
    :hover {
      opacity: 0.5;
    }
  }
  .openPanelControl {
    height: 100%;
    overflow: hidden;
    position: relative;
    i {
      font-size: 16px;
      padding-left: 2px;
    } 
    em {
      transform: rotate(90deg);
      display: block;
      padding: 5px 0;
    }
    .collapsedComponent {
      display: block;
      position: absolute;
      top: 9em;
      left: 0;
      width: 50vh;
      overflow: hidden;
      transform: rotate(90deg);
      transform-origin: 0 100%;
      height: ${collapsedWidth}px;
    }
  }
  
  
`;

function Panel ({name, collapsible, startOpen, width=200, disableOverflow, collapsedComponent, children}) {
  const [isOpen, setIsOpen] = useState(startOpen);
  return (
    <AnimPanel
      key={name}
      width={collapsible ? width : null}
      pose={isOpen? "open" : "closed"}
      className={"CollapsiblePanel " + name + (isOpen ? " open" : " closed") + (disableOverflow ? " noOverflow" : "")}
      onClick={() => !isOpen && setIsOpen(true)}
    >
      {collapsible && isOpen && <span className={"closePanelControl"} onClick={() => setIsOpen(false)}>{name} <i>{"⇤"}</i></span>}
      {!isOpen && <span className={"openPanelControl"}><i>{"⇥"}</i><em>{name}</em>{collapsedComponent && <span className={"collapsedComponent"}>{collapsedComponent}</span>}</span>}
      {isOpen && children}
    </AnimPanel>
  )
}

export function MainLayout({panels}) {

  return (
    <StyledLayout>{
      panels.filter(x => x).map(({name, component, collapsedComponent, isCollapsible, isCollapsed, width, disableOverflow}) => (
          <Panel key={name} name={name} width={width} collapsedComponent={collapsedComponent} disableOverflow={disableOverflow} collapsible={isCollapsible} startOpen={!isCollapsed}>
            {component}
          </Panel>
        )
      )}</StyledLayout>
  )
}

