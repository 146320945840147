import React from "react";
import styled from "styled-components";
import { space } from "../styles/styleConsts";
import { compose } from "redux";
import { withRouter } from "react-router";
import { requireUser, statelessPure } from "../utils/hocs";
import { connect } from "react-redux";
import { actionCreators } from "../reducers/user";
import userIcon from "../images/user-icon@2x.png";
import { Link } from "react-router-dom";
import {useFetchPromise} from "../fetching/UseFetch";
import { useMsal } from "@azure/msal-react";
// import {useAuth0} from "./Auth0Context";

const UserInfoInner = styled.div`
  padding: ${space}px 10px ${space}px ${space}px;
  color: #fff;
  font-size: 9px;

  width: 160px;
  display: grid;
  grid-template:
    "img username username"
    "img settings logout"
    / 40px auto auto;

  .menuUserIcon {
    grid-area: img;
    width: 31px;
    height: 31px;
    padding-top: 1px;
  }

  .menuUsername {
    grid-area: username;
    font-size: 12px;
  }
  .userSettingsLink {
    grid-area: settings;
  }
  .logoutLink {
    grid-area: logout;
  }
  .userSettingsLink a,
  .logoutLink {
    cursor: pointer;
    font-size: inherit;
    text-decoration: underline;
    color: inherit;
  }
`;

export const UserMenu = compose(
  withRouter,
  requireUser,
  connect(
    null,
    dispatch => ({
      logout: () => {
        dispatch(actionCreators.logout());
      }
    })
  ),
  statelessPure
)(({ user, history, logout }) => {
  // const {logout: logoutAuth0} = useAuth0();
  const fetcher = useFetchPromise();
  const expireSession = () => {
    fetcher("/api/login", {
      headers: {
        Accept: "EXPIRE_SESSION",
        "Content-Type": "application/json"
      }
    });
  };

  const {instance} = useMsal();

  return (
    <UserInfoInner className="sel-headerUserInfoSection">
      <img className="menuUserIcon" alt="" src={userIcon} />
      <div className="menuUsername">{user.name}</div>
      <div className={"userSettingsLink"}>
        <Link to={"/myUserSettings"}>Settings</Link>
      </div>
      <div
        className="sel-logoutLink logoutLink"
        onClick={() => {
          logout();
          if (instance) {
            instance.clearCache()
          }
          // logoutAuth0();  NB: This will automatically redirect, to whatever is configured at Auth0 (eg. it could be localhost for testing)
          history.push("/");
        }}
      >
        Logout
      </div>
      {process.env.NODE_ENV === "development" ? (
        <button onClick={expireSession}>Expire Session</button>
      ) : null}
    </UserInfoInner>
  );
});
