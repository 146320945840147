/* eslint 'jsx-a11y/accessible-emoji': 'off' */
import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { HashRouter as Router, Link, withRouter } from "react-router-dom";
import styled from "styled-components";
import Helmet from "react-helmet";
import { hot } from "react-hot-loader";

import { ErrorMessages } from "../containers/error_messages";
import Routes from "../routing/routes";
import { Menu } from "../components/menu";
import { Loading, Logo } from "../components/primitives/things";
import { blackish, navWidth } from "../styles/styleConsts";
import { lighten } from "polished";

import { LookupArtistContainer as LookupArtist } from "./lookup_artist";
import { ScoutInfoOverlay } from "./modals/ScoutInfoOverlay";
import { TrackDetailModal } from "./modals/track_detail_modal";
import { SpyTrackDetailModal } from "./modals/spy_track_detail_modal";
import { VideoDetailOverlay } from "./modals/youtube_video_modal";

import { LoginOverlay } from "./login";

import { requireUser, statelessPure } from "../utils/hocs";
import { selectNumNewAlerts } from "./my_whitelist";
import { UserMenu } from "./userMenu";
import { GlobalStyles } from "../styles/globalStyles";

const MainNav = (function () {
  const hoc = connect(state => ({
    user: state.user.currentUser,
    numNewAlerts: selectNumNewAlerts(state),
  }));

  const MainNav = styled.nav`
    background: ${lighten(0.05, blackish)};
    width: ${navWidth}px;
    min-width: ${navWidth}px;
    position: fixed;
    z-index: 10;
    height: 100vh;

    .privacyLink {
      position: absolute;
      width: ${navWidth}px;
      min-width: ${navWidth}px;
      max-width: ${navWidth}px;
      bottom: 0px;
      height: 43px;
      color: white;
      text-display: underline;
      font-size: 10px;
      padding: 0 3px;
      text-align: center;
    }
  `;
  return withRouter(
    hoc(
      statelessPure(({ user, location, numNewAlerts }) => (
        <MainNav>
          <Logo />
          <Menu
            user={user}
            numNewAlerts={numNewAlerts}
            pathname={location.pathname}
          />
          <Link className="privacyLink" to={"/Privacy"}>
            Privacy & Terms
          </Link>
        </MainNav>
      ))
    )
  );
})();

const MainBody = (function () {
  const MainBody = styled.div`
    display: flex;
    align-items: stretch;
    width: 100%;
    height: 100vh;
  `;

  const MainContent = styled.section`
    flex-basis: 100%;
    margin-left: ${navWidth}px;
    width: calc(100% - ${navWidth}px); /* allow horizontal scrolling sections */
  `;

  const hoc = compose(
    withRouter,
    requireUser,
    connect(state => ({
      loginInProgress: state.ui.loginInProgress,
    }))
  );

  function render({ user, loginInProgress }) {
    if (loginInProgress) {
      return (
        <div style={{ textAlign: "center", padding: "50px" }}>
          <Loading>Loading...</Loading>
        </div>
      );
    }
    return (
      <MainBody>
        <MainContent>
          <Routes user={user} />
        </MainContent>
      </MainBody>
    );
  }

  return hoc(render);
})();

const Header = styled.div`
  position: absolute;
  display: flex;
  justify-content: flex-end;
  left: 0;
  right: 0;
  z-index: 5;
`;

export const App = () => {
  return (
    <Router>
      <GlobalStyles />
      <React.Fragment>
        <Helmet>
          <title>Sony Music DNA</title>
        </Helmet>
        <ErrorMessages />
        <LoginOverlay />
        <ScoutInfoOverlay />
        <SpyTrackDetailModal />
        <TrackDetailModal />
        <VideoDetailOverlay />
        <Header>
          <LookupArtist />
          <UserMenu />
        </Header>
        <MainNav />
        <MainBody />
      </React.Fragment>
    </Router>
  );
};

export default hot(module)(App);
