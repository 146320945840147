import React, { createContext, useContext } from "react";
import { connect } from "react-redux";

const DispatchContext = createContext(() =>
  console.error("No dispatch method provided")
);

export const ProvideConnectedDispatch = connect()(function DispatchProvider({
  dispatch,
  children,
}) {
  console.log("Putting dispatch in hook.");
  return (
    <DispatchContext.Provider value={dispatch}>
      {children}
    </DispatchContext.Provider>
  );
});

export function useReduxDispatch() {
  return useContext(DispatchContext);
}
