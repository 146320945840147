import React, {useState} from "react";
import styled from "styled-components";
import ResultsPage from "../components/results_page";
import ErrorBoundary from "../components/error_boundary";
import {UniversalRowItem} from "../components/charts";
import {useJsonFetch} from "../fetching/UseFetch";
import {unhideArtistForMe} from "../reducers/artist";
import {useReduxDispatch} from "../reducers/ReduxHooks";
import {sortBy} from "../utils";

const Styled = styled.section`
  padding: 10px;

  h1 {
    font-weight: bold;
    font-size: 1.4em;
    margin: 5px 0;
  }
  .sheetsContainer {
    max-width: 900px;
  }
`;



export const PublishedSpreadsheets = () => {
  const { isLoading, data, error, refresh } = useJsonFetch(
    "/api/admin/published_spreadsheets"
  );

  const [unhidden, setUnhidden] = useState([]);
  const reduxDispatch = useReduxDispatch();

  function Table({ sheets }) {

    const sorted_sheets = sheets.slice()
    sorted_sheets.sort((a, b) => b.createdDay.localeCompare(a.createdDay))

    return (
      <>
        <h1>Sheets</h1>
        {sorted_sheets.length === 0 ? (
          <p>
            No sheets found
          </p>
        ) : (
          <>

            <div className={"sheetsContainer"}>
              <table>
                <thead>
                  <tr>
                    <td colSpan={2}></td>
                    <td style={{textAlign: "center"}} colSpan={100}>Num Rows</td>
                  </tr>
                  <tr>
                    <th>Kind</th>
                    <th>Date</th>
                    <th>Link</th>
                    <th>Num Sheets</th>
                  </tr>
                </thead>
                <tbody>
                {sorted_sheets.map(sheet => (
                  <tr>
                    <td>{sheet.sheetName}</td>
                    <td>{sheet.createdDay}</td>
                    <td><a href={sheet.sheetUrl} target="_blank">Open</a></td>
                    <td>{sheet.tabs.length}</td>
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
          </>
        )}
      </>
    );
  }

  return (
    <ResultsPage
      pageId="my_hidden_artists"
      pageTitle={"My Settings"}
      loading={isLoading}
      loaded={!isLoading}
      ResultsContainerStyle={{ overflowY: "auto" }}
    >
      <Styled>
        <ErrorBoundary>
          {isLoading ? null : error || !data ? (
            <span>{error}</span>
          ) : (
            <Table sheets={data.results}/>
          )}
        </ErrorBoundary>
      </Styled>
    </ResultsPage>
  );
};
