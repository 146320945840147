import { combineReducers } from "redux";

import admin from "./admin";
import datasources from "./datasources";
import user, { actionCreators as userActionCreators } from "./user";
import { reduceDomain as domain } from "./domain";
import { reduceUi as ui } from "./ui";
import { reducer as scouts } from "../containers/modals/ScoutInfoOverlay";

export default combineReducers({
  /* Settings related to the logged in user. Most data pulled at login */
  user,
  /* Domain data, artists, songs, videos etc. */
  domain,
  /* Various UI states that need to be global */
  ui,
  /* Related to changing scouts */
  admin,
  /* Old style of domain data, for the old scout page views, xxx: migrate to domain */
  datasources,
  /* Information on scouts, for display in the popup. xxx: could move to domain */
  scouts,
});

export const actionCreators = {
  init: () => dispatch => {
    dispatch(userActionCreators.fetchUser());
  }
};
