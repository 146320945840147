import React from "react";
import styled from "styled-components";
import {transparentize} from "polished";
import {blackish, dangerAction, red, tiktokPink} from "../../styles/styleConsts";
import {Button, Label, TextInput} from "../primitives/inputs";
import {renderProfile} from "../../containers/profilelookup";
import {Spinner} from "../primitives/things";

const placeholder = {
  spotify: "https://open.spotify.com/user/ID/playlist/ID -or- spotify:user:ID:playlist:ID",
  twitter: "https://twitter.com/username -or- @username",
  instagram: "@insta, insta, instagram.com/insta/",
  soundcloud: "https://soundcloud.com/username",
  youtube: "https://www.youtube.com/channel/ID",
  tiktok: "https://www.tiktok.com/@username",
};

const datasource_to_name = (ds) => {
  return {tiktok: 'TikTok', soundcloud: 'SoundCloud', youtube: 'YouTube'}[ds] || (
    ds.charAt(0).toUpperCase() + ds.substring(1)
  )
}

const helpText = {
  tiktok: <span>
      Please provide the <strong>username</strong> or the <strong>url</strong> of the user's
      page on the tiktok website.  If you are in the mobile app, the username is the thing
      with the <strong>@</strong> sign in front of it.<br/>
      <br/>
      The app's sharing links for user pages  will not work (links that look like https://vm.tiktok.com/9EFN1G/). However
      if you open those links in your own browser, you can copy and paste the url you end up on
      (as long as it includes the /@username in it.
  </span>,
  instagram: <span>Provide either the user's Instagram handle or the link to their page. It should look
    like <b>https://www.instagram.com/kylie</b></span>,
  soundcloud: <span>Provide either the user's SoundCloud username or the link to their page. It should look
    like <b>https://www.soundcloud.com/robbie</b></span>
}


export default class Form extends React.Component {
  constructor() {
    super();
    this.add = this.add.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeUrl = this.onChangeUrl.bind(this);
  }
  onSubmit(e) {
    e.preventDefault();
    this.props.addFormCheck(this.props.datasource, this.props.url);
  }
  add() {
    this.props.addFormSave(this.props.datasource, this.props.url);
  }
  onChangeUrl(e) {
    this.props.addFormChange(this.props.datasource, e.target.value);
  }
  render() {
    const readyToAdd = this.props.check === "PASSED";
    const checking = this.props.check === "IN_PROGRESS";
    const failed = this.props.check === "FAIL";
    const help = helpText[this.props.datasource];
    const checkResult = this.props.checkResult;
    const friendlyName = datasource_to_name(this.props.datasource)
    const checkFailureInfo = this.props.checkFailureInfo || '';

    return <form onSubmit={this.onSubmit} style={{marginBottom: 30}}>
      <Label>Add {this.props.recordType}</Label>
      <TextInput width={400} value={this.props.url} onChange={this.onChangeUrl} placeholder={`e.g. ${placeholder[this.props.datasource]}`} />
      <Button type="submit">Lookup on {friendlyName}</Button>
      {help && <p style={{maxWidth: "600px"}}>{help}</p>}
      <div>
        {checking ? <Banner color={blackish}><Spinner style={{display:"inline-block", zoom: 0.6, marginRight: '10px', position: 'relative', top: '4px'}} />Finding on {friendlyName}...</Banner> : null}
        {failed ? <Banner color={red}>Profile could not be found at {friendlyName}. {checkFailureInfo} Please check and try again.</Banner> : null}
        {readyToAdd && (
          <Banner color={checkResult.validate_url ? '#fff200' : blackish}>
            {renderProfile(checkResult, {onAcceptProfile: null})}
            <p>Do you want to add this scout?<br/><Button className="t-button-add" type="button" onClick={this.add}>Yes, add this to my {friendlyName} scouts!</Button></p>
          </Banner>
        )}
      </div>
    </form>
  }
}

const Banner = styled.div`
  background-color: ${props => transparentize(0.85, props.color)};
  padding: 20px;
  border-radius: 4px;
  margin: 20px 0;
`;
