import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import Autosuggest from "react-autosuggest";
import { Loading } from "../../components/primitives/things";
import { DEFAULT } from "./reducer";

import {Spinner} from "../../components/primitives/things";

import _ from "lodash";

function getSuggestionValue(video) {
  return `${video.name || video.title}`;
}

const StyledAutosuggest = styled.span`
  .react-autosuggest__container {
    position: relative;
  }

  .react-autosuggest__input {
    width: 190px;
    height: 30px;
    padding: 5px 10px;
    border: 1px solid #aaa;
    border-radius: 4px;
  }

  .react-autosuggest__input:focus {
    outline: none;
  }

  .react-autosuggest__container--open .react-autosuggest__input {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .react-autosuggest__suggestions-container {
    display: none;
    max-height: 300px;
    overflow-y: auto;
  }

  .react-autosuggest__container--open
    .react-autosuggest__suggestions-container {
    display: block;
    position: absolute;
    top: 31px;
    width: 190px;
    border: 1px solid #aaa;
    background-color: #fff;
    font-weight: 300;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 5px 10px;
  }

  .react-autosuggest__suggestion:not(:first-child) {
    border-top: 1px solid #ddd;
  }

  .react-autosuggest__suggestion--focused {
    background-color: #0c7eaf;
    color: #fff;
  }

  .suggestion-content {
    display: flex;
    align-items: center;
    background-repeat: no-repeat;
  }

  .highlight {
    color: #ee0000;
    font-weight: bold;
  }

  .react-autosuggest__suggestion--focused .highlight {
    color: #120000;
  }
`;

class Suggest extends React.Component {
  constructor() {
    super();
    this.state = {
      value: ""
    };
  }

  render() {
    const {
      newResults,
      suggestionsLoading,
      itemRenderer,
      onSuggestionsFetchRequested,
      onSuggestionsClearRequested,
      onItemClicked,
      handleChoice,
      inputPlaceholder
    } = this.props;

    const itemClick = choice => {
      if (handleChoice) handleChoice(choice);
      onItemClicked(choice);
    };

    const inputProps = {
      placeholder: inputPlaceholder || "Find track...",
      onChange: () => {},
      value: this.state.value || ""
    };

    return (
      <StyledAutosuggest>
        <Autosuggest
          suggestions={newResults || []}
          onSuggestionsFetchRequested={e => {
            this.setState({ value: e.value });
            onSuggestionsFetchRequested(e);
          }}
          onSuggestionsClearRequested={() => {
            this.setState({ value: "" });
            onSuggestionsClearRequested();
          }}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={choice => {
            return (
              <span onClick={() => itemClick(choice)}>
                {itemRenderer
                  ? itemRenderer(choice)
                  : choice.name || choice.title}
              </span>
            );
          }}
          inputProps={inputProps}
          shouldRenderSuggestions={v => true}
          renderSuggestionsContainer={({ containerProps, children, query }) => {
            return (
              <div {...containerProps}>
                {children}
                <div>{suggestionsLoading ? <Spinner /> : null}</div>
              </div>
            );
          }}
        />
      </StyledAutosuggest>
    );
  }
}

export function makeSuggester(
  namespace,
  lookupPath,
  handleChoice,
  itemRenderer,
  { inputPlaceholder }
) {
  itemRenderer =
    itemRenderer ||
    (o => (
      <b>
        {o.title}
        <br />
        {o.username && <small style={{ opacity: 0.6 }}>@{o.username}</small>}
      </b>
    ));

  const hoc = connect(
    state => ({
      suggesterState: state.ui.autoSuggest[namespace] || DEFAULT,
      comparisonLoading: false, // tood;
      handleChoice: handleChoice
    }),
    dispatch =>
      bindActionCreators(
        {
          onSuggestionsFetchRequested: ({ value }) => ({
            type: "lookupFetch",
            namespace: namespace,
            query: value,
            api: { resource: lookupPath + "?q=" + encodeURIComponent(value) }
          }),
          onSuggestionsClearRequested: () => ({
            type: "clearSuggestions",
            namespace: namespace
          }),
          onItemClicked: choice => {
            return {
              type: "lookupResultChosen",
              namespace: namespace,
              choice: choice
            };
          }
        },
        dispatch
      )
  );

  function Component(props) {
    if (props.comparisonLoading) {
      return <Loading>Loading</Loading>;
    }
    return (
      <Suggest
        inputPlaceholder={inputPlaceholder}
        {...props}
        moreLoading={props.suggesterState.moreLoading}
        newResults={props.suggesterState.newResults}
        onSuggestionsFetchRequested={_.debounce(
          props.onSuggestionsFetchRequested,
          500
        )}
        handleChoice={props.handleChoice}
        itemRenderer={itemRenderer}
      />
    );
  }

  return hoc(Component);
}
