export function getUpdatedLocationIdPath(newId) {
  return removeLocationParts(1) + '/' + newId;
}

function removeLocationParts(numParts) {
  const current = window.location.hash.substring(1); // ignore '#'
  const parts = current.split("/");
  const replaced = parts.slice(0, parts.length - numParts);
  return replaced.join('/');
}

export function addLocationParts(part1, part2, part3) {
  return window.location.hash.substring(1).split("/.m/")[0] + "/.m/" + Array.from(arguments).join('/');
}