import React from "react";
import moment from "moment";
import { compose } from "redux";
import { connect } from "react-redux";
import styled from "styled-components";
import { updateStatelessOnlyOn } from "../../utils/hocs";

import {actionCreators, pk} from "../../reducers/admin";

import { Link } from "react-router-dom";
import { Modal } from "../../components/modal";
import { LittleSourceIcon } from "../../components/primitives/things";
import { shortenUrlForDisplay } from "../../utils";
import { Loading } from "../../components/primitives/things";

import placeholderImage from "../../images/user-icon@2x.png";
import { SOURCE_TO_NAME } from "../../utils";
import { sourceIconMap } from "../../styles/styleConsts";
import {createSelector} from "reselect";
import {EditableCategory} from "../../components/scouts/scout_components";
import {_toKey} from "../../reducers/data_access";

const Styled = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 5px;
  padding: 20px;

  button {
    grid-column: 4 / span 1;
    grid-row: 1;
    border: 0px solid gray;
    border-bottom: 0px solid #bbbbbb;
    background-color: transparent;
    font-weight: bold;
    color: red;
    text-align: right;
    margin-bottom: 10px;
    :hover {
      background-color: #f5f5f5;
    }
  }

  .loading {
    grid-column: span 4;
  }
  .avatar {
    grid-row: span 3;
    grid-column: 1;

    width: 100%;
    border-radius: 100%;
  }
  dt {
    grid-column: 1 / span 3;
    grid-row: 1;
    font-size: 11px;
    font-weight: bold;
    color: #859091;
  }
  h1 {
    grid-column: span 3;

    font-size: 35px;
    font-weight: bold;
    line-height: 30px;
    margin: 0 0 5px 20px;
  }
  > a {
    grid-column: 2 / span 3;
    text-decoration: none;
    margin-left: 20px;
    :hover {
      text-decoration: underline;
      opacity: 0.7;
    }
    img {
      display: inline-block;
      vertical-align: middle;
      margin-right: 6px;
    }
    span {
      display: inline-block;
      vertical-align: middle;
    }
  }
  .myCategory {
    grid-column: 2 / span 3;
    margin-left: 20px;
    
    b {
      padding: 4px;
      color: white;
      font-weight: bold;
      border-radius: 3px;
    }
  }
  .description {
    grid-column: span 4;
  }
  .followed {
    grid-column: span 4;
  }
  .followed a {
    color: inherit;
    text-decoration: none;
    :hover {
      text-decoration: underline;
    }
  }

  h3 {
    margin: 10px 0;
    font-size: inherit;
    font-weight: inherit;

    small {
      font-size: inherit;
      display: inline;
    }
  }

  ul {
    margin-top: 10px;
  }
  li {
    border-bottom: 1px solid #bbbbbb;
    &:first-of-type {
      border-top: 1px solid #bbbbbb;
    }
    b {
      font-weight: inherit;
    }
    small {
      display: block;
    }
    a {
      display: block;
      margin: 0;
      padding: 10px 0;
    }

    a:hover {
      opacity: 0.8;
      background-color: #e5f4fe;
    }
  }

  label {
    display: block;
    margin: 20px 0 10px 0;
    font-weight: bold;
    font-size: 11px;
    color: #859091;
    small {
      display: inline;
      font-weight: normal;
    }
  }
`;

export const actions = {
  selectInfluencer(source, id) {
    return {
      type: "viewInfluencerDetail",
      selectedSource: source,
      selectedId: id
    };
  },
  fetchInfluencerDetails(source, id) {
    if (source && id) {
      const resource = `/api/influencers/${source}/${id}`;
      return {
        type: "fetchInfluencer",
        api: {
          resource: resource
        }
      };
    }
  }
};

const initialState = {
  selectedSource: null,
  selectedId: null,
};

export function reducer(state = initialState, action) {
  // Todo this should move to UI, everything else is in domain and user.
  switch (action.type) {
    case "fetchInfluencer":
      return {
        ...state,
        isLoading: true
      };
    case "fetchInfluencerCompleted":
      return {
        ...state,
        isLoading: false
      };
    case "viewInfluencerDetail":
      return {
        ...state,
        selectedId: action.selectedId,
        selectedSource: action.selectedSource
      };

    default:
      return state;
  }
}

const selectModalInfluencer = createSelector(
  state => state.scouts.selectedSource,
  state => state.scouts.selectedId,
  state => state.domain.scoutDb.byKey,
  state => state.user.myScouts,
  (source, id, scoutDb, myScouts) => {
    if (!source || !id) {
      return null;
    }
    const key = _toKey(source, id);
    const scout = scoutDb[key] || {};
    scout.category = (myScouts[key]||{}).category;
    return scout;
  }
);

const hoc = compose(
  connect(
    (state) => ({
      isLoading: state.scouts.isLoading,
      selectedSource: state.scouts.selectedSource,
      selectedId: state.scouts.selectedId,
      influencer: selectModalInfluencer(state),
    }),
    dispatch => ({
      dispatch: dispatch,
      closeModal() {
        dispatch(actions.selectInfluencer(null, null));
      },
      fetchInfluencer(selectedSource, selectedId) {
        if (selectedSource && selectedId) {
          const resource = `/api/influencers/${selectedSource}/${
            selectedId
          }`;
          dispatch({
            type: "fetchInfluencer",
            api: {
              resource: resource
            }
          });
        }
      },
      dispatchUpdateCategory(selectedSource, selectedId, newCategory, resolve) {
        dispatch(
          actionCreators.adminInfluencerEditFormSave(
            selectedSource,
            {
              [pk[selectedSource]]: selectedId,
              category: newCategory
            },
            _toKey(selectedSource, selectedId),
            resolve
          )
        );
      }
    }),
  ),
  updateStatelessOnlyOn(["isLoading", "influencer"])
);

class Component extends React.PureComponent {
  componentDidUpdate(prevProps) {
    if (
      this.props.selectedSource &&
      this.props.selectedId &&
      String(this.props.selectedId) !== String(prevProps.selectedId)
    ) {
      this.props.fetchInfluencer(this.props.selectedSource, this.props.selectedId);
    }
  }

  updateScoutCategory = async (newCategory) => {
    return newCategory === this.props.influencer.category
      ? Promise.resolve()
      : new Promise(resolve => (
        this.props.dispatchUpdateCategory(
          this.props.selectedSource,
          this.props.selectedId,
          newCategory,
          resolve
        )
      ));
  }

  render() {
    const { isLoading, influencer, closeModal, updateScoutCategory } = this.props;
    if (!influencer) {
      return null;
    }

    const sourcePrimaryColour = (sourceIconMap[influencer.source] || [
      null,
      "inherit"
    ])[1];
    return (
      <Modal
        contentStyles={{
          left: "31%",
          right: "31%",
          bottom: "17%",
          minWidth: "200px"
        }}
      >
        <Styled>
          {isLoading && (
            <Loading className="loading">Fetching influencer...</Loading>
          )}
          <button onClick={closeModal}>close</button>
          <img
            alt=""
            className="avatar"
            src={influencer.avatar || placeholderImage}
          />
          <dt>{SOURCE_TO_NAME[influencer.source]} Scout Detail</dt>
          <h1>{influencer.name}</h1>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={influencer.source_url}
          >
            <LittleSourceIcon source={influencer.source} />
            <span style={{ color: sourcePrimaryColour }}>
              {shortenUrlForDisplay(influencer.source_url)}
            </span>
          </a>
          <div className={"myCategory"}>{
            influencer.category && (
              <EditableCategory
                savedCategory={influencer.category}
                onSave={this.updateScoutCategory}
              />
            )
          }</div>
          <div className="description">
            <label>Biography</label>
            <div>{influencer.description}</div>
          </div>
          {influencer.last_follows && (
            <div className="followed">
              <label className="followedTitle">
                Recent Follows <small>Includes non-artists</small>
              </label>
              <h3>
                {influencer.recent_follow_count}{" "}
                <small>follows in the last 14 days.</small>
              </h3>
              <span>Last 3:</span>
              <ul>
                {(influencer.last_follows || []).slice(0, 3).map(f => (
                  <li key={f[0]}>
                    <Link to={`/artist/${influencer.source}/${f[0]}`}>
                      <b>{f[1]}</b>
                      <small>{moment(f[2]).fromNow()}</small>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </Styled>
      </Modal>
    );
  }
}

export const StorybookScoutInfoOverlay = Component;

export const ScoutInfoOverlay = hoc(Component);
