// Reducer.  Installed at 'ui.profileLookup'.
const initialState = {
  profileToConfirm: null,
  lookupInProgress: false,
  profileShouldDisplay: false,
  errorMessage: ""
};

function _assignWithDefault(state, namespace, obj) {
  const nameSpacedState = {
    ...initialState,
    ...(state[namespace] || {}),
    ...obj
  };
  return {
    ...state,
    [namespace]: nameSpacedState
  };
}

export function reduceNamespacedProfileLookup(state = {}, action) {
  if (!action.namespace) {
    return state;
  }

  switch (action.type) {
    case "profileLookup/lookupProfile":
    case "profileLookup/lookupProfileStarted":
      return _assignWithDefault(state, action.namespace, {
        lookupInProgress: true,
        profileShouldDisplay: false,
        errorMessage: ""
      });

    case "profileLookup/lookupProfileCompleted":
      return _assignWithDefault(state, action.namespace, {
        lookupInProgress: false,
        profileShouldDisplay: true,
        profileToConfirm: action.data
      });

    case "profileLookup/inputValueChanges":
      return _assignWithDefault(state, action.namespace, {
        lookupInProgress: false,
        profileShouldDisplay: false
      });

    case "profileLookup/lookupProfileFailed":
      const errorObj = action.error;
      if (action.error.status === 404) {
        // actually not an 'error' per se, just no profile.
        return _assignWithDefault(state, action.namespace, {
          lookupInProgress: false,
          profileShouldDisplay: true,
          profileToConfirm: null,
          errorMessage: ""
        });
      }

      let msg = errorObj.info || errorObj.message || "" + errorObj;
      return _assignWithDefault(state, action.namespace, {
        lookupInProgress: false,
        errorMessage: msg
      });

    case "profileLookup/reset":
    case "profileLookup/profileAccepted":
      return _assignWithDefault(state, action.namespace, initialState);

    default:
      return state;
  }
}
