import { combineReducers } from "redux";
import { reduceArtistDb } from "./artistDb";
import { _toKey } from "../data_access";
import { pk } from "../admin";

export const datasourceMapping = {
  twitter_by_day: "tw",
  instagram_by_day: "in",
  soundcloud_by_day: "sc",
  twitter: "tw",
  soundcloud: "sc",
  instagram: "in",
  spotify: "spy",
  youtube: "yt",
};

const initialState = {
  ytData: {
    videosIndex: {},
    videos: [],
    dataMatrixIndex: {},
    dataMatrix: [],
    // ...ytDataFixture
  },

  scTrackData: {
    tracksIndex: {},
  },

  spyTrackData: {
    index: {},
    sorts: {},
  },
  spyPlaylistData: {
    index: {},
  },
  spyGenreData: [],
  ttData: {
    sounds: {},
  },

  scoutDb: {
    byKey: {},
  },
};

const video = (data = {}) => ({
  channel_name: null,
  title: null,
  ytid: null,
  published: null,
  view_count: null,
  like_count: null,
  dislike_count: null,
  comment_count: null,
  like_change: 0,
  view_change: 0,
  dislike_change: 0,
  comment_change: 0,
  dataMatrix: [],
  ...data,
});
function addVideos(state, videoDataList) {
  const newVideosIndex = { ...state.videosIndex };
  videoDataList.forEach(videoData => {
    const ytid = videoData.ytid;
    newVideosIndex[ytid] = {
      ...video(newVideosIndex[ytid]),
      ...videoData,
    };
    if (newVideosIndex[ytid].dataMatrix) {
      newVideosIndex[ytid].dataMatrix = newVideosIndex[ytid].dataMatrix.map(
        row => {
          const forJs = row.slice(0);
          // forJs[0] *= 24 * 3600 * 1000; // convert days since epoch to js timestamps (millis since epoch).
          return forJs;
        }
      );
    }
  });

  let newState = {
    ...state,
    videosIndex: newVideosIndex,
  };
  return newState;
}
export function reduceYtData(state = initialState.ytData, action) {
  switch (action.type) {
    case "fetchDatasourceCompleted":
      if (action.datasource === "youtube") {
        return addVideos(state, action.data.videos);
      } else {
        return state;
      }
    case "fetchVideoDetailCompleted":
      return addVideos(state, [action.data]);

    case "youtube/fetchDataCompleted":
      return addVideos(state, action.data.videos);

    case "explorer/ytVideosCompleted":
      return addVideos(
        state,
        action.data.results.map(v => v.video)
      );

    case "fetchArtistCompleted":
      return addVideos(
        state,
        ((action.data.ytVideoData || {}).videos || []).map(v => v.video)
      );
    default:
      return state;
  }
}

const scTrack = (data = {}) => ({
  scid: null,
  artist_scid: null,
  title: null,
  created_at: null,
  duration: null,
  genre: null,
  artwork_url: null,
  permalink_url: null,
  playback_count: null,
  download_count: null,
  favoritings_count: null,
  comment_count: null,
  dataMatrix: [],
  ...data,
});
function nullSafeSeriesValue(stats, index) {
  return stats && stats.series
    ? (stats.series[index] || [null, null])[1]
    : null;
}

function convertNewStyleStatsTrackToDomainTrack(track) {
  // should really decide on a format....'source', 'sc',
  // 		'scid', sct.scid,
  // 		'artist_scid', sct.artist_scid,
  // 		'title', sct.name,
  // 		'artwork_url', COALESCE (sct.data->>'artwork_url', sct.data->'user'->>'avatar_url'),
  // 		'soundcloud_url', sct.data->>'permalink_url',
  // 		'release_date', sct.created_at,
  // 		'total_plays', COALESCE (sct.data->>'playback_count','0')::int,
  // 		'total_likes', COALESCE (sct.data->>'favoritings_count','0')::int,
  // 		'total_comments', COALESCE (sct.data->>'comment_count','0')::int,
  // 		'genre', sct.data->>'genre',
  // 		'stats', scores.timeseries,
  // 		'scores', jsonb_build_object(
  // 		    'plays', scores.plays,
  // 		    'likes', scores.likes
  // 		  )

  return scTrack({
    scid: track.scid,
    artist_scid: track.artist_scid,
    title: track.title,
    created_at: track.release_date,
    duration: null,
    genre: track.genre,
    artwork_url: track.artwork_url,
    permalink_url: track.soundcloud_url,
    dataMatrix: track.stats.map(row => [
      row[0], // date
      row[1], // plays
      0, // downloads (defunct)
      row[3], // favoritings
      row[2], // comments
    ]),
  });
}

function convertBrainsTrackToDomainTrack(brainsTrack) {
  // should really decide on a format....
  return scTrack({
    scid: brainsTrack.track_scid,
    artist_scid: brainsTrack.track_info.artist_scid,
    title: brainsTrack.track_info.data.title,
    created_at: brainsTrack.track_info.data.created_at,
    duration: brainsTrack.track_info.data.duration,
    genre: brainsTrack.track_info.genre,
    artwork_url: brainsTrack.track_info.data.artwork_url,
    permalink_url: brainsTrack.track_info.data.permalink_url,
    dataMatrix: ((brainsTrack.playback_stats || {}).series || []).reduce(
      (acc, playsPoint, i) => {
        if (!playsPoint || playsPoint.length === 0) {
          // strange data.
          return acc;
        }
        acc.push([
          playsPoint[0],
          playsPoint[1],
          nullSafeSeriesValue(brainsTrack.download_stats, i),
          nullSafeSeriesValue(brainsTrack.favoritings_stats, i),
          nullSafeSeriesValue(brainsTrack.comment_stats, i),
        ]);

        return acc;
      },
      []
    ),
  });
}

export function reduceScTrackData(state = initialState.scTrackData, action) {
  const tracksIndex = { ...state.tracksIndex };

  switch (action.type) {
    case "fetchArtistCompleted":
      ((action.data.soundCloudTrackData || {}).tracks || []).forEach(
        ({ track, scid }) => {
          tracksIndex[scid] = convertNewStyleStatsTrackToDomainTrack(track);
        }
      );
      return {
        ...state,
        tracksIndex,
      };

    case "fetchScTrackDetailsCompleted":
      const track = action.data;
      tracksIndex[track.track_scid] = convertBrainsTrackToDomainTrack(track);
      return {
        ...state,
        tracksIndex,
      };

    case "explorer/scTracksCompleted":
      action.data.results.forEach(({ track }) => {
        tracksIndex[track.scid] = { ...tracksIndex[track.scid], ...track };
      });
      return { ...state, tracksIndex };

    default:
      return state;
  }
}

export function reduceSpyTrackData(state = initialState.spyTrackData, action) {
  const index = { ...state.index };
  switch (action.type) {
    case "explorer/spyTracksCompleted":
      action.data.results.forEach((track, i) => {
        index[track.spyid] = track.track;
      });
      return {
        ...state,
        index,
      };
    case "fetchArtistCompleted":
      ((action.data.spotifyTrackData || {}).tracks || []).forEach(
        (track, i) => {
          index[track.spyid] = {
            track: track.track,
            artist: action.data, // slightly weird structure. guess the right thing will be to move the artist to the artistDb.
          };
        }
      );
      return {
        ...state,
        index,
      };

    case "spotify/fetchTrackCompleted":
      if (action.data && action.data.spyid) {
        index[action.data.spyid] = action.data;
      }
      return {
        ...state,
        index,
      };

    case "charts/fetchPageCompleted":
      // Some charts return spy tracks. To keep the data consolidated with the spotify tab
      // we store the data in the domain.  The chart will only use a list of spyids created in
      // the charts reducer
      if (
        action.data &&
        action.data.results &&
        action.data.results.length &&
        action.data.results[0].track_spyid
      ) {
        // we have spotify track results from a chart.
        action.data.results.forEach((track, i) => {
          index[track.track_spyid] = track;
        });
        return {
          ...state,
          index,
        };
      }
      return state;

    case "charts/newChartResultsCompleted":
      // this is connected from the useChartPaginatedFetch hook
      if (
        action.data &&
        Array.isArray(action.data.results) &&
        action.data.results.length
      ) {
        action.data.results.forEach((track, i) => {
          if (track.track_spyid) {
            index[track.track_spyid] = track;
          }
        });
        return {
          ...state,
          index,
        };
      }
      return state;

    case "spotify/markArtistSignedCompleted":
      action.data.tracks.forEach((track, i) => {
        const o = index[track.spyid];
        if (o) {
          index[track.spyid] = { ...o, track: track.track };
          console.log("Found", track.spyid, index[track.spyid]);
        } else {
          console.log("Not loaded yet.");
        }
      });
      return {
        index,
        sorts: state.sorts,
      };

    default:
      return state;
  }
}

export function reduceSpyPlaylistData(
  state = initialState.spyPlaylistData,
  action
) {
  switch (action.type) {
    case "fetchUserCompleted":
    case "loginCompleted":
      return {
        index: action.data.spyPlaylists.reduce((acc, pl) => {
          acc[pl.spyid] = pl;
          return acc;
        }, {}),
      };
    default:
      return state;
  }
}

export function reduceSpyGenreData(state = initialState.spyGenreData, action) {
  switch (action.type) {
    case "fetchUserCompleted":
    case "loginCompleted":
      return action.data.spyGenres || [];
    default:
      return state;
  }
}

// <key, source, id, display_name, avatar, source_url, description, isActive,
// last_follows: [[id,name,when]...],
// total_follow_count,
// recent_follow_count>
function reduceScoutDb(state = initialState.scoutDb, action) {
  const upsertFromArtistModel = byKey => inf => {
    const key = _toKey(inf.source, inf.id);
    const scout = byKey[key] || { key, source: inf.source, id: inf.id };
    byKey[key] = {
      ...scout,
      display_name: inf.name,
      avatar: inf.avatar,
    };
  };

  switch (action.type) {
    case "charts/newChartResultsCompleted":
      const chartResults = action.data.results;
      if (chartResults.length > 0 && chartResults[0].artist) {
        const byKey = { ...state.byKey };
        chartResults.forEach(({ artist: { influencers = [] } }) => {
          influencers.forEach(upsertFromArtistModel(byKey));
        });
        return {
          ...state,
          byKey: byKey,
        };
      }

    case "fetchArtistCompleted":
      if (action.data.influencers && action.data.influencers.length > 0) {
        const byKey = { ...state.byKey };
        action.data.influencers.forEach(upsertFromArtistModel(byKey));
        return { ...state, byKey };
      }

    case "fetchInfluencerCompleted":
      return {
        ...state,
        byKey: {
          ...state.byKey,
          [action.data.key]: {
            ...state.byKey[action.data.key],
            ...action.data,
          },
        },
      };
      return state;

    case "fetchDatasourceCompleted":
      // datasource: 'soundcloud_by_day',
      // data: {
      //   influencers: {
      //     '1000001': {
      //       id: 1000001,
      //       name: 'Form Integral Progress Pudding',
      //       username: 'influentialrecords',
      //       thumbnail: 'https://robohash.org/influentialrecords.png?set=set3',
      //       description: 'Email: SpzMusic17@gmail.com\n#YoungKidOldSoul\nIf you like my music, feel free to leave a comment...',
      //       category: null
      //   }
      // },
      let shortSource = datasourceMapping[action.datasource];
      if (
        shortSource &&
        action.data.influencers &&
        Object.keys(action.data.influencers).length > 0
      ) {
        const byKey = { ...state.byKey };
        Object.entries(action.data.influencers).forEach(([id, inf]) => {
          const key = _toKey(shortSource, id);
          // xxxx: can we use username field to crate source_url?
          const scout = byKey[key] || { key, source: shortSource, id };
          byKey[key] = {
            ...scout,
            display_name: inf.name,
            avatar: inf.thumbnail,
            description: inf.description,
          };
        });
        return {
          ...state,
          byKey,
        };
      }

    case "adminInfluencersGetListCompleted":
      shortSource = datasourceMapping[action.datasource];
      if (shortSource && action.data.length > 0) {
        const byKey = { ...state.byKey };
        action.data.forEach(inf => {
          const id = inf[pk[shortSource]];
          const key = _toKey(shortSource, id);
          const scout = byKey[key] || { key, source: shortSource, id };
          byKey[key] = {
            ...scout,
            display_name: inf.scout_display_name,
            avatar: inf.avatar,
            isActive: inf.active,
          };
        });
        return {
          ...state,
          byKey,
        };
      }
    // {
    //   datasource: 'soundcloud',
    //   type: 'adminInfluencersGetListCompleted',
    //   data: [
    //     {
    //       scid: 1000001,
    //       scout_display_name: 'Form Integral Progress Pudding',
    //       category: null,
    //       active: true
    //     },
    //     {
    //       scid: 1000002,
    //       scout_display_name: 'Dandruff Deed Epoch Holding Of Sophisticated Stadium The With',
    //       category: null,
    //       active: true
    //     },
    //     {
    //       scid: 1000003,
    //       scout_display_name: 'Form Integral Progress Pudding',
    //       category: null,
    //       active: true
    //     }

    default:
      return state;
  }
}

export const reduceDomain = combineReducers({
  artistDb: reduceArtistDb,
  ytData: reduceYtData,
  scTrackData: reduceScTrackData,
  spyTrackData: reduceSpyTrackData,
  spyPlaylistData: reduceSpyPlaylistData,
  spyGenres: reduceSpyGenreData,
  ttData: function(state = initialState.ttData, action) {
    switch (action.type) {
      case "explorer/ttSoundsCompleted":
        let nextState = { ...state, sounds: { ...state.sounds } };
        action.data.results.forEach(ttSound => {
          nextState.sounds[ttSound.ttid] = {
            ...state.sounds[ttSound.ttid],
            ...ttSound,
          };
        });
        return nextState;
      default:
        return state;
    }
  },
  scoutDb: reduceScoutDb,
});

export function actionMarkSpyArtistAsSigned(artistSpyid, then) {
  return {
    type: "spotify/markArtistSigned",
    artistSpyid,
    api: {
      resource: `/api/spotify/${artistSpyid}/mark_signed`,
      options: { method: "PUT" },
      then,
    },
  };
}

export function getTikTokSoundById(ttid, ttData) {
  return ttData.sounds[ttid];
}
