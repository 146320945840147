import React from "react";
import styled from "styled-components";
import icon from "../images/icon-magnifying-glass@2x.png";
import {blackish} from "../styles/styleConsts";
import {transparentize} from "polished";

export default class Searcher extends React.Component {
  constructor(props) {
    super(props);

    this.state = {value: props.value};

    this._onChange = this._onChange.bind(this);
  }

  _onChange(ev) {
    this.setState({value: ev.target.value});
    this.props.onChange((ev.target.value||"").toLowerCase());
  }

  render() {
    return (
        <div className={`searcher ${this.props.className||""}`}>
          <Label>{this.props.label}</Label>
          <Wrapper>
            <SearchIcon src={icon} width="15" height="21" />
            <Input placeholder={this.props.placeholder || "Filter..."} value={this.state.value || ""} onChange={this._onChange}/>
          </Wrapper>
        </div>
    );
  }
}

const Label = styled.h3`
  margin-bottom: 2px;
  margin-top: 13px;
  font-size: 13px;
  padding-bottom: 0;
`;

const Wrapper = styled.div`
  position: relative;
`;

const SearchIcon = styled.img.attrs(props => ({src: props.src}))`
  position: absolute;
  left: 6px;
  top: 4px;
  width: 15px;
  height: 21px;
`;

const Input = styled.input`
  padding: 6px 10px 6px 25px;
  border-radius: 12px;
  background-color: ${transparentize(0.96, blackish)};
  border: none;
`;
