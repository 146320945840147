import React from "react";
import moment from "moment/moment";
import { connect } from "react-redux";
import { createSelector } from "reselect";

import { RoutedModal } from "../../components/modal";
import { MetricsGraph } from "../../components/metrics/modal";
import { BigTitle, SmallLabel } from "../../components/metrics/styles";

import { selectArtist } from "../artist_selectors";

const ALL_METRICS = [
  { index: 0, id: "SpotifyFollowers", label: "Spotify Followers" },
  { index: 1, id: "SoundCloudFollowers", label: "SoundCloud Followers" },
  { index: 2, id: "TwitterFollowers", label: "Twitter Followers" },
  { index: 3, id: "InstagramFollowers", label: "Instagram Followers" },
  { index: 4, id: "SpotifyPopularity", label: "Spotify Popularity" },
  { index: 5, id: "SoundCloudEngagement", label: "SoundCloud Engagement" },
  { index: 6, id: "YouTubeSubscribers", label: "YouTube Subscribers" },
  { index: 7, id: "YouTubeChannelViews", label: "YouTube Channel Views" }
];

function _getMetricData(artistBrains = {}, metricId) {
  switch (metricId) {
    case "SpotifyFollowers":
      return (artistBrains.FollowerCounts || {}).Spotify || {};
    case "SoundCloudFollowers":
      return (artistBrains.FollowerCounts || {}).SoundCloud || {};
    case "TwitterFollowers":
      return (artistBrains.FollowerCounts || {}).Twitter || {};
    case "InstagramFollowers":
      return (artistBrains.FollowerCounts || {}).Instagram || {};
    case "SpotifyPopularity":
      return artistBrains.SpotifyEngagement || {}; // bad naming in API
    case "SoundCloudEngagement":
      return artistBrains.SoundCloudEngagement || {};
    case "YouTubeSubscribers":
      return {
        series: (artistBrains.youTubeArtistChannelSeries || []).map(r => [
          r[0],
          r[1]
        ])
      };
    case "YouTubeChannelViews":
      return {
        series: (artistBrains.youTubeArtistChannelSeries || []).map(r => [
          r[0],
          r[2]
        ])
      };
    default:
    console.error("Unknown artist metric id: " + metricId);
    return {};
  }
}

const selectArtistWithDataMatrix = createSelector(selectArtist, artist => {
  const dataMatrixV2 = ALL_METRICS.map(
    m => _getMetricData(artist.brains, m.id).series
  );
  return {
    ...artist,
    dataMatrixV2,
    title: artist.name
  };
});

const hoc = connect(
  state => {
    return {
      isShown: state.ui.location.hash.endsWith("/zoomed"),
      selectedArtist: selectArtistWithDataMatrix(state),
      windowDotLocation: window.location
    };
  }
);

class Component extends React.PureComponent {
  render() {
    const {
      isShown,
      selectedArtist,
      windowDotLocation
    } = this.props;

    if (!isShown) {
      return null;
    }

    const metrics = ALL_METRICS.filter(
      m => (selectedArtist.dataMatrixV2[m.index] || []).length > 0
    );

    if (!selectedArtist) {
      return null;
    }

    return (
      <RoutedModal
        isOpen
        isLoadingContent={false}
        closeLinkTo={windowDotLocation.hash.split("/.m/")[0].substring(1)}
      >
        <section className="row">
          <SmallLabel>Artist:</SmallLabel>
        </section>
        <section
          className="row"
          style={{ backgroundColor: "#f4f4f4", color: "black" }}
        >
          <div className="title">
            <BigTitle>{selectedArtist.name}</BigTitle>
            <div>{selectedArtist.username}</div>
            <div>
              First Seen{" "}
              {moment(selectedArtist.first_seen).format("D MMM, YYYY")}
              <span> ({moment(selectedArtist.first_seen).fromNow()})</span>
            </div>
          </div>
          <img alt="" src={selectedArtist.avatar} height="120" width="120" />
        </section>
        <MetricsGraph
          {...{
            selectedTrackData: selectedArtist,
            metrics,
            initialComparisonMode: "disabled",
            ComparisonFinderComponent: null // OtherArtistsFinder
          }}
        />
      </RoutedModal>
    );
  }
}

export const ArtistDetailModal = hoc(Component);
