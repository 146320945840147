import React from "react";
import { Flag } from "./flag_icons";
import  {
  getCountryNameFromCode
} from "./primitives/country_mapping";
import moment from "moment";
import numeral from "numeral";
import styled from "styled-components";
import {
  spotifyGreen,
} from "../styles/styleConsts";
import SparkLine from "react-canvas-spark-line";
import {sortBy} from "../utils";

const Styled = styled.div`
  h1 {
    font-size: 22px;
    margin-bottom: 5px;
  }
  table {
    width: auto;
  }

  td {
    padding: 3px 10px;
    border-bottom: 1px solid #ddd;
  }

  .innerTitleRow {
    font-size: 0.7em;
    text-transform: uppercase;
  }

  .streams {
    text-align: right;
  }

  .position::after {
    padding-left: 3px;
  }
  .position {
    &.up::after {
      color: green;
      content: "▲";
    }
    &.down::after {
      color: red;
      content: "▼";
    }
    &.flat::after {
      content: "—";
    }
    &.new::after {
      color: yellow;
      content: "★";
    }
  }

  .countryRow {
    background-color: #f3f3f3;
    > span {
      display: table-cell;
      padding: 4px;
    }
    .countryName {
      width: 90px;
      min-width: 90px;
    }
  }

  .exitPosition {
    width: 50px;
    font-size: 18px;

    small {
      font-size: 0.6em;
    }
  }
`;

export class SpotifyTop200Details extends React.PureComponent {
  state = {
    expanded: {}
  };

  toggleGroup = iso2 => {
    const newState = {
      ...this.state.expanded,
      [iso2]: !this.state.expanded[iso2]
    };
    console.log("collapsed state", iso2, this.state.expanded, newState);
    this.setState({ expanded: newState });
  };

  render() {
    const { track } = this.props;

    if (!track || !track.top200History || !track.top200History.length) {
      return null;
    }

    const rollups = track.top200History.reduce((acc, row) => {
      const [iso2, day, position, streams] = row;
      let movement;
      if (!acc[iso2]) {
        acc[iso2] = [];
        movement = "new";
      } else {
        const prevRow = acc[iso2][acc[iso2].length - 1];
        movement =
          prevRow[1] > position
            ? "down"
            : prevRow[1] < position
              ? "up"
              : "flat";
      }
      acc[iso2].push([day, position, streams, movement]);

      return acc;
    }, {});

    return (
      <Styled>
        <h1>Top 200 Chart Appearance</h1>
        <table>
          {sortBy(Object.entries(rollups), ([iso2, rows]) => [
            -(iso2.toUpperCase() === "GLOBAL"),
            -rows[rows.length - 1][0],
            -rows[rows.length - 1][1]
          ]).map(([iso2, rows]) => {
            const isExpanded = this.state.expanded[iso2];
            return (
              <React.Fragment key={"chart" + iso2}>
                <tbody>
                  <tr>
                    <td className="countryRow" colSpan={5}>
                      {isExpanded ? (
                        <span className="expanded">
                          <button onClick={this.toggleGroup.bind(this, iso2)}>
                            -
                          </button>
                        </span>
                      ) : (
                        <span className="collapsed">
                          <button onClick={this.toggleGroup.bind(this, iso2)}>
                            +
                          </button>
                        </span>
                      )}
                      <span className="countryFlag">
                        <Flag code={iso2} includeName={false} />
                      </span>
                      <span className={"countryName"}>
                        {getCountryNameFromCode(iso2)}
                      </span>
                      <span className="exitPosition">
                        {rows[rows.length - 1][1]}
                        <small>/200</small>
                      </span>
                      <span>
                        <SparkLine
                          animate={false}
                          width={80}
                          height={20}
                          color={spotifyGreen}
                          data={rows.map(r => -r[1] / 10)}
                          includeZero={false} // Default: true
                        />
                      </span>
                      <span className="entered">
                        <b>Entered Chart:</b>
                        <b>
                          {moment(rows[0][0] * 1000 * 24 * 3600).format(
                            "DD MMM"
                          )}
                        </b>
                      </span>
                      <span className="exited">
                        {new Date().getTime() / 1000 / 3600 / 24 - 1 <
                        rows[rows.length - 1][0] ? (
                          <b>Still on chart</b>
                        ) : (
                          <React.Fragment>
                            <b>Exited Chart:</b>
                            <b>
                              {moment(
                                rows[rows.length - 1][0] * 1000 * 24 * 3600
                              ).format("DD MMM")}
                            </b>
                          </React.Fragment>
                        )}
                      </span>
                    </td>
                  </tr>
                </tbody>
                {!isExpanded ? null : (
                  <tbody key={"innerRow" + iso2}>
                    <tr className={"innerTitleRow"}>
                      <th>Day</th>
                      <th>Rank</th>
                      <th>Daily Streams</th>
                    </tr>
                    {rows.map(([day, position, streams, movement]) => (
                      <tr key={"row" + iso2 + day}>
                        <td className="day">
                          {moment(day * 3600 * 24 * 1000).format("DD MMM")}
                        </td>
                        <td className={"position " + movement}>{position}</td>
                        <td className="streams">
                          {numeral(streams).format("0,0")}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </React.Fragment>
            );
          })}
        </table>
      </Styled>
    );
  }
}
