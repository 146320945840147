import React from "react";
import _ from "lodash";
import icon from "../images/icon-magnifying-glass@2x.png";

export class Toggler extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      domain: props.domain,
      selected: props.selected
    };
  }

  toggleChoice(choice) {
    let selected;
    if (this.state.selected.indexOf(choice.value) !== -1) {
      selected = _.without(this.state.selected, choice.value);
      if (this.props.onChoose) {
        this.props.onChoose(choice, false);
      }
    } else {
      selected = this.state.selected.concat([choice.value]);
      if (this.props.onChoose) {
        this.props.onChoose(choice, true);
      }
    }
    if (this.props.onChange) {
      this.props.onChange(selected);
    }
    this.setState({ selected: selected });
  }

  render() {
    return (
      <div className={"toggler " + this.props.className || ""}>
        <h3>
          {this.props.label} <small> (on/off)</small>
        </h3>
        {this.state.domain.map(choice => {
          const isSelected = this.state.selected.indexOf(choice.value) !== -1;
          const className =
            "choice " + (isSelected ? "on " : "off ") + choice.value;

          return (
            <b
              className={className}
              style={choice.style || {}}
              onClick={this.toggleChoice.bind(this, choice)}
              key={choice.value}
            >
              {choice.display || choice.value}
            </b>
          );
        })}
      </div>
    );
  }
}

const Options = props => {
  const { handleClose, handleChoice } = props;
  return (
    <div className="options-box">
      <span className="close-options" onClick={handleClose}>
        Close
      </span>
      <ul>
        {props.domain
          .filter(
            choice =>
              props.selected.indexOf(choice.value) === -1 &&
              choice.display.toLowerCase().indexOf(props.query) !== -1
          )
          .map(choice => {
            return (
              <li key={choice.value} onClick={() => handleChoice(choice)}>
                {choice.enhancedDisplay || choice.display}
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export class Tagger extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      domain: props.domain,
      selected: props.initialSelection || props.selected,
      isTyping: false,
      query: ""
    };

    this._onSearch = this._onSearch.bind(this);
    this._onFocus = this._onFocus.bind(this);
    this._closeOptions = this._closeOptions.bind(this);
    this.clear = this.clear.bind(this);
    this._selectChoice = this._selectChoice.bind(this);
  }

  _onSearch(ev) {
    this.setState(
      Object.assign({}, this.state, {
        query: ev.target.value
      })
    );
  }
  _onFocus(ev) {
    this.setState(
      Object.assign({}, this.state, {
        isTyping: true
      })
    );
  }
  _selectChoice(choice) {
    const selected = _.uniq(this.state.selected.concat([choice.value]));
    this.setState(
      Object.assign({}, this.state, {
        selected: selected
      })
    );
    if (this.props.onSelectChoice) {
      this.props.onSelectChoice(choice);
    }
    if (this.props.onChange) {
      this.props.onChange(selected);
    }
  }
  _removeChoice(choice) {
    const selected = _.without(this.state.selected, choice.value);
    this.setState(
      Object.assign({}, this.state, {
        selected: selected
      })
    );
    if (this.props.onRemoveChoice) {
      this.props.onRemoveChoice(choice);
    }
    if (this.props.onChange) {
      this.props.onChange(selected);
    }
  }
  _closeOptions(ev) {
    ev.preventDefault();
    this.setState(
      Object.assign({}, this.state, {
        isTyping: false,
        query: ""
      })
    );
  }

  clear(ev) {
    ev.preventDefault();
    this.setState(
      Object.assign({}, this.state, {
        selected: [],
        isTyping: false,
        query: ""
      })
    );
    if (this.props.onClear) {
      this.props.onClear();
    }
    if (this.props.onChange) {
      this.props.onChange([]);
    }
  }
  // componentDidMount() {
  //   this.refs.queryInput.getDOMNode().focus();
  // }

  render() {
    return (
      <div className={"tagger " + this.props.className || ""}>
        <header>
          <h3>{this.props.label}</h3>
          {this.state.selected.length > 0 ? (
            <span className="clear" onClick={this.clear}>
              Clear
            </span>
          ) : (
            <span />
          )}
        </header>
        <div className="tagger-input">
          <div className="tagger__input-with-icon">
            <img alt="" className="tagger__icon" src={icon} width="15" height="21" />
            <input
              ref="queryInput"
              type="text"
              onChange={this._onSearch}
              onFocus={this._onFocus}
              onBlur={this._onBlur}
              placeholder="Find..."
            />
          </div>

          {this.state.isTyping ? (
            <Options
              domain={this.state.domain}
              query={this.state.query}
              selected={this.state.selected}
              handleClose={this._closeOptions}
              handleChoice={this._selectChoice}
            />
          ) : (
            <span />
          )}
        </div>
        <div className="selected-tags">
          {this.state.selected.map(value => {
            const choice = this.state.domain.find(
              choice => choice.value === value
            );
            return (
              <b
                key={choice.value}
                className={"choice " + choice.value}
                style={choice.style || {}}
                onClick={this._removeChoice.bind(this, choice)}
              >
                {choice.display}
              </b>
            );
          })}
        </div>
      </div>
    );
  }
}
