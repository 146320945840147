const initialState = {
  twitter_by_day: {},
  soundcloud_by_day: {},
  instagram_by_day: {}
};

const handleExperimental = data => {
  // translate a compacted version of the activity data.
  const types = ["Follow", "Like"];

  const activity = data.activity.map(row => ({
    time: new Date(row[0] * 1000),
    artist_id: row[1],
    influencer_id: row[2],
    type: types[row[3]],
    previous: (row[4] || []).map(p => ({
      time: new Date(p[0] * 1000),
      influencer_id: p[1]
    }))
  }));

  return {
    ...data,
    activity
  };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "fetchDatasource":
      return {
        ...state,
        [action.datasource]: { ...state[action.datasource], loading: true }
      };
    case "fetchDatasourceCompleted": {
      let data = action.data;
      if (action.experimental) {
        data = handleExperimental(data);
      }
      return {
        ...state,
        moreArtistsUrl: data.nextUrl,
        [action.datasource]: {
          ...state[action.datasource],
          ...data,
          loaded: true,
          loading: false,
          influencers: undefined,
        }
      };
    }

    case "fetchDatasourceFailed":
      return {
        ...state,
        [action.datasource]: { ...state[action.datasource], loading: false },
      };

    case "fetchMoreArtistsStarted":
      return { ...state, moreArtistsUrl: null };

    case "fetchMoreArtistsCompleted":
      let data = action.data;
      const artists = { ...state[action.datasource].artists, ...data };
      return {
        ...state,
        moreArtistsUrl: null, // this should also set to null meaning no more artists to fetch.
        [action.datasource]: { ...state[action.datasource], artists }
      };

    default:
      return state;
  }
};

export const actionCreators = {
  fetchDatasource: datasource => ({
    type: "fetchDatasource",
    datasource,
    api: { resource: "/api/" + datasource }
  }),

  fetchDatasourceExp: datasource => ({
    type: "fetchDatasource",
    datasource,
    api: { resource: "/api/" + datasource },
    experimental: true
  }),

  fetchMoreArtists: (datasource, nextUrl) => ({
    type: "fetchMoreArtists",
    datasource,
    api: { resource: nextUrl }
  }),

  filterDatasource: (datasource, field, value) => ({
    type: "filterDatasource",
    datasource,
    field,
    value
  })
};
