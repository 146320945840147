// eslint default-case: "off"

export const hideArtistForMe = artist => ({
  type: "artist/hideForMe",
  api: {
    resource: "/api/users/hidden_artists/" + artist.keys[0],
    options: {
      method: "PUT"
    }
  }
});

export const unhideArtistForMe = artist => ({
  type:  "artist/hideForMe",
  api: {
    resource: "/api/users/hidden_artists/" + artist.keys[0],
    options: {
      method: "DELETE"
    }
  }
});

export const actionCreators = {
  fetchArtist: (datasource, id) => {
    return {
      type: "fetchArtist",
      api: {
        resource:
          "/api/artists/" +
          encodeURIComponent(datasource) +
          "/" +
          encodeURIComponent(id) +
          "?ver=new_setf"
      }
    };
  },
  fetchUpdatedArtist: (datasource, id, then) => {
    return {
      type: "fetchUpdatedArtist",
      api: {
        resource:
          "/api/artists/" +
          encodeURIComponent(datasource) +
          "/" +
          encodeURIComponent(id) +
          "?ver=new_setf",
        options: {
          method: "POST"
        },
        then
      }
    };
  },
  artistBrains: (datasource, id) => {
    return {
      type: "artistBrains",
      version: 4,
      api: {
        resource: [
          "/api",
          "artists",
          encodeURIComponent(datasource),
          encodeURIComponent(id),
          "brains?ver=new_setf"
        ].join("/")
      }
    };
  },
  addCompareToArtistBrains: (datasource, id) => {
    return {
      type: "addCompareToArtistBrains",
      api: {
        resource: [
          "/api",
          "artists",
          encodeURIComponent(datasource),
          encodeURIComponent(id),
          "brains?noTracks=1"
        ].join("/")
      }
    };
  },
  removeCompareToArtist: index => {
    return {
      type: "removeCompareToArtist",
      index: index
    };
  },
  addToWhitelist: (whitelistId, datasource, id, then) => {
    return {
      type: "addToMyWhitelist",
      api: {
        resource: [
          "/api",
          "ManageWhitelist",
          whitelistId,
          encodeURIComponent(datasource),
          encodeURIComponent(id)
        ].join("/"),
        options: {
          method: "PUT",
        },
        then
      }
    };
  },
  removeFromWhitelist: (whitelistId, arid, then) => {
    return {
      type: "removeFromMyWhitelist",
      api: {
        resource: [
          "/api",
          "ManageWhitelist",
          whitelistId,
          encodeURIComponent(arid)
        ].join("/"),
        options: {
          method: "DELETE",
        },
        then
      }
    };
  }
};
