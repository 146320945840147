/**
 * Defines some data model defaults and methods for extracting data from the store
 * This file is on it's own to reduce circular importing.
 * Ideally this file is a leaf in the import, as these methods could be used like selectors.
 */
/* How you might fetch an artist from the 'artistDb' */
import {isNumber} from "../utils";

export const artist = (data = {}) => ({
  // sourceIds: {
  //   // The unique ids, only one must be present.
  //   a: null,
  //   tw: null,
  //   sc: null,
  //   spy: null
  // },
  // profileData: {
  //   name: null,
  //   location: null,
  //   biography: null,
  //   avatar: null,
  //   banner: null,
  //   webLinks: []
  // },
  // tracks: {
  //   sc: [],
  //   spy: []
  // },
  // media: [], // Images from instagram, twitter, album art.
  // followers: [],
  // alerts: [], // Important events like a track appearing on a big playlist
  // metrics: {}, // The metrics tracked, eg. soundcloud follower count.
  ...data,
  recentAlerts: data.recentAlerts || []
});

export function _toKey(source, id) {
  return source + "/" + id;
}

export function getArtist(artistDb, source, id) {
  return getArtistByKey(artistDb, _toKey(source, id));
}

export function getArtistByKey(artistDb, key) {
  const index = artistDb.index[key];
  return isNumber(index) ? artist(artistDb.list[index]) : null;
}
