import React from "react";

const badImageCache = {};

const imageOnError = (placeholderImage, bgColor) => errorEvent => {
  console.warn("Error loading image", errorEvent.target.src, errorEvent);
  badImageCache[errorEvent.target.src] = true;
  errorEvent.target.onerror = null;
  errorEvent.target.src = placeholderImage;
  errorEvent.target.style.backgroundColor = bgColor;
};

export const imgWithFallback = (placeholderImage, bgColor) => {
  const onError = imageOnError(placeholderImage, bgColor);
  return function Img({ src, style, ...props }) {
    return (
      <img
        {...props}
        style={{
          ...style,
          ...(badImageCache[src] ? { backgroundColor: bgColor } : {}),
        }}
        src={badImageCache[src] ? placeholderImage : src}
        onError={onError}
      />
    );
  };
};
