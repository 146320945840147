import React from "react";
import styled from "styled-components";
import posed, { PoseGroup } from "react-pose";
import { BigTitle } from "./metrics/styles";
import CountUp from "react-countup/build";
import { Loading } from "./primitives/things";
import {COMPONENT_ELEMENT_COLOR} from "../styles/styleConsts";
import numeral from "numeral";
export function categorize(data, transforms = {}, maxGroupings = 4, excludeUnmappedFields=false) {
  const allCategories = data.reduce((categories, dataRow) => {
    Object.entries(dataRow).forEach(([categoryKey, categoryValue]) => {
      const transform = transforms[categoryKey];
      if (transform) {
        if (transform.ignored) {
          return categories;
        }
        if (transform.func) {
          categoryValue = transform.func(categoryValue);
        }
      } else if (excludeUnmappedFields) {
        return categories;
      }

      const maybeMultipleValues = Array.isArray(categoryValue) ? categoryValue : [categoryValue];

      maybeMultipleValues.forEach(categoryValue => {

        if (categoryValue === "" || categoryValue === null) {
          categoryValue = "none";
        }
        if (!categories[categoryKey]) {
          categories[categoryKey] = {};
        }
        if (!categories[categoryKey][categoryValue]) {
          categories[categoryKey][categoryValue] = 1;
        } else {
          categories[categoryKey][categoryValue] += 1;
        }
      });
    });
    return categories;
  }, {});

  Object.keys(allCategories).forEach(key => {
    allCategories[key] = reduceNumberOfGroupings(
      allCategories[key],
      maxGroupings,
      (transforms[key]||{}).customSort,
    );
  });

  return Object.entries(allCategories)
    .map(([categoryKey, groups]) => ({
      key: categoryKey,
      name: (transforms[categoryKey] || {}).name || categoryKey,
      position: (transforms[categoryKey] || {}).position || 0,
      groups
    }))
    .sort((a, b) => a.position - b.position);
}

export function reduceNumberOfGroupings(groupings, maxNumber, customSort) {
  const entries = Object.entries(groupings);
  if (entries.length <= maxNumber) {
    return groupings;
  }
  const sortedEntries = entries.sort(customSort);
  return sortedEntries.reduce(
    (reducedCounts, [groupName, count], ix) => {
      if (ix < maxNumber - 1) {
        reducedCounts[groupName] = count;
      } else {
        reducedCounts.Other += count;
      }
      return reducedCounts;
    },
    { Other: 0 }
  );
}

const BAR_HEIGHT = 16;

const Styled = styled.div`
  label {
    text-transform: capitalize;
  }
  .categoryName {
    font-weight: inherit;
    display: block;
    margin: 12px 0 6px 0;
  }
  .categoryContainer {
    margin: 0 10px 10px 10px;
    display: inline-block;
    vertical-align: top;
  }

  .refinements {
    //height: ${BAR_HEIGHT + 8}px;
    margin: 5px;
    border-bottom: 1px solid #eee;
  }
  .refinement {
    //height: ${BAR_HEIGHT + 4}px;
    //line-height: ${BAR_HEIGHT + 4}px;
    margin-bottom: 2px;
    font-size: 12px;
    display: block;
    padding: 4px;
    flex-direction: row;
    //width: 200px;
    position: relative;
    background-color: #ddd;
    //margin-left: 10px;
    cursor: pointer;
    &:hover {
      background-color: #f1f1f1;
      color: #999;
    }
  }
  .refinementCategoryName {
    font-weight: bold;
    &:after {
      content: ": ";
    }
    flex: 1;
    margin-left: 3px;
    text-transform: capitalize;
  }
  .refinementValue {
    margin-right: 3px;
  }
  .refinementRemoveLink {
    width: 20px;
    margin-right: 3px;
    text-align: center;
  }
`;

const PosedCategoryContainer = posed.div({
  enter: { opacity: 1 },
  exit: { opacity: 0 },
  flip: {
    scale: 1,
    transition: {
      scale: {
        type: "tween"
      },
      default: {
        type: "tween"
      }
    }
  }
});

export function Faceter({
  dataRows,
  dataMapping,
  handleRefine,
  handleUnrefine,
  isLoading = false,
  refinedBy = [],
  excludeUnmappedFields=false,
  isArtists = false,
}) {
  handleRefine = handleRefine || (() => {});
  handleUnrefine = handleUnrefine || (() => {});
  const categories = categorize(dataRows, dataMapping, 5, excludeUnmappedFields);
  const refinedCategories = refinedBy.map(r => r.key);
  const entityType = isArtists ? 'artists' : 'tracks';
  return isLoading ? (
    <Styled>
      <Loading>Loading {entityType}</Loading>
    </Styled>
  ) : (
    <Styled>
      {
        <div className="refinements">
          {refinedBy.length > 0 && <label>Showing Only Results With:</label>}
          <PoseGroup>
            {refinedBy.map(refinement => (
              <PosedCategoryContainer
                key={refinement.key}
                className="refinement"
                onClick={handleUnrefine.bind(this, refinement)}
              >
                <span className="refinementCategoryName">
                  {refinement.name}
                </span>
                <span className="refinementValue">{refinement.valueLabel}</span>
                <span className="refinementRemoveLink">x</span>
              </PosedCategoryContainer>
            ))}
          </PoseGroup>
        </div>
      }
      {dataRows.length === 0 ? (
        <Styled>
          <span className={"noData"}>No matching {entityType} found.</span>
        </Styled>
      ) : (
        <>
          <BigTitle
            style={{
              fontSize: "30px",
              marginLeft: "10px",
              fontWeight: "bold",
              color: "#333"
            }}
          >
            <CountUp
              duration={1.1}
              ease={"none"}
              start={0}
              end={dataRows.length}
              separator={","}
            />
            <small
              style={{
                display: "inline-block",
                marginLeft: "5px",
                fontSize: "12px",
                marginTop: "0px",
                fontWeight: "normal"
              }}
            >
              matching {entityType}.
            </small>
          </BigTitle>

          <PoseGroup>
            {categories.map(({ key, name, groups }) =>
              refinedCategories.indexOf(key) !== -1 ? null : (
                <PosedCategoryContainer
                  key={key}
                  className={"categoryContainer"}
                  id={key}
                >
                  <label className={"categoryName"}>{name}</label>
                  <Facet
                    groupings={groups}
                    handleClick={handleRefine.bind(this, dataMapping, key, name)}
                    customSort={(dataMapping[key]||{}).customSort}
                    customStyler={(dataMapping[key]||{}).customStyler}
                  />
                </PosedCategoryContainer>
              )
            )}
          </PoseGroup>
        </>
      )}
    </Styled>
  );
}


const StyledFacet = styled.div`
  margin: 0 auto;

  @keyframes growright {
    0% {
      transform: scaleX(0.2);
    }
    100% {
      transform: scaleX(1);
    }
  }

  .PercentBar {
    height: ${BAR_HEIGHT}px;
    margin-bottom: 3px;
    font-size: 10px;

    cursor: pointer;
    &:hover {
      opacity: 0.4;
    }

    label {
      display: inline-block;
      width: 65px;
      line-height: ${BAR_HEIGHT}px;
      height: ${BAR_HEIGHT}px;
      overflow: hidden;
      font-size: 9px;
    }
    .outerBar {
      display: inline-block;
      width: 130px;
      height: ${BAR_HEIGHT}px;
      position: relative;
      background-color: #ddd;
    }
    .bar {
      transform-origin: top left;
      position: absolute;
      background-color: ${COMPONENT_ELEMENT_COLOR};
      z-index: 1;
      top: 0;
      left: 0;
      height: ${BAR_HEIGHT}px;
      animation: growright 0.7s ease-out;
    }
    .value {
      position: absolute;
      z-index: 2;
      top: 0;
      right: 3px;
      line-height: ${BAR_HEIGHT}px;
    }
  }
  .wideBar {
    label {
      width: 120px !important;
    }
    .outerBar {
      width: 120px !important;
    }
  }
`;

function PercentBar({ name, displayName=null, total, value, handleClick, isWide=false, numberFormat=null, barColor }) {
  const barStyle = { width: ((value / total) * 100).toFixed(4) + "%" };
  if (barColor) {
    barStyle.backgroundColor = barColor;
  }
  return (
    <div
      className={"PercentBar" + (isWide ? " wideBar" : "")}
      onClick={handleClick ? () => handleClick(name, value) : null}
    >
      <label>{displayName || name}</label>
      <div className={"outerBar"}>
        <div
          className="bar"
          style={barStyle}
        />
        <span className="value">{numberFormat ? numeral(value).format(numberFormat) : value}</span>
      </div>
    </div>
  );
}

export function Facet({ groupings, handleClick, customSort, isWide=false, numberFormat=null, barColor=null, bottomCategoryLabel = "Other", renderName=null, customStyler=null }) {
  const total = Object.values(groupings).reduce((s, v) => s + v, 0);
  const sortedGroupings = Object.entries(groupings).sort((entryA, entryB) => {

    if (entryA[0] === bottomCategoryLabel) {
      return 1;
    }
    if (entryB[0] === bottomCategoryLabel) {
      return -1;
    }
    return customSort ? customSort(entryA[0], entryB[0]) : entryB[1] - entryA[1];
  });
  return (
    <StyledFacet>
      {sortedGroupings.map(([name, count]) => (
        <PercentBar
          isWide={isWide}
          key={name}
          total={total}
          value={count}
          name={name}
          displayName={typeof renderName === 'function' ? renderName(name) : null}
          handleClick={handleClick}
          numberFormat={numberFormat}
          barColor={customStyler && customStyler.barColor ? customStyler.barColor(name, count, total, barColor) : barColor}
        />
      ))}
    </StyledFacet>
  );
}
