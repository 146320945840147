import {combineReducers} from "redux";

export const pk = {
  spotify: "spyid",
  spy: "spyid",
  instagram: "inid",
  in: "inid",
  twitter: "eid",
  tw: "eid",
  soundcloud: "scid",
  sc: "scid",
  youtube: "ytid",
  yt: "ytid",
  tiktok: "unique_id",
  tt: "unique_id",
};

const endpoints = {
  instagram: "instagram_influencer",
  in: "instagram_influencer",
  soundcloud: "soundcloud_influencer",
  sc: "soundcloud_influencer",
  spotify: "spotify_playlist",
  spy: "spotify_playlist",
  twitter: "twitter_influencer",
  tw: "twitter_influencer",
  youtube: "youtube_channel",
  yt: "youtube_channel",
  tiktok: "tiktok_influencer",
  tt: "tiktok_influencer",
};




const initialState = {
  url: "",
  influencers: [],
  check: "NOT_STARTED",
  checkResult: null
};

const makeAdminReducer = datasource => (state = initialState, action) => {
  if (action.datasource && action.datasource !== datasource) {
    return state;
  }
  switch (action.type) {
    case "adminInfluencersGetListCompleted":
      return {...state, influencers: action.data};

    case "adminInfluencerAddFormChange":
      return {...state, url: action.url, check: "NOT_STARTED", checkData: null};

    case "adminInfluencerAddFormCheckStarted":
      return {...state, check: "IN_PROGRESS", checkResult: null};
    case "adminInfluencerAddFormCheckFailed":
      let errorObject = action.errorObject || {status: 500};
      return {...state, check: "FAIL", checkResult: null, checkFailureInfo: errorObject.status < 500 ? errorObject.info : null};
    case "adminInfluencerAddFormCheckCompleted":
      return {...state, check: "PASSED", checkResult: action.data};

    case "adminRemoveInfluencerStarted":
      return {...state};
    case "adminRemoveInfluencerCompleted":
      return {...state, influencers: state.influencers.filter(x => x[pk[datasource]] !== action.data[pk[datasource]])};
    case "adminRemoveInfluencerFailed":
      return {...state};

    case "adminInfluencerAddFormSave":
      return {...state, url: "", check: "NOT_STARTED", checkData: null};

    case "adminInfluencerEditFormChange":
      return {...state, influencers: state.influencers.map(p => p[pk[datasource]] === action.influencerData[pk[datasource]] ? {...p, ...action.influencerData} : p)};
    default:
      return state;
  }
};

export default combineReducers({
  instagram: makeAdminReducer("instagram"),
  soundcloud: makeAdminReducer("soundcloud"),
  spotify: makeAdminReducer("spotify"),
  twitter: makeAdminReducer("twitter"),
  youtube: makeAdminReducer("youtube"),
  tiktok: makeAdminReducer("tiktok"),
});

export const actionCreators = {
  adminInfluencerAddFormChange: (datasource, url) => ({datasource, type: "adminInfluencerAddFormChange", url}),
  adminInfluencerAddFormSave: (datasource, url) => ({
    datasource,
    type: "adminInfluencerAddFormSave",
    api: {
      resource: `/api/admin/${endpoints[datasource]}/add`,
      options: {
        method: "POST",
        body:JSON.stringify({url})
      },
      then: (dispatch) => {
        dispatch(actionCreators.adminInfluencersGetList(datasource));
      }
    }
  }),
  adminInfluencerAddFormCheck: (datasource, url) => ({
    datasource,
    type: "adminInfluencerAddFormCheck",
    api: {
      resource: `/api/admin/${endpoints[datasource]}/check`,
      options: {
        method: "POST",
        body:JSON.stringify({url})
      }
    }
  }),
  adminInfluencersGetList: (datasource) => ({
    datasource,
    type: "adminInfluencersGetList",
    api: { resource: `/api/admin/${endpoints[datasource]}s` }
  }),
  adminInfluencerEditFormChange: (datasource, influencerData) => ({
    datasource,
    type: "adminInfluencerEditFormChange",
    influencerData,
  }),
  adminInfluencerEditFormSave: (datasource, influencerData, key, thenFunc) => ({
    datasource,
    type: "adminInfluencerEditFormSave",
    influencerData,
    key,
    api: {
      resource: `/api/admin/${endpoints[datasource]}/edit`,
      options: {
        method: "POST",
        body:JSON.stringify(clean(influencerData)),
      },
      thenFunc: thenFunc,
    }
  }),
  adminRemoveInfluencer: (datasource, influencerId) => ({
    datasource,
    type: "adminRemoveInfluencer",
    api: {
      resource: `/api/admin/${endpoints[datasource]}/edit`,
      options: {
        method: "DELETE",
        body:JSON.stringify({[pk[datasource]]: influencerId}),
      }
    }
  }),
};

const clean = i => {
  if (i.category) {
    return {...i, category: i.category.trim()};
  }
  return i;
};
