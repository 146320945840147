import React from "react";
import {purplish} from "../../styles/styleConsts";

import * as polished from "polished";
import styled from "styled-components";

const chartBackgrounds = {
  soundcloud1: require("../../images/charts/soundcloud3.png"),
  instagram1: require("../../images/charts/instagram3.png"),
  spotify1: require("../../images/charts/spotify2.png"),
  whitelist1: require("../../images/charts/whitelist1.png"),
  youtube1: require("../../images/charts/youtube1.png"),
  soundcloudmiami: require("../../images/charts/soundcloudmiami.png"),
  soundcloudny: require("../../images/charts/soundcloudny.png"),
  soundcloudchicago: require("../../images/charts/soundcloudchicago.png"),
  tiktok1: require("../../images/charts/tiktok1.png"),
};

function _createGradient(source, color) {
  const from = polished.lighten(0.2, color);
  const to = polished.darken(0.2, color);
  return `linear-gradient(30deg, ${from}, ${to})`;
}

const boxDim = 160;

export const StyledChartSelector = styled.section`
  margin: 0 10px;
  width: 100%;
  max-width: 100%;
  padding: 0;

  display: flex;
  overflow-x: auto;
  position: relative;
  height: ${boxDim+12}px;

  .chartBox {
    width: ${boxDim}px;
    min-width: ${boxDim}px;
    height: ${boxDim}px;
    padding: 0;
    margin-right: -14px;
  }

  .chartBoxContent {
    width: ${boxDim}px;
    min-width: ${boxDim}px;
    height: ${boxDim}px;

    background-size: cover;
    background-color: black;
    padding: 10px;

    color: white;

    cursor: pointer;
    transition: transform 300ms, opacity 200ms;
    transform: scale(0.76);
    opacity: 0.6;

    &:hover {
      opacity: 1;
      transform: scale(0.9);
    }
  }

  .chartBox.selected {
    position: sticky;
    left: 0;
    right: 0;
    z-index: 1;
    outline: 2px solid white;

    .chartBoxContent {
      opacity: 1;
      transform: scale(1) translateY(12px);
    }
  }

  h1 {
    font-size: 17px;
    margin: 0 0 10px 0;
  }
  h2 {
    font-size: 13px;
    line-height: 18px;
    margin: 0;
  }
`;

export function ChartBox({ chart, handleSelectChart, selected }) {
  return (
    <div
      className={"chartBox" + (selected ? " selected" : "")}
      onClick={handleSelectChart ? () => handleSelectChart(chart) : undefined}
    >
      <div
        className={"chartBoxContent" + (selected ? " selected" : "")}
        style={{
          backgroundImage: chartBackgrounds[chart.backgroundId || chart.bg || chart.id]
            ? `url(${chartBackgrounds[chart.backgroundId || chart.bg || chart.id]})`
            : _createGradient(chart.source, purplish)
        }}
      >
        <h1>{chart.title}</h1>
        <h2>{chart.subtitle}</h2>
      </div>
    </div>
  );
}

export class ChartSelector extends React.PureComponent {
  componentDidMount() {
    if (this.props.onMount) {
      this.props.onMount();
    }
  }

  render() {
    const { chartsList, selectedChartId, handleSelectChart } = this.props;
    return (
      <StyledChartSelector>
        {chartsList.map(chart => (
          <ChartBox
            key={chart.id}
            chart={chart}
            selected={chart.id === selectedChartId}
            handleSelectChart={handleSelectChart}
          />
        ))}
        {chartsList.length > 0 ? null : (
          <div key={"nocharts"} className="chartBox">
            <div
              style={{
                backgroundImage: "none",
                backgroundColor: "white",
                border: "2px dashed #e5e5e5",
                color: "#ccc",
                padding: "10px",
                transform: "scale(0.78)",
                height: boxDim + "px",
                width: boxDim + "px"
              }}
            >
              <h1>Choose some charts...</h1>
              <h2>
                You don't have any in your list, pick some by clicking the
                button above.
              </h2>
            </div>
          </div>
        )}
        {chartsList.length === 0 ? null : (
          <div key={"comingsoon"} className="chartBox">
            <div
              style={{
                backgroundImage: "none",
                backgroundColor: "white",
                border: "2px dashed #e5e5e5",
                color: "#ccc",
                padding: "10px",
                transform: "scale(0.78)",
                height: boxDim + "px",
                width: boxDim + "px"
              }}
            >
              <h1>More charts coming soon...</h1>
              <h2>Let us know if there's a chart you'd like to see.</h2>
            </div>
          </div>
        )}
      </StyledChartSelector>
    );
  }
}
