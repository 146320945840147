import {
  AreaChart,
  ChartContainer,
  ChartRow,
  Charts,
  Legend,
  LineChart,
  Resizable,
  ScatterChart,
  styler,
  YAxis
} from "react-timeseries-charts";
import * as polished from "polished";
import {
  blackish,
  linkblue,
  sourceIconMap,
  spotifyGreen
} from "../../styles/styleConsts";
import styled from "styled-components";
import posed, { PoseGroup } from "react-pose";
import React from "react";
import { LittleSourceIcon } from "../primitives/things";

import moment from "moment";

import { TimeSeries } from "pondjs";

function convertStatDateRowsToTimestamp(statsRow) {
  const vals = [...statsRow];
  vals[0] = vals[0] * 24 * 3600 * 1000;
  return vals;
}

const StyledChart = styled.div`
  .selectedEventDetails {
    display: grid;
    grid-template-columns: 50px 120px 200px;
    font-size: 15px;
    padding: 15px 20px;
    width: 400px;
    background: #f5f5f5;
    border-left: 2px solid ${blackish};

    img {
      grid-column: 1;
      grid-row: 1 / 3;
    }

    .recentActivityDate {
      display: inline-block;
      vertical-align: middle;
      font-weight: normal;
    }
    .recentActivityTitle {
      display: inline-block;
      vertical-align: middle;
      font-weight: normal;
    }
    .recentActivityWords {
      grid-column: 2 / 4;
      margin-top: 3px;
    }
  }

  .highlightedEventContainer {
    min-height: 100px;
  }
`;
const PosedEventLabel = posed.div({
  enter: { opacity: 1, duration: 400 },
  exit: { opacity: 0, duration: 100 }
});
const popGraphStyle = styler([
  { key: "value", color: polished.transparentize(0.5, spotifyGreen) },
  {
    key: "popularity",
    color: polished.transparentize(0.2, linkblue),
    width: "3px"
  }
]);

export class LineChartWithEvents extends React.PureComponent {
  constructor(props) {
    super(props);

    const { track, artist } = this.props;

    const popPoints = track.stats.map(convertStatDateRowsToTimestamp);
    const popTimeSeries = new TimeSeries({
      name: "trackstats",
      columns: ["time", "value"],
      points: popPoints
    });

    const timeRange = popTimeSeries.timerange();

    const events = artist && artist.recentAlerts ? artist.recentAlerts : [];
    const daysToEventCount = {};
    const eventPoints = events.reduce((acc, ev, i) => {
      const ts = new Date(ev.as_of);
      const pop = timeRange.contains(ts) ? popTimeSeries.atTime(ts) : null;
      if (pop) {
        const point = pop.toPoint();
        const daysSinceEpoch = Math.floor(point[0] / (24 * 3600 * 1000));
        daysToEventCount[daysSinceEpoch] =
          (daysToEventCount[daysSinceEpoch] || 0) + 1;
        const yPosition = daysToEventCount[daysSinceEpoch] * 8;

        point[0] += i; // Avoid collisions by sticking events on the same day a tiny bit apart (milliseconds)  the TimeSeries library requires this.
        point[1] = yPosition;
        point.push(ev.type);
        point.push(ev.title);
        point.push(ev.words);
        acc.push(point);
      }
      return acc;
    }, []);
    eventPoints.sort((p1, p2) =>
      p1[0] < p2[0] ? -1 : p1[0] === p2[0] ? 0 : 1
    );
    const eventsTimeSeries = new TimeSeries({
      name: "events",
      columns: ["time", "value", "type", "title", "words"],
      points: eventPoints
    });

    const artistStats = track && track.artistStats ? track.artistStats : [];
    const artistPoints = artistStats.map(r => {
      const converted = convertStatDateRowsToTimestamp(r);
      return converted;
    });
    const artistTimeSeries = new TimeSeries({
      name: "artistStats",
      columns: ["time", "followers", "popularity"],
      points: artistPoints
    });

    const top200TimeSeries = null;
    // not working in graph form...
    // new TimeSeries({
    //   name: "top200",
    //   columns: ["time", "Global"],
    //   points: artistPoints.map(p => ([
    //     p[0],
    //     (1 + Math.floor(Math.random()*50)),
    //   ]))
    // })

    this.state = {
      hoveredEvent: null,
      popTimeSeries,
      timeRange,
      eventsTimeSeries,
      artistTimeSeries,
      artistStats,
      artistPoints,
      top200TimeSeries
    };
  }

  handleMouseNear = point => {
    if (point || true) {
      this.setState({
        highlight: point
      });
    }
  };

  render() {
    const {
      popTimeSeries,
      timeRange,
      eventsTimeSeries,
      artistTimeSeries,
      top200TimeSeries,
      highlight
    } = this.state;
    const highlightedEvent = highlight ? highlight.event : null;

    return (
      <StyledChart style={{ flex: 1 }}>
        <div className="legendContainer">
          <Legend
            type="swatch"
            marginBottom="0"
            align="right"
            categories={[
              { key: "value", label: "Track Popularity" },
              { key: "popularity", label: "Artist Popularity" }
            ]}
            style={popGraphStyle}
          />
        </div>
        <Resizable>
          <ChartContainer timeRange={timeRange} utc={true} padding={20}>
            <ChartRow height="220">
              <YAxis
                id="axis1"
                label="Popularity"
                width="50"
                type="linear"
                min={0}
                max={101}
              />
              <Charts>
                <AreaChart
                  axis="axis1"
                  series={popTimeSeries}
                  style={popGraphStyle}
                />
                <LineChart
                  axis="axis1"
                  series={artistTimeSeries}
                  columns={["popularity"]}
                  style={popGraphStyle}
                />
                <ScatterChart
                  onMouseNear={this.handleMouseNear}
                  highlight={highlight}
                  axis="axis1"
                  series={eventsTimeSeries}
                  radius={(event, column) => {
                    // return (highlightedEvent && highlightedEvent.get('time') === event.get('time')) ? 10 : 5;
                    return 5;
                  }}
                  style={(column, event) => {
                    const [, fill] = sourceIconMap[event.get("type")];

                    return {
                      normal: {
                        fill: fill,
                        opacity: 0.6
                      },
                      highlighted: {
                        fill: fill,
                        opacity: 1.0,
                        stroke: blackish,
                        strokeWidth: 2
                      },
                      selected: {
                        fill: fill,
                        opacity: 1.0
                      },
                      muted: {
                        fill: "grey",
                        opacity: 0.5
                      }
                    };
                  }}
                />
              </Charts>
            </ChartRow>
            <ChartRow height="80">
              <YAxis
                id="axis2"
                label="Artist Followers"
                width="50"
                type="linear"
                min={0}
                max={artistTimeSeries.max("followers")}
              />
              <Charts>
                <LineChart
                  axis="axis2"
                  series={artistTimeSeries}
                  columns={["followers"]}
                />
              </Charts>
            </ChartRow>
            {!top200TimeSeries ? (
              <React.Fragment /> // The chart lib fails on null...
            ) : (
              <ChartRow height="40">
                <YAxis
                  id="axis_global"
                  label="Global Top 200"
                  width="50"
                  type="linear"
                  min={1}
                  max={200}
                />
                <Charts>
                  <AreaChart axis="axis3" series={top200TimeSeries} />
                </Charts>
              </ChartRow>
            )}
          </ChartContainer>
        </Resizable>
        <div className="highlightedEventContainer">
          <PoseGroup>
            {highlightedEvent && (
              <PosedEventLabel
                className="selectedEventDetails"
                key={highlightedEvent.get("words")}
              >
                <React.Fragment>
                  <LittleSourceIcon
                    source={highlightedEvent.get("type")}
                    height={30}
                    width={30}
                  />
                  <div className="recentActivityDate">
                    {moment(highlightedEvent.toPoint()[0]).format(
                      "Do MMM YYYY"
                    )}
                  </div>
                  <div className="recentActivityTitle">
                    {highlightedEvent.get("title")}
                  </div>
                  <div className="recentActivityWords">
                    {highlightedEvent.get("words")}
                  </div>
                </React.Fragment>
              </PosedEventLabel>
            )}
          </PoseGroup>
        </div>
      </StyledChart>
    );
  }
}
