import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";

// import createAuth0Client from '@auth0/auth0-spa-js';

const poolData = {
  UserPoolId: "eu-west-1_Q9RIAHBuV",
  ClientId: "3fqbkgoe766qdmm5nbes3fmo9h"
  // UserPoolId: "eu-west-1_qjSyK7A05",
  // ClientId: "1q3a86ijguc5otreqqhscn69hu"
};

export function getUsername() {
  return localStorage.getItem(
    [
      "CognitoIdentityServiceProvider",
      poolData.ClientId,
      "LastAuthUser"
    ].join(".")
  );
}

export function getIdToken() {
  const lastUser = getUsername();
  if (!lastUser) {
    return null;
  }

  const idToken = localStorage.getItem(
    [
      "CognitoIdentityServiceProvider",
      poolData.ClientId,
      lastUser,
      "idToken"
    ].join(".")
  );
  return idToken || null;
}

export function getAccessToken() {
  const lastUser = getUsername();
  if (!lastUser) {
    return null;
  }

  const token = localStorage.getItem(
    [
      "CognitoIdentityServiceProvider",
      poolData.ClientId,
      lastUser,
      "accessToken"
    ].join(".")
  );
  return token || null;
}

function getCognitoUser(user) {
  var userData = {
    Username: user,
    Pool: new AmazonCognitoIdentity.CognitoUserPool(poolData)
  };
  return new AmazonCognitoIdentity.CognitoUser(userData);
}

export function getRefreshToken(username) {
  return localStorage.getItem([
      "CognitoIdentityServiceProvider",
      poolData.ClientId,
      getUsername() || '',
      "refreshToken"
    ].join(".")
  );
}

export function clearRefreshToken() {
  const lastUser = getUsername();
  if (lastUser) {
    localStorage.removeItem([
      "CognitoIdentityServiceProvider",
      poolData.ClientId,
      lastUser,
      "refreshToken"
    ].join("."))
  }
}

export function refreshSession() {
  const username = getUsername() || '';
  const refreshTokenStr = getRefreshToken(username);
  return new Promise((resolve, reject) => {
    if (!refreshTokenStr) {
      reject("No refresh token available")
      return
    }

    getCognitoUser(username).refreshSession(new AmazonCognitoIdentity.CognitoRefreshToken({
      RefreshToken: refreshTokenStr,
    }), resolve);
  })
}

/**
 * Login user with cognito
 * May require a one-time pass code (ie from google authenticator)
 * or SMS
 * Returns a promise that resolves with the accessToken JWT
 * or rejects with the message from cognito.
 */
export function cognitoLogin(username, pass) {

  var cognitoUser = getCognitoUser(username);
  var authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails({
    Username: username,
    Password: pass
  });

  return new Promise((resolve, reject) => {

    function onError(cognitoError) {
      console.error("cognito login error", cognitoError);
      return reject(cognitoError.message || cognitoError);
    }

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: result => {
        resolve(result.accessToken.jwtToken);
      },

      onFailure: onError,

      newPasswordRequired: (userAttributes, requiredAttributes) => {
        reject({action: "NEW_PASSWORD_REQUIRED", cognitoUser});
      },
      mfaRequired: (challengeName, challengeParameters) => {
        const code = prompt("Please provide your security code");
        cognitoUser.sendMFACode(code, {
          onSuccess: result => {
            console.log("Cognito sms login", username, result);
            resolve(result.accessToken.jwtToken);
          },
          onFailure: onError,
        });
      },
      totpRequired: (challengeName, challengeParameters) => {
        const code = prompt("Please provide your security code");
        cognitoUser.sendMFACode(code, {
          onSuccess: result => {
            console.log("Cognito totp login", username, result);
            resolve(result.accessToken.jwtToken);
          },
          onFailure: onError,
        }, "SOFTWARE_TOKEN_MFA");
      },
      customChallenge: (challengeParameters) => alert("There's a problem with your account, please contact joel@sonymusic.com or rob@sonymusic.com for help"),
      mfaSetup: (challengeName, challengeParameters) => alert("There's a problem with your account, please contact joel@sonymusic.com or rob@sonymusic.com for help"),
      selectMFAType: (challengeName, challengeParameters) => alert("There's a problem with your account, please contact joel@sonymusic.com or rob@sonymusic.com for help")
    });
  });
}

export function cognitoLogout() {
  const username = getUsername();
  if (username) {
    console.log("Signing out of cognito")
    getCognitoUser(username).signOut();
    console.log("Signed out cognito")
  }
}



/**** auth0 ***/


// window.onload = async () => {
//   await configureClient();
//
//   const isAuthenticated = await auth0.isAuthenticated();
//
//   if (isAuthenticated) {
//     console.log("User is authenticated")
//     const token = await auth0.getTokenSilently();
//     console.log("Got token", token)
//     return;
//   }
//
//   const query = window.location.search;
//   if (query.includes("code=") && query.includes("state=")) {
//     // Process the login state
//     const redirectResult = await auth0.handleRedirectCallback();
//     console.log("Auth0 redirect result", redirectResult);
//
//   }
// }

export async function loginWithAuth0() {
  // await auth0.loginWithRedirect({
  //   redirect_uri: window.location.origin
  // });
  //
  // // make the request to the login page.


}

export function logoutAuth0() {
  // auth0.logout({
  //   returnTo: window.location.origin
  // });
}


window._logoutAuth0 = logoutAuth0;
