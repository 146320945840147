import React from "react";
import moment from "moment/moment";
import { connect } from "react-redux";
import { createSelector } from "reselect";

import { RoutedModal } from "../../components/modal";
import {
  BigTitle,
  MediumTitle,
  SmallLabel
} from "../../components/metrics/styles";
import ErrorBoundary from "../../components/error_boundary";
import { Link } from "react-router-dom";
import SpotifyEmbed from "../../components/embeds/spotify_embed";
import { artistLink, renderCopyright } from "../../components/charts";
import { LineChartWithEvents } from "../../components/graphing/timeseries_chart";
import { SpotifyTop200Details } from "../../components/spotify_top200";

const selectTrackId = state =>
  (state.ui.location.matchParams || {}).selectedTrackId;

const selectTrack = createSelector(
  selectTrackId,
  state => state.domain.spyTrackData.index,
  (spyid, index) => {
    if (!index[spyid]) {
      return null;
    }
    const { artist, track } = index[spyid];

    return {
      artist,
      track
    };
  }
);

const hoc = connect(
  state => {
    const selectedTrackSpyid = selectTrackId(state);
    const selectedTrack = selectTrack(state);
    return {
      isShown: !!selectedTrackSpyid,
      selectedTrackSpyid,
      selectedTrack: selectedTrack ? selectedTrack.track : null,
      selectedArtist: selectedTrack ? selectedTrack.artist : null,
      popTimeseries: selectedTrack ? selectedTrack.popTimeseries : null,
      events: selectedTrack ? selectedTrack.events : [],
      chartPosition: null,
      nextTrackLink: null,
      prevTrackLink: null,
      windowDotLocation: window.location
    };
  },
  dispatch => {
    return {
      fetchTrack: spyid =>
        dispatch({
          type: "spotify/fetchTrack",
          spyid,
          api: {
            resource: `/api/spotify_tracks/${spyid}`
          }
        })
    };
  }
);

class Component extends React.PureComponent {
  render() {
    const {
      isShown,
      selectedTrack,
      selectedArtist,
      nextTrackLink,
      prevTrackLink,
      selectedTrackSpyid,
      fetchTrack,
      windowDotLocation // ensure we render when this changes
    } = this.props;

    if (!isShown) {
      return null;
    }

    return (
      <RoutedModal
        isOpen
        isLoadingContent={!selectedTrack}
        closeLinkTo={windowDotLocation.hash.split("/.m/")[0].substring(1)}
        onAfterOpen={() => fetchTrack(selectedTrackSpyid)}
      >
        {selectedTrack && (
          <ErrorBoundary>
            <section className="row">
              <SmallLabel>Spotify Track:</SmallLabel>
              {prevTrackLink && (
                <Link
                  className="popupNavLink popupNavLinkPrev"
                  to={prevTrackLink}
                >
                  Previous Track
                </Link>
              )}
              {nextTrackLink && (
                <Link
                  className="popupNavLink popupNavLinkNext"
                  to={nextTrackLink}
                >
                  Next Track
                </Link>
              )}
            </section>
            <section
              className="row"
              style={{ backgroundColor: "#f4f4f4", color: "black" }}
            >
              <div className="title">
                <BigTitle>{selectedTrack.title}</BigTitle>
                <MediumTitle>{selectedArtist.name}</MediumTitle>
                <div>
                  Released:{" "}
                  {moment(selectedTrack.release_date).format("D MMM, YYYY")}
                  <span> ({moment(selectedTrack.release_date).fromNow()})</span>
                </div>
                <div>{renderCopyright(selectedTrack.copyrights)}</div>
                <a className="listenSpotify" href={selectedTrack.spotify_uri}>
                  Listen on Spotify
                </a>
                {artistLink(selectedArtist)}
              </div>
              <div>
                <img
                  alt={""}
                  src={selectedTrack.artwork_url}
                  style={{ height: "120px" }}
                />
              </div>
              <div
                className={"spotifyPlayer"}
                style={{
                  marginTop: "20px",
                  marginRight: "30px",
                  marginLeft: "30px",
                  height: "80px",
                  width: "80px",
                  transform: `scale(${120 / 80})`,
                  opacity: 0.5
                }}
              >
                <SpotifyEmbed
                  height={80}
                  width={80}
                  type="track"
                  id={selectedTrack.spyid}
                />
              </div>
            </section>
            <section
              style={{ width: "95%", maxWidth: "800px", margin: "0 auto" }}
            >
              <LineChartWithEvents
                key={selectedTrack.spyid}
                track={selectedTrack}
                artist={selectedArtist}
              />
            </section>
            <section
              style={{ width: "95%", maxWidth: "800px", margin: "0 auto" }}
            >
              <SpotifyTop200Details track={selectedTrack} />
            </section>
          </ErrorBoundary>
        )}
      </RoutedModal>
    );
  }
}

export const SpyTrackDetailModal = hoc(Component);
