import React from "react";
import { connect } from "react-redux";
import { Loading } from "../../components/primitives/things";

export const statelessPure = updateStatelessOnlyOn(null);

export function updateStatelessOnlyOn(keys) {
  return function(Stateless) {
    if (Stateless.setState) {
      console.warn(
        `Passed a component to updateStatelessOnlyOn. Expect a stateless functional component. 
        ${
          Stateless.displayName
        } Internal state is not considered for update. You may not see 
        renders happening as intended.`
      );
    }

    return class StatelessPure extends React.Component {
      shouldComponentUpdate(nextProps, nextState, nextContext) {
        keys = keys || Object.keys(nextProps);
        let propName, thisVal, nextVal;
        for (var i = 0; i < keys.length; ++i) {
          propName = keys[i];
          thisVal = this.props[propName];
          nextVal = nextProps[propName];
          if (thisVal !== nextVal) {
            return true;
          }
        }
        return false;
      }

      render() {
        return <Stateless {...this.props} />;
      }
    };
  };
}

export function fetchOnMount(Component) {
  return class extends React.Component {
    componentDidMount() {
      this.props.fetchData();
    }

    componentDidUpdate() {
      if (this.props.fetchMoreArtists) {
        window.setTimeout(() => {
          // Scary race condition here, reference the moreArtistUrl prop inside the timeout.
          if (this.props.moreArtistsUrl) {
            this.props.fetchMoreArtists(this.props.moreArtistsUrl);
          }
        }, 200);
      }
    }

    render() {
      return React.createElement(Component, this.props);
    }
  };
}

/**
 * Executes the function 'propName' on mount.
 * Follows the 'composable' pattern.
 * @param propName
 */
export function execOnMount(propName, propNameOnUpdate) {
  return function(Component) {
    return class extends React.Component {
      componentDidMount() {
        console.log("componentDidMount", execOnMount);
        if (
          this.props[propName] &&
          typeof this.props[propName] === "function"
        ) {
          this.props[propName](this.props);
        }
      }

      componentDidUpdate() {
        if (
          this.props[propName] &&
          typeof this.props[propName] === "function"
        ) {
          this.props[propName](this.props);
        }
      }

      render() {
        return React.createElement(Component, this.props);
      }
    };
  };
}

/**
 * Will render the Component only if there's an authenticated user (as represented in the redux store)
 * Follows the 'composable' pattern.
 */
export function requireUser(Component) {
  return connect(state => ({ user: state.user.currentUser }))(
    class extends React.PureComponent {
      render() {
        if (!this.props.user) {
          return null;
        }
        return <Component {...this.props} />; // user is passed down.
      }
    }
  );
}

/** Renders a '<Loading />' until the condition is false.
 *
 * @param condition a function (props) => boolean or a string for a propName.
 * @param loadingText optional text to displayl Default Loading...
 * @returns {Function}
 */
export function showLoadingUntil(condition, loadingText = "Loading...") {
  return function(Component) {
    if (condition instanceof "string") {
      const propName = condition;
      condition = props => {
        return props[propName];
      };
    }
    return class WithLoading extends React.Component {
      render() {
        if (condition(this.props)) {
          return <Loading>{loadingText}</Loading>;
        }
        return <Component {...this.props} />;
      }
    };
  };
}
