import React from "react";
import Analyzer from "./Analyzer";

function NoResults() {
  return <div style={{
    fontSize: "1.2em",
    padding: "50px",
    lineHeight: "1.9em",
    margin: "100px auto",
    borderRadius: "5px",
    maxWidth: "500px",
  }}>There are no TikTok scoutings to display. Please use the "Change My Scouts" button below to add some TikTok scouts.
  Please note in can take 24 hours for sounds to come in once a scout is added.</div>
}

function Page () {
  return <Analyzer initialType={'tt_scouts'} settingsLink={"tiktok/settings"} renderEmptyResults={<NoResults />}/>
}

export default Page;
