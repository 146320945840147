import React from "react";
import { connect } from "react-redux";
import numeral from "numeral";
import moment from "moment";

import ResultsPage from "../components/results_page";
import SortHeaderCell from "../components/sort_header_cell";
import { Tagger } from "../components/multi_select";

import { StyledControls } from "../styles/styleConsts";

import { BigTitle } from "../components/metrics/styles";

import {
  ExternalLink,
  splitTrackTitle,
  TrackLink,
  TrackName,
  TrackWords
} from "../components/track_link";
import StyledReactVirtualizedTable from "../components/styled_reactvirtualized_table";
import { Column, WindowScroller } from "react-virtualized";
import { createSelector } from "reselect";
import sort from "../utils/sort";

import { Link } from "react-router-dom";

// import { VideoDetailOverlay } from "./modals/youtube_video_modal";
import RadioGroup from "../components/radio";
import {
  ChartFilters,
  SliderControl,
  StyledFilters
} from "../components/chart_filters";

const DEFAULT_FILTERS = {
  maxPublishedDaysAgo: 120,
  minPublishedDaysAgo: 0
};

function createActionFetchYouTube(filters) {
  return {
    type: "youtube/fetchData",
    api: {
      resource: "/api/youtube",
      options: {
        method: "POST",
        body: JSON.stringify({ filters: filters || DEFAULT_FILTERS })
      }
    },
    filters: filters || DEFAULT_FILTERS
  };
}

const YoutubeFilters = (function() {
  const hoc = connect(
    state => ({
      initialFilters: {
        minPublishedDaysAgo: 0,
        maxPublishedDaysAgo: 120,
        channelIds: null
      },
      channelData: state.user.ytChannels,
      trackCount: selectFilteredVideos(state).length
    }),
    dispatch => {
      return {
        handleSave: filters => {
          dispatch(createActionFetchYouTube(filters));
        }
      };
    }
  );

  class _YoutubeFilters extends React.PureComponent {
    render() {
      const { channelData, initialFilters, trackCount } = this.props;
      const dayMapping = [0, 1, 2, 5, 10, 30, 60, 120, 1000];

      return (
        <StyledFilters>
          <BigTitle
            style={{ fontSize: "30px", fontWeight: "bold", color: "#333" }}
          >
            {trackCount}
            <small
              style={{
                fontSize: "13px",
                marginTop: "0px",
                fontWeight: "normal"
              }}
            >
              matching videos
            </small>
          </BigTitle>
          <ChartFilters
            handleSave={filters => {
              this.props.handleSave(filters);
            }}
            initialValues={initialFilters}
            render={(currentValues, onModify, revertCount, isModified) => {
              return (
                <React.Fragment>
                  <div className="filterControl filterPublishedDate">
                    <SliderControl
                      title="Published"
                      mapping={dayMapping}
                      minVal={currentValues.minPublishedDaysAgo}
                      maxVal={currentValues.maxPublishedDaysAgo}
                      minValStateKey="minPublishedDaysAgo"
                      maxValStateKey="maxPublishedDaysAgo"
                      onChange={onModify}
                      textDisplay={function textDisplay(
                        minVal,
                        maxVal,
                        mapping
                      ) {
                        const minAllowedValue = mapping[0];
                        const maxAllowedValue = mapping[mapping.length - 1];
                        const formattedMin = numeral(minVal).format("0,0");
                        const formattedMax = numeral(maxVal).format("0,0");
                        return minVal > minAllowedValue &&
                          maxVal < maxAllowedValue
                          ? `Between ${formattedMin} and ${formattedMax} days ago`
                          : minVal > minAllowedValue
                          ? `More than ${formattedMin} days ago`
                          : maxVal < maxAllowedValue
                          ? `Less than ${formattedMax} days ago`
                          : "Anytime";
                      }}
                    />
                  </div>

                  <Tagger
                    label="Channels"
                    key={revertCount + "playlists"}
                    domain={channelData.map(channel => ({
                      value: channel.ytid,
                      display: channel.name
                    }))}
                    initialSelection={
                      currentValues.channelIds === null
                        ? channelData.map(ch => ch.ytid)
                        : currentValues.channelIds
                    }
                    className="filterControl filter-channels"
                    onChange={channelIds => onModify({ channelIds })}
                  />
                </React.Fragment>
              );
            }}
          />
        </StyledFilters>
      );
    }
  }

  const YoutubeFilters = hoc(_YoutubeFilters);

  return YoutubeFilters;
})();

const TitleCell = ({ video }) => (
  <TrackLink>
    <ExternalLink href={"https://www.youtube.com/watch?v=" + video.ytid}>
      <b>⬈</b>
    </ExternalLink>
    <img
      alt=""
      src={"https://img.youtube.com/vi/" + video.ytid + "/default.jpg"}
      width={40}
      style={{ border: "1px solid #444", width: "40px", minWidth: "40px" }}
    />
    <Link to={`/youtube/.m/ytvid/${video.ytid}`}>
      <TrackWords>
        <TrackName style={{ marginTop: "2px" }}>
          {splitTrackTitle(video.title)}
        </TrackName>
      </TrackWords>
    </Link>
  </TrackLink>
);

const selectVideosWithDelta = createSelector(
  state => state.allVideos,
  state => state.delta,
  state => state.metric,
  (videos, deltaDays, dataMatrixIndex) => {
    return videos.map(video => {
      const dataMatrix = video.dataMatrix;
      const lastIndex = dataMatrix.length - 1;
      const lastRow = dataMatrix[lastIndex];
      let days = 0,
        i = lastIndex;
      while (days < deltaDays && i > 0) {
        i--;
        days = lastRow[0] - dataMatrix[i][0];
      }
      return {
        ...video,
        latestValue: lastRow[dataMatrixIndex],
        deltaValue: dataMatrix[i][dataMatrixIndex],
        deltaDays: lastIndex - i,
        delta: lastRow[dataMatrixIndex] - dataMatrix[i][dataMatrixIndex]
      };
    });
  }
);

const selectSortedVideos = createSelector(
  selectVideosWithDelta,
  state => state.sort,
  (videos, currentSort) => {
    return sort(videos, currentSort);
  }
);

class YouTubeResults extends React.Component {
  state = {
    sort: this.props.initialSort,
    delta: this.props.initialDelta,
    metric: this.props.initialMetric
  };

  handleSort = sort => this.setState({ sort });

  handleChangeDelta = delta => this.setState({ delta });

  handleChangeMetric = metric => this.setState({ metric });

  componentDidMount() {
    this.props.onMount();
  }

  render() {
    const { loading, allVideos } = this.props;
    const { sort, delta, metric } = this.state;
    const videos = selectSortedVideos({ ...this.state, allVideos });

    return (
      <ResultsPage
        pageId="youtube"
        pageTitle="YouTube"
        settingsLink="/youtube/settings"
        filters={<YoutubeFilters />}
        loading={loading}
        loaded={true}
      >
        <PureBody
          {...{
            currentSort: sort,
            delta,
            metricIndex: metric,
            videos,
            handleSort: this.handleSort,
            handleChangeDelta: this.handleChangeDelta,
            handleChangeMetric: this.handleChangeMetric
          }}
        />
      </ResultsPage>
    );
  }
}

class PureBody extends React.PureComponent {
  render() {
    const {
      currentSort,
      delta,
      metricIndex,
      videos,
      handleSort,
      handleChangeDelta,
      handleChangeMetric
    } = this.props;

    const deltaOptions = [1, 2, 5, 10, 30];
    const deltaControls = (
      <div>
        <h4>Change Over:</h4>
        <RadioGroup
          choices={deltaOptions.map(d => ({
            label: d + " Day" + (d > 1 ? "s" : ""),
            value: d
          }))}
          defaultSelectedIndex={deltaOptions.indexOf(delta)}
          handleChange={({ value }) => handleChangeDelta(value)}
        />
      </div>
    );

    const metricChoices = [
      { label: "Views", value: 1 },
      { label: "Likes", value: 2 },
      { label: "Dislikes", value: 3 },
      { label: "Comments", value: 5 }
    ];
    const currentMetricName = metricChoices.find(c => c.value === metricIndex)
      .label;
    const metricControls = (
      <div>
        <h4>Metric:</h4>
        <RadioGroup
          choices={metricChoices}
          defaultSelectedIndex={metricChoices.findIndex(
            c => c.value === metricIndex
          )}
          handleChange={({ value }) => handleChangeMetric(value)}
        />
      </div>
    );

    return (
      <React.Fragment>
        <StyledControls>
          {metricControls}
          {deltaControls}
        </StyledControls>

        <WindowScroller disableHeight>
          {({ width, height }) => (
            <StyledReactVirtualizedTable
              rowHeight={50}
              rowCount={videos.length}
              rowGetter={({ index }) => videos[index]}
              headerHeight={42}
              width={width - 300}
              height={height - 130}
            >
              <Column
                label="Title"
                dataKey="title"
                cellDataGetter={({ rowData }) => rowData}
                cellRenderer={({ cellData }) => <TitleCell video={cellData} />}
                width={430}
              />
              <Column label="Channel" dataKey="channel_name" width={150} />
              <Column
                label="Published"
                dataKey="published"
                style={{ textAlign: "right" }}
                headerStyle={{ textAlign: "right" }}
                headerRenderer={() => (
                  <SortHeaderCell
                    onSortChange={handleSort}
                    currentSort={currentSort}
                    sortKey="published.val"
                  >
                    Published
                  </SortHeaderCell>
                )}
                cellRenderer={({ cellData, rowData }) => (
                  <span>
                    {moment(cellData.val).format("D MMM YYYY")}
                    <br />
                    <small style={{ fontSize: "9px", opacity: 0.8 }}>
                      {_toDaysFromNow(rowData)} days ago
                    </small>
                  </span>
                )}
                width={100}
              />
              <Column
                label={currentMetricName}
                dataKey="latestValue"
                style={{ textAlign: "right" }}
                headerStyle={{ textAlign: "right" }}
                headerRenderer={() => (
                  <SortHeaderCell
                    onSortChange={handleSort}
                    currentSort={currentSort}
                    sortKey="latestValue"
                  >
                    Views
                  </SortHeaderCell>
                )}
                cellRenderer={({ cellData }) => numeral(cellData).format("0,0")}
                width={100}
              />
              <Column
                label="Change"
                dataKey="delta"
                style={{ textAlign: "right" }}
                headerStyle={{ textAlign: "right" }}
                headerRenderer={() => (
                <SortHeaderCell
                  onSortChange={handleSort}
                  currentSort={currentSort}
                  sortKey="delta"
                >
                  Change
                </SortHeaderCell>
              )}
                cellRenderer={({ cellData }) => numeral(cellData).format("0,0")}
                width={100}
              />
            </StyledReactVirtualizedTable>
          )}
        </WindowScroller>
      </React.Fragment>
    );
  }
}

const DEFAULT_SORT = { key: "delta", dir: "desc" }; // extract for shallow compare.

function _toDaysFromNow(video) {
  const dateStr = (video.published || {}).val;
  if (!dateStr || dateStr.length < 10) {
    return null;
  }

  return Math.round(
    (new Date().getTime() - new Date(dateStr).getTime()) / (24 * 3600 * 1000)
  );
}

const selectFilteredVideos = createSelector(
  state => state.ui.lastFetchedYtVideoIds,
  state => state.domain.ytData.videosIndex,
  (videoIds, index) => {
    return videoIds ? videoIds.map(ytid => index[ytid]) : [];
  }
);

export default connect(
  state => {
    return {
      loading: state.ui.youtubeFetchInProgress,
      initialSort: DEFAULT_SORT,
      initialDelta: 5,
      initialMetric: 1,
      allVideos: selectFilteredVideos(state),
      currentUser: state.user.currentUser
    };
  },
  dispatch => ({
    onMount: () => dispatch(createActionFetchYouTube())
  })
)(YouTubeResults);
