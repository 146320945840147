import { useEffect, useReducer, useRef } from "react";
import {useFetchPromise} from "./UseFetch";

/**
 * Handles pagination using the charts API protocol.
 *
 * The chart API endpoints all accept POST'ed settings objects, and return data in pages, of the form:
 *
 * { results: [...], nextApi: string | null }
 *
 * The nextApi is the url for the next page, and will be null if there are no more pages.
 *
 * This hook keeps track of all the pages, and provides the following object:
 *
 * {
 *   allResults: an array of all the results in order returned by the sserver. Each
 *      page of results is concatenated, ie, a flat list.
 *   isLoading: indicates a page is loading,
 *   isInitialLoading: indicates the first page is loading,
 *   hasMore: indicates there are more pages to load,
 *   fetchMore: a function, (isRefresh: bool) : void that consumers should call to fetch
 *      the next page of results. Passing true will clear the allResults, and call the \
 *      provided initialUrl
 * }
 *
 * @param initialUrl - the url for the first page of results.
 * @param settings - the settings object, that will be POST'ed to the endpoint as a json body
 * @param onNewResults - optional callback (newResults: array, isRefresh: bool) that receives the page of results.
 *
 */
export function usePaginatedFetch(initialUrl, settings, onNewResults, reduxFetchActionName=null) {
  const fetchPromise = useFetchPromise(reduxFetchActionName || "usePaginatedFetch");

  const initState = {
    nextUrl: initialUrl,
    isLoading: false,
    isInitialLoading: true,
    allResults: []
  };

  const isMounted = useRef(true);

  function reducer(state, action) {
    if (!isMounted.current) {
      return state;
    }

    switch (action.type) {
      case "completed":
        const allResults = action.isRefresh
          ? action.results
          : state.allResults.concat(action.results);
        return {
          allResults: allResults,
          nextUrl: action.nextApi,
          isLoading: false,
          isInitialLoading: false,
          errorMessage: null
        };
      case "started":
        return {
          ...state,
          isLoading: true,
          isInitialLoading: action.isRefresh,
          allResults: action.isRefresh ? [] : state.allResults,
          errorMessage: null
        };
      case "failed":
        return {
          ...state,
          isLoading: false,
          isInitialLoading: false,
          errorMessage: action.errorMessage
        };
      case "updateItems":
        // action.items -> {[index]: <merge props>}
        return {
          ...state,
          allResults: state.allResults.map((x, i) =>
            action.items[i] ? { ...x, ...action.items[i] } : x
          )
        };
      default:
        return state;
    }
  }

  const [state, dispatchInner] = useReducer(reducer, initState);

  const dispatch = action => {
    if (isMounted.current) {
      dispatchInner(action);
    }
  };

  const loadNext = (isRefresh = false) => {
    if ((state.isLoading || !state.nextUrl) && !isRefresh) {
      return;
    }

    dispatch({type: "started", isRefresh});

    return fetchPromise(
      isRefresh ? initialUrl : state.nextUrl,
      { method: "POST", body: JSON.stringify(settings || {})}
    )
    .then(({results, nextApi}) => {
      dispatch({
        type: "completed",
        results,
        nextApi,
        isRefresh
      });
      if (onNewResults) {
        return onNewResults(results, isRefresh);
      }
      return Promise.resolve(results);
    })
    .catch(e => {
      dispatch({type: "failed", errorMessage: String(e)});
      throw e;
    });

  };

  function updateOneItem(item, findBy) {
    const itemIndex = state.allResults.findIndex(findBy);
    if (itemIndex !== -1) {
      dispatch({ type: "updateItems", items: { itemIndex: item } });
    } else {
      console.warn(
        "[usePaginatedFetch] Could not find item in results, ignoring."
      );
    }
  }

  function updateManyItems(newProps, findBy) {
    const items = {};
    state.allResults.forEach((item, ix) => {
      if (findBy(item)) {
        items[ix] = newProps;
      }
    });
    if (Object.keys(items).length > 0) {
      dispatch({ type: "updateItems", items });
    } else {
      console.warn(
        "[usePaginatedFetch] Could not find any items in results, ignoring."
      );
    }
  }

  // xxx: we only want to refresh the results when the url or settings props change.
  // if we added 'loadNext' here as a dependency, we get infinite requests, since
  // loadNext depends on the 'nextUrl', which changes after each request.
  useEffect(() => {
    loadNext(true);
    // eslint-disable-next-line
  }, [initialUrl, settings]);

  useEffect(() => () => (isMounted.current = false), []);

  return {
    allResults: state.allResults,
    isLoading: state.isLoading,
    hasMore: !!state.nextUrl,
    fetchMore: loadNext,
    refresh: () => loadNext(true),
    updateOneItem,
    updateManyItems
  };
}
