export const icons = {
  my_whitelist: require("./images/mylist-icon.png"),
  whitelist: require("./images/charts.png"),
  charts: require("./images/charts.png"),
  analyzer: require("./images/sc_analyzer.png"),
  yt_analyzer: require("./images/yt_analyzer.png"),
  spotify: require("./images/channel-icon-spotify@2x.png"),
  youtube: require("./images/channel-icon-youtube@2x.png"),
  twitter: require("./images/channel-icon-twitter@2x.png"),
  twitter_by_day: require("./images/channel-icon-twitter@2x.png"),
  soundcloud: require("./images/channel-icon-soundcloud@2x.png"),
  soundcloud2: require("./images/channel-icon-soundcloud@2x.png"),
  soundcloud_by_day: require("./images/channel-icon-soundcloud@2x.png"),
  instagram: require("./images/channel-icon-instagram@2x.png"),
  instagram_by_day: require("./images/channel-icon-instagram@2x.png"),
  tiktok: require("./images/tiktok.png")
};
export const largeIcons = {
  spotify: require("./images/channel-icon-large-spotify@2x.png"),
  youtube: require("./images/channel-icon-large-youtube@2x.png"),
  twitter: require("./images/channel-icon-large-twitter@2x.png"),
  soundcloud: require("./images/channel-icon-large-soundcloud@2x.png"),
  soundcloud2: require("./images/channel-icon-large-soundcloud@2x.png"),
  instagram: require("./images/channel-icon-large-instagram@2x.png"),
  demos: require("./images/channel-icon-large-demos@2x.png"),
  admin: require("./images/channel-icon-large-admin@2x.png")
};
