import React from "react";
import styled from "styled-components";


const Styled = styled.div`
  max-width: 600px;
  display: block;
  margin: 40px auto;
  font-size: 1.2em;
  h1 {
    font-size: 2em;
  }
  .sourceName {
    margin-top: 20px;
    font-weight: bold;
    display: block;
  }
  .info {
    display: block;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  a {
    display: block;
  }
`;


export function PrivacyPage() {
  return <Styled>

    <h1>Governing Terms and Privacy Policies</h1>
    <p>Sony Music DNA uses various third party data sources to deliver its functionality. When using the app you are bound
      by the following terms of service and privacy policies:</p>

    <ul>
      <li>
        <span className="sourceName">Spotify Embedded Player</span>
        <span className="info">The Spotify music player uses browser cookies which are governed by this policy.</span>
        <a href="https://www.spotify.com/ca-en/legal/cookies-policy/" target="_blank" rel="noopener noreferrer">https://www.spotify.com/ca-en/legal/cookies-policy/</a>
      </li>
      <li>
        <span className="sourceName">SoundCloud Embedded Player</span>
        <span className="info">The SoundCloud music player uses browser cookies which are governed by this policy.</span>
        <a href="https://soundcloud.com/pages/cookies" target="_blank" rel="noopener noreferrer">https://soundcloud.com/pages/cookies</a>
      </li>
      <li>
        <span className="sourceName">YouTube Terms of Service</span>
        <span className="info">The terms of service for using YouTube data and services is covered by this policy.</span>
        <a href="https://www.youtube.com/t/terms" target="_blank" rel="noopener noreferrer">https://www.youtube.com/t/terms</a>
      </li>
      <li>
        <span className="sourceName">Google Privacy Policy</span>
        <span className="info">This privacy policy applies to the usage of YouTube data and services.</span>
        <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">https://policies.google.com/privacy</a>
      </li>
    </ul>
  </Styled>
}
