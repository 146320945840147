/* eslint jsx-a11y/accessible-emoji: "off" */

import React from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment";

import { actionCreators } from "../reducers/artist";

import styled from "styled-components";
import * as styleConsts from "../styles/styleConsts";
import Padded from "../components/primitives/padded";
import myListIcon from "../images/mylist-icon.png";

import Helmet from "react-helmet";
import "react-tabs/style/react-tabs.css";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { Box, Heading, Section } from "../components/primitives/grid";
import { Col, Row } from "react-styled-flexboxgrid";
import { Flag } from "../components/flag_icons";

import { Loading } from "../components/primitives/things";
import SoundcloudEmbed from "../components/embeds/soundcloud_embed";
import SpotifyEmbed from "../components/embeds/spotify_embed";
import Avatar from "../components/avatar";
import PhotoGrid from "../components/photo_grid";
import ErrorBoundary from "../components/error_boundary";

import SelectedArtistBrains from "./artist_brains";
import { ArtistDetailModal } from "./modals/artist_detail_modal";
import { selectSource, selectSourceId, selectArtist } from "./artist_selectors";

import { ManageArtistSources } from "./manage_artist_sources";

import Img from "react-image";
import { ArtistAlertsList } from "../components/charts";

import { shortenUrlForDisplay, SOURCE_TO_NAME, uniq } from "../utils";
import { convertIsrcCountryToIso2 } from "../components/primitives/country_mapping";
import { _toKey } from "../reducers/data_access";

const isEmpty = maybeArray => (maybeArray || []).length === 0;

class ArtistBody extends React.PureComponent {
  render() {
    const {
      bio,
      countryCodes,
      location,
      text_locations,
      webProfiles,
      scid,
      spotifyPlaylists,
      spyid,
      recentMedia,
      influencers,
      myScouts,
      recentAlerts,
    } = this.props;

    return (
      <React.Fragment>
        <Padded style={{ backgroundColor: "white" }}>
          <Row>
            <Col xs={12} sm={6} md={3} lg={2}>
              <Box display={true}>
                <ContactInfo
                  {...{
                    bio,
                    countryCodes,
                    location: text_locations
                      ? text_locations.join(", ")
                      : location,
                  }}
                />
                {isEmpty(webProfiles) ? null : (
                  <WebProfiles webProfiles={webProfiles} />
                )}
              </Box>
              <ManageArtistSources />
            </Col>

            <Col xs={12} sm={6} md={3} lg={3}>
              <Heading>Ears</Heading>
              <Box display={!!scid}>
                <SoundCloudTracks scid={scid} />
              </Box>
              {isEmpty(spotifyPlaylists) ? null : (
                <Playlists spotifyPlaylists={spotifyPlaylists} />
              )}
              <Box display={!!spyid}>
                <SpotifyTracks artistSpyid={spyid} />
              </Box>
            </Col>

            <Col xs={12} md={6}>
              <Tabs defaultIndex={(recentMedia || []).length > 0 ? 1 : 0}>
                <TabList>
                  {(recentMedia || []).length > 0 ? (
                    <Tab>
                      <Heading>Eyes</Heading>
                    </Tab>
                  ) : null}
                  <Tab>
                    <Heading>Brains</Heading>
                  </Tab>
                  {(recentAlerts || []).length > 0 && (
                    <Tab>
                      <Heading>Timeline</Heading>
                    </Tab>
                  )}
                </TabList>

                {(recentMedia || []).length > 0 ? (
                  <TabPanel>
                    <Box display={true}>
                      <InstagramFeed recentMedia={recentMedia} />
                    </Box>
                  </TabPanel>
                ) : null}

                <TabPanel>
                  <ErrorBoundary>
                    {isEmpty(influencers) ? null : (
                      <ArtistScouts
                        allScouts={influencers}
                        myScouts={myScouts}
                      />
                    )}
                  </ErrorBoundary>
                  <ErrorBoundary>
                    <SelectedArtistBrains />
                  </ErrorBoundary>
                </TabPanel>

                {(recentAlerts || []).length > 0 && (
                  <TabPanel>
                    <ErrorBoundary>
                      <ArtistRecentAlerts recentAlerts={recentAlerts} />
                    </ErrorBoundary>
                  </TabPanel>
                )}
              </Tabs>
            </Col>
          </Row>
        </Padded>
      </React.Fragment>
    );
  }
}

const Playlist = styled.div`
  background-color: ${styleConsts.spotifyGreen};
  border-radius: 3px;
  padding: 3px 5px;
  line-height: 1.4;
  font-size: 10px;
  font-weight: bold;
  display: inline-block;
  color: white;
  margin-right: 2px;
  margin-bottom: 2px;
`;

const Bio = styled.div`
  font-size: 12px;
  font-weight: normal;
  opacity: 0.6;
`;

const InlineLink = styled.span`
  display: inline-block;
  margin: 1px;
  color: #4aaff2;
  font-weight: 400;
  text-decoration: none;
  padding: 1px 5px;
  :hover {
    text-decoration: underline;
    opacity: 0.8;
  }
  a {
    color: inherit;
    text-decoration: inherit;
  }
`;

const ContactInfo = ({ bio, countryCodes, location }) => {
  return (
    <Section>
      <Heading>About</Heading>
      <Bio>
        <span style={{ whiteSpace: "pre-wrap", maxHeight: "300px" }}>
          {bio || ""}
        </span>
        <br />
        {location ? <div>Location: {location}</div> : null}
        {countryCodes ? (
          <React.Fragment>
            <h5 style={{ margin: "5px 0", fontWeight: "bold" }}>From:</h5>
            <ul style={{ display: "block", margin: 0, padding: 0 }}>
              {" "}
              {uniq(countryCodes.map(convertIsrcCountryToIso2)).map(code => (
                <li style={{ display: "block", margin: 0 }} key={code}>
                  <Flag code={code} includeName />
                </li>
              ))}
            </ul>
          </React.Fragment>
        ) : null}
      </Bio>
    </Section>
  );
};

function Playlists({ spotifyPlaylists }) {
  const [isExpanded, setIsExpanded] = React.useState(false);

  const count = spotifyPlaylists.length;

  return (
    <Section>
      <Heading>Spotify Playlists ({count})</Heading>
      <Bio>
        {spotifyPlaylists.slice(0, isExpanded ? count : 5).map(playlist => (
          <Playlist key={playlist}>{playlist}</Playlist>
        ))}
        {count > 5 && (
          <span
            onClick={() => setIsExpanded(!isExpanded)}
            style={{ textDecoration: "underline" }}
          >
            {isExpanded ? "hide" : "see all..."}
          </span>
        )}
      </Bio>
    </Section>
  );
}

const WebProfiles = ({ webProfiles }) => (
  <Section>
    <Heading>On The Web</Heading>
    <Bio>
      {webProfiles.map((profile, i) => {
        const title = shortenUrlForDisplay(profile.title);
        return (
          <InlineLink key={i}>
            <a href={profile.url} target="_blank" rel="noopener noreferrer">
              {title.length > 20 ? title.substring(0, 18) + "…" : title}
            </a>
          </InlineLink>
        );
      })}
    </Bio>
  </Section>
);

const SoundCloudTracks = ({ scid }) => (
  <div>
    <div style={{ height: 400 }}>
      <SoundcloudEmbed
        urlEncodedPlaylistUrl={encodeURIComponent(
          "https://api.soundcloud.com/users/" + scid
        )}
      />
    </div>
  </div>
);

const SpotifyTracks = ({ artistSpyid }) => (
  <div>
    <Heading>Top Spotify Tracks</Heading>
    <div style={{ height: 400 }}>
      <SpotifyEmbed type="artist" id={artistSpyid} />
    </div>
  </div>
);

export const ArtistScouts = ({ allScouts, myScouts }) => {
  const categories = {};
  allScouts.forEach(s => {
    const key = _toKey(s.type || s.source, s.id);
    const myScout = myScouts[key];
    const category = !myScout ? "zzz" : myScout.category || "zz";
    if (!categories[category]) {
      categories[category] = [];
    }
    categories[category].push({ ...s, id: myScout ? s.id : null });
  });
  const entries = Object.entries(categories);
  entries.sort();
  return (
    <Section>
      <Heading style={{ marginBottom: "15px", paddingLeft: "5px" }}>
        {allScouts.length} Scouts
      </Heading>
      <table>
        {/*<tr><th style={{fontSize:"8px"}}>Your Scouts</th><td></td></tr>*/}
        {entries.map(([categoryName, scouts]) => (
          <tr>
            <th style={{ padding: "10px", textAlign: "left" }}>
              {categoryName === "zz" ? (
                <span style={{ color: "#999", fontStyle: "italic" }}>
                  No
                  <br />
                  Category
                </span>
              ) : categoryName === "zzz" ? (
                <span style={{}}>
                  Other
                  <br />
                  Scouts
                </span>
              ) : (
                categoryName
              )}
            </th>
            <td style={{ paddingBottom: "10px" }}>
              {scouts.map((inf, i) => (
                <Avatar
                  key={i}
                  data={inf}
                  withHover={true}
                  size={categoryName === "zzz" ? "big" : "huge"}
                />
              ))}
            </td>
          </tr>
        ))}
      </table>
    </Section>
  );
};

const ArtistRecentAlerts = ({ recentAlerts }) => (
  <Section style={{ paddingLeft: "10px" }}>
    <ArtistAlertsList recentAlerts={recentAlerts} showAll={true} />
  </Section>
);

const InstagramFeed = ({ recentMedia }) => <PhotoGrid images={recentMedia} />;

// exported for storybook
export const ArtistHeading = (function () {
  const Styled = styled.div`
    section {
      display: flex;
      width: 100%;
      position: relative;
      font-weight: 600;
      vertical-align: middle;
    }

    .bigAvatar {
      vertical-align: -44%;
      margin-right: 10px;
      width: 100px !important;
      height: 100px !important;
      border-radius: 100%;
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7);
    }
    .artistName {
      margin-top: 3px;
      font-size: 47px;
      line-height: 50px;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);
      color: rgba(255, 255, 255, 0.9);
    }
    h6 {
      letter-spacing: normal;
      line-height: 1;
      margin-bottom: 5px;
      text-transform: uppercase;
      opacity: 0.4;
      font-weight: normal;
      font-size: 12px;
    }
  `;

  const Heading = function ({ isSmall, artist, handleClickWhitelistButton }) {
    const fromColor = styleConsts.blackish;
    const toColor = styleConsts.purplish;
    let headerContainerBackground = `linear-gradient(30deg, ${fromColor}, ${toColor})`;
    if (artist && artist.twitterBanner) {
      headerContainerBackground = `url(${artist.twitterBanner}) no-repeat top center / cover`;
    } else if (
      artist &&
      artist.banner_images &&
      artist.banner_images.length > 0
    ) {
      headerContainerBackground = `url(${artist.banner_images[0]}) no-repeat top center / cover`;
    }
    let headerBackground =
      "linear-gradient(rgba(52, 57, 59, 0) 0%, rgb(52, 57, 59) 100%)";

    return (
      <React.Fragment>
        <Styled
          style={{
            background: headerContainerBackground,
            position: "sticky",
            top: "-166px",
            zIndex: "1",
          }}
        >
          <div
            style={{
              padding: "0",
              height: "240px",
              background: headerBackground,
              position: "relative",
            }}
          >
            <section
              style={{
                position: "absolute",
                top: isSmall ? "290px" : "100px",
                left: "50px",
                zoom: isSmall ? 0.6 : 1,
              }}
            >
              <Img className="bigAvatar" src={artist.avatar_urls} />
              <div className="artistName">
                <h6>Artist</h6>
                <span>{artist.name + " "}</span>
              </div>
            </section>
          </div>
        </Styled>
      </React.Fragment>
    );
  };

  return Heading;
})();

// exported for storybook
export const AddToWhitelistControl = (function () {
  /* An IEF module pattern for hiding vars cheaply */
  const height = 31;
  const shared = `
    display: block;
    position: relative;
    margin-bottom: 5px;
    // position: absolute;
    // top: 10px;
    // left: calc(50% - 125px);
    width: 270px;
    font-size: 11px;
    padding-left: ${height + 8}px;
    line-height: ${height}px;
    box-sizing: border-box;
    height: ${height}px;
    z-index: 1;
    vertical-align: middle;
    box-shadow: 2px 2px 4px rgba(20,20,20,0.8);
    transition: background-color 1s;
    border-radius: 15px;
    color: white;
    cursor: pointer;
    whitespace: nowrap;
    :hover {
      outline: 2px blue;
      opacity: 0.8;
    }
    
    :before {
      position: absolute;
      top: 4px;
      left: 5px;
      content:" ";
      background: url(${myListIcon}) no-repeat center center / cover;
      display: inline-block;
      height: ${height - 7}px;
      width: ${height - 7}px;
      border-radius: 100%;
    }
  `;

  const CanButton = styled.div`
    background-color: black;
    ${shared}
  `;

  const AddedButton = styled.div`
    background-color: rgba(40, 40, 40, 0.5);
    ${shared}
    :before {
      opacity: 1;
    }
  `;
  const LoadingButton = styled.div`
    background-color: rgba(40, 40, 40, 0.5);
    ${shared}
    :before {
      opacity: 1;
      background-image: none;
    }
  `;

  function AddToWhitelistControl({
    whitelistId,
    whitelistedInfo,
    handleClickWhitelistButton,
    prefix = "Listed",
    cta = "Add to My List",
  }) {
    const [inProgress, setInProgress] = React.useState(false);

    const onClick = async () => {
      setInProgress(true);
      await handleClickWhitelistButton(whitelistId);
      setInProgress(false);
    };

    const whitelisted = whitelistedInfo ? whitelistedInfo[whitelistId] : null;

    return inProgress ? (
      <LoadingButton>One Moment...</LoadingButton>
    ) : whitelisted && whitelisted.current_state === "LISTED" ? (
      <AddedButton onClick={onClick}>
        {prefix} {moment(whitelisted.as_of).fromNow()} [unlist]
      </AddedButton>
    ) : (
      <CanButton onClick={onClick}>{cta}</CanButton>
    );
  }

  return AddToWhitelistControl;
})();

const hoc = connect(
  state => {
    return {
      source: selectSource(state),
      sourceId: selectSourceId(state),
      artist: selectArtist(state),
      userHasTeamWhitelists: state.user.currentUser.capabilities.teamWhitelists,
      myScouts: state.user.myScouts,
      fetchFailureInfo: state.ui.artist.fetchFailureInfo,
    };
  },
  dispatch =>
    bindActionCreators(
      {
        fetchArtist: actionCreators.fetchArtist,
        fetchUpdatedArtist: actionCreators.fetchUpdatedArtist,
        fetchArtistBrains: actionCreators.artistBrains,
        addToWhitelist: actionCreators.addToWhitelist,
        removeFromWhitelist: actionCreators.removeFromWhitelist,
      },
      dispatch
    )
);

class ArtistPage extends React.Component {
  state = {
    isSmall: false,
  };

  componentDidMount() {
    const { source, sourceId } = this.props;
    if (source && sourceId) {
      this.props.fetchArtist(source, sourceId);
      this.props.fetchArtistBrains(source, sourceId);
    }
  }

  componentDidUpdate(prevProps, prevState, prevContext) {
    const prevSource = prevProps.source;
    const prevId = prevProps.sourceId;
    const prevArtist = prevProps.artist;
    const { source, sourceId } = this.props;

    if (!source || !sourceId) {
      // These can be set back to null in certain scenarios.
      return;
    }

    if (
      source === "a" &&
      prevArtist &&
      String(prevArtist.arid) === String(sourceId)
    ) {
      // we have redirected from a non 'a' record to an 'a' record. We don't want to refetch.
      return;
    }

    if (prevSource !== source || String(prevId) !== String(sourceId)) {
      this.props.fetchArtist(source, sourceId);
      this.props.fetchArtistBrains(source, sourceId);
    }
  }

  handleClickWhitelistButton = async whitelistId => {
    const {
      source,
      sourceId,
      artist,
      myScouts,
      removeFromWhitelist,
      addToWhitelist,
    } = this.props;
    return new Promise(resolve => {
      if (
        artist.whitelistedInfo &&
        artist.whitelistedInfo[whitelistId] &&
        artist.whitelistedInfo[whitelistId].current_state === "LISTED"
      ) {
        removeFromWhitelist(whitelistId, artist.arid, resolve);
      } else if (artist.arid) {
        addToWhitelist(whitelistId, "a", artist.arid, resolve);
      } else {
        addToWhitelist(whitelistId, source, sourceId, resolve);
      }
    });
  };

  redirectToCanonicalIfNeeded = artist => {
    // for any sc/123 (or other artists) we should redirect to a/456
    // using location.replace to do it without adding to the back button history
    // not using redux router Redirect, because it was causing a headache with
    // the history, and trying to diagnose re-mounting.
    // todo: honestly wondering if mounting this every time.
    // I feel like routes are nice, but maybe the whole switch thing is also not 'single page'
    if (
      artist &&
      artist.arid &&
      !window.location.hash.startsWith("#/artist/a/")
    ) {
      var baseUrl = window.location.href.split("#")[0];
      window.location.replace(baseUrl + "#/artist/a/" + artist.arid);
    }
  };

  setIsSmall = torf => {
    this.setState({ isSmall: torf });
  };

  render() {
    const { artist, userHasTeamWhitelists, fetchFailureInfo, myScouts } =
      this.props;
    const { isSmall } = this.state;
    // this.redirectToCanonicalIfNeeded(artist);

    if (!artist && !fetchFailureInfo) {
      return (
        <Loading style={{ textAlign: "center", paddingTop: "30px" }}>
          Artist Loading
        </Loading>
      );
    }

    if (fetchFailureInfo) {
      const sourceName = SOURCE_TO_NAME[this.props.source] || "";
      return (
        <div>
          <h4
            style={{
              fontSize: "13px",
              color: "#dd9999",
              textAlign: "center",
              paddingTop: "30px",
            }}
          >
            <span style={{ fontSize: "2em" }}>{"⚠️"}</span>
            <br />
            {fetchFailureInfo.status === 404 ? (
              <span>
                No artist found for {sourceName} ID {this.props.sourceId}.{" "}
                <br />
                How did you get here? Please contact whitelist support if you
                continue to see this message for other artists.
              </span>
            ) : (
              <span>Something went wrong.</span>
            )}
          </h4>
        </div>
      );
    }
    const loaded = artist && artist.name;
    return (
      <div
        style={{ height: "100%", overflow: "auto" }}
        onScroll={ev => {
          const scrolledAmount = ev.target.scrollTop;
          if (!isSmall && scrolledAmount > 100) {
            this.setIsSmall(true);
          } else if (isSmall && scrolledAmount < 80) {
            this.setIsSmall(false);
          }
        }}
      >
        <ArtistHeading artist={artist} isSmall={isSmall} />
        {!loaded ? (
          <div style={{ textAlign: "center", padding: "50px" }}>
            <Loading>Loading...</Loading>
          </div>
        ) : (
          <React.Fragment>
            <Helmet>
              <title>DNA 🎵 {artist.name}</title>
            </Helmet>
            <ArtistDetailModal />
            <section
              style={{
                position: "absolute",
                zIndex: 6,
                top: "10px",
                left: "calc(50% - 140px",
              }}
            >
              <AddToWhitelistControl
                whitelistedInfo={artist.whitelistedInfo}
                whitelistId={"private"}
                handleClickWhitelistButton={this.handleClickWhitelistButton}
              />
              {userHasTeamWhitelists && (
                <AddToWhitelistControl
                  whitelistedInfo={artist.whitelistedInfo}
                  whitelistId={"team"}
                  handleClickWhitelistButton={this.handleClickWhitelistButton}
                  prefix={"Teamlisted"}
                  cta={"Share with My Teamlist"}
                />
              )}
            </section>
            <ArtistBody {...artist} myScouts={myScouts} />
          </React.Fragment>
        )}
      </div>
    );
  }
}

const Hoc = hoc(ArtistPage);

export default Hoc;
