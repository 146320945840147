import styled from "styled-components";

export const SmallLabel = styled.h4`
  margin: 0 0 5px 0;
  padding: 0;
  font-size: 12px;
  line-height: 15px;
  font-weight: normal;
  color: gray;
`;

export const BigTitle = styled.h1`
  margin: 0 0 5px 0;
  padding: 0;
  font-size: 25px;
  line-height: 31px;
  font-weight: normal;
  color: inherit;
  small {
    display: block;
    margin-top: 0.2em;
  }
`;

export const MediumTitle = styled.h1`
  margin: 0 0 5px 0;
  padding: 0;
  font-size: 19px;
  line-height: 23px;
  font-weight: normal;
  color: inherit;
`;
export const metricLabels = {
  plays: "Plays",
  downloads: "Downloads",
  favoritings: "Favoritings",
  comments: "Comments"
};

export const metricIndexes = {
  plays: 1,
  downloads: 2,
  favoritings: 3,
  comments: 4
};

export const withSelectedBoxStyle = (baseStyles, isSelected) => {
  const selectedStyles = {
    border: "1px solid",
    borderRadius: "3px",
    borderColor: "#82beff",
    backgroundColor: "#f4f4f4"
  };

  const unselectedStyles = {
    border: "1px solid transparent"
  };

  return {
    ...baseStyles,
    ...(isSelected ? selectedStyles : unselectedStyles)
  };
};
