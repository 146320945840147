import React from "react";
import ResultsPage from "../components/results_page";
import {RecommendScouts} from "./recommend_influencers";
import styled from "styled-components";
import List from "../components/settings/list";
import Form from "../components/settings/form";
import {connect} from "react-redux";
import {actionCreators} from "../reducers/admin";
import {bindActionCreators} from "redux";
import Padded from "../components/primitives/padded";

const recordType = {
  spotify: "Playlist",
  twitter: "Scout",
  soundcloud: "Scout",
  instagram: "Scout",
  youtube: "Channel",
  tiktok: "Scout",
};


const Settings = connect(
  (state, ownProps) => ({
    ...state.admin[ownProps.datasource],
    datasource: ownProps.datasource
  }),
  dispatch =>
    bindActionCreators(
      {
        getList: actionCreators.adminInfluencersGetList,
        addFormChange: actionCreators.adminInfluencerAddFormChange,
        addFormCheck: actionCreators.adminInfluencerAddFormCheck,
        addFormSave: actionCreators.adminInfluencerAddFormSave,
        editFormChange: actionCreators.adminInfluencerEditFormChange,
        editFormSave: actionCreators.adminInfluencerEditFormSave,
        removeInfluencer: actionCreators.adminRemoveInfluencer
      },
      dispatch
    )
)(props => (
    <div>
      <Padded>
        <Form recordType={recordType[props.datasource]} {...props} />
        <h2 style={{fontSize: '1.2em'}}>Current Scouts</h2>
      </Padded>
      <List recordType={recordType[props.datasource]} {...props} />
    </div>
));


const TwoPanels = styled.section`
  display: flex;
  height: 100%;
  > div:nth-of-type(1) {
    flex: 8;
  }
  > div:nth-of-type(2) {
    flex: 5;
  }
`;


export const SoundCloud = () => {
  return (
    <ResultsPage
      pageId="soundcloud"
      pageTitle="My SoundCloud Scouts"
      narrow
      loading={false}
      loaded={true}
    >
      <TwoPanels>
        <Settings datasource="soundcloud" />
        <RecommendScouts />
      </TwoPanels>
    </ResultsPage>
  );
};

export const Spotify = () => {
  return (
    <ResultsPage
      pageId="spotify"
      pageTitle="Spotify Settings"
      narrow
      loading={false}
      loaded={true}
    >
      <Settings datasource="spotify" />
    </ResultsPage>
  );
};

export const Instagram = () => {
  return (
    <ResultsPage
      pageId="instagram"
      pageTitle="Instagram Settings"
      narrow
      loading={false}
      loaded={true}
    >
      <Settings datasource="instagram" />
    </ResultsPage>
  );
};

export const Twitter = () => {
  return (
    <ResultsPage
      pageId="twitter"
      pageTitle="Twitter Settings"
      narrow
      loading={false}
      loaded={true}
    >
      <Settings datasource="twitter" />
    </ResultsPage>
  );
};

export const YouTube = () => {
  return (
    <ResultsPage
      pageId="youtube"
      pageTitle="YouTube Settings"
      narrow
      loading={false}
      loaded={true}
    >
      <Settings datasource="youtube" />
    </ResultsPage>
  );
};


export const TikTok = () => {
  return (
    <ResultsPage
      pageId="tiktok"
      pageTitle="TikTok Settings"
      narrow
      loading={false}
      loaded={true}
    >
      <Settings datasource="tiktok" />
    </ResultsPage>
  );
};
