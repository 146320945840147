import React from "react";
import styled from "styled-components";
import {transparentize} from "polished/lib/index";
import Padded from "./padded";
import {blackish} from "../../styles/styleConsts";

export const GridRow = styled.div`display: flex;`;

const GRID_UNIT_WIDTH = 1200 / 12;

export const Unknown = styled.i`
   color: #888;
   font-style: italic;
   ::after {
     content: "unknown";
   }
`;
export const GridCell = styled(Padded)`
  width: ${props => props.units * GRID_UNIT_WIDTH}px;
  height: ${props =>
    props.heightUnits ? props.heightUnits * GRID_UNIT_WIDTH + "px" : "auto"};
`;

const StyledBox = styled.div`
  //padding: 10px;
  //border-radius: 3px;
  // background-color: ${transparentize(0.95, blackish)};
  // border: 1px solid ${transparentize(0.90, blackish)};
  margin-bottom: 15px;
`;

export const Box = (props) => !props.display ? null : <StyledBox>{props.children}</StyledBox>

export const Heading = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 15px;
`;

export const Section = styled.div`
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid ${transparentize(0.9, blackish)};
`;

export const Table = styled.table`width: 100%;`;
