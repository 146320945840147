import React from "react";

// type = artist, track etc
// id = artistId, trackId etc

const SpotifyEmbed = ({ type, id, width, height }) => {
  return (
    <iframe
      title="spotify player"
      src={`https://open.spotify.com/embed/${type}/${id}?theme=white`}
      width={width || "100%"}
      height={height || "100%"}
      frameBorder={0}
      style={{ border: "none" }}
      allow="encrypted-media"
    />
  );
};

export default SpotifyEmbed;
