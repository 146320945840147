import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Helmet from "react-helmet";

import { NotFound } from "./route_errors";
import { isArray, isEmptyObject } from "../utils";

import {
  Instagram,
  SoundCloud,
  Twitter,
} from "../containers/daily_activity_view";
import SpotifyResults from "../containers/spotify2";
import YouTubeResults from "../containers/youtube";
import ChartsPage from "../containers/charts";
import ArtistResults from "../containers/artist";
import FollowingResults from "../containers/my_whitelist";
import SoundCloudAnalyzerPage from "../containers/SoundCloudAnalyzer";
import YouTubeAnalyzerPage from "../containers/YouTubeAnalyzer";
import TikTokScoutings from "../containers/TikTokScoutings";
import * as ScoutAdmin from "../containers/scout_admin";
import { UserSettings } from "../containers/userSettings";
import { PublishedSpreadsheets } from "../containers/PublishedSpreadsheets";
import { SoundCloudScoutings } from "../containers/soundcloud2";
import { useAmplitude } from "../containers/Amplitude";
import { PrivacyPage } from "../containers/PrivacyPage";

const defaultPageForUser = user =>
  user.capabilities["soundcloud_by_day"]
    ? "soundcloud"
    : Object.keys(user.capabilities)[0];

// xxx: the react router swtich will remount the component based on the 'key' being different.  This sucks
// as we end up passing the same key, which will trigger a warning we have to ignore.
export const ROUTE_CONFIG = [
  {
    path: "/my_list",
    trackingName: "my whitelist",
    requires: "my_whitelist",
    Component: FollowingResults,
    title: "My Whitelist",
  },
  {
    path: "/my_whitelist",
    trackingName: "my whitelist",
    requires: "my_whitelist",
    Component: FollowingResults,
    title: "My Whitelist",
  },
  {
    path: ["/charts", "/charts/:chartId"],
    trackingName: "charts",
    requires: "charts",
    Component: ChartsPage,
    title: "Charts",
  },
  {
    path: ["/artist/:source/:id", "/artist/:source/:id/.m/zoomed"],
    trackingName: "artist",
    requires: "my_whitelist",
    Component: ArtistResults,
  },

  {
    path: "/soundcloud",
    trackingName: "soundcloud scoutings",
    requires: "soundcloud_by_day",
    Component: SoundCloud,
    title: "SoundCloud",
  },
  {
    path: "/soundcloud2",
    requires: "soundcloud_by_day",
    Component: SoundCloudScoutings,
    title: "SoundCloud Scoutings",
  },

  {
    path: "/soundcloud/settings",
    trackingName: "add soundcloud scouts",
    requires: "add_influencers",
    Component: ScoutAdmin.SoundCloud,
    noModals: true,
  },
  {
    path: "/spotify",
    trackingName: "spotify",
    requires: "spotify",
    Component: SpotifyResults,
    title: "Spotify",
  },
  {
    path: "/spotify/settings",
    trackingName: "add spotify scouts",
    requires: "add_influencers",
    Component: ScoutAdmin.Spotify,
  },
  {
    path: "/youtube",
    trackingName: "youtube channels",
    requires: "youtube",
    Component: YouTubeResults,
    title: "YouTube",
  },
  {
    path: "/youtube/settings",
    trackingName: "add youtube scouts",
    requires: "add_influencers",
    Component: ScoutAdmin.YouTube,
  },
  {
    path: "/twitter",
    trackingName: "twitter scoutings",
    requires: "twitter_by_day",
    Component: Twitter,
    title: "Twitter",
  },
  {
    path: "/twitter/settings",
    trackingName: "add twitter scouts",
    requires: "add_influencers",
    Component: ScoutAdmin.Twitter,
  },
  {
    path: "/instagram",
    trackingName: "instagram scoutings",
    requires: "instagram_by_day",
    Component: Instagram,
    title: "Instagram",
  },
  {
    path: "/instagram/settings",
    trackingName: "add instagram scouts",
    requires: "add_influencers",
    Component: ScoutAdmin.Instagram,
  },
  {
    path: "/analyzer",
    trackingName: "soundcloud analyzer",
    requires: "my_whitelist",
    Component: SoundCloudAnalyzerPage,
    title: "Analyzer",
  },
  {
    path: "/yt_analyzer",
    trackingName: "youtube analyzer",
    requires: "my_whitelist",
    Component: YouTubeAnalyzerPage,
    title: "YouTube Analyzer",
  },
  {
    path: "/tiktoks",
    trackingName: "tiktok scoutings",
    requires: "my_whitelist",
    Component: TikTokScoutings,
    title: "TikTok Scoutings",
  },
  {
    path: "/tiktokScoutings",
    requires: "my_whitelist",
    Component: props => <Redirect to="/tiktoks" />,
  },
  {
    path: "/tiktok/settings",
    requires: "my_whitelist",
    Component: ScoutAdmin.TikTok,
  },

  {
    path: "/myUserSettings",
    requires: "my_whitelist",
    Component: UserSettings,
    title: "My Settings",
  },

  {
    path: "/bangerSheets",
    requires: "my_whitelist",
    Component: PublishedSpreadsheets,
    title: "Bangers",
  },
  {
    path: "/Privacy",
    requires: "my_whitelist",
    Component: PrivacyPage,
    title: "Privacy Policies",
  },
].reduce((allRoutes, thisRoute) => {
  function extraRoutes(path) {
    return [
      path + "/.m/sct/:track_scid",
      path + "/.m/spyt/:selectedTrackId",
      path + "/.m/ytvid/:selectedVideoId",
    ];
  }

  if (thisRoute.noModals) {
    allRoutes.push(thisRoute);
  } else {
    allRoutes.push({
      ...thisRoute,
      path: isArray(thisRoute.path)
        ? thisRoute.path.concat(extraRoutes(thisRoute.path[0]))
        : [thisRoute.path].concat(extraRoutes(thisRoute.path)),
    });
  }
  return allRoutes;
}, []);

function logToAmplitude(amplitude, routeProps, match, mainPath) {
  if (amplitude) {
    const trackingName = routeProps.trackingName;
    const path = match.path;
    const modalNameMap = {
      ytvid: "youtube video",
      sct: "soundcloud track",
      spyt: "spotify track",
    };
    let eventName, eventProps;
    if (path.indexOf("/.m/") !== -1) {
      const modalType = path.split("/.m/")[1].split("/")[0];
      const modalName = modalNameMap[modalType];
      if (modalName) {
        eventName = modalName + " expanded";
        eventProps = {
          ...match.params,
          currentPage: trackingName || match.path.split("/.m/")[0],
        };
      }
    } else if (trackingName) {
      eventName = trackingName + " opened";
      eventProps = isEmptyObject(match.params) ? null : match.params;
    }
    if (eventName) {
      amplitude.logEvent(eventName, eventProps);
    }
  }
}

function getRoutesForUser(user, amplitude) {
  const defaultRoute = (
    <Route key="/" path="/" exact>
      <Redirect to={defaultPageForUser(user)} />
    </Route>
  );

  const routerRoutes = [defaultRoute];
  /* eslint 'jsx-a11y/accessible-emoji': 'off' */

  ROUTE_CONFIG.forEach(({ requires, title, Component, ...routeProps }) => {
    if (user.capabilities[requires]) {
      const mainPath = isArray(routeProps.path)
        ? routeProps.path[0]
        : routeProps.path;
      routerRoutes.push(
        <Route
          key={routeProps.key || mainPath}
          exact={true}
          {...routeProps}
          render={function renderFunc({
            match,
            location,
            history,
            staticContext,
          }) {
            logToAmplitude(amplitude, routeProps, match, mainPath);
            return (
              <React.Fragment>
                <Helmet>
                  <title>DNA 🎵 {title || mainPath}</title>
                </Helmet>
                <Component />
              </React.Fragment>
            );
          }}
        />
      );
    }
  });
  return routerRoutes;
}

function Routes({ user }) {
  // NB, don't make this pure, as it will stop rendering.  xxx, figure that out.
  let amplitude = useAmplitude();
  return (
    <Switch>
      {getRoutesForUser(user, amplitude)}
      <Route component={NotFound} />
    </Switch>
  );
}

export default Routes;
