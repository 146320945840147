import _ from "lodash";
import {EMPTY_OBJ} from "../consts";

export function isEmptyObject(o) {
  if (!o) {
    return true;
  }
  if (o === EMPTY_OBJ) {
    return true;
  }
  if (o.constructor !== Object) {
    return false;
  }
  for (let i in o) {
    return false;
  }
  return true;
}

export const datify = objOrStrOrTimestamp => {
  if (
    typeof objOrStrOrTimestamp === "undefined" ||
    objOrStrOrTimestamp === null
  ) {
    return null;
  }
  if (objOrStrOrTimestamp.__type__ === "datetime") {
    return datify(objOrStrOrTimestamp.val);
  }
  if (typeof objOrStrOrTimestamp === "number") {
    return new Date(objOrStrOrTimestamp * 1000);
  }
  if (typeof objOrStrOrTimestamp === "string") {
    if (
      objOrStrOrTimestamp.indexOf("T") !== -1 &&
      objOrStrOrTimestamp.indexOf("Z") === -1
    ) {
      return new Date(objOrStrOrTimestamp + "Z");
    }
    return new Date(objOrStrOrTimestamp);
  }
};

export function immutUpsert(array, test, obj) {
  let testFunc = test;
  if (_.isString(test)) {
    testFunc = o => {
      return !_.isUndefined(o[test]) && o[test] === obj[test];
    };
  }
  let foundInArray = false;
  const newArray = array.map(o => {
    if (testFunc(o)) {
      foundInArray = true;
      return { ...o, ...obj };
    }
    return o;
  });
  if (!foundInArray) {
    newArray.push(obj);
  }
  return newArray;
}

export function andList(list) {
  if (!list) return "";

  if (list.length === 1) return "" + list[0];

  if (list.length === 2) return list.join(" and ");

  return (
    list.slice(0, list.length - 2).join(", ") + " and " + list[list.length - 1]
  );
}

export function withEllipse(str, len) {
  if (str && str.length > len) {
    return str.substring(0, len) + "...";
  }
  return str;
}

export function shortenUrlForDisplay(url) {
  /* https://www.instagram.com/foo/ => instagram.com/foo */
  if (!url || url.indexOf("//") === -1) {
    return url;
  }
  return url
    .split("//")[1]
    .replace("www.", "")
    .trim();
}

export const SOURCE_TO_NAME = {
  spy: "Spotify",
  in: "Instagram",
  sc: "SoundCloud",
  tw: "Twitter",
  yt: "YouTube"
};

export const ADMIN_BASE_HREF =
  process.env.NODE_ENV === "local_api" || process.env.NODE_ENV === "end2end_api"
    ? "http://localhost:8000/admin/wladmin"
    : "https://ar.whtlst.in/admin/wladmin";

export function daysAgo(asOf) {
  return (new Date().getTime() - new Date(asOf).getTime()) / (24 * 3600 * 1000);
}

export function isNumber(o) {
  return typeof o == 'number';
}

export function sortBy(collection, f) {
  return _.sortBy(collection, f);
}

export function isArray(o) {
  return Array.isArray(o);
}

export function throttle(f, millis) {
  return _.throttle(f, millis)
}

export function uniq(array) {
  return Array.from(new Set(array));
}
