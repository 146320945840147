import React from "react";
import styled from "styled-components";
import {linkblue} from "../styles/styleConsts";

const SortTypes = {
  ASC: "asc",
  DESC: "desc",
};

const reverseSortDirection = (sortDir) => (
  sortDir ? sortDir === SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC : undefined
);

const SortHeaderCell = (props) => {
  const onClick = (e) => {
    e.preventDefault();
    const sortDir = props.currentSort.key === props.sortKey ? reverseSortDirection(props.currentSort.dir) : SortTypes.DESC;
    props.onSortChange({
      key: props.sortKey,
      dir: sortDir
    });
  };

  const {children, onSortChange, currentSort={}, sortKey, ..._props} = props;
  return (
    <div {..._props}>
      <SortLink className={"column-sort " + sortKey} onClick={onClick}>
        {children} {currentSort.key===sortKey ? (currentSort.dir === SortTypes.DESC ? "↓" : "↑") : ""}
      </SortLink>
    </div>
  );
};

export default SortHeaderCell;

export const SortLink = styled.a`
  color: ${linkblue};
  transition: 0.2s;
  cursor: pointer;
  display: block;
  &:hover {
    opacity: 0.5;
  }
`;
