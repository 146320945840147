import styled from "styled-components";
import React from "react";
import {transparentize} from "polished";
import categoryColour from "../../styles/category_colour";
import {Spinner} from "../primitives/things";

const StyledEditableCategory = styled.div`
    position: relative;
    width: 160px;
    input {
      overflow: hidden;
      min-width: 160px;
      height: 31px;
      border-radius: 3px;
      box-sizing: border-box;
      padding: 3px;
      border-style: solid;
      border-width: 2px;
    }
    input:hover {
      opacity: 0.9;
    }
    .quickChoiceBox {
      z-index: 1;
      position: absolute;
      background-color: white;
      border: 1px solid black;
      width: 160px;
      
      li {
        padding: 5px;
        border-bottom: 1px solid black;
        cursor: default;
        :hover {
          opacity: 0.8;
          background-color: aliceblue;
        }
      }
    }

    .spinnerContainer {
      position: absolute;si
      top: 3px;
      right: 4px;
    }
  `;

export function EditableCategory({savedCategory, allCategories = [], onSave}) {
  const [category, setCategory] = React.useState(savedCategory);
  const [isFocused, setIsFocused] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const inputRef = React.useRef();

  return (
    <StyledEditableCategory style={{position: "relative"}}>
      <input
        onBlur={async () => {
          setIsLoading(true);
          setIsFocused(false);
          await onSave(category);
          setIsLoading(false);
        }}
        key={'foo'}
        onFocus={() => {
          setIsFocused(true);
        }}
        onChange={e => setCategory(e.target.value)}
        ref={inputRef}
        value={category ? category : !isFocused ? "" : ""}
        type="text"
        style={{
          backgroundColor: isFocused ? transparentize(0.7, categoryColour(category)) : category ? categoryColour(category) : '#eee',
          borderColor: category ? categoryColour(category) : '#eeeeee',
          fontWeight: !isFocused && category && 'bold',
          fontStyle: !category && 'italic',
          color: !isFocused && category ? 'white' : !isFocused ? '#bbb' : '',
        }}
        // placeholder={!isFocused && "Click to edit"}
      />
      {/*{category || (<em style={{fontStyle: "italic", color:"#efefef"}}>Click to edit</em>)}*/}
      {isLoading && <div className="spinnerContainer"><Spinner style={{zoom: 0.77, opacity: 0.7}}/></div>}
      {/*{isShowingCategories && allCategories.length > 0 && (*/}
      {/*  <div className="quickChoiceBox">*/}
      {/*    <ul>*/}
      {/*      {allCategories.map(c => <li key={c} onClick={() => {*/}
      {/*        console.log("Click happened")*/}
      {/*        setCategory(c);*/}
      {/*        setIsFocused(true);*/}
      {/*        inputRef.focus();*/}
      {/*      }}>{c}</li>)}*/}
      {/*    </ul>*/}
      {/*  </div>*/}
      {/*)}*/}
    </StyledEditableCategory>
  );
}
