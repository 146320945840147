import {MainLayout} from "../../containers/mosaic";
import React, {useEffect, useReducer, useRef, useState} from "react";
import {EMPTY_OBJ} from "../../consts";
import {ChartFilters, StyledFilters} from "../chart_filters";
import {useFetchPromise, useJsonFetch} from "../../fetching/UseFetch";
import {Faceter} from "../Faceter";
import {applyFilters} from "../../utils/matrix_filter";
import {SHORT_ROW_HEIGHT,} from "./index";
import {AutoSizer} from "react-virtualized";
import {getArtistByKey} from "../../reducers/data_access";
import {connect} from "react-redux";
import StyledReactVirtualizedTable from "../styled_reactvirtualized_table";
import {sortBy} from "../../utils";
import {makeColumnComponents} from "./ChartColumns";
import {getTikTokSoundById} from "../../reducers/domain";
import {Loading} from "../primitives/things";
import {useUser} from "../../containers/UserContext";
import {useReduxDispatch} from "../../reducers/ReduxHooks";
import {hideArtistForMe} from "../../reducers/artist";

function parseServerResponse({ matrix, schema }) {
  return matrix.map(row =>
    schema.reduce((obj, key, ix) => {
      obj[key] = row[ix];
      const artistKey = obj.artistKey || (obj.artist_scid ? 'sc/' + obj.artist_scid : obj.artist_spyid ? 'spy/' + obj.artist_spyid : null);
      if (artistKey) {
        obj.artistKey = artistKey;
      }
      return obj;
    }, {})
  );
}

function filterHiddenRows(rows, {hiddenArtistKeys}) {
  console.log('filtering', rows, hiddenArtistKeys)
  return hiddenArtistKeys && hiddenArtistKeys.length
    ? rows.filter(row => hiddenArtistKeys.indexOf(row.artistKey) === -1)
    : rows;

}

function matrixHookReducer(user) {
  return function matrixHookReducer(state, action) {
    switch (action.type) {
      case "dataFetched":
        const serverDataRows = filterHiddenRows(parseServerResponse(action.result), user);

        return {
          refinedDataRows: serverDataRows,
          allDataRows: serverDataRows,
          refinements: [],
          isLoading: false,
        };
      case "hideArtist":
        console.log("Hising Artist!", action)
        return {
          ...state,
          refinedDataRows: filterHiddenRows(state.refinedDataRows, {hiddenArtistKeys: [action.artistKey]}),
          allDataRows: filterHiddenRows(state.allDataRows, {hiddenArtistKeys: [action.artistKey]}),
        };
      case "refine":
        const moreRefinements = state.refinements.concat(action.refinement);
        return {
          ...state,
          refinements: moreRefinements,
          refinedDataRows: applyFilters(state.refinedDataRows, moreRefinements)
        };
      case "unrefine":
        let fewerRefinements = state.refinements.filter(
          r => r.key !== action.refinementKey
        );
        return {
          ...state,
          refinements: fewerRefinements,
          refinedDataRows: applyFilters(state.allDataRows, fewerRefinements)
        };
      default:
        return state;
    }
  }
}

matrixHookReducer.initialState = {
  refinedDataRows: [],
  allDataRows: [],
  refinements: [],
  isLoading: true,
};


matrixHookReducer.handleRefine = dispatch => (
  dataMapping,
  categoryKey,
  categoryName,
  categoryValue
) => {
  const dataMapper = dataMapping[categoryKey];
  const valueToBucket = (value) => {
    if (dataMapper && dataMapper.func) {
      return dataMapper.func(value);
    }
    if (categoryValue === "" || categoryValue === null) {
      return "none";
    }
    return value;
  }
  dispatch({
    type: "refine",
    refinement: {
      key: categoryKey,
      name: categoryName,
      valueLabel: categoryValue,
      filter: fieldValue => {
        const bucket = valueToBucket(fieldValue);

        return Array.isArray(bucket) ? bucket.indexOf(categoryValue) !== -1 : categoryValue === bucket;
      }
    }
  });
};

matrixHookReducer.handleUnrefine = dispatch => refinement => {
  dispatch({ type: "unrefine", refinementKey: refinement.key });
};


function useMatrixFetcher(fetcher, user) {
  const isMounted = useRef(true);
  const reduxDispatch = useReduxDispatch();
  useEffect(() => () => { isMounted.current = false; }, []);

  const [matrixState, dispatch] = useReducer(
    matrixHookReducer(user),
    matrixHookReducer.initialState
  );

  useEffect(() => {
    if (fetcher.data) {
      if (isMounted.current) {
        dispatch({ type: "dataFetched", result: fetcher.data });
      }
    }
    // eslint-disable-next-line
  }, [fetcher.isLoading]);

  const handleHideArtist = (artistKey) => {
    reduxDispatch(hideArtistForMe({keys: [artistKey]}))
    dispatch({type:"hideArtist", artistKey})
  };

  const handleRefine = matrixHookReducer.handleRefine(dispatch);
  const handleUnrefine = matrixHookReducer.handleUnrefine(dispatch);

  return {...matrixState, handleRefine, handleUnrefine, handleHideArtist}

}


function filterReducer(state=EMPTY_OBJ, action) {
  switch (action.type) {
    case 'apply':
      return {...state, ...action.filters};
    default:
      return state;
  }
}


function ResultsTable ({refinedDataRows, columnList, defaultSort, isLoadingData, handleHideArtist}) {
  console.log("Rendering results table");

  const [currentSort, setCurrentSort] = useState(defaultSort);
  const [isSortedDesc, setIsSortedDesc] = useState(true);

  function toggleSort(key) {
    if (currentSort === key) {
      setIsSortedDesc(!isSortedDesc);
    } else {
      setIsSortedDesc(false);
      setCurrentSort(key);
    }
  }

  const sortedRows = React.useMemo(
    () => {
      return sortBy(refinedDataRows, item => item[currentSort]*(isSortedDesc ? -1 : 1))
    },
    [currentSort, isSortedDesc, refinedDataRows]
  );

  function matrixItemToFetchingItem(index) {
    const row = sortedRows[index];
    if (!row) {
      return EMPTY_OBJ;
    }
    const artistKey = row.artistKey || (row.artist_scid ? 'sc/' + row.artist_scid : row.artist_spyid ? 'spy/' + row.artist_spyid : null);
    return {
      trackScid: row.track_scid || row.scid,
      artistKey: artistKey,
      trackSpyid: row.track_spyid,
      soundTtid: row.soundTtid,
      videoYtid: row.videoYtid,
      handleHideArtist,
    };
  }
  return (isLoadingData
      ? <Loading>Loading data...</Loading>
      : <RenderWithConnectedDomain
        handleHideArtist={handleHideArtist}
      render={(getDomainItem, ensureItemsLoaded) => {
        console.log("Rendering with domain");
        return (
          <AutoSizer>
            {({width, height}) => (
              <StyledReactVirtualizedTable
                key={currentSort + String(isSortedDesc)}
                rowHeight={SHORT_ROW_HEIGHT}
                rowCount={sortedRows.length}
                rowGetter={({index}) => ({...getDomainItem(matrixItemToFetchingItem(index)), ...sortedRows[index]})}
                headerHeight={42}
                width={width}
                height={height}
                // scrollTop={scrollTop}
                onRowsRendered={
                  ({overscanStartIndex, overscanStopIndex, startIndex, stopIndex}) => {
                    const scannedItems = new Array(overscanStopIndex - overscanStartIndex + 1);
                    for (let i = overscanStartIndex; i <= overscanStopIndex; i++) {
                      scannedItems.push(matrixItemToFetchingItem(i));
                    }
                    ensureItemsLoaded(scannedItems);
                  }
                }
                overscanRowCount={4}
              >
                {makeColumnComponents(columnList, currentSort, isSortedDesc, toggleSort)}
              </StyledReactVirtualizedTable>
            )}
          </AutoSizer>
        );
      }}
    />
  )
}

function useSet() {
  const [items, setItems] = useState(new Set());
  const addAll = function addAll(newItems) {
    if (newItems) {
      setItems(new Set([...items].concat(newItems)))
    }
  };
  return [items, addAll]
}

const RenderWithConnectedDomain = connect(
  state => ({
    artistDb: state.domain.artistDb,
    scTrackData: state.domain.scTrackData,
    spyTrackData: state.domain.spyTrackData,
    ytData: state.domain.ytData,
    ttData: state.domain.ttData,
  })
)(
  function DomainFetcher({artistDb, scTrackData, spyTrackData, ytData, ttData, render, dispatch, handleHideArtist}) {

    const [loadingArtists, addLoadingArtists] = useSet();
    const [loadingScTracks, addLoadingScTracks] = useSet();
    const [loadingSpyTracks, addLoadingSpyTracks] = useSet();
    const [loadingTtSounds, addLoadingTtSounds] = useSet();
    const [loadingYtVideos, addLoadingYtVideos] = useSet();

    const artistFetcher = useFetchPromise('explorer/artists');
    const scTrackFetcher = useFetchPromise('explorer/scTracks');
    const spyTrackFetcher = useFetchPromise('explorer/spyTracks');
    const ttSoundFetcher = useFetchPromise('explorer/ttSounds');
    const ytVideoFetcher = useFetchPromise('explorer/ytVideos');

    function getScTrack(track_scid) {
      return track_scid ? scTrackData.tracksIndex[track_scid] : undefined;
    }

    function getSpyTrack(track_spyid) {
      return track_spyid ? spyTrackData.index[track_spyid] : undefined;
    }

    function fetchItem({artistKey, trackScid, trackSpyid, videoYtid, soundTtid}) {
      const artist = artistKey ? getArtistByKey(artistDb, artistKey) : undefined;
      const track = getScTrack(trackScid) || getSpyTrack(trackSpyid) || undefined;
      const video = videoYtid ? ytData.videosIndex[videoYtid] : undefined;
      const ttSound = soundTtid ? getTikTokSoundById(soundTtid, ttData) : undefined;
      return {
        artist,
        track,
        video,
        ttSound,
        handleHideArtist: artist && artist.query_key ? () => handleHideArtist(artist.query_key) : null,
      };
    }

    async function ensureItemsLoaded(itemList) {
      // add soundTtid, maybe videoTtid
      const needingLoading = itemList.reduce((acc, {artistKey, trackScid, trackSpyid, videoYtid, soundTtid}) => {
          if (artistKey && !getArtistByKey(artistDb, artistKey) && !loadingArtists.has(artistKey)) {
            acc.artistKeys.push(artistKey);
          }
          if (trackScid && !getScTrack(trackScid) && !loadingScTracks.has(trackScid)) {
            acc.scTracks.push(trackScid);
          }
          if (trackSpyid && !getSpyTrack(trackSpyid) && !loadingSpyTracks.has(trackSpyid)) {
            acc.spyTracks.push(trackSpyid);
          }
          if (soundTtid && !getTikTokSoundById(soundTtid, ttData) && !loadingTtSounds.has(soundTtid)) {
            acc.ttSounds.push(soundTtid);
          }
          if(videoYtid && !ytData.videosIndex[videoYtid] && !loadingYtVideos.has(videoYtid)) {
            acc.ytVideos.push(videoYtid);
          }

          return acc;
        }, {scTracks: [], spyTracks: [], artistKeys: [], ttSounds: [], ytVideos: []}
      );
      console.log("The following need loading:", needingLoading, itemList)

      if (needingLoading.scTracks.length) {
        addLoadingScTracks(needingLoading.scTracks);
        scTrackFetcher('/api/soundcloud_tracks_list?scids=' + needingLoading.scTracks.join(','))
      }
      if (needingLoading.spyTracks.length) {
        addLoadingSpyTracks(needingLoading.spyTracks);
        spyTrackFetcher('/api/spotify_tracks_list?spyids=' + needingLoading.spyTracks.join(','))
      }
      if (needingLoading.artistKeys.length) {
        addLoadingArtists(needingLoading.scTracks);
        artistFetcher('/api/artists_by_id?use_cache_only=1&refresh_cache=1', {method: "POST", body: JSON.stringify({keys: needingLoading.artistKeys})});
      }
      if (needingLoading.ttSounds.length) {
        addLoadingTtSounds(needingLoading.ttSounds);
        ttSoundFetcher('/api/tiktok_sounds?ttids=' + needingLoading.ttSounds.join(','));
      }
      if (needingLoading.ytVideos.length) {
        addLoadingYtVideos(needingLoading.ytVideos);
        ytVideoFetcher('/api/youtube_videos_list?ytids=' + needingLoading.ytVideos.join(','));
      }
    }

    return render(fetchItem, ensureItemsLoaded)
  }
);

/**
 * A ChartExplorer is a view of music or artist data.
 * 'Chart' refers to the table of results (like a Billboard chart), 'Explorer' is the
 * controls the users have to look at the data.
 *
 * An explorer has a table of results, a panel for changing the query, and a panel for
 * the summary breakdown of the results.
 *
 * @param apiEndpoint - the path to fetch the matrix of data.
 * @param defaultFilters - the initial filter settings
 * @param defaultSort - dataKey of the initial sort.  should match a value in the matrix's schema.
 * @param renderFilters - function that returns the controls for hanging
 * @param isArtists
 * @param facetMapping
 * @param columnList
 */
export function ChartExplorer({apiEndpoint, defaultFilters=EMPTY_OBJ, defaultSort, renderFilters, isArtists, facetMapping, columnList, renderEmptyResults}) {

  const [currentFilters, dispatchFilters] = React.useReducer(filterReducer, defaultFilters);

  const user = useUser();
  const matrixFetcher = useJsonFetch(apiEndpoint, {method:"POST", body:{filters:currentFilters}});
  const mer = useMatrixFetcher(matrixFetcher, user);

  const Filters = renderFilters && (() => <StyledFilters style={{width: "190px", padding: "0 10px"}}>
      <ChartFilters
        handleSave={filters => dispatchFilters({type: 'apply', filters})}
        initialValues={currentFilters}
        render={renderFilters}
      />
    </StyledFilters>
  );

  const Breakdown = facetMapping && (() => (
    <Faceter
      dataRows={mer.refinedDataRows}
      dataMapping={facetMapping}
      excludeUnmappedFields={true}
      handleRefine={mer.handleRefine}
      refinedBy={mer.refinements}
      handleUnrefine={mer.handleUnrefine}
      isArtists={isArtists}
    />)
  );

  const Refinements = facetMapping && (() => {
    return <>{
      mer.refinements.map(refinement => (
        <span key={refinement.key} style={{fontSize: "10px", padding: "4px", backgroundColor: "rgba(255,255,255,0.3)", display: "inline-block", marginRight: "2px"}}>
           {refinement.name}:{refinement.valueLabel}
        </span>
      ))
    }</>;
  });

  if (renderEmptyResults && !mer.isLoading && mer.allDataRows.length === 0) {
    return renderEmptyResults;
  }

  return <MainLayout panels={[
    Filters ? {name: "Settings", component: <Filters/>, isCollapsible: true } : null,
    Breakdown ? {name: "Summary", component: <Breakdown/>, isCollapsible: true, width: 250, collapsedComponent: <Refinements />} : null,
    {
      name: "Results",
      isCollapsible: false,
      disableOverflow: true,
      component: <ResultsTable
        key={"ResultsTable"}
        isLoadingData={matrixFetcher.isLoading}
        allDataRows={mer.allDataRows}
        refinedDataRows={mer.refinedDataRows}
        columnList={columnList}
        defaultSort={defaultSort}
        handleHideArtist={mer.handleHideArtist}
      />
    },
  ]}/>

}
