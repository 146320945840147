import React from "react";
import PropTypes from "prop-types";

import numeral from "numeral";
import moment from "moment";

import {Heading} from "../components/primitives/grid";
import {Col, Row} from "react-styled-flexboxgrid";
import {instagramPink, linkblue, soundcloudOrange, spotifyGreen, twitterBlue, youtubeRed} from "../styles/styleConsts";
import styled from "styled-components";
import ErrorBoundary from "../components/error_boundary";
import {Link} from "react-router-dom";

import {LittleSourceIcon, Loading, UpDownTriangleNumber} from "../components/primitives/things";

import Timeseries from "../components/timeseries_charts";

import RadioGroup from "../components/radio";

import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {actionCreators} from "../reducers/artist";
import {selectArtist} from "./artist_selectors";
import {UniversalRowItem} from "../components/charts";
import {addLocationParts} from "../routing/navigator";
import {Facet} from "../components/Faceter";
import {isEmptyObject} from "../utils";
import {lighten} from "polished";
import {Flag} from "../components/flag_icons";
import {BigTitle} from "../components/metrics/styles";

const colours = {
  Spotify: spotifyGreen,
  Twitter: twitterBlue,
  SoundCloud: soundcloudOrange,
  Instagram: instagramPink,
  YouTube: youtubeRed
};

const StyledFollowersCount = styled.div`
  display: flex;
  border-bottom: 1px solid #e0e0e0;
  height: 31px;
  font-size: 10px;
  .icon {
    height: 31px;
    width: 31px;
    padding: 0;
    border: 0;
    text-align: center;
    line-height: 31px;
    color: #282c2d;
  }
  .icon::before {
    content: "▶";
  }
  .LittleSourceIcon {
    vertical-align: middle;
  }
  span {
    flex-grow: 2;
    padding: 10px;
  }
  .right {
    flex-grow: 1;
    text-align: right;
  }

  .zoomLink {
    text-decoration: none;
    color: ${linkblue};
    font-weight: bold;
  }
`;

const StyledRadios = styled.div`
  padding: 5px;
  label {
    display: block;
    padding: 2px;
  }
`;

function ArtistGraphsControls(props) {
  const handleChangeTime = props.handleChangeTime;
  const handleChangeDfdt = props.handleChangeDfdt;

  return (
    <Row>
      <Col xs={12} sm={4}>
        <StyledRadios>
          <RadioGroup
            handleChange={handleChangeTime}
            defaultSelectedIndex={0}
            choices={[
              { value: null, label: "All time" },
              { value: 30, label: "Last 30 days" },
              { value: 5, label: "Last 5 days" }
            ]}
          />
        </StyledRadios>
      </Col>
      <Col xs={12} sm={4}>
        <StyledRadios>
          <RadioGroup
            handleChange={handleChangeDfdt}
            defaultSelectedIndex={1}
            choices={[
              { value: null, label: "Graph value" },
              { value: "dfdt", label: "Velocity (change over time)" },
              { value: "pctChange", label: "Percent change" }
            ]}
          />
        </StyledRadios>
      </Col>
      <Col xs={12} sm={4}>
        <Link
          style={{
            color: linkblue,
            fontSize: "16px",
            textDecoration: "none",
            padding: "4px 8px",
            border: "1px solid " + linkblue,
            backgroundColor: "rgba(50,50,50,0.05)"
          }}
          to={addLocationParts("zoomed")}
        >
          {"🔎 Expand Stats"}
        </Link>
      </Col>
    </Row>
  );
}

ArtistGraphsControls.propTypes = {
  handleChangeTime: PropTypes.any,
  handleChangeDfdt: PropTypes.any,
};

const sourceNameToCode = {
  youtube: "yt",
  soundcloud: "sc",
  spotify: "spy",
  twitter: "tw",
  instagram: "in"
};

function TimeseriesHeader({ sourceName, metricName, data }) {
  return (
    <ErrorBoundary>
      <StyledFollowersCount style={{ fontWeight: "bold" }}>
        <span>
          {sourceNameToCode[sourceName.toLowerCase()] && (
            <React.Fragment>
              <LittleSourceIcon
                source={sourceNameToCode[sourceName.toLowerCase()]}
              />{" "}
            </React.Fragment>
          )}
          {sourceName + " " + metricName}
        </span>
        <span style={{ fontSize: "1.2em" }} className={"right"}>
          {numeral(data.latest).format("0,0")}
        </span>
      </StyledFollowersCount>
      <StyledFollowersCount>
        <span>5 day change</span>
        <span className="right">
          {data.d5 ? numeral(data.d5 / (data.v5 + 1)).format("0.00%") : "n/a"}
        </span>
      </StyledFollowersCount>
      <StyledFollowersCount>
        <span>30 day change</span>
        <span className="right">
          {data.d30
            ? numeral(data.d30 / (data.v30 + 1)).format("0.00%")
            : "n/a"}
        </span>
      </StyledFollowersCount>
    </ErrorBoundary>
  );
}

TimeseriesHeader.propTypes = {
  sourceName: PropTypes.string,
  metricName: PropTypes.string,
  data: PropTypes.shape({
    latest: PropTypes.number,
    d5: PropTypes.number,
    v5: PropTypes.number,
    v30: PropTypes.number,
    d30: PropTypes.number
  })
};

function _convertSeriesToData(series, metricNames) {
  // Convert a series of [ [statdate, number, number, number...],... ] to the old
  // data format held in the stats_* tables.
  // returns a list of data objects, one per metric.
  const length = (series || []).length;
  if (!length) {
    return null;
  }

  // uses 'stat date' format of days since epoch
  const [lastDay, ...lastValues] = series[length - 1];
  let day5Values = null,
    day30Values = null;

  for (let i = length - 1; i >= 0; i--) {
    const [thisDay, ...thisValues] = series[i];
    const dayDelta = lastDay - thisDay;
    // Allow for a few days grace, in case we miss a day.
    if (dayDelta >= 5 && dayDelta <= 7 && day5Values === null) {
      day5Values = thisValues;
    } else if (dayDelta >= 30 && dayDelta <= 35 && day30Values === null) {
      day30Values = thisValues;
    } else if (dayDelta > 35) {
      break;
    }
  }

  return metricNames.map((metric, indx) => {
    const latest = lastValues[indx];
    const v5 = day5Values ? day5Values[indx] : null;
    const d5 = v5 ? latest - v5 : null;
    const v30 = day30Values ? day30Values[indx] : null;
    const d30 = v30 ? latest - v30 : null;
    return {
      series: series.map(row => [row[0], row[indx + 1]]),
      latest: latest,
      v5: v5,
      d5: d5,
      v30: v30,
      d30: d30
    };
  });
}

// exported for stories
export class ArtistMetrics extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      zoomDomain: null,
      graphDisplaySetting: 'dfdt',
    };
    this.handleZoomChange = this.handleZoomChange.bind(this);
    this.handleDateChoice = this.handleDateChoice.bind(this);
    this.handleGraphDisplayChoice = this.handleGraphDisplayChoice.bind(this);
  }

  handleZoomChange(domain) {
    this.setState({ zoomDomain: domain });
  }

  handleDateChoice(choice) {
    this.setState({ lastNDays: +choice.value ? +choice.value : null });
  }

  handleGraphDisplayChoice(choice) {
    this.setState({ graphDisplaySetting: choice.value });
  }

  render() {
    const {
      followerCounts,
      spotifyEngagement,
      soundcloudEngagement,
      youtubeSeries,
      spotifyMonthlyListeners
    } = this.props;

    const metricDatasets = Object.entries(followerCounts || {}).map(
      ([key, data]) => ({
        sourceName: key,
        data: data,
        metricName: "Followers"
      })
    );

    if (
      spotifyEngagement &&
      spotifyEngagement.series &&
      spotifyEngagement.series.length
    ) {
      metricDatasets.push({
        sourceName: "Spotify",
        data: spotifyEngagement,
        metricName: "Popularity"
      });
    }

    if (
      soundcloudEngagement &&
      soundcloudEngagement.series &&
      soundcloudEngagement.series.length
    ) {
      metricDatasets.push({
        sourceName: "SoundCloud",
        data: soundcloudEngagement,
        metricName: "Engagement"
      });
    }

    if (youtubeSeries && youtubeSeries.length) {
      const [subscriberData, channelViewData] = _convertSeriesToData(
        youtubeSeries,
        ["Subscribers", "Views"]
      );
      metricDatasets.push({
        sourceName: "YouTube",
        data: subscriberData,
        metricName: "Subscribers"
      });
      metricDatasets.push({
        sourceName: "YouTube",
        data: channelViewData,
        metricName: "Channel Views"
      });
    }

    return (
      <BrainsSection>
        <Heading>Artist Growth Metrics</Heading>
        <ArtistGraphsControls
          handleChangeTime={this.handleDateChoice}
          handleChangeDfdt={this.handleGraphDisplayChoice}
        />
        <Row>
          {metricDatasets.map(({ sourceName, data, metricName }) => {
            if (!data || !data.series || data.series.length === 0) {
              return null;
            }
            return (
              <Col key={sourceName + " " + metricName} xs={6} sm={4}>
                <React.Fragment>
                  <TimeseriesHeader
                    sourceName={sourceName}
                    metricName={metricName}
                    data={data}
                  />

                  <ErrorBoundary>
                    <Timeseries
                      primarySeries={data.series}
                      primaryColour={colours[sourceName]}
                      lastNDays={this.state.lastNDays}
                      applyDfdt={this.state.graphDisplaySetting === "dfdt"}
                      applyPercentChange={
                        this.state.graphDisplaySetting === "pctChange"
                      }
                    />
                  </ErrorBoundary>
                </React.Fragment>
              </Col>
            );
          })}
        </Row>
      </BrainsSection>
    );
  }
}

const BrainsSection = styled.div`
  margin-bottom: 40px;
  :last-of-type {
    margin-bottom: 100px;
  }
`;

const hoc = connect(
  state => {
    const artist = selectArtist(state);
    return {
      artistBrainsData: artist.brains,
    };
  },
  dispatch =>
    bindActionCreators(
      {
        addArtistToCompare: actionCreators.addCompareToArtistBrains,
        handleRemoveArtist: actionCreators.removeCompareToArtist
      },
      dispatch
    )
);

const ArtistGraphs = hoc(function ArtistGraphs({
  artistBrainsData,
}) {
  return !artistBrainsData ? (
    <Loading style={{ color: "black" }}>Loading artist metrics</Loading>
  ) : (
    <ArtistMetrics
      followerCounts={artistBrainsData.FollowerCounts}
      spotifyEngagement={artistBrainsData.SpotifyEngagement}
      soundcloudEngagement={artistBrainsData.SoundCloudEngagement}
      youtubeSeries={artistBrainsData.youTubeArtistChannelSeries}
    />
  );
});

const SpotifyTracks = connect(state => ({
  spotifyTrackData: selectArtist(state).spotifyTrackData
}))(function SpotifyTracks({ spotifyTrackData }) {
  if (!spotifyTrackData) {
    return null;
  }

  return (
    <BrainsSection>
      <Heading>
        <LittleSourceIcon source={"spy"} /> {"Spotify Tracks"}
      </Heading>
      <div>
        {spotifyTrackData.tracks.map(track => {
          return (
            <UniversalRowItem
              key={track.spyid}
              track={track.track}
              displaySettings={{
                includeScouts: false,
                includeAlerts: false,
                includeTrackStats: true,
                shortRow: true
              }}
            />
          );
        })}
      </div>
    </BrainsSection>
  );
});

const SoundCloudTracks = connect(state => ({
  soundCloudTrackData: selectArtist(state).soundCloudTrackData
}))(function SoundCloudTracks({ soundCloudTrackData }) {
  if (!soundCloudTrackData) {
    return null;
  }
  return (
    <BrainsSection>
      <Heading>
        <LittleSourceIcon source={"sc"} /> {"SoundCloud Tracks"}
      </Heading>
      <div>
        {soundCloudTrackData.tracks.map(track => {
          return (
            <UniversalRowItem
              key={track.scid}
              track={track.track}
              displaySettings={{
                includeScouts: false,
                includeAlerts: false,
                includeTrackStats: true,
                shortRow: true
              }}
            />
          );
        })}
      </div>
    </BrainsSection>
  );
});

const YouTubeVideos = connect(state => ({
  ytVideoData: selectArtist(state).ytVideoData
}))(function YouTubeVideos({ ytVideoData }) {
  const videos = (ytVideoData||{}).videos||[];
  if (videos.length === 0) {
    return null;
  }
  return (
    <BrainsSection>
      <Heading>
        <LittleSourceIcon source={"yt"} /> {" YouTube Videos"}
      </Heading>
      <div>
        {(videos || []).map(video => {
          return (
            <UniversalRowItem
              key={video.ytid}
              video={video.video}
              displaySettings={{
                includeScouts: false,
                includeAlerts: false,
                includeTrackStats: true,
                shortRow: true
              }}
            />
          );
        })}
      </div>
    </BrainsSection>
  );
});


const StyledMonthlyListnerBreakdown = styled.div`

`;

// exported for Storybook
export function MonthlyListenerBreakdown({data}) {
  const COLLAPSED_LENGTH = 5;
  const [isExpanded, setIsExpanded] = React.useState(false);

  const total = data.overall.monthly_listeners;
  const as_of = data.overall.as_of;
  let totalCityFollowers = 0;

  const citiesGrouping = data.cities ? (data.cities.cities||[]).reduce((acc, city, ix) => {
    if (isExpanded || ix < COLLAPSED_LENGTH) {
      acc[city.name + ' ' + city.country] = city.listeners;
      totalCityFollowers += city.listeners;
    }
    return acc;
  }, {}) : null;
  if (!isEmptyObject(citiesGrouping) && total > totalCityFollowers) {
    citiesGrouping['Other'] = total - totalCityFollowers;
  }

  let totalPlaylistListeners = 0;
  const playlistsGrouping = data.playlists ? (data.playlists.playlists||[]).reduce((acc, playlist) => {
    acc[playlist.name] = playlist.listeners;
    totalPlaylistListeners += playlist.listeners;
    return acc;
  }, {}) : null;
  if (!isEmptyObject(playlistsGrouping) && total > totalPlaylistListeners) {
    playlistsGrouping['Other / Non-Playlist'] = total - totalPlaylistListeners;
  }

  return <StyledMonthlyListnerBreakdown>
    <div>
      <div style={{fontStyle: "italic"}}>{"Data as of "}{moment(as_of).format("Do MMM")}{""}</div>
      <span>{"Listeners:"}</span>
      <BigTitle>
        {numeral(total).format("0,0")}
        {data.overall.monthly_listeners_delta && <>
          <UpDownTriangleNumber value={data.overall.monthly_listeners_delta} style={{marginLeft: "20px"}}/>
          <span style={{fontSize: "11px", marginLeft: "5px"}}>In last 28 days</span>
          </>}
      </BigTitle>
    </div>
    <Row>
      <Col xs={6} sm={4}>
    <div><ErrorBoundary>
      <Timeseries
        primarySeries={data.listeners_series}
        primaryColour={colours['Spotify']}
        lastNDays={false}
        applyDfdt={false}
        applyPercentChange={false}
      />
    </ErrorBoundary></div>
      </Col>
      <Col xs={6} sm={4}>
    {!isEmptyObject(citiesGrouping) && <div>
      <h3>
        {"Top Cities:"}
        {data.cities.cities.length <= COLLAPSED_LENGTH
          ? null
          : <span style={{marginLeft:"50px", color: linkblue}} onClick={() => setIsExpanded(!isExpanded)}>{isExpanded ? "- collapse" : "+ expand"}</span>

        }
      </h3>
      <Facet
        groupings={citiesGrouping}
        isWide
        numberFormat={"0.0a"}
        barColor={lighten(0.1, spotifyGreen)}
        renderName={(name) => {
          if (name == 'Other') {
            return <span style={{whiteSpace:"nowrap"}}>{name}</span>;
          }
          const code = name.substring(name.length - 2);
          return <span style={{whiteSpace:"nowrap"}}><Flag style={{zoom: "0.6", opacity: "0.9"}} code={code}/>{name}</span>;
        }}
      />
    </div>}
      </Col>
      <Col xs={6} sm={4}>
    {!isEmptyObject(playlistsGrouping) && <div><h3>Top Playlists:</h3><Facet
      groupings={playlistsGrouping}
      numberFormat={"0.0a"}
      barColor={lighten(0.1, spotifyGreen)}
      bottomCategoryLabel="Other / Non-Playlist"
      isWide
    /></div>}
      </Col>
    </Row>

  </StyledMonthlyListnerBreakdown>
}

const SpotifyMonthlyListeners = connect(selectArtist)(
function SpotifyMonthlyListeners({spotifyMonthlyListenerData}) {
  if (!spotifyMonthlyListenerData || !spotifyMonthlyListenerData.overall) {
    return null;
  }
  return  (
    <BrainsSection>
      <Heading><LittleSourceIcon source={"spy"} /> {"Spotify Monthly Listeners"}</Heading>
      <MonthlyListenerBreakdown data={spotifyMonthlyListenerData} />
    </BrainsSection>
  );
});

function BrainsComponent() {
  return (
    <React.Fragment>
      <ArtistGraphs />
      <SpotifyMonthlyListeners />
      <SpotifyTracks />
      <SoundCloudTracks />
      <YouTubeVideos />
    </React.Fragment>
  );
}

export default BrainsComponent;
