import moment from "moment/moment";
import React from "react";

import { createSelector } from "reselect";
import { RoutedModal } from "../../components/modal";

import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { selectArtist } from "../artist_selectors";
import { MetricsGraph } from "../../components/metrics/modal";
import { BigTitle, SmallLabel } from "../../components/metrics/styles";
import { makeSuggester } from "../autosuggest";
import { getUpdatedLocationIdPath } from "../../routing/navigator";

class TrackDetailModalUnconnected extends React.PureComponent {
  render() {
    const {
      selectedTrackScid,
      selectedTrackData,
      prevTrackLink,
      nextTrackLink,
      windowDotLocation, // ensure we render when this changes.
      fetchTrack
    } = this.props;
    if (!selectedTrackScid) {
      return null;
    }

    const OtherTracksFinder = makeSuggester(
      "nsSoundcloudDetail",
      "/api/soundcloud_tracks",
      this.props.handleSelectComparisonTrack,
      undefined,
      {}
    );

    const metrics = [
      { index: 1, label: "Plays" },
      { index: 2, label: "Downloads" },
      { index: 3, label: "Favoritings" },
      { index: 4, label: "Comments" }
    ];

    return (
      <RoutedModal
        isOpen
        isLoadingContent={!selectedTrackData}
        closeLinkTo={windowDotLocation.hash.split("/.m/")[0].substring(1)}
        onAfterOpen={() => fetchTrack(selectedTrackScid)}
      >
        {!selectedTrackData ? null : (
          <>
            <section className="row">
              <SmallLabel>SoundCloud Track:</SmallLabel>
              {prevTrackLink && (
                <Link
                  className="popupNavLink popupNavLinkPrev"
                  to={prevTrackLink}
                >
                  Previous Track
                </Link>
              )}
              {nextTrackLink && (
                <Link
                  className="popupNavLink popupNavLinkNext"
                  to={nextTrackLink}
                >
                  Next Track
                </Link>
              )}
            </section>
            <section
              className="row"
              style={{ backgroundColor: "#f4f4f4", color: "black" }}
            >
              <div className="title">
                <BigTitle>{selectedTrackData.title}</BigTitle>
                <div>{selectedTrackData.genre}</div>
                <div>
                  Uploaded{" "}
                  {moment(selectedTrackData.created_at).format("D MMM, YYYY")}
                  <span>
                    {" "}
                    ({moment(selectedTrackData.created_at).fromNow()})
                  </span>
                </div>
              </div>
              <img
                alt=""
                src={selectedTrackData.artwork_url}
                height="120"
                width="120"
              />
              <iframe
                title="modal soundcloud player"
                width="200"
                height="120"
                scrolling="no"
                frameBorder="no"
                allow="autoplay"
                src={
                  "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/" +
                  selectedTrackData.scid +
                  "&color=%23ff5500&auto_play=false&hide_related=true&show_comments=true&show_user=false&show_reposts=false&show_teaser=true&visual=false"
                }
              />
            </section>
            <MetricsGraph
              {...{
                selectedTrackData,
                metrics,

                comparisons: this.props.soundcloudComparisons,
                handleRemoveComparison: this.props.removeScTrackFromComparison,
                ComparisonFinderComponent: OtherTracksFinder
              }}
            />
          </>
        )}
      </RoutedModal>
    );
  }
}

function selectTrackScid(state) {
  return +(state.ui.location.matchParams.track_scid || 0);
}

const selectTrackData = createSelector(
  selectTrackScid,
  state => state.domain.scTrackData.tracksIndex,
  (scid, index) => (scid ? index[scid] : null)
);

const selectSoundcloudComparisonTracks = createSelector(
  state => state.ui.soundcloudComparisons,
  state => state.domain.scTrackData.tracksIndex,
  (scids, tracksIndex) => {
    return scids.reduce((acc, s) => {
      if (tracksIndex[s]) acc.push(tracksIndex[s]);
      return acc;
    }, []);
  }
);

const selectNextAndPrevScTrack = createSelector(
  selectArtist,
  selectTrackScid,
  (artist, trackScid) => {
    if (artist && artist.soundCloudTrackData) {
      const tracks = artist.soundCloudTrackData.tracks;
      for (let i = 0; i < tracks.length; i++) {
        if (tracks[i].scid === trackScid) {
          return {
            previous:
              i > 0 ? getUpdatedLocationIdPath(tracks[i - 1].scid) : null,
            next:
              i < tracks.length - 1
                ? getUpdatedLocationIdPath(tracks[i + 1].scid)
                : null
          };
        }
      }
    }
    return {
      previous: null,
      next: null
    };
  }
);

export const TrackDetailModal = connect(
  state => {
    const trackLinks = selectNextAndPrevScTrack(state);
    return {
      // ...state,
      selectedTrackScid: selectTrackScid(state),
      selectedTrackData: selectTrackData(state),
      prevTrackLink: trackLinks.previous,
      nextTrackLink: trackLinks.next,
      comparisonLoading: state.ui.scComparisonLoading,
      soundcloudComparisons: selectSoundcloudComparisonTracks(state),
      windowDotLocation: window.location
    };
  },
  dispatch => {
    return {
      fetchTrack: scid =>
        dispatch({
          type: "fetchScTrackDetails",
          api: { resource: "/api/soundcloud_tracks/" + scid }
        }),
      handleSelectComparisonTrack: ({ scid }) => {
        dispatch({
          type: "soundcloudComparisons/add",
          scid: scid
        });
        dispatch({
          type: "fetchScTrackDetails",
          api: { resource: "/api/soundcloud_tracks/" + scid }
        });
      },
      removeScTrackFromComparison: scid => {
        dispatch({
          type: "soundcloudComparisons/remove",
          scid: scid
        });
      }
    };
  }
)(TrackDetailModalUnconnected);
