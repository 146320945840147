const Rollbar = window.Rollbar;


export function rollbarMiddleware() {
  if (Rollbar) {
    Rollbar.configure({
      enabled: process.env.NODE_ENV === "production",
      payload: {
        environment: process.env.NODE_ENV
      }
    });
  }

  return store => next => action => {
    try {
      return next(action);
    } catch (err) {
      if (Rollbar) {
        console.error('Rollbar middleware caught an exception!', err);
        Rollbar.error(err, {
          actionType: (action||{}).type,
          actionApi: (action||{}).api,
        });
      }
      throw err;
    }
  };
}
