import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { actions } from "../containers/modals/ScoutInfoOverlay";

import fallbackImage from "../images/user-icon@2x.png";
import {useReduxDispatch} from "../reducers/ReduxHooks";
import {sourceIconMap} from "../styles/styleConsts";
import {transparentize} from "polished";

const Avatar = ({
  name,
  username,
  thumbnail,
  type,
  colour,
  size,
  onClick,
  withHover,
}) => {
  return (
    <Styled
      type={type}
      colour={colour || sourceIconMap[type][1]}
      size={size}
      onClick={onClick}
      className={withHover ? 'hoverable' : ''}
    >
      <img
        width={size === 'huge' ? 50 : size === 'big' ? 30 : 21}
        height={size === 'huge' ? 50 : size === 'big' ? 30 : 21}
        alt={name}
        src={
          thumbnail ||
          `https://twitter.com/${username}/profile_image?size=normal`
        }
        onError={e => {
          if (e.target.src !== fallbackImage) {
            e.target.src = fallbackImage;
            e.target.style.backgroundColor = "#cccccc";
          }
        }}
      />
      {size === 'huge' && (
        <span className="nameLabel">{name.length > 40 ? name.substring(0, 38) + '...' : name}</span>
      )}
    </Styled>
  );
};

export const AvatarContainer = React.memo(function AvatarContainer({scoutList, allowedScoutsMap=null}) {
  const dispatcher = useReduxDispatch();

  return scoutList.map(
    ({source, id, name, avatar}, i) => (
      !allowedScoutsMap || allowedScoutsMap[source][id] ? <Avatar
        key={i}
        name={name}
        thumbnail={avatar}
        colour={(sourceIconMap[source]||[])[1]}
        size={'small'}
        withHover={true}
        onClick={() => {
          dispatcher(actions.selectInfluencer(source, id));
          dispatcher(actions.fetchInfluencerDetails(source, id));
        }}
      /> : null
    )
  )

});

const ConnectedAvatar = connect(
  null,
  (dispatch, ownProps) => {
    return {
      onClick: () => {
        if (ownProps.source && ownProps.id) {
          dispatch(actions.selectInfluencer(ownProps.source, ownProps.id));
          dispatch(actions.fetchInfluencerDetails(ownProps.source, ownProps.id));
        }
      }
    };
  }
)(Avatar);

export default ({
  data: { id, name, username, thumbnail, avatar, type, source, colour },
  size
}) => (
  <ConnectedAvatar
    {...{
      name,
      username,
      thumbnail: thumbnail || avatar,
      type: type || source,
      colour,
      size
    }}
    source={type || source}
    id={id}
    withHover={!!id}
  />
);

const Styled = styled.div`
  cursor: default;
  border-radius: 100%;
  font-size: 9px;
  font-weight: bold;
  text-align: center;
  display: inline-block;
  width: ${props => (props.size === "huge" ? 50 : props.size === "big" ? 30 : 21)}px;
  height: ${props => (props.size === "huge" ? 50 : props.size === "big" ? 30 : 21)}px;
  border: 2px solid ${props => props.colour};
  overflow: visible;
  margin-right: ${props => (props.size === "huge" ? -7 : props.size === "big" ? -7 : -5)}px;
  margin-bottom: ${props => (props.size === "huge" ? -14 : props.size === "big" ? -14 : -10)}px;
  position: relative;
  transition: transform 0.3s, translateY 0.3s;
  transform: scale(1);
  
  &.hoverable:hover {
    transform: scale(1.3) translateY(2px);
    z-index: 10;
    text-align: center;
    
    .nameLabel {
      display: block;
    }
  }
  
  img {
    display: block;
    width: 100%;
    height: 100%;
    padding-top: 0;
    border-radius: 100%;
    background-color: #fff;
  }
  
  .nameLabel {
    display: none;
    position: absolute;
    left: 50%;
    top: 45px;
    padding: 2px;
    border-radius: 1px 3px;
    transform: translateX(-50%);
    background-color: ${props => transparentize(0.2, props.colour)};
  }
`;


