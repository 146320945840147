import React, { useState } from "react";
import { connect } from "react-redux";
import hash from "object-hash";
import numeral from "numeral";
import { Tagger } from "../components/multi_select";
import ResultsPage from "../components/results_page";
import {ConnectedScrollTable, useChartPaginatedFetch} from "../components/charts/ScrollFetchTable"
import {
  ChartFilters,
  SliderControl,
  SortBy,
  StyledFilters
} from "../components/chart_filters";
import country_mapping from "../components/primitives/country_mapping";
import {SHORT_ROW_HEIGHT, UniversalRowItem} from "../components/charts";

const DEFAULT_FILTERS = {
  includeSigned: false,
  playlistIds: null, // The selector will overwrite null with the user's playlists.
  selectedGenres: [],
  minFollowers: 0,
  maxFollowers: 1e6,
  minPublishedDaysAgo: 0,
  maxPublishedDaysAgo: 30,
  minTotalPlaylistFollowers: 0,
  maxTotalPlaylistFollowers: 1e9,
  minTrackPopularity: 0,
  maxTrackPopularity: 100,
  minArtistPopularity: 0,
  maxArtistPopularity: 100,
  top200CountryCodes: []
};

function createSettingsObject(filters, sort) {
  filters = { ...DEFAULT_FILTERS, ...filters };
  sort = sort || "popularity";
  return {
    filters,
    sort,
    key: hash.MD5({ filters, sort })
  };
}

const SpyFilters = ({
  initialSettings,
  playlistData,
  allGenres,
  allChartCountries,
  handleSave
}) => {
  // This internal state is for the SortBy section, we hold the filters so that we can
  // update the view when sort changes.
  // xxx: this state can probably be lifted a level higher.
  const [sort, setSort] = useState(initialSettings.sort);
  const [filters, setFilters] = useState(initialSettings.filters);

  const sortChoices = [
    { label: "Newest", value: "new" },
    { label: "Popularity", value: "popularity" },
    { label: "Playlist Size", value: "playlist" }
  ];
  const dayMapping = [0, 1, 2, 5, 10, 30, 60, 120, 1000];
  const popMapping = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
  // prettier-ignore
  const artistFollowerMapping = [0, 10, 20, 30, 50, 100, 500, 1e3, 3e3, 5e3, 1e4, 3e4, 5e4, 1e5, 3e5, 5e5, 1e6, 1e9];
  const playlistFollowerMapping = [0, 1e4, 5e4, 1e6, 5e6, 1e7, 5e7, 1e9];
  return (
    <StyledFilters>
      <SortBy
        sortKey={sort}
        choices={sortChoices}
        handleChange={choice => {
          setSort(choice.value);
          handleSave(filters, choice.value);
        }}
      />
      <ChartFilters
        handleSave={filters => {
          handleSave(filters, sort);
        }}
        handleUpdate={filters => {
          setFilters(filters);
        }}
        initialValues={initialSettings.filters}
        render={(currentValues, onModify, revertCount, isModified) => {
          return (
            <React.Fragment>
              <div className="filterControl filterReleaseDate">
                <SliderControl
                  title="Release Date"
                  mapping={dayMapping}
                  minVal={currentValues.minPublishedDaysAgo}
                  maxVal={currentValues.maxPublishedDaysAgo}
                  minValStateKey="minPublishedDaysAgo"
                  maxValStateKey="maxPublishedDaysAgo"
                  onChange={onModify}
                  textDisplay={function textDisplay(minVal, maxVal, mapping) {
                    const minAllowedValue = mapping[0];
                    const maxAllowedValue = mapping[mapping.length - 1];
                    const formattedMin = numeral(minVal).format("0,0");
                    const formattedMax = numeral(maxVal).format("0,0");
                    return minVal > minAllowedValue && maxVal < maxAllowedValue
                      ? `Between ${formattedMin} and ${formattedMax} days ago`
                      : minVal > minAllowedValue
                      ? `More than ${formattedMin} days ago`
                      : maxVal < maxAllowedValue
                      ? `Less than ${formattedMax} days ago`
                      : "Anytime";
                  }}
                />
              </div>
              <div className="filterControl filterFollowers">
                <SliderControl
                  title="Spotify Followers"
                  mapping={artistFollowerMapping}
                  minVal={currentValues.minFollowers}
                  maxVal={currentValues.maxFollowers}
                  minValStateKey="minFollowers"
                  maxValStateKey="maxFollowers"
                  onChange={onModify}
                />
              </div>
              <div className="filterControl filterTrackPop">
                <SliderControl
                  title="Track Popularity"
                  mapping={popMapping}
                  minVal={currentValues.minTrackPopularity}
                  maxVal={currentValues.maxTrackPopularity}
                  minValStateKey="minTrackPopularity"
                  maxValStateKey="maxTrackPopularity"
                  onChange={onModify}
                />
              </div>
              <div className="filterControl filterArtistPop">
                <SliderControl
                  title="Artist Popularity"
                  mapping={popMapping}
                  minVal={currentValues.minArtistPopularity}
                  maxVal={currentValues.maxArtistPopularity}
                  minValStateKey="minArtistPopularity"
                  maxValStateKey="maxArtistPopularity"
                  onChange={onModify}
                />
              </div>
              <div className="filterControl filterTotalPlaylistFollowers">
                <SliderControl
                  title="Total Playlist Followers"
                  mapping={playlistFollowerMapping}
                  minVal={currentValues.minTotalPlaylistFollowers}
                  maxVal={currentValues.maxTotalPlaylistFollowers}
                  minValStateKey="minTotalPlaylistFollowers"
                  maxValStateKey="maxTotalPlaylistFollowers"
                  onChange={onModify}
                />
              </div>
              <div className={"filterControl filterSigned"}>
                <label className={currentValues.includeSigned ? "checked" : ""}>
                  <input
                    type="checkbox"
                    checked={currentValues.includeSigned}
                    onChange={ev => {
                      onModify({ includeSigned: ev.target.checked });
                    }}
                  />
                  {"Include signed artists"}
                </label>
              </div>
              <Tagger
                label="Top 200 Charts"
                key={revertCount + "top200"}
                domain={allChartCountries.map(code => ({
                  value: code,
                  display:
                    code === "global"
                      ? "Global"
                      : country_mapping[code.toUpperCase()]
                }))}
                initialSelection={currentValues.top200CountryCodes || []}
                className="filterControl filter-top200"
                onChange={top200CountryCodes =>
                  onModify({ top200CountryCodes })
                }
              />
              <Tagger
                label="Playlists"
                key={revertCount + "playlists"}
                domain={playlistData.map(pl => ({
                  value: pl.spyid,
                  display: pl.name
                }))}
                initialSelection={currentValues.playlistIds || []}
                className="filterControl filter-playlists"
                onChange={playlistIds => onModify({ playlistIds })}
              />
              <Tagger
                label="Genres"
                key={revertCount + "genres"}
                domain={allGenres.map(g => ({
                  value: g,
                  display: g
                }))}
                initialSelection={currentValues.selectedGenres || []}
                className="filterControl filter-genres"
                onChange={selectedGenres => onModify({ selectedGenres })}
              />
            </React.Fragment>
          );
        }}
      />
    </StyledFilters>
  );
};

function SpotifyTable ({ currentSettings }) {
  const paginator = useChartPaginatedFetch(
    "/api/charts/SpotifyOlap?use_cache_only=1&refresh_cache=1",
    currentSettings,
  );
  const RenderRow = (props) => <UniversalRowItem
    {...props}
    displaySettings={{
      includeTrackStats: true,
      includeScouts: false,
      includeAlerts: false,
      shortRow: true
    }} />;
  return (
    <ConnectedScrollTable
      pageFetcher={paginator}
      RenderFunction={RenderRow}
      scrollFetchProps={{
        rowHeight: SHORT_ROW_HEIGHT,
        containerStyle: {
          height: "calc(100vh - 110px)"
        }
      }}
    />
  );
}

const connectSpotifyDomain = connect(state => ({
  currentUser: state.user.currentUser,
  playlistData: Object.values(state.domain.spyPlaylistData.index),
  allGenres: state.domain.spyGenres,
  // prettier-ignore
  allChartCountries: ["global", "AU", "BR", "CA", "DE", "DK", "FI", "FR", "GB", "IE", "IT", "MX", "NZ", "SE", "US"]
}));

const Spotify = React.memo(function Spotify({
  playlistData,
  allGenres,
  allChartCountries,
  currentUser
}) {
  const playlistIds = playlistData
    .filter(pl => pl.is_users)
    .map(pl => pl.spyid);
  const initialSettings = createSettingsObject({ playlistIds }, "new");

  const [currentSettings, setCurrentSettings] = useState(initialSettings);

  const Filters = () => {
    return (
      <SpyFilters
        initialSettings={currentSettings}
        playlistData={playlistData}
        allGenres={allGenres}
        allChartCountries={allChartCountries}
        handleSave={(newFilters, newSort) => {
          const newSettings = createSettingsObject(newFilters, newSort);
          console.log("Updating settings", newSettings);
          setCurrentSettings(newSettings);
        }}
      />
    );
  };

  return (
    <React.Fragment>
      <ResultsPage
        pageId="spotify"
        pageTitle="Spotify"
        filters={<Filters />}
        loading={false}
        loaded={true}
        settingsLink="/spotify/settings"
        currentUser={currentUser}
      >
        <SpotifyTable currentSettings={currentSettings} />
      </ResultsPage>
    </React.Fragment>
  );
});

export default connectSpotifyDomain(Spotify);
