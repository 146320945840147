import React from "react";
import {COMPONENT_ELEMENT_COLOR, StyledControls} from "../styles/styleConsts";
import RadioGroup from "./radio";
import ReactSlider from "react-slider";
import numeral from "numeral";
import styled from "styled-components";
import * as polished from "polished";
import ErrorBoundary from "./error_boundary";
import { Button } from "./primitives/inputs";
import { StyledSidebarFilters } from "./StyledSidebar";
import {EMPTY_OBJ} from "../consts";

/** Filter controls for charts */

const FilterTitle = ({ title, tooltipText }) => {
  return tooltipText ? (
      <span className="filterTitle tooltip" data-tooltip={tooltipText}>{title}:</span>

  ) : (
    <span className="filterTitle">{title}:</span>
  );
};


export const StyledFilters = styled(StyledSidebarFilters)`
  .FilterMainTitle {
    margin: 0 0 10px 0;
    font-size: 14px;
  }
  

  
  .filterDaysTrackedValues {
    text-align: center;
  }

  .sliderContainer {
    height: 50px;
    width: 90%;
    margin: 0 auto;
  }

  .bar {
    height: 8px;
    //border: solid #bbb;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  }

  .bar-0 {
    background: linear-gradient(to bottom, #eee, #ddd);
    border-radius: 4px 0 0 4px;
    border-width: 1px 0 1px 1px;
  }
  .bar-2 {
    background: linear-gradient(to bottom, #eee, #ddd);
    border-radius: 0 4px 4px 0;
    border-width: 1px 1px 1px 0;
  }
  .bar-1 {
    //background: linear-gradient(to bottom, #8abde2, #80afd5);
    background: ${COMPONENT_ELEMENT_COLOR};
    border-width: 1px 0 1px 0;
    border-color: #80afd5;
    z-index: 1;
  }
  .sliderSingleContainer .bar-1 {
    //background: linear-gradient(to bottom, #eee, #ddd);
    background: #eee;
  }
  .sliderSingleContainer .bar-0 {
    //background: linear-gradient(to bottom, #8abde2, #80afd5);
    background: ${COMPONENT_ELEMENT_COLOR};
  }
  .sliderHandle {
    background: radial-gradient(circle at 30% 30%, #cfcfcf, #c4c4c4);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
    border: 1px solid #aaa;
    border-radius: 0 100% 100% 100%;
    color: white;
    text-align: center;
    vertical-align: middle;
    width: 21px;
    height: 21px;
    margin-top: 9px;
    cursor: pointer;
    transform: rotate(45deg);
  }

  .sliderHandleLeft {
    margin-left: -10px;
  }

  .active .sliderHandle,
  .sliderHandle:hover {
    background: radial-gradient(circle at 30% 30%, #affff7, #8eeee5);
  }

  .filterSigned label {
    padding: 5px;
    display: block;
  }
  label.checked {
    background-color: ${polished.lighten(0, "#80afd5")};
    color: white;
  }

  .tagger .selected-tags .choice {
    // the playlists bg
    background-color: ${polished.lighten(0, "#80afd5")};
  }
  .tagger header,
  .filterTitle,
  .searcher h3 {
    font-weight: normal;
    color: #999;
  }

  .filter-genres {
    margin-bottom: 300px;
  }

  .changesButtons {
    min-height: 70px;
    margin-left: -20px;
    margin-bottom: 10px;
    padding: 2px 20px;
    width: calc(100% + 40px);
    background: rgba(240, 240, 240, 1); /* copied, should reuse */
    z-index: 5; /* sliders go above otherwise */

    border-bottom: 1px solid #bbb;
    //border-top: 1px solid #bbb;

    .changesInfoText {
      opacity: 0.4;
      font-size: 0.8em;
      text-align: center;
      padding-top: 12px;
      display: block;
    }

    &.modified {
      position: sticky;
      top: -20px;
    }

    button {
      margin: 2px auto;
      width: 80%;
      display: block;
      background-color: ${COMPONENT_ELEMENT_COLOR};
    }

    button.secondary {
      background-color: transparent;
      margin-bottom: 0;
    }
  }
`;

export class SortBy extends React.PureComponent {
  render() {
    const { sortKey, handleChange, choices } = this.props;
    return (
      <StyledControls
        style={{
          marginLeft: 0,
          borderBottom: "none",
          paddingBottom: "10px",
          marginBottom: "0"
        }}
      >
        <div className="filterTitle">Sort By:</div>
        <RadioGroup
          labelStyle={{ margin: 0 }}
          defaultSelectedIndex={choices.findIndex(c => c.value === sortKey)}
          choices={choices}
          handleChange={handleChange}
        />
      </StyledControls>
    );
  }
}

export class SingleSliderControl extends React.PureComponent {
  render() {
    const {
      mapping,
      val,
      title,
      stateKey,
      onChange,
      textDisplay = null, // optional override
      tooltipText = null
    } = this.props;

    return (
      <React.Fragment>
        <FilterTitle title={title} tooltipText={tooltipText} />
        <div className="filterDaysTrackedValues">
          {textDisplay
            ? textDisplay(val, mapping)
            : this.textDisplay(val, mapping)}
        </div>
        <div className="sliderContainer sliderSingleContainer">
          <ReactSlider
            withBars={true}
            minDistance={1}
            value={mapping.findIndex(x => x >= val)}
            min={0}
            max={mapping.length - 1}
            onChange={valIx =>
              onChange({
                [stateKey]: mapping[valIx]
              })
            }
          >
            <div className="sliderHandle sliderHandleLeft" />
            {/*<div className="sliderHandle sliderHandleRight" />*/}
          </ReactSlider>
        </div>
      </React.Fragment>
    );
  }

  textDisplay(val, validValues) {
    const maxAllowedValue = validValues[validValues.length - 1];
    const formatted = numeral(val).format("0,0a");
    return val >= maxAllowedValue ? "any" : formatted;
  }
}

export class SliderControl extends React.PureComponent {
  render() {
    const {
      mapping,
      minVal,
      maxVal,
      title,
      tooltipText,
      minValStateKey,
      maxValStateKey,
      onChange,
      textDisplay = null // optional override
    } = this.props;
    return (
      <React.Fragment>
        <FilterTitle title={title} tooltipText={tooltipText} />
        <div className="filterDaysTrackedValues">
          {textDisplay
            ? textDisplay(minVal, maxVal, mapping)
            : this.textDisplay(minVal, maxVal, mapping)}
        </div>
        <div className="sliderContainer">
          <ReactSlider
            withBars
            minDistance={1}
            value={[
              mapping.findIndex(x => x >= minVal),
              mapping.findIndex(x => x >= maxVal)
            ]}
            min={0}
            max={mapping.length - 1}
            onChange={([minIxVal, maxIxVal]) =>
              onChange({
                [minValStateKey]: mapping[minIxVal],
                [maxValStateKey]: mapping[maxIxVal]
              })
            }
          >
            <div className="sliderHandle sliderHandleLeft" />
            <div className="sliderHandle sliderHandleRight" />
          </ReactSlider>
        </div>
      </React.Fragment>
    );
  }

  textDisplay(currentMin, currentMax, validValues) {
    const minAllowedValue = validValues[0];
    const maxAllowedValue = validValues[validValues.length - 1];
    const formattedMin = numeral(currentMin).format("0,0a");
    const formattedMax = numeral(currentMax).format("0,0a");
    return currentMin > minAllowedValue && currentMax < maxAllowedValue
      ? `Between ${formattedMin} and ${formattedMax}`
      : currentMin > minAllowedValue
      ? `More than ${formattedMin}`
      : currentMax < maxAllowedValue
      ? `Less than ${formattedMax}`
      : "Any";
  }
}

export class ChartFilters extends React.PureComponent {
  /* render prop, responsible for managing save, revert. It holds the initial
   * settings and previous changes to allow reverting.
   *
   * Signature for render is:
   * render(currentValues, modify, revertCount, isModified)
   *   - the modify function should be called whenever a filter value changes, it
   *   acts like setState in that it will merge in the keys to the settings object.
   *   modify({foo: "bar"})
   */

  constructor(props) {
    super(props);
    const initialValues = this.props.initialValues || EMPTY_OBJ;

    this.state = {
      initialValues,
      lastSaved: initialValues,
      currentValues: initialValues,
      isModified: false,
      isModifiedFromInitial: false,
      revertCount: 0
    };
  }

  modify = newValues => {
    /** takes a single object, 'newValues', which is keys to update */
    let isModified = false;
    let isModifiedFromInitial = false;

    const initialValues = this.state.initialValues;
    const lastSaved = this.state.lastSaved;
    const updatedValues = { ...this.state.currentValues };

    const allKeys = new Set(Object.keys(updatedValues).concat(Object.keys(newValues)))

    allKeys.forEach(key => {
      const initialValue = initialValues[key];
      const lastSavedValue = lastSaved[key];
      const currentValue = updatedValues[key];
      const newValue = newValues[key];

      if (typeof newValue !== "undefined" && newValue !== currentValue) {
        updatedValues[key] = newValue;
      }
      if (
        (typeof newValue !== "undefined" && newValue !== lastSavedValue) ||
        (typeof newValue === "undefined" && currentValue !== lastSavedValue)
      ) {
        // we check all values, in case we are setting the state back to the initial state
        isModified = true;
      }
      if (
        (typeof newValue !== "undefined" && newValue !== initialValue) ||
        (typeof newValue === "undefined" && currentValue !== initialValue)
      ) {
        // we check all values, in case we are setting the state back to the initial state
        isModifiedFromInitial = true;
      }
    });

    if (!this.state.isModified && isModified) {
      this.setState({ lastSaved: this.state.currentValues });
    }

    this.setState({
      currentValues: updatedValues, // this may be same as before.
      isModified,
      isModifiedFromInitial
    });

    if (this.props.handleUpdate) {
      this.props.handleUpdate(updatedValues);
    }
  };

  handleSave = () => {
    this.props.handleSave(this.state.currentValues);
  };

  render() {
    const { render } = this.props;
    const {
      currentValues,
      isModified,
      isModifiedFromInitial,
      revertCount
    } = this.state;
    return (
      <ErrorBoundary>
        <div className={"changesButtons" + (isModified ? " modified" : "")}>
          {!isModified && !isModifiedFromInitial && <span className="changesInfoText">Adjust settings below</span>}
          {isModified && (
            <React.Fragment>
              <Button
                type="button"
                onClick={() => {
                  this.setState({ isModified: false }, this.handleSave);
                }}
              >
                Apply changes
              </Button>
              <Button
                type="button"
                className="secondary"
                onClick={() => {
                  this.modify(this.state.lastSaved);
                  this.setState(
                    {
                      revertCount: this.state.revertCount + 1
                    },
                    this.handleSave
                  );
                }}
              >
                Undo
              </Button>
            </React.Fragment>
          )}
          {!isModified && isModifiedFromInitial && (
            <React.Fragment>
              <Button
                type="button"
                className="secondary"
                onClick={() =>
                  this.setState(
                    {
                      currentValues: this.state.initialValues,
                      isModified: false,
                      isModifiedFromInitial: false,
                      revertCount: this.state.revertCount + 1
                    },
                    this.handleSave
                  )
                }
              >
                Back to default
              </Button>
            </React.Fragment>
          )}
        </div>
        {render(currentValues, this.modify, revertCount, isModified)}
      </ErrorBoundary>
    );
  }
}
