import styled, {css} from "styled-components";
import {transparentize} from "polished";
import {blackish, primaryAction} from "../../styles/styleConsts";

const inputBase = css`
  padding: 6px 10px;
  border-radius: 3px;
`;

export const TextInput = styled.input`
  ${inputBase}
  margin-right: 10px;
  background-color: ${transparentize(0.96, blackish)};
  border: 1px solid ${transparentize(0.9, blackish)};
  ::placeholder {
    opacity: 0.5;
  }
  ${props => css`
    width: ${props.width || 200}px;
  `}
`;

export const Button = styled.button`
  ${inputBase}
  background-color: ${primaryAction};
  border: 1px solid ${primaryAction};
  color: white;
  font-weight: 400;
  cursor: pointer;
  &:disabled {
    background-color: ${transparentize(0.7, primaryAction)};;
    border-color: ${transparentize(0.6, primaryAction)};;
  }
  
  &.secondary {
    background-color: transparent;
    color: ${primaryAction};
    border: none;
    text-decoration: underline;
  }
`;

export const Label = styled.label`
  margin-right: 20px;
  margin-bottom: 5px;
  display: inline-block;
`;
