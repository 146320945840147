import React from "react";

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    if (window.Rollbar) {
      window.Rollbar.error(error, {error, info});
    }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <span style={{padding: "5px", backgroundColor:'#f5ded7', color:'#d9555a', ...(this.props.style||{})}}>Something went wrong</span>;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;