import {isNumber} from "../../utils";
import {_toKey, artist} from "../data_access";
import {datasourceMapping} from "./index";

const initialState = {
  /* the 'domain model' is under here.  This is like a client side db */
  domain: {
    /**
     * Artists in our domain have multiple unique identifiers (one at each source listed above). There is no source
     * is required to exist, for example an artist may only have a soundcloud id,
     * and another may only have an instagram id. This includes our internal 'arid' id.
     * 'artistDb' holds these artist objects, and also a map ('index') from an id to that artist, handling this
     * fact of multiple ids.  A <source, id> combination is called a 'key'
     */
    artistDb: {
      list: [], // A list of artist objects
      index: {} // an index of different keys (eg. 'sc/123') to the index in the above list that artist
    },

    /**
     * Activity Feeds list are lists of the follows and likes on the social sources (SoundCloud,
     * Twitter, and Instagram).
     */
    activityFeeds: {
      sc: [],
      tw: [],
      in: []
    },

    /* YouTube data */
    ytData: {
      recentVideos: [],
      channels: {}
    },

    /**
     * Influencers are not resolved across the different social sources, so they can be held in simple
     * dictionaries.
     */
    influencers: {
      sc: {},
      in: {},
      tw: {}
    }
  },

  /**
   * The 'application' model that uses the above domain' (mostly
   * the user's settings
   */
  app: {
    user: null,
    whitelistedArtists: [],
    usersInfluencers: {
      sc: [],
      tw: [],
      in: [],
      spy: [],
      yt: []
    }
  },

  /* State of the ui, ideally router/path info would be here too. */
  ui: {
    currentArtistIndex: null,
    displayedErrors: []
  }
};

function _keyList(artist) {
  let keyList = [artist.query_key];

  if (artist.cache_status === 'LOADING') {
    return keyList;
  }

  if (artist.otherInids) {
    keyList = keyList.concat(artist.otherInids.map(_toKey.bind(null, 'in')));
  }

  if (artist.keys) {
    return Array.from(new Set(artist.keys.concat(keyList)));
  } else if (artist.links) {
    // returns list ['sc/123', 'in/foobar'] from artist { sourceIds: { in:'foobar', sc:123, tw:null }}
    const aridLink = artist.arid ? [_toKey("a", artist.arid)] : [];
    // older version of artist object.
    return Array.from(new Set(
      keyList.concat(aridLink).concat(
        artist.links.map(link => _toKey(link.source, link.id))
      )
    ))
  } else {
    console.error("Unknown artist type, cannot create keylist", artist);
    return [];
  }

}

/**
 * Inserts or updates (mutates) the list and index with the artistData.
 */
function _upsertArtistIntoListAndIndex(artistData, list, index) {
  const keys = _keyList(artistData);
  if (!artistData.keys) {
    artistData.keys = keys;
  }
  const existingKey = keys.find(k => isNumber(index[k]));
  if (existingKey) {
    const existingIndex = index[existingKey];
    // Merge to existing record, and add any new index keys.
    list[existingIndex] = {...list[existingIndex], ...artistData};
    keys.forEach(k => (index[k] = existingIndex));
  } else {
    // Insert into list, and add to the index.
    const newIndex = list.length;
    list.push(artist(artistData));
    keys.forEach(k => (index[k] = newIndex));
  }
}

export function reduceArtistDb(state = initialState["domain"]["artistDb"], action) {
  const list = [...state.list];
  const index = {...state.index};

  switch (action.type) {
    case "fetchArtistCompleted":
    case "addToMyWhitelistCompleted":
    case "removeFromMyWhitelistCompleted":
    case "fetchUpdatedArtistCompleted":
    case "manageArtistSources/upsertCompleted":
      _upsertArtistIntoListAndIndex(action.data, list, index);
      return {...state, list, index};

    case "spotify/fetchTrackCompleted":
      _upsertArtistIntoListAndIndex(action.data.artist, list, index);
      return {...state, list, index};

    case "charts/fetchPageCompleted":
    case "charts/newChartResultsCompleted":
      // this is connected from the useChartPaginatedFetch hook
      (action.data.results||[]).forEach(a => {
        if (a.artist) {
          _upsertArtistIntoListAndIndex(a.artist, list, index);
        }
      });
      return {...state, list, index};

    case "artistBrainsCompleted":
      const brains = action.data.brains;
      _upsertArtistIntoListAndIndex(
          {
            ...action.data,
            brains
          },
          list,
          index
      );
      return {...state, list, index};

    case "getCachingArtistsCompleted":
    case "explorer/artistsCompleted":
      action.data.artists.forEach(a => {
        _upsertArtistIntoListAndIndex(a.artist, list, index);
      });
      return {...state, list, index};

    case "fetchMyWhitelistCompleted":
      action.data.artists.forEach(a => {
        _upsertArtistIntoListAndIndex(
            {
              ...a.artist,
              arid: a.arid
            },
            list,
            index
        );
      });

      return {...state, list, index};

    case "fetchDatasourceCompleted":
      if (datasourceMapping[action.datasource]) {
        Object.values(action.data.artists || {}).forEach(a => {
          _upsertArtistIntoListAndIndex(
              {
                avatar: a.thumbnail,
                links: [
                  {id: a.id, source: datasourceMapping[action.datasource]}
                ],
                name: a.name
              },
              list,
              index
          );
        });
      }
      return {...state, list, index};

    case "fetchMoreArtistsCompleted":
      if (datasourceMapping[action.datasource]) {
        Object.values(action.data.artists || {}).forEach(a => {
          _upsertArtistIntoListAndIndex(
              {
                avatar: a.thumbnail,
                links: [
                  {id: a.id, source: datasourceMapping[action.datasource]}
                ],
                name: a.name
              },
              list,
              index
          );
        });
      }
      return {...state, list, index};

    default:
      return state;
  }
}
