import React, {useState} from "react";
import {connect} from "react-redux";
import hash from "object-hash";
import {Tagger} from "../components/multi_select";
import ResultsPage from "../components/results_page";
import {ConnectedScrollTable, useChartPaginatedFetch} from "../components/charts/ScrollFetchTable"
import {ChartFilters, StyledFilters} from "../components/chart_filters";
import {SHORT_ROW_HEIGHT, UniversalRowItem} from "../components/charts";
import {createSelector} from "reselect";
import country_mapping from "../components/primitives/country_mapping";
import Searcher from "../components/searcher";

const countries = Object.entries(country_mapping).map(([code, name]) => ({
  value: code,
  display: name
})).filter(x => ['QX', 'QZ', 'QM', '00'].indexOf(x.value) === -1).sort((c1, c2) => {
  return (-1000*['DE', 'FR', 'AU', 'CA', 'GB', 'US'].indexOf(c1.value))
       - (-1000*['DE', 'FR', 'AU', 'CA', 'GB', 'US'].indexOf(c2.value))
       + c1.display.localeCompare(c2.display);
});

const DEFAULT_FILTERS = {
 countries: [],
 nameStartsWith: null,
};

function createSettingsObject(filters, sort) {
  filters = { ...DEFAULT_FILTERS, ...filters };
  sort = sort || "popularity";
  return {
    filters,
    sort,
    key: hash.MD5({ filters, sort })
  };
}

const ScFilters = ({
  initialFilters,
  handleSave
}) => {
  // const [filters, setFilters] = useState(initialFilters);

  return (
    <StyledFilters>
      <ChartFilters
        handleSave={filters => {
          handleSave(filters);
        }}
        handleUpdate={filters => {
          // setFilters(filters);
        }}
        initialValues={initialFilters}
        render={(currentValues, onModify, revertCount, isModified) => {
          return (
            <React.Fragment>
              <Searcher
                label="Artist Name"
                value={currentValues.nameStartsWith}
                className="filter-name"
                onChange={nameStartsWith => onModify({nameStartsWith})}
              />
              <Tagger
                label="Country"
                key={revertCount + "country"}
                domain={countries}
                initialSelection={currentValues.countries || []}
                className="filterControl filter-top200"
                onChange={countries => onModify({ countries })}
              />
              {/*<Tagger*/}
              {/*  label="Scouts"*/}
              {/*  key={revertCount + "playlists"}*/}
              {/*  domain={playlistData.map(pl => ({*/}
              {/*    value: pl.spyid,*/}
              {/*    display: pl.name*/}
              {/*  }))}*/}
              {/*  initialSelection={currentValues.playlistIds || []}*/}
              {/*  className="filterControl filter-playlists"*/}
              {/*  onChange={playlistIds => onModify({ playlistIds })}*/}
              {/*/>*/}
            </React.Fragment>
          );
        }}
      />
    </StyledFilters>
  );
};

function Table ({ paginator, allowedScoutsMap }) {

  const RenderRow = (props) => <UniversalRowItem
    {...props}
    displaySettings={{
      includeTrackStats: false,
      includeScouts: true,
      includeAlerts: false,
      shortRow: true,
      allowedScoutsMap,
    }} />;
  return (
    <ConnectedScrollTable
      pageFetcher={paginator}
      RenderFunction={RenderRow}
      scrollFetchProps={{
        rowHeight: SHORT_ROW_HEIGHT,
        containerStyle: {
          height: "calc(100vh - 110px)"
        }
      }}
    />
  );
}

const selectAllowedScoutsMap = createSelector(
  state => state.user.currentUser.scouts['sc'],
  state => state.user.currentUser.scouts['in'],
  state => state.user.currentUser.scouts['tw'],
  (sc, _in, tw) => {
    return {
      'sc': sc.reduce((acc, s) => { acc[s.scid] = true; return acc }, {}),
      'in': _in.reduce((acc, s) => { acc[s.inid] = true; return acc }, {}),
      'tw': tw.reduce((acc, s) => { acc[s.eid] = true; return acc }, {}),
    };
  }
);

const connectDomain = connect(state => ({
  allowedScoutsMap: selectAllowedScoutsMap(state)
}));

export const SoundCloudScoutings = connectDomain(function SoundCloudScoutings({allowedScoutsMap}) {

  const [currentSettings, setCurrentSettings] = useState({filters: DEFAULT_FILTERS});

  const paginator = useChartPaginatedFetch(
    "/api/charts/SoundCloudMyScoutings?use_cache_only=1&refresh_cache=1",
    currentSettings,
  );

  const Filters = () => {
    return (<ScFilters
      initialFilters={currentSettings.filters}
      handleSave={(newFilters, newSort) => {
        const newSettings = createSettingsObject(newFilters, newSort);
        console.log("Updating settings", newSettings);
        setCurrentSettings(newSettings);
      }}
    />);
  };

  return (
    <React.Fragment>
      <ResultsPage
        pageId="soundcloud"
        pageTitle="SoundCloud Scoutings"
        filters={<Filters />}
        loading={false}
        loaded={true}
        settingsLink="/soundcloud/settings"
      >
        <Table paginator={paginator} allowedScoutsMap={allowedScoutsMap} />
      </ResultsPage>
    </React.Fragment>
  );
});

