import {matchPath} from "react-router-dom";
import {ROUTE_CONFIG} from "./routes";

/**
 * This is still a proof of concept for putting the location in the redux store, rather that using
 * withRouter() in places.
 */
export function configureStore(store) {
  if (window.__hashReduxListener) {
    window.removeEventListener('hashchange', window.__hashReduxListener);
  }
  window.__hashReduxListener = function ({newURL, oldURL}) {
    store.dispatch({type: "location/changed", newUrl: newURL, oldUrl: oldURL, payload: parseLocationObject(newURL)});
  };
  window.addEventListener('hashchange', window.__hashReduxListener);
  store.dispatch({type: "location/init", url: window.location.href, payload: parseLocationObject(window.location.href)});
}

function parseHash(url) {
  return url.indexOf('#') !== -1 ? url.substring(url.indexOf('#')+1) : '';
}

function parseRouteMatchParams(url) {
  for (var i=0; i<ROUTE_CONFIG.length; i++) {
    let {path, exact=true, pageId} = ROUTE_CONFIG[i];
    let match = matchPath(url, {path, exact});
    if (match) {
      return {match, pageId};
    }
  }
  return {}; // should make it easier to user this ui.location.match.foo ...  foo can be undefined..
}

function parseQueryObject(matchParams) {
  if (!matchParams || !matchParams.query || matchParams.query.length < 4) {
    return {};
  }
  const query = matchParams.query;
  const commandCode = query.substring(0, 2), encoded = query.substring(2);
  if (commandCode === '!j') {
    return JSON.parse(atob(encoded));
  } else {
    console.warn("Unkown commandCode in hash: ", commandCode);
    return {};
  }
}

function parseLocationObject(url) {
  const hash = parseHash(url);
  const routeMatch = parseRouteMatchParams(hash);
  const pageId = routeMatch.pageId;
  const matchParams = (routeMatch.match || {}).params || {};
  return {
    url,
    hash,
    pageId,
    matchParams,
    queryObject: parseQueryObject(matchParams),
  };
}

export function reducer(state={}, action) {
  switch (action.type) {
    case "location/init":
      return action.payload;
    case "location/changed":
      return action.payload;
    default:
      return state;
  }
}

