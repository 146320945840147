import React from "react";
import moment from "moment";
import numeral from "numeral";
import initials from "initials";
import styled from "styled-components";
import _ from "lodash";
import { Link } from "react-router-dom";

import { Tagger } from "../components/multi_select";
import countryMapping from "../components/primitives/country_mapping";

import StyledReactVirtualizedTable from "../components/styled_reactvirtualized_table";
import { Column, WindowScroller } from "react-virtualized";
import Avatar from "../components/avatar";

import ResultsPage from "../components/results_page";
import {
  ExternalLink,
  TrackArtist,
  TrackLink,
  TrackName,
  TrackWords,
} from "../components/track_link";
import { blackish, sourceIconMap, space } from "../styles/styleConsts";
import Searcher from "../components/searcher";
import categoryColour from "../styles/category_colour";
import { transparentize } from "polished";
import { Flag } from "../components/flag_icons";
import { createSelector } from "reselect";
import { StyledSidebarFilters } from "../components/StyledSidebar";
import { datasourceMapping } from "../reducers/domain";
import { imgWithFallback } from "../components/img";
import fallbackImage from "../images/user-icon@2x.png";

const CategoryLabel = styled.div`
  opacity: ${props => (props.selected ? "1" : "0.3")};
  margin-bottom: ${space}px;
  border: 0 solid black;
  padding: 0;
  cursor: pointer;
  font-weight: bold;
  color: ${blackish};
  border-left: 6px solid ${props => props.colour};
  :hover {
    opacity: 1;
  }
  b {
    display: block;
    vertical-align: middle;
    padding: 0 ${space}px;
  }
  small {
    font-weight: normal;
    color: ${transparentize(0.5, blackish)};
  }
`;

const CategoryDiv = styled.div``;

const CategoryChooser = ({
  categoryFilter,
  filter,
  total_recent,
  categories,
}) => {
  return (
    <CategoryDiv>
      <CategoryLabel
        selected={!categoryFilter}
        colour={blackish}
        onClick={() => filter("categoryFilter", null)}
      >
        <b>
          All Scouts
          <br />
          <small>Recent: {total_recent}</small>
        </b>
      </CategoryLabel>
      {categories.map(category => (
        <CategoryLabel
          key={category.name}
          selected={categoryFilter === category.name}
          colour={category.colour}
          onClick={() => filter("categoryFilter", category.name)}
        >
          <b>
            {category.name}
            <br />
            <small>Recent: {category.recent_count}</small>
          </b>
        </CategoryLabel>
      ))}
    </CategoryDiv>
  );
};

const Results = props => {
  const pageIdMappingForArtistUrl = {
    twitter: "tw",
    soundcloud: "sc",
    instagram: "in",
    spotify: "spy",
    youtube: "yt",
  };

  return (
    <ResultsPage
      pageId={props.pageId}
      pageTitle={props.pageTitle}
      settingsLink={props.settingsLink || props.pageId + "/settings"}
      currentUser={props.currentUser}
      loading={props.loading}
      loaded={props.loaded}
      customSystemMessage={
        props.pageId === "instagram"
          ? "We are having issues collecting large number of Instagram scouts. New scoutings may be slow to come in.  We are working on a fix with highest priority."
          : null
      }
      filters={<Filters {...props} />}
    >
      <ResultsTable
        rows={props.rows}
        source={pageIdMappingForArtistUrl[props.pageId]}
        selectInfluencer={props.selectInfluencer}
      />
    </ResultsPage>
  );
};

const ArtistImg = imgWithFallback(fallbackImage, "#ccc");

export const ArtistNameCell = ({ artist, source }) =>
  artist.type === "SUBHEADING" ? (
    <SubHeadingText>{moment(artist.title).format("Do MMM")}</SubHeadingText>
  ) : (
    <TrackLink>
      <ExternalLink href={artist.url}>
        <b>⬈</b>
      </ExternalLink>
      <ArtistImg
        alt=""
        src={artist.thumbnail}
        style={{ border: "1px solid #444" }}
      />
      <Link to={`/artist/${source}/${artist.artist_id}`}>
        <TrackWords>
          <TrackName>{artist.name}</TrackName>
          <TrackArtist>
            {artist.country_code && artist.country_code !== "00" ? (
              <Flag
                code={artist.country_code}
                style={{ zoom: 0.6, marginRight: "5px" }}
              />
            ) : null}
            @{artist.username}
          </TrackArtist>
        </TrackWords>
      </Link>
    </TrackLink>
  );

class ResultsTable extends React.PureComponent {
  render() {
    const { rows, source } = this.props;
    return (
      <WindowScroller>
        {propsb => {
          return (
            <StyledReactVirtualizedTable
              rowHeight={50}
              rowGetter={({ index }) => rows[index]}
              rowCount={rows.length}
              headerHeight={42}
              width={propsb.width - 300}
              height={propsb.height - 100}
              noRowsRenderer={() => (
                <div
                  style={{
                    fontStyle: "italic",
                    padding: "30px",
                    textAlign: "center",
                    color: "#bbb",
                  }}
                >
                  No artists found for your current scouts and filters.
                </div>
              )}
            >
              <Column
                label="Artist"
                dataKey="artist"
                cellDataGetter={wholeRowData}
                cellRenderer={({ cellData }) => (
                  <ArtistNameCell artist={cellData} source={source} />
                )}
                flexGrow={3}
                width={180}
              />
              <Column
                label="New Scouts"
                dataKey="new_follows"
                cellDataGetter={wholeRowData}
                cellRenderer={({ cellData }) => (
                  <RecentFollowsCell artist={cellData} source={source} />
                )}
                flexGrow={1}
                width={80}
              />
              <Column
                label="Total Scouts"
                dataKey="total_influencers"
                cellDataGetter={wholeRowData}
                cellRenderer={({ cellData }) => (
                  <TotalInfluencersCell artist={cellData} source={source} />
                )}
                flexGrow={1}
                width={100}
              />
              <Column
                label="Total Followers"
                dataKey="total_followers"
                cellDataGetter={wholeRowData}
                cellRenderer={({ cellData }) => (
                  <TotalFollowersCell artist={cellData} />
                )}
                width={80}
              />
              <Column
                label="First Seen"
                dataKey="first_seen"
                cellDataGetter={wholeRowData}
                cellRenderer={({ cellData }) => (
                  <FirstSeenCell artist={cellData} />
                )}
                width={100}
              />
            </StyledReactVirtualizedTable>
          );
        }}
      </WindowScroller>
    );
  }
}

const wholeRowData = ({ rowData }) => rowData;

const Filters = props => (
  <StyledSidebarFilters>
    <Searcher
      label="Artist Name"
      value={props.currentFilter.artistName}
      className="filter-name"
      onChange={value => props.filter("artistName", value)}
    />
    {props.allCountries && props.allCountries.length ? (
      <Tagger
        label="Countries"
        domain={props.allCountries}
        selected={props.currentFilter.country_code || []}
        className="filter-countries"
        onChange={value => props.filter("country_code", value)}
      />
    ) : null}
    <CategoryChooser {...props} />
  </StyledSidebarFilters>
);

const RecentFollowsCell = ({ artist, source }) =>
  artist.type === "SUBHEADING" ? null : (
    <div style={{ height: "40px" }}>
      {artist.influencers.slice(0, artist.recent_influencers).map((inf, i) => (
        <Avatar
          key={i}
          data={{ ...inf, type: source, id: inf.id || inf.username }}
          size="big"
        />
      ))}
    </div>
  );

const TotalInfluencersCell = ({ artist, source }) =>
  artist.type === "SUBHEADING" ? null : (
    <div style={{ height: "40px" }}>
      {artist.total_influencers}{" "}
      {artist.influencers.map((inf, i) => (
        <Avatar
          key={i}
          data={{ ...inf, type: source, id: inf.id || inf.username }}
        />
      ))}
    </div>
  );

const TotalFollowersCell = ({ artist }) =>
  artist.type === "SUBHEADING" ? null : (
    <div>{numeral(artist.total_followers).format("0 a")}</div>
  );

const FirstSeenCell = ({ artist }) =>
  artist.type === "SUBHEADING" ? null : (
    <div>
      {moment(artist.first_seen).format("D MMM YYYY")}
      <br />
      <small>
        <i>{moment(artist.first_seen).fromNow()}</i>
      </small>
    </div>
  );

const SubHeadingText = styled.div`
  font-weight: bold;
  font-size: 120%;
`;

export function group_activity(
  state,
  categoryFilter,
  artistNameFilter,
  contryCodesFilter = [],
  influencers
) {
  const { activity, artists } = state;
  if (!activity) {
    return { ...state, rows: [] };
  }

  const allRows = [];
  let totalRecent = 0;
  const categoryCounts = {};

  const seenDays = {};
  const allCategories = {};
  const allCountries = {};
  // initialise these keys, in case we don't actually have any artists that match, as they need to appear in the
  // 'domain' of the tagger anyway
  contryCodesFilter.forEach(code => (allCountries[code] = 0));
  let artistsSeenThisDay = {};
  let artistsThisDay = [];
  let isFirstDay = true;

  const handle_completed_day = () => {
    // sort the day's artists, and append to the master list.
    artistsThisDay.sort((a, b) => {
      if (a.recent_influencers > b.recent_influencers) {
        return -1;
      }
      if (a.recent_influencers < b.recent_influencers) {
        return 1;
      }
      if (a.total_influencers > b.total_influencers) {
        return -1;
      }
      if (a.total_influencers < b.total_influencers) {
        return 1;
      }
      return 0;
    });
    if (!artistsThisDay.length) {
      allRows.splice(allRows.length - 1, 1);
    } else {
      allRows.push(...artistsThisDay);
    }

    // reset these for the next day's:
    artistsSeenThisDay = {};
    artistsThisDay = [];
  };

  function createArtistObject(item) {
    const artist = artists[item.artist_id];

    let all_influencers = [item].concat(item.previous || []);
    const artObj = {
      ...item,
      ...artist,
      type: "ARTIST",
      total_influencers: (item.previous || []).length + 1,
      recent_influencers: 1,

      influencers: all_influencers.reduce((acc, inf) => {
        const infObj = influencers[inf.influencer_id];
        if (infObj) {
          acc.push({
            ...infObj,
            initials: initials(infObj.name),
            colour: categoryColour(infObj.category),
          });
        }
        return acc;
      }, []),
    };
    return artObj;
  }

  activity.forEach((item, rowNum) => {
    const day = moment(item.time)
      .startOf("day")
      .toISOString();

    if (!seenDays[day]) {
      seenDays[day] = day;
      if (rowNum !== 0) {
        isFirstDay = false;
      }
      handle_completed_day();
      allRows.push({ type: "SUBHEADING", title: day });
    }

    const thisCategory = (influencers[item.influencer_id] || {}).category;

    const thisArtistName = (
      (artists[item.artist_id] || {}).name || ""
    ).toLowerCase();
    const thisArtistCountryCode = (
      (artists[item.artist_id] || {}).country_code || "00"
    ).toUpperCase();

    if (isFirstDay) {
      totalRecent++;
      categoryCounts[thisCategory] = (categoryCounts[thisCategory] || 0) + 1;
    }
    const matchesCurrentFilter =
      (!categoryFilter || categoryFilter === thisCategory) &&
      (!artistNameFilter || thisArtistName.indexOf(artistNameFilter) !== -1) &&
      (!contryCodesFilter ||
        !contryCodesFilter.length ||
        contryCodesFilter.indexOf(thisArtistCountryCode) !== -1);

    if (!artistsSeenThisDay[item.artist_id]) {
      const artObj = createArtistObject(item);

      artObj.influencers.forEach(influencer => {
        if (influencer.category) {
          allCategories[influencer.category] = true;
        }
      });

      if (matchesCurrentFilter) {
        artistsSeenThisDay[item.artist_id] = artObj;
        artistsThisDay.push(artObj);
      }
      if (countryMapping[artObj.country_code]) {
        allCountries[artObj.country_code] =
          (allCountries[artObj.country_code] || 0) + 1;
      }
    } else {
      // we've already seen this artist in this group, so just add to the 'recent' influencers
      artistsSeenThisDay[item.artist_id].recent_influencers++; // suck it immutability
    }
  });

  handle_completed_day();

  let categoryObjects = _(allCategories)
    .keys()
    .uniq()
    .sortBy(["name"])
    .map(cat => {
      return {
        name: cat,
        colour: categoryColour(cat),
        recent_count: categoryCounts[cat] || 0,
      };
    })
    .value(); // lodash chain

  return {
    ...state,
    rows: allRows,
    categories: categoryObjects,
    allCountries: _.sortBy(Object.entries(allCountries), entry => entry[0]).map(
      ([code, count]) => {
        const withNumber = `${countryMapping[code]} (${count})`;
        const enhancedDisplay =
          code === "00" ? (
            <i style={{ color: "#b4ac8b" }}>{withNumber}</i>
          ) : (
            withNumber
          );
        return {
          value: code,
          display: countryMapping[code],
          enhancedDisplay,
        };
      }
    ),
    total_recent: totalRecent,
  };
}

export function makeGroupActivitySelect(
  datasource_id,
  pageId,
  pageTitle,
  includeCountry
) {
  const selectInfluencersWithCategory = createSelector(
    state => state.domain.scoutDb.byKey,
    state => state.user.myScouts,
    (scouts, myScouts) => {
      return Object.values(scouts).reduce((acc, s) => {
        if (s.source === datasourceMapping[datasource_id]) {
          acc[s.id] = {
            ...s,
            category: (myScouts[s.key] || {}).category,
          };
        }
        return acc;
      }, {});
    }
  );

  const selectGroupedActivity = createSelector(
    state => state.datasources[datasource_id],
    state => state.ui[datasource_id].currentFilter.categoryFilter,
    state => state.ui[datasource_id].currentFilter.artistName,
    state =>
      includeCountry
        ? state.ui[datasource_id].currentFilter.country_code
        : undefined,
    selectInfluencersWithCategory,
    (
      datasourceState,
      categoryFilter,
      artistName,
      country_code,
      influencersWithCategory
    ) => {
      return group_activity(
        datasourceState,
        categoryFilter,
        artistName,
        country_code,
        influencersWithCategory
      );
    }
  );

  return state => {
    const ui = state.ui[datasource_id];
    const originalSourceState = state.datasources[datasource_id];
    const groupedActivity = selectGroupedActivity(state);
    return {
      currentUser: state.user.currentUser,
      pageId,
      pageTitle,
      moreArtistsUrl: state.datasources.moreArtistsUrl,
      loaded: originalSourceState.loaded,
      loading: originalSourceState.loading,
      categoryFilter: ui.currentFilter.categoryFilter,
      artistName: ui.currentFilter.artistName,
      country_code: ui.currentFilter.country_code,
      currentFilter: ui.currentFilter,
      rows: groupedActivity.rows,
      categories: groupedActivity.categories,
      allCountries: groupedActivity.allCountries,
      total_recent: groupedActivity.total_recent,
    };
  };
}

export default Results;
