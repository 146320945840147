import React, { Component } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {ChartBox} from "./chartselector";

import {StyledChartSelector} from "./chartselector";

import styled from "styled-components";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: 0,
  margin: 0,

  // change background colour if dragging
  // background: isDragging ? "lightgreen" : undefined,

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "lightblue" : "#f5f5f5",
  minHeight: "130px",
  // padding: grid,
  // display: "flex",
  // overflow: "auto"
});


const onDragEnd = (previousSelected, previousAvailable, onChange, result) => {
  const { source, destination } = result;

  // dropped outside the list
  if (!destination) {
    return;
  }

  if (source.droppableId === destination.droppableId && destination.droppableId === 'dropSelected') {
    const items = reorder(
        previousSelected,
        source.index,
        destination.index
    );
    onChange(items);
  } else {
    const result = move(
        source.droppableId === 'dropSelected' ? previousSelected : previousAvailable,
        destination.droppableId === 'dropSelected' ? previousSelected : previousAvailable,
        source,
        destination
    );
    onChange(result['dropSelected']);
  }
};

const Styled = styled.div`
  .dropList {
    margin: 0 10px;
    font-weight: bold;
    font-size: 17px;
    background-color: #f5f5f5;
    padding-top: 5px;
    text-indent: 1em;
    border-bottom: 1px solid #ccc;
    aside {
      display: inline;
      font-size: 9px;
      font-weight: normal;
      margin-left: 10px;
    }
  }
`;

class App extends Component {

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  render() {
    const {allItems, selectedIds, onChangeSelected} = this.props;

    const availableItems = allItems.filter(i => selectedIds.indexOf(i.id) === -1);
    const selectedItems = selectedIds.map(id => allItems.find(i => i.id === id));

    return (
        <Styled>
      <DragDropContext onDragEnd={onDragEnd.bind(null, selectedItems, availableItems, onChangeSelected)}>
        <h3 className="dropList">Your Selected Charts <aside>Drag to reorder, or to the list below to remove</aside></h3>
        <Droppable droppableId="dropSelected" direction="horizontal">
          {(provided, snapshot) => (
            <StyledChartSelector
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {selectedItems.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <ChartBox chart={item} />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </StyledChartSelector>
          )}
        </Droppable>
        <h3 className="dropList">Available Charts <aside>Drag to add to your list above</aside></h3>
        <Droppable droppableId="dropAvailable" direction="horizontal">
          {(provided, snapshot) => (
            <StyledChartSelector
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {availableItems.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <ChartBox chart={item} />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </StyledChartSelector>
          )}
        </Droppable>
      </DragDropContext>
        </Styled>
    );
  }
}

export default App;
